import { Box, Grid, Slider, Stack } from "@mui/material";
import CustomCheckBox from "../../../../../../ui/checkbox/CustomCheckBox";

const Step3 = ({ competitorPositioningAxes, setCompetitorPositioningAxes }) => {
  return (
    <>
      Here are the category brand differentiators that have been identified.
      <br />
      <br />
      Select an even number between four and eight attributes with which we’ll
      plot your brand against its key competitors. Selected attributes become
      the Positioning Axes for your competitive matrix.
      <Grid container mt={2}>
        {competitorPositioningAxes && competitorPositioningAxes.length
          ? competitorPositioningAxes.map((a, idx) => (
              <Grid item lg={3} md={3} sm={4} xs={4}>
                <CustomCheckBox
                  label={a.name || ""}
                  checked={a.checked || false}
                  onchange={(e) => {
                    const data = [...competitorPositioningAxes];

                    if(!e || (e && data.filter((b) => b.checked).length < 8)) {
                      data[idx].checked = e;
                    }

                    setCompetitorPositioningAxes(data);
                  }}
                />
              </Grid>
            ))
          : null}
      </Grid>
      <Box mt={2} fontSize="18px">
        How important is each?
      </Box>
      <Box mt={2}>
        When considering differentiators it helps to know how important they are
        to customers. Give each a weighting where 1 is least important and 10 is
        most important.
      </Box>
      <Stack gap={"10px"} mt="20px">
        {competitorPositioningAxes && competitorPositioningAxes.length
          ? competitorPositioningAxes.map(
              (a, idx) =>
                a.checked && (
                  <Box gap="10px" display="flex">
                    <Box flex="0.5" fontSize="16px">
                      {a.name}
                    </Box>
                    <Box flex="0.5">
                      <Slider
                        color="secondary"
                        value={a.score || 0}
                        min={0}
                        max={10}
                        valueLabelDisplay="auto"
                        step={1}
                        onChange={(_, e) => {
                          const data = [...competitorPositioningAxes];
                          data[idx].score = e;
                          setCompetitorPositioningAxes(data);
                        }}
                      />
                    </Box>
                  </Box>
                )
            )
          : null}
      </Stack>
    </>
  );
};

export default Step3;
