import { toast } from "react-toastify";

export const LOGOUT = "LOGOUT";
export const SET_LOGIN_USER = "SET_LOGIN_USER";
export const SET_TOKENS = "SET_TOKENS";

export const SET_HEADER_TITLE = "SET_HEADER_TITLE";
export const SET_THEME = "SET_THEME";
export const SET_DEFAULT_THEME = "SET_DEFAULT_THEME";
export const SET_THEME_API = "SET_THEME_API";
export const SET_CLIENT_ID = "SET_CLIENT_ID";
export const SET_PROJECT_ID = "SET_PROJECT_ID";
export const SET_LIST_USERS = "SET_LIST_USERS";
export const SET_PROJECT_LOADER = "SET_PROJECT_LOADER";
export const SET_PROJECT_DATA = "SET_PROJECT_DATA";
export const GET_ALL_TEMPLATE = "GET_ALL_TEMPLATE";
export const GET_CLIENT_DASHBOARD = "GET_CLIENT_DASHBOARD";
export const GET_CAT_DATA = "GET_CAT_DATA";
export const SET_SIDEBAR_LAYOUT_DATA = "SET_SIDEBAR_LAYOUT_DATA";
export const SET_ACTIVE_SIDEBAR_SECTION = "SET_ACTIVE_SIDEBAR_SECTION";
export const SET_AGENCIES = "SET_AGENCIES";

export const ERRORMSG = "Something went wrong.!";
export const LOGINMSG = "Login Success!";
export const GENERALMESSAGE = "Action Success!";
export const REGISTERMSG = "Account Created!";
export const TOGGLECLIENTVIEW = "TOGGLECLIENTVIEW";
export const WHITELABELMSG = "White label updated!";
export const COMPANYDETAILSMSG = "company details updated Success!";
export const CLIENT_PROJECT_ADD_MSG = "Client and project added Success!";
export const PROJECT_ADD_MSG = "project added Success!";
export const PERMISSION_ERROR = "You don't have permission!";

const resCodes = [200, 201, 202, 203, 204];

export const apiStatusCheck = (res) =>
  res && res.status && resCodes.find((a) => a === res.status);
export const msgShowFun = (color, msg) => {
  if (msg) {
    if (color) toast.success(msg);
    else toast.error(msg);
  }
};
