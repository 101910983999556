import { Grid } from "@mui/material";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import useBindActionCreator from "../../hooks/useBindActionCreator";
import { getAllProjects } from "../../redux/actions/project/ProjectAction";
import PrivateSidebar from "../layout/PrivateSidebar";
import Loader from "../ui/loader/Loader";
import ProjectRightComponent from "./projectRightComponent/ProjectRightComponent";

const ProjectComponent = () => {
  const loader = useSelector((state) => state.project.loading);
  const actions = useBindActionCreator({ getAllProjects });
  const agenciesUUID = useSelector((state) => state.auth.user?.agencies?.uuid);

  useEffect(() => {
    actions.getAllProjects(agenciesUUID);
  }, []);
  useEffect(() => {}, [loader]);
  return loader ? (
    <Grid pt={"4rem !important"}>
      <Loader />
    </Grid>
  ) : (
    <Grid container>
      <PrivateSidebar />
      <ProjectRightComponent />
    </Grid>
  );
};

export default ProjectComponent;
