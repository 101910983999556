import { Box, Grid, Stack } from "@mui/material";
import WorkshopBrandDifferentiation from "../../../../../../../assets/images/workshop-brand-differentiation.png";
import { makeStyles } from "@mui/styles";
import ArrangeContainer from "./Other/ArrangeContainer";

const useStyle = makeStyles((theme) => ({
  imgc: {
    display: "block",
    margin: "auto",
    maxWidth: "30%",
  },
}));

const Step6 = (props) => {
  const classes = useStyle();

  return (
    <Stack>
      <Box>
        <Box color="secondary.main" fontSize="20px" fontWeight="800px">
          Brand Differentiation
        </Box>
        <Grid container pt={4}>
          <Grid item lg={6} md={7} sm={7} xs={7}>
            <Box marginTop={"30px !important"}>
              {" "}
              Your brand strengths that overlap with your customers' greatest
              needs, and that don't overlap with your competitors' strengths.{" "}
            </Box>
            <br />
            <Box>
              {" "}
              Are there any others that you think should be added to this list?{" "}
            </Box>
            <br />
            <br />
            <Box maxHeight={"400px"} overflow="auto">
              <ArrangeContainer
                placeholder="Brand differentiation name"
                handleBrandDifferentiation={props.handleBrandDifferentiation}
                slides={props.leftSlide}
                setItemOnDrag={(i) => props.setLeftSlide(() => i)}
                switchBox={() => props.switchBox(props.lidx, true)}
                selIdx={props.lidx}
                indentifyStart={props.indentifyStart}
                setIndentifyStart={(e) => props.setIndentifyStart(e)}
                left={true}
                setThisSide={() => props.setThisSide(() => true)}
                thisSide={props.thisSide}
                selectBox={(idx) => props.setLidx(() => idx)}
                onMoveUp={(idx) => props.onMoveUp(idx, true)}
                onMoveDown={(idx) => props.onMoveDown(idx, true)}
              />
            </Box>
            <br />
            <br />
            <Box>
              {" "}
              The above points provide the basis for your Differentiated Brand
              Value Proposition. Discuss these to understand which will have the
              strongest resonance with the core customer, but also which best
              reflect the strengths and passions of the organisation.{" "}
            </Box>
            <br />
          </Grid>
          <Grid item lg={1} md={1} sm={1} xs={1}></Grid>
          <Grid item lg={5} md={4} sm={4} xs={4}>
            <br />

            <img
              className={classes.imgc}
              src={WorkshopBrandDifferentiation}
              alt=""
            />
            <br />
            <Box> Drag and drop your top three brand differentiators </Box>
            <br />
            <ArrangeContainer
              placeholder="Brand differentiation"
              selIdx={props.ridx}
              slides={props.rightSlide}
              left={false}
              indentifyStart={props.indentifyStart}
              setIndentifyStart={(e) => props.setIndentifyStart(e)}
              setThisSide={() => props.setThisSide(() => false)}
              thisSide={props.thisSide}
              switchBox={() => props.switchBox(props.ridx, false)}
              setItemOnDrag={(i) => props.setRightSlide(() => i)}
              selectBox={(idx) => props.setRidx(() => idx)}
              onMoveUp={(idx) => props.onMoveUp(idx, false)}
              onMoveDown={(idx) => props.onMoveDown(idx, false)}
            />
          </Grid>
        </Grid>
      </Box>
    </Stack>
  );
};

export default Step6;
