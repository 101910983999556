import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";

import ExstingRes from "../../../research/RRight/exstingRes/ExstingRes";
import EmptyList from "../../../ui/emptyList/EmptyList";
import RTHeader from "./RTHeader";
import RTList from "./RTList";

const useStyles = makeStyles((theme) => {
  return {
    title: {
      color: theme.palette.secondary.main,
      marginBottom: "20px",
      fontWeight: 600,
      fontSize: "20px",
    },
  };
});

const Research = ({ td, callApi }) => {
  const classes = useStyles();
  const resData = useSelector((state) => state.project?.project?.researches);

  const [checkArr, setCheckArr] = useState([]);
  useEffect(() => {
    if (td && td.data && td.data.selectedResearch) {
      setCheckArr(() => td.data.selectedResearch);
    } else if (resData && resData.length) {
      setCheckArr(() =>
        resData.map((a) => ({
          checked: false,
          name: a.name,
          type: a.researchType,
          uuid: a.uuid,
        }))
      );
    }
  }, [td, resData]);
  const handleSelect = (c, uuid, name, type) => {
    const data = [...checkArr];
    const findIdx = data.findIndex((a) => a.uuid === uuid);
    if (findIdx !== -1) {
      data[findIdx].checked = c;
    } else {
      data.push({
        checked: c,
        name: name,
        type: type,
        uuid: uuid,
      });
    }
    setCheckArr(data);
    callApi({ selectedResearch: data });
  };
  return (
    <Grid container item lg={12} md={12} sm={12} xs={12}>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <div className={classes.title}>Research</div>
      </Grid>
      <RTHeader />
      {resData && resData.length ? (
        resData.map((a) => (
          <RTList
            {...a}
            checked={checkArr.find((e) => e.uuid === a.uuid)?.checked || false}
            handleSelect={(e) =>
              handleSelect(e, a.uuid, a.name, a.researchTypeF)
            }
          />
        ))
      ) : (
        <EmptyList
          color="#D40841"
          text="Add research to your project which help validate your project and recommendations."
        />
      )}
      <ExstingRes />
    </Grid>
  );
};

export default Research;
