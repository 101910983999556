import { Box, Divider, Stack } from "@mui/material";
import { cloneDeep } from "lodash";
import { useSelector } from "react-redux";
import CustomButton from "../../../../../ui/button/CustomButton";

const CreativeBriefSummary = (props) => {
  const projectName = useSelector((state) => state.client?.projectId?.name);
  const clientName = useSelector((state) => state.client?.clientId?.name);
  const projectData = useSelector((state) => state.project.project);

  const data = props?.td?.data || false;

  const brandCustom = () => {
    if (data) {
      const d1 = data.custom;
      const d2 =
        (data.exercises && data.exercises.filter((a) => a.custom)) || [];

      if (d1 && d2) {
        return d2.map((a) => (
          <Box>
            <Box mb={2}>Custom {a.name}</Box>
            <Stack gap="20px" direction="column" flexWrap="wrap" mb={2}>
              {a.questions && a.questions.length
                ? a.questions.map((b) =>
                    d1[b.uuid] ? (
                      <Box flex="0.5">
                        <Box mb="5px" fontSize="18px">
                          {b.name || ""}
                        </Box>
                        {customSelect(
                          b.title,
                          d1[b.uuid],
                          b["Question Multi Choice"]
                        )}
                      </Box>
                    ) : null
                  )
                : null}
            </Stack>
            <Divider />
          </Box>
        ));
      } else return <></>;
    } else {
      return <></>;
    }
  };
  const customSelect = (t, d, h) => {
    switch (t) {
      case "Text Only":
        return <Box fontSize="22px">{d || ""}</Box>;
      case "Question Yes/No":
        return <Box fontSize="22px">{d || ""}</Box>;
      case "Question - Select one":
        return <Box fontSize="22px">{d || ""}</Box>;
      case "Question Multi Choice": {
        const options = d && h && Object.keys(h).filter((a) => d[a]);
        if (options) {
          return (
            <Box fontSize="22px">
              {options.map((a) => (
                <li>{a}</li>
              ))}
            </Box>
          );
        }
      }
      case "Full Width Image":
        return <img src={d} alt={d} />;
      default:
        return <></>;
    }
  };
  const brandPersonality = () => {
    if (data) {
      const d1 =
        data.brandPersonality &&
        data.brandPersonality.personalitySets &&
        data.brandPersonality.personalitySets.filter(
          (a) => a.score && a.summary
        );
      if (d1) {
        return (
          <Box>
            <Box mb={2}>Brand Personality</Box>
            <Stack gap="20px" direction="column" flexWrap="wrap" mb={2}>
              {d1.map((a) => (
                <Box flex="0.5">
                  <Box mb="5px" fontSize="18px">
                    {a?.personalities.join(", ")}
                  </Box>

                  <Box fontSize="22px">{a.summary || "-"}</Box>
                </Box>
              ))}
            </Stack>
            <Divider />
          </Box>
        );
      } else {
        return <></>;
      }
    }
    return <></>;
  };
  const handleSections = () => {
    if (data && data.exercises) {
      const componets = [];
      const d1 = cloneDeep(data.exercises);
      const d2 = d1.filter(
        (a) =>
          !a.custom &&
          a.key !== "business" &&
          a.key !== "brand-personality" &&
          a.key !== "creative-brief-summary"
      );
      if (d2 && d2.length) {
        d2.forEach((a) => {
          if (a.key === "brand-positioning" && data.brandPositioning) {
            componets.push(
              <>
                <Box>Brand Essence</Box>
                <Box display="flex" gap="20px" fontSize="20px">
                  <Box>{data.brandPositioning.brandEssence || ""}</Box>
                </Box>
                <Divider />
                <Box>Brand Values</Box>
                <Box display="flex" gap="20px" fontSize="20px">
                  <Box>{data.brandPositioning.brandValueOne || ""}</Box>
                  <Box>{data.brandPositioning.brandValueTwo || ""}</Box>
                  <Box>{data.brandPositioning.brandValueThree || ""} </Box>
                  <Box>{data.brandPositioning.brandValueFour || ""}</Box>
                </Box>
                <Divider />
              </>
            );
          } else if (
            a.key === "communication-reason" &&
            data.primaryReasonForTheCommunication
          ) {
            componets.push(
              <>
                <Box>Primary reason for the communication</Box>
                <Box fontSize="20px">
                  {data.primaryReasonForTheCommunication || ""}
                </Box>
                <Divider />
              </>
            );
          } else if (
            a.key === "communication-objective" &&
            data.communicationObjective
          ) {
            componets.push(
              <>
                <Box>Communication objective</Box>
                <Box fontSize="20px">{data.communicationObjective || ""}</Box>
                <Divider />
              </>
            );
          } else if (a.key === "keyinsight" && data.keyInsight) {
            componets.push(
              <>
                <Box>Key insight</Box>
                <Box fontSize="20px">{data.keyInsight || ""}</Box>
                <Divider />
              </>
            );
          } else if (a.key === "target-audience" && data.targetAudience) {
            componets.push(
              <>
                <Box mb={2}>Target audience</Box>
                <Box>Core customers</Box>
                <Box fontSize="20px">
                  {data.targetAudience.coreCustomer || ""}
                </Box>
                <Box>What do we want the target audience to think:</Box>
                <Box fontSize="20px">{data.targetAudience.think || ""}</Box>
                <Box>What do we want the target audience to feel:</Box>
                <Box fontSize="20px">{data.targetAudience.feel || ""}</Box>
                <Box>What do we want the target audience to do:</Box>
                <Box fontSize="20px">{data.targetAudience.do || ""}</Box>
                <Divider />
              </>
            );
          } else if (
            a.key === "single-minded-proposition" &&
            data.singleMindedProposition
          ) {
            componets.push(
              <>
                <Box>Single minded proposition</Box>
                <Box fontSize="20px">{data.singleMindedProposition || ""}</Box>
                <Divider />
              </>
            );
          } else if (
            a.key === "target-audience-and-proposition" &&
            data.targetAudienceAndProposition
          ) {
            componets.push(
              <>
                <Box>Target audience and proposition</Box>
                <Box fontSize="20px">
                  {data.targetAudienceAndProposition || ""}
                </Box>
                <Divider />
              </>
            );
          } else if (a.key === "tone-of-voice" && data.toneOfVoice) {
            componets.push(
              <>
                <Box>Tone of voice</Box>
                <Box fontSize="20px">{data.toneOfVoice || ""}</Box>
                <Divider />
              </>
            );
          } else if (a.key === "requirements" && data.requirements) {
            componets.push(
              <>
                <Box>Requirements</Box>
                <Box fontSize="20px">{data.requirements || ""}</Box>
                <Divider />
              </>
            );
          } else if (a.key === "considerations" && data.considerations) {
            componets.push(
              <>
                <Box>Considerations</Box>
                <Box fontSize="20px">{data.considerations || ""}</Box>
                <Divider />
              </>
            );
          } else if (a.key === "deadline" && data.deadline) {
            componets.push(
              <>
                <Box>Deadline</Box>
                <Box fontSize="20px">{data.deadline || ""}</Box>
                <Divider />
              </>
            );
          } else if (
            a.key === "competitors" &&
            projectData &&
            projectData.competitors
          ) {
            const pc = projectData.competitors.filter((a) => a.loaded);
            componets.push(
              <>
                <Box>Competitors</Box>
                {pc.map((a) => (
                  <li
                    style={{
                      fontSize: "20px",
                    }}
                  >
                    {a.name}
                  </li>
                ))}
                <Divider />
              </>
            );
          }
        });
        return componets;
      }
    }
    return [];
  };
  return (
    <>
      <Stack gap="20px" padding="10px">
        <Box>Client: {clientName}</Box>
        <Box>Project: {projectName}</Box>
        <Divider />
        {handleSections()}
        {brandPersonality()}
        {brandCustom()}
      </Stack>

      <Box display="flex" justifyContent="space-between" mt="auto" mb="0">
        <CustomButton name="<   Prev" onclick={() => props.prvStep()} />
        <CustomButton name="Next   >" onclick={() => props.nextStep()} />
      </Box>
    </>
  );
};

export default CreativeBriefSummary;
