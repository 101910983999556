import { api } from "../../api/api";
import { GET_CAT_DATA, SET_LIST_USERS } from "../../constants";

export const getCat = (id) => {
  return async (dispatch) => {
    try {
      const res = await api(`agencies/category/${id}`, "", "get");
      if (res && res.success && res.categories) {
        dispatch({ type: GET_CAT_DATA, payload: { data: res.categories } });
        return res.categories;
      } else return [];
    } catch (error) {}
  };
};

export const getUsers = (id, active = true) => {
  return async (dispatch) => {
    try {
      let url = `users?sort=name&order=asc&agencyId=${id}&active=true`;
      if (!active) {
        url = `users?sort=name&order=asc&agencyId=${id}`;
      }
      const res = await api(url, "", "get");
      if (res && res.success && res.users) {
        dispatch({
          type: SET_LIST_USERS,
          payload: { data: res.users },
        });
      }
    } catch (error) {}
  };
};
