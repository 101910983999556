import { Box, Tab, Tabs } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import Competitors from "../../../projectSettings/competitors/Competitors";
import Research from "../research/Research";
import Business from "../workshop/steps/BrandStregy/Business";
import Customer from "./Customer";
import Intro from "./Intro";

const useStyles = makeStyles((theme) => {
  return {
    container: {
      width: "80%",
    },
  };
});
const selectComponent = (index, toolData, callApi, btnLoading) => {
  switch (index) {
    case 0:
      return <Intro />;
    case 2:
      return <Research td={toolData} callApi={(d) => callApi(d)} />;
    case 3:
      return (
        <Customer
          data={toolData?.data?.coreCustomer}
          callApi={(d) => callApi(d)}
          btnLoading={btnLoading}
        />
      );
    case 1:
      return <Business td={toolData} saveBtn={true} />;
    case 4:
      return <Competitors />;
    default:
      return <Intro />;
  }
};
const Discovery = ({ toolData, callApi, btnLoading }) => {
  const classes = useStyles();
  const [tabs, setTabs] = useState(0);

  return (
    <div className={classes.container}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }} mb={2}>
        <Tabs
          value={tabs}
          onChange={(_, val) => setTabs(val)}
          aria-label="basic tabs example"
          variant="scrollable"
          scrollButtons="auto"
          textColor="secondary"
          indicatorColor="secondary"
        >
          <Tab label="What is discovery?" />
          <Tab label="Your business" />
          <Tab label="Add research" />
          <Tab label="Core customers" />
          <Tab label="Competitors" />
        </Tabs>
      </Box>
      <Box>{selectComponent(tabs, toolData, callApi, btnLoading)}</Box>
    </div>
  );
};

export default Discovery;
