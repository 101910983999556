import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { shallowEqual, useSelector } from "react-redux";
import { useNavigate } from "react-router";

const useStyle = makeStyles((theme) => ({
  headingOuter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  title: {
    fontSize: "28px",
    color: `${theme.palette.secondary.main} !important`,
    marginBottom: "10px",
  },
  settingUrl: {
    fontSize: "28px",
    color: `${theme.palette.secondary.main} !important`,
    marginBottom: "10px",

    cursor: "pointer",
  },
}));

const CommonTitle = ({ isRightLink = false }) => {
  const classes = useStyle();
  const history = useNavigate();

  const { title } = useSelector((state) => state.titleH, shallowEqual);
  return (
    <Grid lg={12} md={12} sm={12} xs={12} mt={0} mb={3}>
      <div className={classes.headingOuter}>
        <div className={classes.title}> {title} </div>
        {isRightLink && (
          <div
            className={classes.settingUrl}
            onClick={() => history("/projects/settings")}
          >
            Settings
          </div>
        )}
      </div>
    </Grid>
  );
};

export default CommonTitle;
