import {
  Button,
  Menu,
  MenuItem,
  Tab,
  Tabs,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import {
  PrivateLandingNav,
  PublicLandingNav,
  publicNavOp,
} from "../../../utils/NavBarConstants";
import NotificationComponent from "./NotificationComponent";
import ProfileComponent from "./ProfileComponent";
import AddClientWithProject from "../modals/AddClientWithProject";

const useStyles = makeStyles((theme) => ({
  tabContainer: {
    marginLeft: "auto",
    paddingRight: "2em",
  },
  tab: {
    ...theme.typography.tab,
    minWidth: "10 !important",
    marginLeft: "25px !important",
  },
}));

const NavTabs = () => {
  const classes = useStyles();

  const matches = useMediaQuery(useTheme().breakpoints.down("lg"));
  const history = useNavigate();
  const loc = useLocation();
  const userRoles = useSelector((state) => state.auth.user.role);
  const clientListing = useSelector((state) => state.project.clients);

  const [tabActive, setTabActive] = useState(loc.pathname);
  const color = useTheme().palette.secondary.main;
  useEffect(() => {
    setTimeout(
      () =>
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        }),
      0
    );
    if (!loc.pathname.includes(tabActive)) setTabActive(loc.pathname);
  }, [loc.pathname]);

  const handleTabChange = (e, value) =>
    value === "/projects" && (!clientListing || !clientListing?.length)
      ? null
      : setTabActive(value);
  const check = publicNavOp.find((a) => a === loc.pathname);
  const op = check ? PublicLandingNav : PrivateLandingNav;

  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [clientModal, setClientModal] = useState(false);
  const handleClick = (event) => {
    setOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handleRequestClose = (a = "") => {
    if (a) {
      if (a === "/projects" && (!clientListing || !clientListing?.length)) {
        setClientModal(true);
      } else history(a);
    }
    setOpen(false);
    setAnchorEl(null);
  };

  return (
    <Tabs
      variant="scrollable"
      value={tabActive}
      className={classes.tabContainer}
      textColor="secondary"
      indicatorColor="secondary"
      TabIndicatorProps={{ style: { background: color } }}
      onChange={handleTabChange}
    >
      {matches ? (
        <>
          <Button
            aria-owns={open ? "simple-menu" : null}
            aria-haspopup="true"
            onClick={handleClick}
            onMouseOver={handleClick}
            className={classes.tab}
            style={{ color: color }}
          >
            Menu
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            open={open}
            onRequestClose={handleRequestClose}
            onClose={handleRequestClose}
          >
            {op.map((a, idx) => {
              if (a.name === "Admin") {
                if (userRoles && userRoles.includes("ADMIN")) {
                  return (
                    <MenuItem
                      key={idx}
                      onClick={() => handleRequestClose(a.path)}
                      style={{ color: color }}
                    >
                      {a.name}
                    </MenuItem>
                  );
                } else {
                  return null;
                }
              }
              return (
                <MenuItem
                  key={idx}
                  onClick={() => handleRequestClose(a.path)}
                  style={{ color: color }}
                >
                  {a.name}
                </MenuItem>
              );
            })}
          </Menu>
        </>
      ) : (
        op.map((a, idx) => {
          if (a.name === "Admin") {
            if (userRoles && userRoles.includes("ADMIN")) {
              return (
                <Tab
                  key={idx}
                  label={a.name}
                  className={classes.tab}
                  style={{ color: color }}
                  onClick={() => handleRequestClose(a.path)}
                  value={a.path}
                />
              );
            } else {
              return null;
            }
          }
          return (
            <Tab
              key={idx}
              label={a.name}
              className={classes.tab}
              style={{ color: color }}
              onClick={() => handleRequestClose(a.path)}
              value={a.path}
            />
          );
        })
      )}
      {!check && <NotificationComponent />}
      {!check && <ProfileComponent />}
      <AddClientWithProject
        open={clientModal}
        onClose={() => setClientModal(false)}
      />
    </Tabs>
  );
};

export default memo(NavTabs);
