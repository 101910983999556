import { Menu, MenuItem } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  menuClass: {
    background: "#f5f5f5 !important",
    fontFamily: theme.typography.fontFamily,
    padding: "4px",
    borderRadius: "10px",
  },
  menuItem: {
    color: `${theme.palette.secondary.main} !important`,
    borderRadius: "10px !important",
    margin: "6px 4px !important",
    fontSize: "14px !important",
    fontWeight: "400 !important",
    fontFamily: theme.typography.fontFamily,
  },
  menuItemSelect: {
    backgroundColor: "white !important",
  },
}));

const DropDownMenu = ({
  enchorEl,
  openP,
  handleClose = () => {},
  handleSelect = () => {},
  options = [],
  val = "",
}) => {
  const classes = useStyles();

  return (
    <Menu
      anchorEl={enchorEl}
      open={openP}
      onClose={handleClose}
      transformOrigin={{ horizontal: "left", vertical: "top" }}
      anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
      classes={{ paper: classes.menuClass }}
    >
      {options.map((a) => (
        <MenuItem
          classes={{ selected: classes.menuItemSelect, root: classes.menuItem }}
          value={a.uuid || a}
          selected={a === val || a.uuid === val}
          onClick={() => handleSelect(a)}
        >
          {a.name || a}
        </MenuItem>
      ))}
    </Menu>
  );
};

export default DropDownMenu;
