import { TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import FormGroupContainer from "./FormGroupContainer";

const useStyles = makeStyles((theme) => ({
  textField: {
    backgroundColor: "white !important",
    borderRadius: "10px !important",
    borderColor: "white !important",
    "& .MuiOutlinedInput-root": {
      borderRadius: "10px !important",
      borderColor: "white !important",
    },
    "& fieldset": {
      borderColor: "white",
      borderRadius: "10px !important",
    },
  },
  textFieldWithBorder: {
    backgroundColor: "white !important",
    borderRadius: "10px !important",
    borderColor: "#D6D6D6 !important",
    border: "1px solid",
    "& .MuiOutlinedInput-root": {
      borderRadius: "10px !important",
      borderColor: "#D6D6D6 !important",
      border: "1px solid",
    },
    "& fieldset": {
      borderColor: "#D6D6D6",
      borderRadius: "10px !important",
      border: "1px solid",
    },
  },
  tf2: {
    backgroundColor: "#F5F5F5 !important",
    borderRadius: "10px !important",
    borderColor: "#F5F5F5 !important",
    "& .MuiOutlinedInput-root": {
      borderRadius: "10px !important",
      borderColor: "#F5F5F5 !important",
    },
    "& fieldset": {
      borderColor: "#F5F5F5",
      borderRadius: "10px !important",
    },
  },
}));

const FormGroupTextField = ({
  type,
  label,
  onChange,
  value,
  placeholder = "",
  tf = false,
  size = "medium",
  fullWidth = true,
  onBlur = () => {},
  onKeyPress = () => {},
  validator = null,
  required = false,
  disabled = false,
  border = false,
  InputProps = {},
  other = {},
  inputProps2 = {},
  handleRef = () => {},
}) => {
  const classes = useStyles();

  return label ? (
    <FormGroupContainer label={label} required={required}>
      {" "}
      <TextField
        inputRef={handleRef}
        type={type}
        size={size}
        classes={{
          root: tf
            ? classes.tf2
            : border
            ? classes.textFieldWithBorder
            : classes.textField,
        }}
        fullWidth={fullWidth}
        disabled={disabled}
        value={value}
        variant="outlined"
        placeholder={placeholder || label}
        onBlur={onBlur}
        onKeyPress={onKeyPress}
        color="secondary"
        onChange={(e) => onChange(e.target.value)}
        InputProps={InputProps}
        inputProps={inputProps2}
        {...other}
      />
      {validator}
    </FormGroupContainer>
  ) : (
    <TextField
      inputRef={handleRef}
      type={type}
      size={size}
      classes={{
        root: tf
          ? classes.tf2
          : border
          ? classes.textFieldWithBorder
          : classes.textField,
      }}
      fullWidth={fullWidth}
      disabled={disabled}
      value={value}
      variant="outlined"
      placeholder={placeholder || label}
      onBlur={onBlur}
      onKeyPress={onKeyPress}
      color="secondary"
      onChange={(e) => onChange(e.target.value)}
      InputProps={InputProps}
      inputProps={inputProps2}
      {...other}
    />
  );
};

export default FormGroupTextField;
