import { Box, Stack } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import CustomButton from "../../../../../ui/button/CustomButton";
import DragDrop from "./DragDrop/DragDrop";
import OpeningScreen from "../OpeningScreen";

const MeetCustomerNeeds = (props) => {
  const [meetingCustomerNeeds, setMeetingCustomerNeeds] = useState([]);
  const updateMeetingCust = async (n) => {
    if (step === 0) {
      if (n) {
        setStep(1);
      } else {
        props.prvStep();
      }
    } else if (step === 1) {
      const res = await props.callApi(
        { customerNeeds: meetingCustomerNeeds },
        n,
        n
      );
      if (!n && res) {
        setStep(0);
      }
    }
  };
  const [step, setStep] = useState(0);
  useEffect(() => {
    if (props?.td?.data && props?.td?.data?.customerNeeds) {
      setMeetingCustomerNeeds(props.td.data.customerNeeds);
    }
  }, [props.td]);
  const validation = () => {
    let disable = false;
    if (step === 1) {
      const c =
        (meetingCustomerNeeds &&
          meetingCustomerNeeds.length &&
          meetingCustomerNeeds.filter((a) => a.loaded).length) ||
        0;
      if (c < 3) {
        disable = true;
      }
    }
    return disable;
  };
  return (
    <>
      {step === 0 ? (
        <OpeningScreen
          title="Meeting Customer Needs"
          description="Achieving alignment between your brand and your customers requires an understanding of what your customers value, and what your customers are looking for."
          img={props?.sas?.img || ""}
        />
      ) : (
        <Stack direction="row" gap="30px">
          <Box flex="0.2" display="flex" gap="10px" flexDirection="column">
            <Box color="secondary.main" fontSize="20px" fontWeight="800px">
              What Do Your Customers Find Most Important?
            </Box>
            <Box mb={2} fontSize="14px">
              What are the things your customers find most important / care most
              passionately about when shopping this category?
            </Box>
          </Box>
          <Box flex="0.8">
            <DragDrop
              data={meetingCustomerNeeds || []}
              btnText="Add New Item"
              rightText="Drag and drop the most important strengths into this shortlist box - ideally between 6-8, but at least 3"
              setItemOnDrag={(data) => setMeetingCustomerNeeds(data)}
            />
          </Box>
        </Stack>
      )}
      <Box display="flex" justifyContent="space-between" mt="auto" mb="0">
        <CustomButton
          name="<   Prev"
          onclick={() => updateMeetingCust(false)}
          loading={props.btnLoading}
        />
        <CustomButton
          name="Next   >"
          onclick={() => updateMeetingCust(true)}
          disabled={validation()}
          loading={props.btnLoading}
        />
      </Box>
    </>
  );
};

export default MeetCustomerNeeds;
