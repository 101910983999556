import { Grid } from "@mui/material";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { api } from "../../../redux/api/api";
import HeaderText from "../HeaderText";
import OList from "./OList";
import TableHeader from "./TableHeader";
import CustomButton from "../../ui/button/CustomButton";

const ovData = [
  {
    name: "Core Customers",
    des: "Who we are targeting",
    route: "/projects/settings/core-customers",
    complete: "0",
    id: "useClientCoreCustomerSettings",
    checked: false,
  },
  {
    name: "Research",
    des: "Proof in the market",
    route: "/projects/settings/research",
    complete: "0",
    checked: false,
    id: "useClientResearchSettings",
  },
  {
    name: "Competitors",
    des: "Who are we competing against?",
    route: "/projects/settings/competitors",
    complete: "0",
    id: "useClientCompetitorsSettings",
    checked: false,
  },
];

const Overview = () => {
  const loc = useNavigate();
  const pdata = useSelector((state) => state.project?.project);
  const [data, setData] = useState(ovData);
  const [btnLoading, setBtnLoading] = useState(false);
  const loadData = () => {
    if (pdata) {
      const dd = [...data];
      dd[0].checked = pdata.useClientCoreCustomerSettings || false;
      dd[1].checked = pdata.useClientResearchSettings || false;
      dd[2].checked = pdata.useClientCompetitorsSettings || false;
      if (pdata.competitors && pdata.competitors.length) {
        dd[2].complete = "100";
      }
      if (pdata.researches && pdata.researches.length) {
        dd[1].complete = "100";
      }
      if (pdata.coreCustomer) {
        let finalNum = 0;
        if (pdata.coreCustomer.includeSecondary) {
          const k = Object.keys(pdata.coreCustomer).length;
          const v = Object.values(pdata.coreCustomer).filter(
            (a) => !isEmpty(a)
          ).length;

          let pr = (100 * v) / k;
          if (pr) finalNum = pr;
        } else {
          const newObj = {};
          const keys = Object.keys(pdata.coreCustomer).filter(
            (a) => !a.includes("secondary")
          );

          for (let i = 0; i < keys.length; i++) {
            newObj[keys[i]] = pdata.coreCustomer[keys[i]];
          }
          const k = Object.keys(newObj).length;
          const v = Object.values(newObj).filter((a) => !isEmpty(a)).length;
          let pr = (100 * v) / k;
          if (pr) finalNum = pr;
        }
        dd[0].complete = Math.round(finalNum);
      }
      setData(() => dd);
    }
  };
  useEffect(() => {
    loadData();
  }, [pdata]);
  const callApi = async (d) => {
    const res = await api(
      `projects/${pdata.uuid}`,
      d,
      "patch",
      "",
      "",
      "Project setting updated!"
    );
    if (res && res.success) {
      pdata[Object.keys(d)[0]] = Object.values(d)[0];
      loadData();
    }
  };
  const setArchieveProject = async () => {
    setBtnLoading(true);
    const res = await api(
      `projects/${pdata.uuid}`,
      {
        archived: true,
      },
      "patch",
      "",
      "",
      "Project added into archived!"
    );
    if (res && res.success) {
      loc("/archive-project");
    }
    setBtnLoading(false);
  };
  return (
    <div>
      <HeaderText name="Overview" />
      <Grid container>
        <TableHeader />
        {data.map((a) => (
          <OList
            {...a}
            key={a.name}
            onLocChange={() => loc(a.route)}
            changeStatus={(e) => callApi({ [a.id]: e })}
          />
        ))}
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <CustomButton
            primary={true}
            loading={btnLoading}
            name="Archive Project"
            size="medium"
            onclick={setArchieveProject}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default Overview;
