import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { api } from "../../../../redux/api/api";
import CustomButton from "../../../ui/button/CustomButton";
import HeaderText from "../HeaderText";
import CopetitorsCard from "./CopetitorsCard";
import { v4 as uuidv4 } from "uuid";

const Competitors = ({ hide = false }) => {
  const id = useParams().id;
  const clientListing = useSelector((state) => state.project.clients);

  const [comp, setComp] = useState([]);

  const [index, setIndex] = useState("");
  const [oldData, setOldData] = useState({});
  useEffect(() => {
    if (clientListing && clientListing.length) {
      const find = clientListing.findIndex((a) => a.uuid === id);
      if (find !== -1) {
        setIndex(find);
        if (clientListing[find].settings) {
          setOldData(clientListing[find].settings);
          if (clientListing[find].settings.competitors) {
            setComp(() => clientListing[find].settings.competitors);
          }
        }
      }
    }
  }, [clientListing]);
  const onDel = async (i) => {
    const newArr = [...comp];
    newArr.splice(i, 1);
    setComp(() => newArr);
    await onUpdate(newArr);
  };
  const changeData = (name, idx, change) => {
    const newArr = [...comp];
    newArr[idx][name] = change;
    setComp(() => {
      return newArr;
    });
  };
  const onAddData = async () => {
    const dd = [...comp];
    dd.push({ name: "", logo: "", uuid: { value: uuidv4() }, loaded: true });
    setComp(() => dd);
    await onUpdate(dd);
  };
  const onUpdate = async (data) => {
    const data1 = { ...oldData, competitors: data || comp };
    const res = await api(
      `clients/${id}`,
      data1,
      "put",
      "",
      "",
      "Client competitors updated!"
    );
    if (res && res.success) {
      clientListing[index].settings = data1;
    }
  };
  return (
    <>
      <HeaderText
        name="Competitors"
        btn={
          hide ? null : (
            <CustomButton
              name="Add Competitors"
              size="medium"
              onclick={() => onAddData()}
            />
          )
        }
      />
      <Grid container>
        {comp.map((a, i) => (
          <CopetitorsCard
            key={i}
            {...a}
            onDel={() => onDel(i)}
            changeData={(n, d) => changeData(n, i, d)}
            onUpdate={() => onUpdate()}
          />
        ))}
      </Grid>
    </>
  );
};

export default Competitors;
