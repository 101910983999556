import { useState, Children, useCallback, useRef } from "react";
import { Box, Divider } from "@mui/material";
import _debounce from "lodash/debounce";
import CustomerPersonas from "./steps/BrandStregy/CustomerPersonas";
import Business from "./steps/BrandStregy/Business";
import Competitors from "./steps/BrandStregy/Competitors";
import CompMaping from "./steps/BrandStregy/CompMaping/CompMaping";
import Custom from "./steps/Custom";
import MarketData from "./steps/BrandStregy/MarketData";
import MeetCustomerNeeds from "./steps/BrandStregy/MeetCustomerNeeds";
import Welcome from "./steps/BrandStregy/Welcome";
import BrandArchType from "./steps/BrandStregy/BrandArchType";
import ElivataroPitch from "./steps/BrandStregy/ElivataroPitch";
import BrandValues from "./steps/BrandStregy/BrandValues/BrandValues";
import BrandPersonality from "./steps/BrandStregy/BrandPersonality/BrandPersonality";
import BrandSummary from "./steps/BrandStregy/BrandSummary";
import BrandPositioning from "./steps/CreativrBrif/BrandPositioning";
import CommunicationReason from "./steps/CreativrBrif/CommunicationReason";
import CreativeBriefSummary from "./steps/CreativrBrif/CreativeBriefSummary";
import CommunicationObjective from "./steps/CreativrBrif/CommunicationObjective";
import Keyinsight from "./steps/CreativrBrif/Keyinsight";
import TargetAudience from "./steps/CreativrBrif/TargetAudience";
import SingleMindedProposition from "./steps/CreativrBrif/SingleMindedProposition";
import TargetAudienceProposition from "./steps/CreativrBrif/TargetAudienceProposition";
import ToneVoice from "./steps/CreativrBrif/ToneVoice";
import Requirements from "./steps/CreativrBrif/Requirements";
import Considerations from "./steps/CreativrBrif/Considerations";
import Deadline from "./steps/CreativrBrif/Deadline";
import MediaBriefSummary from "./steps/MediaBrif/MediaBriefSummary";
import DatePlan from "./steps/MediaBrif/DatePlan";
import OtherRequirements from "./steps/MediaBrif/OtherRequirements";
import Budget from "./steps/MediaBrif/Budget";
import AdvertisingTiming from "./steps/MediaBrif/AdvertisingTiming";
import MarketCovrage from "./steps/MediaBrif/MarketCovrage";
import Objectives from "./steps/MediaBrif/Objectives";
import Creative from "./steps/MediaBrif/Creative";
import CreativeCompetitiveOverview from "./steps/CreativeCompetitiveOverview";
import CompetitiveMapping from "./steps/CompetitiveMapping";
import ScriptTemplate from "./steps/ScriptTemplate";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import BookIcon from "@mui/icons-material/Book";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import moment from "moment";
import TextAutoResize from "../../../ui/formgroup/TextAutoResize";
import CustomButton from "./../../../ui/button/CustomButton";
import { api } from "./../../../../redux/api/api";
import StrengthAndWeakness from "./steps/BrandStregy/StrengthAndWeakness";
import BrandProofPoints from "./steps/BrandStregy/BrandProofPoints";
import BrandEssence from "./steps/BrandStregy/BrandEssence";
import CreativeBriefCompetitor from "./steps/BrandStregy/CreativeBriefCompetitor";
import MediaTargetAudience from "./steps/MediaBrif/MediaTargetAudience";
import ThankYou from "./ThankYou";
import FormGroupTextField from "../../../ui/formgroup/FormGroupTextAreaField";
import AddIcon from "@mui/icons-material/Add";
import axios from "axios";

const selectComponent = (t, type, props, ref) => {
  if (type === "CREATIVE_COMPETITIVE_OVERVIEW") {
    return <CreativeCompetitiveOverview {...props} />;
  } else if (type === "COMPETITIVE_MAPPING") {
    return <CompetitiveMapping {...props} fromCompetitve={true} />;
  } else if (type === "SCRIPT_TEMPLATE") {
    return <ScriptTemplate {...props} />;
  } else {
    switch (t) {
      case "welcome":
        return (
          <Welcome
            {...props}
            sas={props?.sas?.find((a) => a.name === "welcome") || ""}
          />
        );
      case "business":
        return <Business {...props} />;
      case "competitors":
        return type === "CREATIVE_BRIEF" ? (
          <CreativeBriefCompetitor {...props} />
        ) : (
          <Competitors
            {...props}
            sas={props?.sas?.find((a) => a.name === "competitors") || ""}
          />
        );
      case "competitor-mapping":
        return (
          <CompMaping
            {...props}
            sas={props?.sas?.find((a) => a.name === "competitor-mapping") || ""}
          />
        );
      case "meeting-customer-needs":
        return (
          <MeetCustomerNeeds
            {...props}
            sas={
              props?.sas?.find((a) => a.name === "meeting-customer-needs") || ""
            }
          />
        );
      case "market":
        return (
          <MarketData
            {...props}
            sas={props?.sas?.find((a) => a.name === "market") || ""}
          />
        );
      case "customer-personas":
        return <CustomerPersonas {...props} />;
      case "brand-archetypes":
        return (
          <BrandArchType
            {...props}
            sas={props?.sas?.find((a) => a.name === "brand-archetypes") || ""}
          />
        );
      case "strengths-and-weaknesses":
        return (
          <StrengthAndWeakness
            {...props}
            sas={
              props?.sas?.find((a) => a.name === "strengths-and-weaknesses") ||
              ""
            }
          />
        );
      case "elevator-pitch":
        return (
          <ElivataroPitch
            {...props}
            sas={props?.sas?.find((a) => a.name === "elevator-pitch") || ""}
          />
        );
      case "brand-values":
        return (
          <BrandValues
            {...props}
            sas={props?.sas?.find((a) => a.name === "brand-values") || ""}
            ref={ref}
          />
        );
      case "brand-proof-points":
        return (
          <BrandProofPoints
            {...props}
            sas={props?.sas?.find((a) => a.name === "brand-proof-points") || ""}
          />
        );
      case "brand-essence":
        return (
          <BrandEssence
            {...props}
            sas={props?.sas?.find((a) => a.name === "brand-essence") || ""}
          />
        );
      case "brand-personality":
        return (
          <BrandPersonality
            {...props}
            sas={props?.sas?.find((a) => a.name === "brand-personality") || ""}
            ref={ref}
          />
        );
      case "brand-summary":
        return <BrandSummary {...props} />;

      case "brand-positioning":
        return <BrandPositioning {...props} />;
      case "communication-reason":
        return <CommunicationReason {...props} />;
      case "communication-objective":
        return <CommunicationObjective {...props} />;
      case "keyinsight":
        return <Keyinsight {...props} />;
      case "target-audience":
        return type === "MEDIA_BRIEF" ? (
          <MediaTargetAudience {...props} />
        ) : (
          <TargetAudience {...props} />
        );
      case "single-minded-proposition":
        return <SingleMindedProposition {...props} />;
      case "target-audience-and-proposition":
        return <TargetAudienceProposition {...props} />;
      case "tone-of-voice":
        return <ToneVoice {...props} />;
      case "requirements":
        return <Requirements {...props} />;
      case "considerations":
        return <Considerations {...props} />;
      case "deadline":
        return <Deadline {...props} />;
      case "creative-brief-summary":
        return <CreativeBriefSummary {...props} />;

      case "objectives":
        return <Objectives {...props} />;
      case "creative":
        return <Creative {...props} />;
      case "market-coverage-priorities":
        return <MarketCovrage {...props} />;
      case "advertising-timing":
        return <AdvertisingTiming {...props} />;
      case "budget":
        return <Budget {...props} />;
      case "other-requirements":
        return <OtherRequirements {...props} />;
      case "date-plan":
        return <DatePlan {...props} />;
      case "media-brief-summary":
        return <MediaBriefSummary {...props} />;
      default:
        return <Custom {...props} />;
    }
  }
};
const Workshop = (props) => {
  const full = useSelector((state) => state.project.workshop);
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [isShowNotepad, setIsShowNotepad] = useState(true);
  const [note, setNote] = useState("");
  const [notes, setNotes] = useState([]);
  const [thesa, setThesa] = useState("");
  const [thesaList, setThesaList] = useState([]);
  const thesaRef = useRef(null);
  const [currBVStep, setCurrBVStep] = useState(thesaRef?.current?.bSteps);

  useEffect(() => {
    scrollToBottomFun();
  }, [notes]);

  useEffect(() => {
    getAllNotes();
    return () =>
      dispatch({
        type: "TOGGLE_WORKSHOP_EXP",
        payload: { data: false },
      });
  }, []);

  const scrollToBottomFun = () => {
    var chatHistory = document.getElementById("needScroll");
    if (chatHistory?.scrollTop)
      chatHistory.scrollTop = chatHistory?.scrollHeight || 0;
  };

  const getAllNotes = () => {
    api(`tools/${props?.td?.uuid}/notes`, {}, "get", "", "", "")
      .then((res) => {
        if (res && res.success) {
          setNotes(res.toolNotes);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handleDebounceFn = async (inputValue) => {
    if (inputValue) {
      const res = await axios.get(
        `https://api.datamuse.com/words?ml=${inputValue}&max=5`,
        {
          "Content-Type": "application/json",
        }
      );
      if (res && Array.isArray(res.data)) setThesaList(res.data);
    } else {
      setThesaList([]);
    }
  };

  const debounceFn = useCallback(_debounce(handleDebounceFn, 500), []);

  const handleSendNote = async () => {
    if (note) {
      setLoading(true);
      try {
        const payload = {
          step: props.tk.name || "",
          email: user.email,
          comment: note,
        };
        const res = await api(
          `tools/${props?.td?.uuid}/notes`,
          payload,
          "post",
          "",
          "",
          "Note Added Sucessfully!"
        );
        if (res && res.success) {
          setNote("");
          setNotes([...notes, res]);
        }
        setLoading(false);
      } catch (err) {
        console.log("err", err);
        setLoading(false);
      }
    }
  };
  const checkTypeBrand = props?.td?.type === "BRAND_STRATEGY";
  const checkForNotePad = isShowNotepad && !full && checkTypeBrand;
  return (
    <Box>
      {checkTypeBrand && (
        <Box
          color="secondary.main"
          mb={2}
          fontSize="20px"
          fontWeight="800px"
          display="flex"
          gap="20px"
        >
          <Box mt="auto" mb="auto">
            {" "}
            {props?.tk?.name || ""}
          </Box>

          <Box>
            {full ? (
              <FullscreenExitIcon
                style={{
                  cursor: "pointer",
                }}
                fontSize="large"
                onClick={() =>
                  dispatch({
                    type: "TOGGLE_WORKSHOP_EXP",
                    payload: { data: false },
                  })
                }
              />
            ) : (
              <FullscreenIcon
                style={{
                  cursor: "pointer",
                }}
                fontSize="large"
                onClick={() =>
                  dispatch({
                    type: "TOGGLE_WORKSHOP_EXP",
                    payload: { data: true },
                  })
                }
              />
            )}
          </Box>
          {!full && (
            <Box mr="0" ml="auto" sx={{ cursor: "pointer" }}>
              <BookIcon
                fontSize="large"
                onClick={() => setIsShowNotepad(!isShowNotepad)}
              />
            </Box>
          )}
        </Box>
      )}
      <Box gap="20px" display="flex">
        <Box
          style={{
            opacity: 1,
            background:
              props?.td?.type === "CREATIVE_COMPETITIVE_OVERVIEW"
                ? "transparent"
                : "#F5F5F5",
          }}
          padding="20px"
          minHeight="359px"
          borderRadius="10px"
          display="flex"
          flexDirection="column"
          width={checkForNotePad === "BRAND_STRATEGY" ? "70%" : "100%"}
        >
          {(props.tk || props?.td?.type) &&
            selectComponent(props?.tk?.key, props?.td?.type, props, thesaRef)}
        </Box>
        {checkForNotePad ? (
          <Box
            style={{
              opacity: 1,
              background: "#F5F5F5",
            }}
            width="30%"
            borderRadius="10px"
            padding="20px"
          >
            <Box
              color="secondary.main"
              fontSize="20px"
              fontWeight="800px"
              style={{ marginBottom: "20px" }}
            >
              Notepad
            </Box>
            <Box
              height={notes && notes.length ? "400px" : 0}
              minHeight={0}
              style={{ overflowX: "hidden" }}
              id="needScroll"
            >
              {notes && notes.length
                ? Children.toArray(
                    notes.map((note, ind) => {
                      return (
                        <Box
                          style={{
                            marginBottom: "10px",
                            padding: "10px",
                            lineHeight: "20px",
                            backgroundColor: ind % 2 === 0 ? "#fff" : "#f5f5f5",
                          }}
                        >
                          <Box style={{ fontSize: "18px", fontWeight: "bold" }}>
                            {note.email}
                          </Box>
                          <Box marginBottom="10px">
                            {moment(note.dateCreated).format(
                              "DD/MM/YYYY, hh:mm A"
                            )}
                          </Box>
                          <Box marginBottom="10px">{note.step}</Box>
                          <Box>{note.comment}</Box>
                        </Box>
                      );
                    })
                  )
                : null}
            </Box>
            <Box style={{ marginTop: "20px" }}>
              <TextAutoResize
                label=""
                other={{ minRows: 6, maxRows: 6 }}
                value={note}
                onChange={(e) => setNote(e)}
              />
            </Box>
            <Box display="flex" justifyContent="flex-end" mt="20px">
              <CustomButton
                size="medium"
                name="Send"
                loading={loading}
                disabled={note ? false : true}
                onclick={handleSendNote}
              />
            </Box>
            {props?.tk?.key === "brand-values" || props?.tk?.key === "brand-personality" ? (
              <>
                <Box mt={4} mb={4}>
                  <Divider variant="fullWidth" />
                </Box>
                <Box
                  color="secondary.main"
                  fontSize="20px"
                  fontWeight="800px"
                  style={{ marginBottom: "10px" }}
                >
                  Need inspiration?
                </Box>
                <Box mb={3}>Try our thesaurus</Box>
                <Box mb={2}>
                  <FormGroupTextField
                    label=""
                    value={thesa}
                    onChange={(e) => {
                      setThesa(e);
                      debounceFn(e);
                    }}
                  />
                </Box>
                <Box
                  style={{
                    marginBottom: "10px",
                    padding: "10px",
                    backgroundColor: thesaList.length > 0 ? "#fff" : "#f5f5f5",
                  }}
                >
                  {thesaList && thesaList.length
                    ? Children.toArray(
                        thesaList.map((el, ind) => {
                          return (
                            <>
                              <Box
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  if(props?.tk?.key === "brand-values") thesaRef?.current?.bValues(el.word);
                                  if(props?.tk?.key === "brand-personality") thesaRef?.current?.descriptors(el.word);
                                  setCurrBVStep(thesaRef?.current?.bSteps);
                                }}
                              >
                                <Box
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "400",
                                  }}
                                >
                                  {el.word}
                                </Box>
                                <Box>
                                  <AddIcon />
                                </Box>
                              </Box>
                              <Box mt={1} mb={1}>
                                <Divider variant="fullWidth" />
                              </Box>
                            </>
                          );
                        })
                      )
                    : null}
                </Box>
              </>
            ) : null}
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};

export default Workshop;
