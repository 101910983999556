import { Box, Grid, Stack } from "@mui/material";
import WorkshopNoWinZone from "../../../../../../../assets/images/workshop-no-win-zone.png";
import { makeStyles } from "@mui/styles";
import FormGroupTextField from "../../../../../../ui/formgroup/FormGroupTextField";

const useStyle = makeStyles((theme) => ({
  imgc: {
    display: "block",
    margin: "auto",
    maxWidth: "50%",
  },
}));

const Step5 = (props) => {
  const classes = useStyle();

  return (
    <Stack>
      <Box>
        <Box color="secondary.main" fontSize="20px" fontWeight="800px">
          No Win Zone
        </Box>
        <Grid container pt={4}>
          <Grid item lg={7} md={8} sm={8} xs={8}>
            <Box marginTop={"30px !important"}>
              {" "}
              The overlap between your brand strengths and your competitors' greatest strengths, but without addressing your customers' most important needs.{" "}
            </Box>
            <br />
            <Box>
              {" "}
              Are there any others that you think should be added to this list?{" "}
            </Box>
            <br />
            <br />
            <Stack maxHeight={"400px"} overflow="auto" pr={2} gap={2}>
              {props.noWinZoneData &&
                props.noWinZoneData.map((item, index) => (
                  <FormGroupTextField
                    key={item.uuid}
                    placeholder="No win zone"
                    type="text"
                    value={item.name}
                    onChange={(e) =>
                      props.handleNoWinZoneData(index, "name", e)
                    }
                  />
                ))}
            </Stack>
            <br />
            <br />
            <Box>
              {" "}
              Not only are these the strengths that you share with your
              competitors, they are the things that your customers do not find
              important. Don't waste any energy building a brand proposition
              around these, no matter how many of and how often your competitors
              may talk about them.{" "}
            </Box>
            <br />
          </Grid>
          <Grid item lg={5} md={4} sm={4} xs={4}>
            <img className={classes.imgc} src={WorkshopNoWinZone} alt="" />
          </Grid>
        </Grid>
      </Box>
    </Stack>
  );
};

export default Step5;
