import FormGroupContainer from "./FormGroupContainer";
import { makeStyles } from "@mui/styles";
import { MuiTelInput } from "mui-tel-input";

const useStyles = makeStyles((theme) => ({
  textField: {
    backgroundColor: "white !important",
    borderRadius: "10px !important",
    borderColor: "white !important",
    "& fieldset": {
      borderColor: "white",
      borderRadius: "10px !important",
    },
  },
}));

const CustomPhone = ({
  label,
  onChange,
  value,
  size = "medium",
  fullWidth = true,
  onBlur = () => {},
  validator = null,
  required = false,
  disabled = false,
  customClass = "",
}) => {
  const classes = useStyles();

  return (
    <FormGroupContainer required={required} label={label}>
      <MuiTelInput
        size={size}
        defaultCountry="AU"
        classes={{ root: classes.textField }}
        fullWidth={fullWidth}
        className={customClass}
        disabled={disabled}
        value={value}
        variant="outlined"
        placeholder={label}
        onBlur={onBlur}
        color="secondary"
        onChange={(e, details) => onChange(e, details)}
      />
      {validator}
    </FormGroupContainer>
  );
};

export default CustomPhone;
