import { Box, Stack } from "@mui/material";
import DragDrop from "../BrandStregy/DragDrop/DragDrop";

const Competitors = ({ setCompetitor, competitor }) => {
  return (
    <>
      {
        <Stack direction="row" gap="30px">
          <Box flex="0.2" display="flex" gap="10px" flexDirection="column">
            <Box color="secondary.main" fontSize="20px" fontWeight="800px">
              Understanding What The Business Does
            </Box>
            <Box mb={2} fontSize="14px">
              All brands live in the context of their competitors. The way a
              customers (client) understands a brand’s positioning is relative
              to how it’s biggest competitors are positioned.
              <br />
              <br />
              For example a prestige car may be priced at $70,000 based on
              competitor set, where the equivalent size and spec’d Korean car
              may be priced at only $30,000.
              <br />
              <br />
              Price is just one dimension of brand positioning that sets the
              competitive context. Other dimensions include quality, design,
              performance, reliability, etc.
            </Box>
          </Box>
          <Box flex="0.8" mb={2}>
            <DragDrop
              leftText={
                <Box mr={2} mb={1}>
                  Who do you consider your top 3 - 10 competitors to be?{" "}
                </Box>
              }
              data={competitor || []}
              btnText="Add New Competitor"
              rightText="Drag and drop at least 3 competitors"
              setItemOnDrag={(d) => setCompetitor(d)}
            />
          </Box>
        </Stack>
      }
    </>
  );
};

export default Competitors;
