import { Divider, Grid, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CustomButton from "../../ui/button/CustomButton";
// import CustomCheckBox from "../../ui/checkbox/CustomCheckBox";

const useStyles = makeStyles((theme) => {
  return {
    res: {
      fontSize: "22px",
      color: theme.palette.secondary.main,
      textDecoration: "underline",
      marginBottom: "6px",
      cursor: "pointer",
    },
    inner: {
      backgroundColor: theme.palette.primary.main,
      height: "20px",
    },
    outer: {
      backgroundColor: "#f5f5f5",
      width: "100%",
      height: "20px",
    },
  };
});

const OList = (props) => {
  const classes = useStyles();
  return (
    <>
      <Grid item lg={4} md={4} sm={4} xs={4}>
        <div className={classes.res} onClick={props.onLocChange}>
          {props.name}
        </div>
        <div className={classes.provider}>{props.des}</div>
      </Grid>
      {/* <Grid item lg={2} md={3} sm={3} xs={3}>
       <CustomCheckBox
          checked={props.checked}
          onchange={(e) => props.changeStatus(e)}
        /> 
      </Grid> */}
      <Grid item lg={7} md={6} sm={6} xs={6} pr={6}>
        <Box fontSize="20px" mb={1.2}>
          {props.complete} %
        </Box>
        <div className={classes.outer}>
          <div
            className={classes.inner}
            style={{ width: `${props.complete}%` }}
          ></div>
        </div>
      </Grid>
      <Grid item lg={1} md={2} sm={2} xs={2} margin="auto">
        <CustomButton
          name="Edit"
          classNameI="grey3"
          size="large"
          onclick={props.onLocChange}
        />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} mb={2} mt={2}>
        <Divider variant="fullWidth" />
      </Grid>
    </>
  );
};

export default OList;
