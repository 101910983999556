import { Box, Stack } from "@mui/material";

const EmptyList = ({ color, text }) => {
  return (
    <Stack
      direction="row"
      justifyContent="center"
      border={`1px solid ${color}`}
      borderRadius="10px"
      height="100px"
      width="100%"
    >
      <Box m="auto">{text}</Box>
    </Stack>
  );
};

export default EmptyList;
