export const config = {
  AWS_S3_REGION: "US_EAST_1",
  AWS_S3_MEDIA_BUCKET: "https://defxr55tfy31u.cloudfront.net/",

  RECAPTCHA_SITE_KEY: process.env.REACT_APP_CAPTCHA_SITE_KEY,

  passValiadtion: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{10,}$/,
  
  dwdPath: process.env.REACT_APP_FILES_PATH,
  stripePublishableKey: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,

  generatePathDownloadPath: function (name = "") {
    if (name.includes("https")) {
      return name;
    }
    return this.dwdPath + name;
  },
};
