import { Box, Stack } from "@mui/material";

const Step3 = ({ data, otherDescriptors, otherSummary }) => {
  return (
    <Stack gap="10px">
      {data.map((a) => (
        <Box mb="10px">
          <Box fontWeight="600">{a.summary || '-'}</Box>
          <Box mb="10px">{a.personalities.join(", ")}</Box>
        </Box>
      ))}

      {otherSummary && otherDescriptors ? (
        <Box mb="10px">
          {otherSummary && <Box fontWeight="600">{otherSummary}</Box>}
          <Box mb="10px">{otherDescriptors.split(" ").join(", ")}</Box>
        </Box>
      ) : null}
    </Stack>
  );
};

export default Step3;
