import FormGroupContainer from "./FormGroupContainer";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { MenuItem, Select } from "@mui/material";
import { makeStyles } from "@mui/styles";
const useStyles = makeStyles((theme) => ({
  select: {
    backgroundColor: "white !important",
    borderRadius: "10px !important",
    borderColor: "white !important",
    "& fieldset": {
      borderColor: "white",
      borderRadius: "10px !important",
    },
  },
  selectWithBorder: {
    backgroundColor: "white !important",
    borderRadius: "10px !important",
    borderColor: "#D6D6D6 !important",
    "& fieldset": {
      borderColor: "#D6D6D6",
      borderRadius: "10px !important",
    },
  },
}));

const CustomSelect = ({
  label,
  onChange,
  value,
  onBlur,
  validator,
  customClass = "",
  options = [],
  size = "medium",
  fullWidth = true,
  required = false,
  disabled = false,
  isMulti = false,
  variant = "outlined",
  id = false,
  border = false,
  isDefaultNone = false,
}) => {
  const usePlaceholderStyles = makeStyles((theme) => ({
    placeholder: {
      color: "#aaa",
    },
  }));
  const Placeholder = ({ children }) => {
    const classes = usePlaceholderStyles();
    return <div className={classes.placeholder}>{children}</div>;
  };
  const classes = useStyles();
  return label ? (
    <FormGroupContainer required={required} label={label}>
      <Select
        renderValue={
          value !== "" ? undefined : () => <Placeholder>{label}</Placeholder>
        }
        color="secondary"
        disabled={disabled}
        multiple={isMulti}
        value={value || "None"}
        onChange={(e) => onChange(e.target.value)}
        onBlur={onBlur}
        IconComponent={KeyboardArrowDownIcon}
        fullWidth={fullWidth}
        size={size}
        className={`${
          border ? classes.selectWithBorder : classes.select
        } ${customClass}`}
        MenuProps={{
          sx: {
            maxHeight: "200px !important",
          },
        }}
      >
        {isDefaultNone ? <MenuItem value="None">None</MenuItem> : null}
        {options &&
          options.map((e, idx) => (
            <MenuItem value={id ? e.uuid : e} key={id ? e.uuid : e}>
              {e.name || e}
            </MenuItem>
          ))}
      </Select>
      {validator}
    </FormGroupContainer>
  ) : (
    <Select
      color="secondary"
      disabled={disabled}
      multiple={isMulti}
      value={value || "None"}
      onChange={(e) => onChange(e.target.value)}
      onBlur={onBlur}
      IconComponent={KeyboardArrowDownIcon}
      fullWidth={fullWidth}
      size={size}
      variant={variant}
      className={`${
        border ? classes.selectWithBorder : classes.select
      } ${customClass}`}
      MenuProps={{
        sx: {
          maxHeight: "200px !important",
        },
      }}
    >
      {isDefaultNone ? <MenuItem value="None">None</MenuItem> : null}
      {options &&
        options.map((e, idx) => (
          <MenuItem value={id ? e.uuid : e} key={id ? e.uuid : e}>
            {e.name || e}
          </MenuItem>
        ))}
    </Select>
  );
};

export default CustomSelect;
