import ProjectRightHeader from "../ProjectRightHeader";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import CollapseWithGrid from "../../../ui/customCollapse/CollapseWithGrid";
import { useEffect, useState } from "react";
import EmptyList from "../../../ui/emptyList/EmptyList";
import CustomButton from "../../../ui/button/CustomButton";
import { useNavigate } from "react-router";
import {
  Chip,
  Grid, ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Loader from "../../../ui/loader/Loader";
import { api } from "../../../../redux/api/api";
import { useSelector } from "react-redux";
import moment from "moment";
import { config } from "../../../../config";
import {Box} from "@mui/system";

const useStyles = makeStyles((theme) => ({
  artImg: {
    width: "100%",
    maxWidth: "100%",
    maxHeight:"100%",
    height:"auto",
    objectFit:"contain",
    cursor: "pointer",
  },
  res: {
    fontSize: "18px",
    color: theme.palette.secondary.main,
    textDecoration: "underline",
    cursor: "pointer",
    marginTop: "10px",
  },
  nameDate: {
    fontSize: "16px",
    marginTop: "4px",
    paddingRight: "4px",
  },
  artImageWrapper: {

   position: "relative"
  },
  artBadge: {
    position: "absolute",
    left:"5px",
    bottom:"5px",
    borderRadius: '5px'

  },
  artworkToggleButtonGroup:{
    border: `1px solid ${theme.palette.secondary.main}`,
    padding: '4px',

    '& .MuiButtonBase-root':{
      border:'none',
      color: theme.palette.secondary.main,

      '&.Mui-selected':{
        color: '#fff',
        backgroundColor: theme.palette.secondary.main,

        '&:hover':{
          backgroundColor: theme.palette.secondary.main,
          color: '#fff',
        }
      },

    }
  },

}));

const FeedbackSection = (props) => {
  const loc = useNavigate();
  const classes = useStyles();
  const projectId = useSelector((state) => state.project.project.uuid);
  const [expand, setExpand] = useState(true);
  const [pageLoading, setPageLoading] = useState(false);
  const [artWorks, setArtWorks] = useState([]);
  const theme = useTheme();
  const [view, setView] = useState('all');

  useEffect(() => {
    setPageLoading(true);
    if (projectId) {
      getArtworks();
    }
  }, [loc]);

  const getArtworks = () => {
    api(`artwork/list/${projectId}`, {}, "get", "", "")
      .then((res) => {
        if (res.success) {
          setArtWorks(res.artworks);
        }
        setPageLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
        setPageLoading(false);
      });
  };

  const handleViewChange = (event, newView) => {
    if (newView !== null) {
      setView(newView); // Update the view based on toggle selection
    }
  };

  const filteredArtworks = view === 'approved'
      ? artWorks.filter(art => art.status === 'APPROVED')
      : artWorks;

  return (
    <>
      <ProjectRightHeader
        title="Feedback Pro"
        mt="2rem"
        setCollsapn={(a) => setExpand(a)}
        isHideExpend={true}

      />

      <Box width={1} padding={4} textAlign={"center"} bgcolor={"#edf6f7"} border={"1px solid #c9e2e6"} borderRadius={5} mb={4}>
        <Box mb={4}>
          <Typography gutterBottom sx={{ color: theme.palette.secondary.main, fontSize: 20 }}>
            Upload artwork files and share them with your clients or team for easy feedback
          </Typography>
          <Typography gutterBottom sx={{ color: theme.palette.secondary.main, fontSize: 14 }}>
            Upload revisions to replace previous versions while retaining access to all comments and files.
          </Typography>
        </Box>

          <CustomButton
              name="Add artwork"
              size="large"
              endIcon={<AddCircleOutlineRoundedIcon  sx={{ color: "#F2D9E7"}}/>}
              onclick={() => loc("/projects/artwork-people")}
          />

      </Box>

      <ToggleButtonGroup
          value={view}
          className={classes.artworkToggleButtonGroup}
          exclusive
          onChange={handleViewChange}
          aria-label="artwork view"
          sx={{ marginBottom: 2,  }}
      >
        <ToggleButton className='artworkToggleButton' value="all" aria-label="view all">
          All Artworks
        </ToggleButton>
        <ToggleButton value="approved" aria-label="view approved">
          Approved only
        </ToggleButton>
      </ToggleButtonGroup>

      <CollapseWithGrid expand={expand}>
        {pageLoading ? (
          <Loader />
        ) : artWorks.length > 0 ? (
            filteredArtworks.map((el) => (
            <Grid
              item
              lg={3}
              md={4}
              sm={6}
              xs={12}
              pr={2}
              pb={3}
              margin="auto 0"
            >
              <Box borderRadius={2} display="flex" gap="16px" className={classes.artImageWrapper} flexWrap="wrap" height={200}  bgcolor="#F5F5F5" p={1} >

                <img
                    alt="Artwork Img"
                    src={`${config.dwdPath}${el.previewImage}`}
                    className={classes.artImg}
                    onClick={() => loc(`/projects/review-feedback?aid=${el.uuid}`)}
                    style={{ objectFit: "contain" }}  // Ensure the image fits within the box
                />

                <Chip className={classes.artBadge}
                      label={el.status === 'NEW' ? 'In review' : 'Approved'}
                      variant="primary"
                      size={"small"}
                      sx={{
                        backgroundColor: el.status === 'NEW' ? '#FFED71' : '#C4ECC6',
                        color: theme.palette.secondary.main,
                      }}
                />

              </Box>

              <div
                  className={classes.res}
                  onClick={() => loc(`/projects/review-feedback?aid=${el.uuid}`)}
              >
                {el?.name || "-"}
              </div>
              <Box sx={{color: theme.palette.secondary.sub}}>
                <div className={classes.nameDate} >
                  {`${
                      el?.status === "APPROVED" ? "Approved " : "Last feedback: "
                  }${moment(el.dateModified).fromNow(true)} ago` || "-"}
                </div>
              </Box>

            </Grid>
            ))
        ) : (
            <EmptyList color="#F19C7E" text="No Feedback Found."/>
        )}
      </CollapseWithGrid>
    </>
  );
};

export default FeedbackSection;
