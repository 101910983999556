export const getMarginFromValue = (value = 0) => {
  if (value === 10) return "0%";
  if (value === 9) return "11%";
  if (value === 8) return "22%";
  if (value === 7) return "33%";
  if (value === 6) return "44%";
  if (value === 5) return "55%";
  if (value === 4) return "66%";
  if (value === 3) return "77%";
  if (value === 2) return "88%";
  if (value === 1) return "100%";

  return "100%";
};
