import { Box, Grid, Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CustomDropzone from "../../../../../ui/dropzone/CustomDropzone";
import { ReactComponent as Upload } from "../../../../../../assets/images/upload.svg";
import { awsUpload } from "../../../../../../utils/s3upload";
import { useState } from "react";
import { ClipLoader } from "react-spinners";
import ImgDelContainer from "../../../../../ui/imgDelContainer/ImgDelContainer";
import TextAutoResize from "../../../../../ui/formgroup/TextAutoResize";
import { config } from "../../../../../../config";

const useStyle = makeStyles((theme) => ({
  profileContainer: {
    background: "#F5F5F5",
    padding: "20px",
    opacity: 1,
    borderRadius: "10px",
  },
  icon: {
    fill: theme.palette.secondary.main,
    height: "84px",
    width: "84px",
    margin: "auto",
  },
  imgContainer: {
    display: "flex",
    width: "100%",
    height: "190px",
    background: "#F2D9E7",
    borderRadius: "10px",
  },
  img: {
    maxWidth: "100%",
    maxHeight: "190px",
    margin: "auto",
  },
  imgc: {
    height: "188px !important",
    maxWidth: "100% !important",
    maxHeight: "188px !important",
    display: "flex",
    borderRadius: "10px",
    [theme.breakpoints.down("lg")]: {
      maxWidth: "260px",
    },
  },
  mediaLabel: {
    display: "block",
    marginBottom: "8px",
  },
  textArea: {
    marginTop: "20px !important",
  },
}));
const CompetitorsCard = ({ name, review, media, logo, changeData }) => {
  const classes = useStyle();
  const [loading, setLoading] = useState(false);
  const handleDrop = async (file) => {
    setLoading(true);
    const fileData = new FormData();
    fileData.append("file", file);
    const fd = await awsUpload(fileData, true);
    if (fd) {
      changeData("media", fd);
    }
    setLoading(false);
  };
  return (
    <Grid item lg={4} md={4} xs={2} sm={2}>
      <Stack gap={2} bgcolor="#F5F5F5" borderRadius="10px" padding="20px">
        <Box fontSize="28px">{name}</Box>
        {logo ? (
          <Box>
            <label className={classes.mediaLabel}>Logo</label>
            <img
              alt={config.generatePathDownloadPath(logo)}
              src={config.generatePathDownloadPath(logo)}
              className={classes.imgc}
            />
          </Box>
        ) : null}
        <Box>
          {loading ? (
            <ClipLoader />
          ) : (
            <>
              <label className={classes.mediaLabel}>Media</label>
              <CustomDropzone
                isMulti={false}
                maxSize={1000000}
                handleDrop={(e) => {
                  if (e && e[0]) handleDrop(e[0]);
                }}
                accept={{
                  "image/png": [".png", ".jpg"],
                }}
                component={
                  media ? (
                    <div className={classes.imgContainer1}>
                      <ImgDelContainer
                        src={config.generatePathDownloadPath(media)}
                        className={classes.imgc}
                        handleDel={() => changeData("media", "")}
                      />
                    </div>
                  ) : (
                    <div className={classes.imgContainer}>
                      <Upload className={classes.icon} />
                    </div>
                  )
                }
              />
            </>
          )}
        </Box>

        <TextAutoResize
          placeholder="Review"
          value={review}
          other={{ minRows: 6, maxRows: 6 }}
          onChange={(e) => changeData("review", e)}
        />
      </Stack>
    </Grid>
  );
};

export default CompetitorsCard;
