import {
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import SearchIcon from "@mui/icons-material/Search";
import ClientContainer from "./ClientContainer";
import { useNavigate } from "react-router";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import ClientCalender from "./ClientCalender";
import Loader from "../../ui/loader/Loader";
import useBindActionCreator from "../../../hooks/useBindActionCreator";
import {
  getAllTools,
  getClientPageClients,
  searchFromProjects,
} from "../../../redux/actions/project/ProjectAction";
import { useCallback } from "react";
import _debounce from "lodash/debounce";
import { api } from "../../../redux/api/api";
import EmptyList from "../../ui/emptyList/EmptyList";

const useStyles = makeStyles((theme) => ({
  textField: {
    backgroundColor: "#F5F5F5 !important",
    borderRadius: "10px !important",
    borderColor: "#F5F5F5 !important",
    "& .MuiOutlinedInput-root": {
      borderRadius: "10px !important",
      borderColor: "#F5F5F5 !important",
    },
    "& fieldset": {
      borderColor: "#F5F5F5",
      borderRadius: "10px !important",
    },
  },
  pcount: {
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));
let storeLen = 0;
const ClientDashboard = () => {
  const classes = useStyles();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("lg"));

  const loc = useNavigate();
  const actions = useBindActionCreator({
    getAllTools,
    getClientPageClients,
    searchFromProjects,
  });
  const agenciesUUID = useSelector((state) => state.auth.user?.agencies?.uuid);

  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [ploader, setPloader] = useState(false);
  const cview = useSelector((state) => state.client.view);
  const data = useSelector((state) => state.project.clientsDash);
  const sd = useSelector((state) => state.project.clientProjectSearch);
  const [len, setLen] = useState(storeLen);
  const searchData = useMemo(() => {
    return sd && sd.length ? sd : [];
  }, [sd]);

  const loadArchive = async () => {
    const res = await api(
      `projects/lite/${agenciesUUID}?sort=dateLastActivity&order=desc&active=false`,
      "",
      "get"
    );
    if (res && res.success) {
      storeLen = res?.projects?.length || 0;
      setLen(res?.projects?.length || 0);
    }
  };
  useState(() => {
    loadArchive();
  }, []);
  const getData = async () => {
    setLoading(() => true);
    await actions.getClientPageClients();
    setLoading(() => false);
    actions.getAllTools(agenciesUUID);
  };
  useEffect(() => {
    getData();
  }, []);
  const callSearch = async (a) => {
    setPloader(() => true);
    await actions.searchFromProjects(a);
    setPloader(() => false);
  };
  const handleDebounceFn = async (inputValue) => {
    if (inputValue) {
      await callSearch(inputValue);
    } else {
      getData();
    }
  };

  const debounceFn = useCallback(_debounce(handleDebounceFn, 1000), []);
  function handleChange(event) {
    setSearch(event.target.value);
    debounceFn(event.target.value);
  }
  const [forceUpdateId, setForceUpdateId] = useState("");

  const handleDisplay = () => {
    if (ploader) return <Loader />;
    if (search) {
      return searchData.map(
        (a) =>
          a && (
            <ClientContainer
              {...a}
              key={a.uuid}
              search={true}
              addToArchive={() => {
                setLen((old) => old + 1);
                storeLen++;
              }}
              forceUpdateId={a.uuid === forceUpdateId}
              handleForceUpdate={(id) => {
                console.log(id, a.uuid);
                setForceUpdateId(() => id);
              }}
              clearForceId={() => setForceUpdateId(() => "")}
            />
          )
      );
    }
    return data && data.length ? (
      data.map((a) => (
        <ClientContainer
          {...a}
          key={a.uuid}
          addToArchive={() => {
            setLen((old) => old + 1);
            storeLen++;
          }}
          forceUpdateId={a.uuid === forceUpdateId}
          handleForceUpdate={(id) => {
            setForceUpdateId(() => id);
          }}
          clearForceId={() => setForceUpdateId(() => "")}
        />
      ))
    ) : (
      <EmptyList color="#1D2D91" text="No Client's Found." />
    );
  };
  return (
    <>
      <Grid container rowSpacing={2}>
        <Grid item lg={1.2} md={0}></Grid>
        {loading ? (
          <Loader />
        ) : cview ? (
          <Grid
            pl={matches ? 0 : 6}
            item
            lg={10.8}
            md={12}
            sm={12}
            xs={12}
            textAlign="left"
          >
            <Grid container item lg={12} md={12} sm={12} xs={12} mb={2}>
              <Grid item lg={5} md={6} sm={6} xs={6}>
                <OutlinedInput
                  fullWidth
                  color="secondary"
                  classes={{ root: classes.textField }}
                  size="small"
                  value={search}
                  onChange={handleChange}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton edge="end">
                        {" "}
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                  placeholder="Search active clients or project names"
                />
              </Grid>
              <Grid item lg={7} md={6} sm={6} xs={6} textAlign="right">
                <span
                  className={classes.pcount}
                  onClick={() => loc("/archive-project")}
                >
                  Archived Projects ({len})
                </span>
              </Grid>
            </Grid>
            {handleDisplay()}
          </Grid>
        ) : (
          <Grid
            pl={matches ? 0 : 6}
            item
            lg={10.8}
            md={12}
            sm={12}
            xs={12}
            textAlign="left"
          >
            <ClientCalender />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default ClientDashboard;
