import { Box, Grid, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { api } from "../../../../../../redux/api/api";
import CustomButton from "../../../../../ui/button/CustomButton";
import FormGroupTextField from "../../../../../ui/formgroup/FormGroupTextField";
import TextAutoResize from "../../../../../ui/formgroup/TextAutoResize";
//businessMetrics
const ScriptTemplate = (props) => {
  const id = useParams().id;
  const toolData = props?.td;
  const [loading, setLoading] = useState(false);
  const [detailsData, setDetailsData] = useState({
    client: "",
    keyNo: "",
    product: "",
    onAir: "",
    rep: "",
    length: "",
    writer: "",
    station: "",
    notes: "",
  });

  useEffect(() => {
    if (toolData && toolData.data) {
      setDetailsData(toolData?.data);
    }
  }, [toolData]);
  const handleUpdateApi = async () => {
    setLoading(true);
    const data = { scriptTemplate: detailsData };
    const res = await api(
      `tools/${id}`,
      data,
      "patch",
      "",
      "",
      "Workshop updated!"
    );
    if (res && res.success) {
      setDetailsData(res?.data);
    }
    setLoading(false);
  };
  const setData = (field, value) =>
    setDetailsData((old) => ({ ...old, [field]: value }));
  return (
    <div>
      <Grid
        container
        padding={3}
        minHeight="359px"
        opacity={1}
        bgcolor="#F5F5F5"
      >
        <Grid item lg={12} md={12} sm={12} xs={12} pl={2} container spacing={3}>
          <Grid item lg={6} md={6} sm={6} xs={6}>
            <FormGroupTextField
              label="CLIENT:"
              placeholder="CLIENT"
              value={detailsData.client}
              onChange={(e) => setData("client", e)}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={6}>
            <FormGroupTextField
              label="KEY NO:"
              placeholder="KEY NO"
              value={detailsData.keyNo}
              onChange={(e) => setData("keyNo", e)}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={6}>
            <FormGroupTextField
              label="PRODUCT:"
              placeholder="PRODUCT"
              value={detailsData.product}
              onChange={(e) => setData("product", e)}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={6}>
            <FormGroupTextField
              label="ON AIR:"
              placeholder="ON AIR"
              value={detailsData.onAir}
              onChange={(e) => setData("onAir", e)}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={6}>
            <FormGroupTextField
              label="REP:"
              placeholder="REP"
              value={detailsData.rep}
              onChange={(e) => setData("rep", e)}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={6}>
            <FormGroupTextField
              label="LENGTH:"
              placeholder="LENGTH"
              value={detailsData.length}
              onChange={(e) => setData("length", e)}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={6}>
            <FormGroupTextField
              label="WRITER:"
              placeholder="WRITER"
              value={detailsData.writer}
              onChange={(e) => setData("writer", e)}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={6}>
            <FormGroupTextField
              label="STATION:"
              placeholder="STATION"
              value={detailsData.station}
              onChange={(e) => setData("station", e)}
            />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box width="100%">
              <TextAutoResize
                label="SCRIPT:"
                placeholder=" "
                other={{ minRows: 6, maxRows: 6 }}
                value={detailsData.notes}
                onChange={(e) => setData("notes", e)}
              />
            </Box>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Stack direction="row" gap="14px">
              <Box>
                <CustomButton
                  name="Save"
                  size="medium"
                  loading={loading}
                  onclick={handleUpdateApi}
                />
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default ScriptTemplate;
