import { Box, Stack } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import CustomButton from "../../../../../ui/button/CustomButton";
import TextAutoResize from "../../../../../ui/formgroup/TextAutoResize";

const TargetAudience = (props) => {
  const [targetAudience, setTargetAudience] = useState({
    coreCustomer: "",
    do: "",
    feel: "",
    think: "",
  });
  const data = props?.td?.data;
  useEffect(() => {
    if (data && data.targetAudience) {
      setTargetAudience(data.targetAudience);
    }
  }, [props]);
  return (
    <>
      <Stack direction="row" gap="30px">
        <Box flex="0.2" display="flex" gap="10px" flexDirection="column">
          <Box color="secondary.main" fontSize="20px" fontWeight="800px">
            Target Audience
          </Box>
        </Box>
        <Box flex="0.8" mb={2}>
          <Stack gap="20px">
            <TextAutoResize
              label="Who is the core customer?"
              other={{ minRows: 6, maxRows: 6 }}
              value={targetAudience.coreCustomer}
              onChange={(e) =>
                setTargetAudience((old) => ({ ...old, coreCustomer: e }))
              }
            />
            <Box>
              What do we want the target audience to think / feel / do after we
              communicate to them?
            </Box>

            <TextAutoResize
              label="Think:"
              other={{ minRows: 6, maxRows: 6 }}
              value={targetAudience.think}
              onChange={(e) =>
                setTargetAudience((old) => ({ ...old, think: e }))
              }
            />
            <TextAutoResize
              label="Feel:"
              other={{ minRows: 6, maxRows: 6 }}
              value={targetAudience.feel}
              onChange={(e) =>
                setTargetAudience((old) => ({ ...old, feel: e }))
              }
            />
            <TextAutoResize
              label="Do:"
              other={{ minRows: 6, maxRows: 6 }}
              value={targetAudience.do}
              onChange={(e) => setTargetAudience((old) => ({ ...old, do: e }))}
            />
          </Stack>
        </Box>
      </Stack>
      <Box display="flex" justifyContent="space-between" mt="auto" mb="0">
        <CustomButton
          name="<   Prev"
          onclick={() => props.callApi({ targetAudience }, true, false)}
          loading={props.btnLoading}
        />
        <CustomButton
          name="Next   >"
          disabled={
            targetAudience &&
            Object.values(targetAudience).filter((a) => a).length === 4
              ? false
              : true
          }
          onclick={() => props.callApi({ targetAudience }, true, true)}
          loading={props.btnLoading}
        />
      </Box>
    </>
  );
};

export default TargetAudience;
