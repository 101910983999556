import { TextareaAutosize } from "@mui/material";
import { makeStyles } from "@mui/styles";
import FormGroupContainer from "./FormGroupContainer";

const useStyles = makeStyles((theme) => {
  return {
    textField: {
      fontFamily: theme.typography.fontFamily,
      font: "inherit",
      letterSpacing: "inherit",
      color: "currentColor",
      width: "100%",
      resize: "vertical",
      backgroundColor: "white !important",
      borderRadius: "10px !important",
      borderColor: "white !important",
      "& .MuiOutlinedInput-root": {
        borderRadius: "10px !important",
        borderColor: "white !important",
      },
      "& fieldset": {
        borderColor: "white",
        borderRadius: "10px !important",
      },
      "&::placeholder": {
        font: "inherit",
        letterSpacing: "inherit",
        padding: "4px 0 5px",
        border: "0",
        boxSizing: "content-box",
        background: "none",
        height: "1.4375em",
        margin: "0",
        WebkitTapHighlightColor: "transparent",
        display: "block",
        minWidth: "0",
        WebkitAnimationName: "mui-auto-fill-cancel",
        animationName: "mui-auto-fill-cancel",
        WebkitAnimationDuration: "10ms",
        animationDuration: "10ms",
        fontSize: "15px",
        color: "#C3C3C3",
      },
    },
    tf2: {
      fontFamily: theme.typography.fontFamily,
      resize: "vertical",
      width: "100%",
      // backgroundColor: "#F5F5F5 !important",
      borderRadius: "10px !important",
      border: "2px solid #d6d6d6 !important",
      "&:hover": {
        border: "2px solid black !important",
      },
      "& .MuiOutlinedInput-root": {
        borderRadius: "10px !important",
        borderColor: "#F5F5F5 !important",
      },
      "& fieldset": {
        borderColor: "#F5F5F5",
        borderRadius: "10px !important",
      },
      "&::placeholder": {
        font: "inherit",
        letterSpacing: "inherit",
        padding: "4px 0 5px",
        border: "0",
        boxSizing: "content-box",
        background: "none",
        height: "1.4375em",
        margin: "0",
        WebkitTapHighlightColor: "transparent",
        display: "block",
        minWidth: "0",
        WebkitAnimationName: "mui-auto-fill-cancel",
        animationName: "mui-auto-fill-cancel",
        WebkitAnimationDuration: "10ms",
        animationDuration: "10ms",
        fontSize: "15px",
        color: "#C3C3C3",
      },
    },
  };
});

const TextAutoResize = ({
  label,
  onChange,
  value,
  placeholder = "",
  tf = false,
  onBlur = () => {},
  validator = null,
  required = false,
  disabled = false,
  other = {},
}) => {
  const classes = useStyles();

  return label ? (
    <FormGroupContainer label={label} required={required}>
      {" "}
      <TextareaAutosize
        className={tf ? classes.tf2 : classes.textField}
        disabled={disabled}
        value={value}
        placeholder={placeholder || label}
        onBlur={onBlur}
        color="secondary"
        onChange={(e) => onChange(e.target.value)}
        {...other}
      />
      {validator}
    </FormGroupContainer>
  ) : (
    <TextareaAutosize
      className={tf ? classes.tf2 : classes.textField}
      disabled={disabled}
      value={value}
      placeholder={placeholder || label}
      onBlur={onBlur}
      color="secondary"
      onChange={(e) => onChange(e.target.value)}
      {...other}
    />
  );
};

export default TextAutoResize;
