import {
  Box,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CustomCheckBox from "../../../../../../ui/checkbox/CustomCheckBox";
import FormGroupTextField from "../../../../../../ui/formgroup/FormGroupTextField";
import { v4 as uuidv4 } from "uuid";

const Step1 = (props) => {
  // comparativeStrengths;

  return (
    <Stack direction="row" gap="30px">
      <Box flex="0.2" display="flex" gap="10px" flexDirection="column">
        <Box color="secondary.main" fontSize="20px" fontWeight="800px">
          Comparative Strengths
        </Box>
        <Box mb={2} fontSize="14px">
          Comparative strengths assessment
          <br />
          <br /> List the key customer needs, competitor strengths, and brand
          strengths, starting with the top three of each{" "}
        </Box>
      </Box>
      <Box flex="0.8">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="left">
                Add new{" "}
                <IconButton
                  edge="end"
                  onClick={() => {
                    let arr = [...props.data];
                    arr.push({
                      name: "",
                      uuid: uuidv4(),
                      customerNeeds: false,
                      competitorStrengths: false,
                      ourBrandStrengths: false,
                    });
                    props.setData(arr);
                  }}
                >
                  {" "}
                  <AddIcon />
                </IconButton>
              </TableCell>
              <TableCell align="left">Customer needs</TableCell>
              <TableCell align="left">Competitor strengths</TableCell>
              <TableCell align="left">Our brand strengths</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.data
              ? props.data.map((a, idx) => (
                  <TableRow key={a.key}>
                    <TableCell align="left">
                      <FormGroupTextField
                        placeholder="Name"
                        type="text"
                        value={a.name}
                        onChange={(e) => props.handleData(idx, "name", e)}
                      />
                    </TableCell>
                    <TableCell align="left">
                      {a.name && a.name !== "" && (
                        <CustomCheckBox
                          checked={a.customerNeeds}
                          onchange={() =>
                            props.handleData(
                              idx,
                              "customerNeeds",
                              !a.customerNeeds
                            )
                          }
                        />
                      )}
                    </TableCell>
                    <TableCell align="left">
                      {a.name && a.name !== "" && (
                        <CustomCheckBox
                          checked={a.competitorStrengths}
                          onchange={() =>
                            props.handleData(
                              idx,
                              "competitorStrengths",
                              !a.competitorStrengths
                            )
                          }
                        />
                      )}
                    </TableCell>
                    <TableCell align="left">
                      {a.name && a.name !== "" && (
                        <CustomCheckBox
                          checked={a.ourBrandStrengths}
                          onchange={() =>
                            props.handleData(
                              idx,
                              "ourBrandStrengths",
                              !a.ourBrandStrengths
                            )
                          }
                        />
                      )}
                    </TableCell>
                  </TableRow>
                ))
              : null}
          </TableBody>
        </Table>
      </Box>
    </Stack>
  );
};

export default Step1;
