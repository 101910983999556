import { Stack } from "@mui/material";
import FormGroupTextField from "../../../../../../ui/formgroup/FormGroupTextField";

const Step2 = ({
  data,
  setData,
  otherSummary = "",
  setOtherSummary = "",
  otherDescriptors = "",
}) => {
  return (
    <Stack gap="20px">
      {data && data.length
        ? data.map((a, idx) =>
            a.score ? (
              <FormGroupTextField
                label={a?.personalities?.join(", ") || ""}
                value={a.summary}
                onChange={(e) => {
                  const d = [...data];
                  d[idx].summary = e;
                  setData(d);
                }}
              />
              
            ) : null
          )
        : null}

      {otherDescriptors?.length ? (
        <FormGroupTextField
          value={otherDescriptors?.split(" ").join(", ") || ""}
          label={otherSummary}
          onChange={(e) => setOtherSummary(e)}
        />
      ) : null}
    </Stack>
  );
};

export default Step2;
