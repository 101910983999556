import { Box, Grid, Stack } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import CustomButton from "../../../../../ui/button/CustomButton";
import AustrailaPost from "../../../../../../assets/images/AustrailaPost.svg";
import DIY from "../../../../../../assets/images/DIY.png";
import AuGraph from "../../../../../research/ProjectResearchDetails/researches/AuGraph";
import OpeningScreen from "../OpeningScreen";

const imgs = {
  AUSTRALIA_POST: AustrailaPost,
  CUSTOM: DIY,
};
const MarketData = (props) => {
  const resData = useSelector((state) => state.project?.project?.researches);
  const [data, setData] = useState([]);
  const loadData = () => {
    const finalData = [];
    const findSelectedRes =
      props?.td?.data?.selectedResearch?.filter((a) => a.checked) || [];
    for (let i = 0; i < findSelectedRes.length; i++) {
      const findRes =
        resData?.find((a) => a.uuid === findSelectedRes[i].uuid) || "";
      if (findRes) {
        if (findRes.researchType === "CUSTOM") {
          const imgs = findRes.data.imgs.filter((a) => a.include);
          for (let j = 0; j < imgs.length; j++) {
            const d = {
              name: findRes.name,
              uuid: findRes.uuid,
              type: findRes.researchType,
              dataPointName: "DIY",
              data: imgs[j].img || [],
              img: true,
            };
            finalData.push(d);
          }
        } else if (findRes.data && findRes.data.dataPoint) {
          const dataPoints = findRes.data.dataPoint.filter((a) => a.include);
          for (let j = 0; j < dataPoints.length; j++) {
            const d = {
              name: findRes.name,
              uuid: findRes.uuid,
              type: findRes.researchType,
              dataPointName: dataPoints[j].name,
              data: dataPoints[j].data || [],
            };
            finalData.push(d);
          }
        }
      }
    }
    setData(finalData);
  };
  const [index, setIndex] = useState(-1);

  useEffect(() => {
    if (
      props.td &&
      props.td.data &&
      props.td.data.selectedResearch &&
      props.td.data.selectedResearch.length &&
      resData &&
      resData.length
    ) {
      loadData();
    }
  }, [resData, props?.td?.data?.selectedResearch]);
  return (
    <>
      {index === -1 ? (
        <OpeningScreen
          title="Understanding
        Your Market"
          description="Including market data into the brand strategy provides the opportunity to analyse the data and extract key insights that can be applied towards shaping the business. Leveraging and moulding this information for the benefit of both the brand and desired or existing customers."
          img={props?.sas?.img || ""}
        />
      ) : (
        <Stack direction="row" gap="30px">
          <Box flex="0.2" display="flex" gap="10px" flexDirection="column">
            <Box color="secondary.main" fontSize="20px" fontWeight="800px">
              Understanding Your Market
            </Box>
            <Box mb={2} fontSize="14px">
              <Box color="secondary.main" fontSize="20px" fontWeight="800px">
                {data[index]?.name || ""}
              </Box>
              <br />
              {data[index]?.type && (
                <img
                  src={imgs[data[index]?.type || ""] || ""}
                  alt="research provider image"
                />
              )}{" "}
            </Box>
          </Box>
          <Box
            flex="0.8"
            style={{
              background: "white",
              padding: "20px",
              borderRadius: "10px",
            }}
            mb={2}
          >
            <Box color="secondary.main" fontSize="20px" fontWeight="800px">
              {" "}
              {data[index]?.dataPointName || ""}
            </Box>
            <Grid container item lg={12} md={12} sm={12} xs={12}>
              {data && data.length ? (
                data[index].img && data[index].data ? (
                  <img
                    src={data[index].data}
                    alt={data[index].data}
                    width="100%"
                    height="auto"
                  />
                ) : (
                  <AuGraph data={data[index]?.data || []} flex={true} />
                )
              ) : (
                <Box>No Research Data Found</Box>
              )}
            </Grid>
          </Box>
        </Stack>
      )}
      <Box display="flex" justifyContent="space-between" mt="auto" mb="0">
        <CustomButton
          name="<   Prev"
          onclick={() => {
            if (index === -1) {
              props.prvStep();
            } else {
              setIndex((old) => old - 1);
            }
          }}
        />
        <CustomButton
          name="Next   >"
          onclick={() => {
            if (data.length === 0 || index === data.length - 1) {
              props.nextStep();
            } else {
              setIndex((old) => old + 1);
            }
          }}
        />
      </Box>
    </>
  );
};

export default MarketData;
