import { Grid } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { api } from "../../../redux/api/api";
import CustomButton from "../../ui/button/CustomButton";
import HeaderText from "../HeaderText";
import CopetitorsCard from "./CopetitorsCard";
import { v4 as uuidv4 } from "uuid";
import { useEffect } from "react";
import ClientComp from "../../client/settings/competitors/Competitors";
const Competitors = () => {
  const [comp, setComp] = useState([]);
  const [loading, setLoading] = useState(false);
  const pd = useSelector((state) => state.project?.project);
  useEffect(() => {
    if (pd && pd.competitors) {
      setComp(() => pd.competitors);
    }
  }, [pd]);
  const onDel = async (i) => {
    const newArr = [...comp];
    newArr.splice(i, 1);
    setComp(() => newArr);
    await onUpdate(newArr);
  };
  const changeData = (name, idx, change) => {
    const newArr = [...comp];
    newArr[idx][name] = change;
    setComp(() => {
      return newArr;
    });
  };
  const onAddData = async () => {
    const dd = [{ name: "", logo: "", uuid: uuidv4(), loaded: false }, ...comp];
    setComp(() => dd);
    await onUpdate(dd);
  };
  const onUpdate = async (data) => {
    setLoading(true);
    await api(
      `projects/${pd.uuid}`,
      { competitors: data || comp },
      "patch",
      "",
      "",
      "Project Competitors Updated!"
    );
    pd.competitors = data || comp;
    setLoading(false);
  };

  return pd.useClientCompetitorsSettings ? (
    <ClientComp hide={true} />
  ) : (
    <>
      <HeaderText
        name="Competitors"
        btn={
          <>
            <CustomButton
              name="Save"
              size="medium"
              onclick={() => onUpdate()}
              styled={{ marginRight: "10px" }}
              loading={loading}
            />
            <CustomButton
              name="Add Competitors"
              size="medium"
              onclick={() => onAddData()}
              loading={loading}
            />
          </>
        }
      />
      <Grid container>
        {comp.map((a, i) => (
          <CopetitorsCard
            key={i}
            {...a}
            onDel={() => onDel(i)}
            changeData={(n, d) => changeData(n, i, d)}
            onUpdate={() => onUpdate()}
          />
        ))}
      </Grid>
    </>
  );
};

export default Competitors;
