import { Grid } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { api } from "../../../redux/api/api";
import { openingScreens, ToolType } from "../../../utils/AllOptions";
import { setHTitle } from "../../../utils/SetHeaderFunction";
import RComment from "../../research/RComment";
import Loader from "../../ui/loader/Loader";
import SettingsSlides from "./settingsSlides/SettingsSlides";
import Workshop from "./workshop/Workshop";
import PrivateSidebar from "../../layout/PrivateSidebar";
import { SET_SIDEBAR_LAYOUT_DATA } from "../../../redux/constants";
import CommonTitle from "../../common/CommonTitle";
import Download from "./download/Download";
import Discovery from "./discovery/Discovery";

const BrandStrategyComponent = () => {
  const loc = useLocation();
  const params = useParams();
  const projectId = useSelector((state) => state.client?.projectId?.id);
  const full = useSelector((state) => state.project.workshop);

  const [oloading, setOLoading] = useState(true);
  const [loading, setLoading] = useState(true);

  const [selWorkshop, setSelWorkShop] = useState("");
  const [toolData, setToolData] = useState("");
  const [steps, setSteps] = useState([]);

  const his = useNavigate();
  const sidebarLayoutData = useSelector(
    (state) => state.sideBar.sidebarLayoutData
  );
  const dispatch = useDispatch();

  const resData = useSelector((state) => state.project?.project?.researches);

  const loadSteps = (res) => {
    let newdd = res?.data?.exercises ? [...res?.data?.exercises] : [];
    let remove = true;
    const findSelectedRes =
      res?.data?.selectedResearch?.filter((a) => a.checked) || [];
    for (let i = 0; i < findSelectedRes.length; i++) {
      const findRes =
        resData?.find((a) => a.uuid === findSelectedRes[i].uuid) || "";
      if (findRes) {
        remove = false;
        break;
      }
    }
    if (remove) {
      newdd = newdd.filter((a) => a?.key && a?.key !== "market");
    }
    setSteps(newdd || []);
    setSelWorkShop(newdd[0] || "");
  };
  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      setOLoading(true);
      if (params.type && params.id && params.name) {
        setHTitle(params.name, true, ToolType[params.type]);
        const str = `/projects/tool/${params.name}/${params.type}/${params.id}`;

        const p = [
          {
            name: "Settings & Slides",
            path: `${str}/settings-slides`,
          },
          {
            name: "What is discovery?",
            path: `${str}/discovery`,
          },
          {
            name:
              params.type === "BRAND_STRATEGY"
                ? "Workshop"
                : `${ToolType[params.type]} Steps`,
            path: `${str}/workshop`,
          },
          {
            name: "Download",
            path: `${str}/download`,
          },
        ];

        let sideBarArray = [...sidebarLayoutData];

        sideBarArray?.forEach((item) => {
          if (item.name === "Tools") {
            item.activePaths = ["/projects/add-tool"];
            p?.forEach((value) => {
              item?.activePaths?.push(value.path);
            });
            item.isChild = true;
            item.children = p;
          }
        });
        dispatch({
          type: SET_SIDEBAR_LAYOUT_DATA,
          payload: { data: sideBarArray },
        });
        if (params.type !== "BRAND_STRATEGY") {
          if(params.type === "CUSTOM") {
            p.splice(0);
          }
          if (
            !loc.pathname.includes("workshop") &&
            !loc.pathname.includes("download")
          ) {
            his(`${str}/workshop`, { replace: true });
          }
        } else if (
          !loc.pathname.includes("workshop") &&
          !loc.pathname.includes("download") &&
          !loc.pathname.includes("discovery") &&
          !loc.pathname.includes("settings-slides")
        ) {
          his(p[1].path, { replace: true });
        }

        setLoading(false);
        const res = await api(`tools/${params.id}`, "", "get");
        if (res && res.success) {
          if (res.projectId !== projectId) his("/projects");
          if (params.type === "BRAND_STRATEGY") {
            if (!res.data.settingsAndSlides) {
              res.data.settingsAndSlides = openingScreens;
            }
          }
          setToolData(res);
          loadSteps(res);
        }
      } else {
        his(-1);
      }
      setOLoading(false);
    };
    getData();
  }, []);
  useEffect(() => {
    if (toolData && projectId && toolData.projectId !== projectId)
      his("/projects");
  }, [projectId]);
  const findIdx = () => {
    let i = -1;
    if (selWorkshop && selWorkshop.key) {
      i = steps.findIndex((a) => a.key === selWorkshop.key);
    }
    return i;
  };
  const nextStep = () => {
    const i = findIdx();
    if (i !== -1 && i + 1 <= steps.length) {
      setSelWorkShop(steps[i + 1]);
    }
  };
  const prvStep = () => {
    const i = findIdx();
    if (i !== -1 && i - 1 !== -1) {
      setSelWorkShop(steps[i - 1]);
    }
  };
  const [btnLoading, setBtnLoading] = useState(false);
  const callApi = async (d, n, p) => {
    setBtnLoading(true);
    const res = await api(
      `tools/${params.id}`,
      d,
      "patch",
      "",
      "",
      "Workshop updated!"
    );
    if (res && res.success) {
      const td = { ...toolData };
      if (td.data) {
        td.data = { ...td.data, ...d };
      } else {
        td.data = { ...d };
      }
      setToolData(td);
      if (d.selectedResearch) {
        loadSteps(td);
      }
      if (n) {
        if (p) {
          nextStep();
        } else {
          prvStep();
        }
      }
      setBtnLoading(false);
      return true;
    } else {
      setBtnLoading(false);
      return false;
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <PrivateSidebar
          steps={steps}
          toolParams={params}
          selWorkshop={selWorkshop}
          setSelWorkShop={setSelWorkShop}
        />
        {loading ? (
          <Loader />
        ) : (
          <Grid
            item
            lg={10}
            md={10}
            sm={10}
            xs={10}
            textAlign="left"
            pt={full ? "2rem !important" : "4rem !important"}
          >
            {!full && <CommonTitle isRightLink={true} />}
            {oloading ? (
              <Loader />
            ) : loc.pathname.includes("workshop") ? (
              <Workshop
                tk={selWorkshop}
                td={toolData}
                sas={
                  toolData?.data?.settingsAndSlides?.filter((a) => a.include) ||
                  []
                }
                nextStep={() => nextStep()}
                prvStep={() => prvStep()}
                callApi={(d, n, p) => callApi(d, n, p)}
                btnLoading={btnLoading}
              />
            ) : loc.pathname.includes("discovery") ? (
              <Discovery
                toolData={toolData}
                callApi={(d) => callApi(d)}
                btnLoading={btnLoading}
              />
            ) : loc.pathname.includes("settings-slides") ? (
              <SettingsSlides
                data={toolData?.data?.settingsAndSlides || []}
                handleData={(d) => {
                  callApi({ settingsAndSlides: d });
                }}
                btnLoading={btnLoading}
                name={toolData?.name || ""}
                template={toolData?.template || ""}
              />
            ) : loc.pathname.includes("download") ? (
              <Download data={toolData?.data} type="tool" />
            ) : null}
          </Grid>
        )}
      </Grid>
      <RComment />
    </>
  );
};

export default BrandStrategyComponent;
