import { Grid, Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import PDHeader from "../../../project/projectRightComponent/downloadSecion/PDHeader";
import PDList from "../../../project/projectRightComponent/downloadSecion/PDList";
import CustomButton from "../../../ui/button/CustomButton";
import DropDownMenu from "../../../ui/menus/DropDownMenu";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import { useEffect } from "react";
import { api } from "../../../../redux/api/api";
import Loader from "../../../ui/loader/Loader";
import { useParams } from "react-router";
import EmptyList from "../../../ui/emptyList/EmptyList";
import DownloadModal from "../../../ui/modals/DownloadModal";
import { DwdOptions, ToolType } from "../../../../utils/AllOptions";
import DeleteModal from "../../../ui/modals/DeleteModal";
import { config } from "../../../../config";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  res: {
    fontSize: "18px",
    color: theme.palette.secondary.main,
    textDecoration: "underline",
  },
  nameDate: {
    fontSize: "16px",
    marginTop: "4px",
    paddingRight: "4px",
  },
  provider: {
    fontSize: "18px",
  },
  title: {
    fontSize: "16px",
    color: theme.palette.secondary.main,
  },
  title1: {
    color: theme.palette.secondary.main,
    fontWeight: 600,
    fontSize: "20px",
    margin: "auto 0",
  },
  btn: {
    backgroundColor: "#F19C7E !important",
    borderColor: "#F19C7E !important",
    color: `${theme.palette.secondary.main} !important`,
    textTransform: "none",
    fontWeight: "normal !important",
  },
}));
const Download = ({ dataMain, type = "tool", rname }) => {
  const classes = useStyles();

  const tid = useParams().id;
  const params = useParams();
  const resData = useSelector((state) => state.project?.project?.downloads);

  const [enchorEl, setEnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openP, setOpenP] = useState(false);
  const [menuVal, setMenuVal] = useState("Download");
  const [open, setOpen] = useState(false);
  const [data, setData] = useState("");
  const [dData, setDData] = useState([]);
  const [delModal, setDelModal] = useState(false);

  const getData = async () => {
    setLoading(true);
    const res = await api(
      `projects/${tid}/reports?reportType=${
        type === "tool" ? "WORKSHOP" : "RESEARCH"
      }`,
      "",
      "get"
    );
    if (res && res.success && res.reports) {
      setDData(res.reports);
      setLoading(false);
      return res.total;
    }
    setLoading(false);
    return 0;
  };
  useEffect(() => {
    getData();
  }, []);
  const handleClick = (event, val) => {
    setEnchorEl(event.currentTarget);
    setData(val);
    setOpenP(true);
  };
  const handleClose = () => {
    setEnchorEl(null);
    setOpenP(false);
  };
  const downloadNow = (file, fileType) => {
    if (!file) return null;
    if (fileType === "PDF") return window.open(config.generatePathDownloadPath(file));
    return window.open(config.generatePathDownloadPath(file));
  };
  const handleSelect = (v) => {
    handleClose();
    switch (v) {
      case DwdOptions[0]:
        downloadNow(data?.file, data?.fileType);
        break;
      // case DwdOptions[1]:
      //   if (data?.notesFile) {
      //     window.open(config.dwdPath + data.notesFile);
      //   }
      //   break;
      case DwdOptions[1]:
        setDelModal(true);
        break;
      case DwdOptions[2]:
        setOpen(true);
        break;
      default:
        break;
    }
  };
  const handleDelete = async () => {
    if (data?.uuid) {
      const res = await api(
        `projects/${tid}/reports/${data.uuid}`,
        "",
        "delete",
        "",
        "",
        "Report Deleted!"
      );
      if (res && res.success) {
        setData("");
        const idx = dData.findIndex((a) => a.uuid === data.uuid);
        const idx2 = resData.findIndex((a) => a.uuid == data.uuid);
        if (idx2 !== -1) {
          resData.splice(idx2, 1);
        }
        const d = [...dData];
        d.splice(idx, 1);
        setDData(d);

        setData("");
      }
      setDelModal(false);
    }
  };

  return (
    <Grid container item lg={12} md={12} sm={12} xs={12}>
      <Grid item lg={12} md={12} sm={12} xs={12} mb={3}>
        <Stack gap="18px" direction="row">
          <div className={classes.title1}>Download</div>
          <div>
            <CustomButton
              variant="contained"
              size="small"
              endIcon={<AddCircleOutlineRoundedIcon />}
              name="Add"
              className={classes.btn}
              onclick={() => setOpen(true)}
            />
          </div>
        </Stack>
      </Grid>
      <PDHeader generalClass={classes} />
      {loading ? (
        <Loader />
      ) : dData && dData.length ? (
        dData.map((a) => (
          <PDList
            {...a}
            type={type}
            rname={rname}
            handleClick={handleClick}
            generalClass={classes}
            downloadNow={downloadNow}
          />
        ))
      ) : (
        <EmptyList
          color="#F19C7E"
          text={`Downloads become available once the ${
            type === "tool" ? "tools" : "researches"
          } have been used.`}
        />
      )}
      <DropDownMenu
        enchorEl={enchorEl}
        openP={openP}
        handleClose={handleClose}
        options={DwdOptions}
        val={menuVal}
        handleSelect={(v) => handleSelect(v)}
      />
      <DownloadModal
        open={open}
        onClose={() => setOpen(false)}
        opvalue={`${ToolType[params.type] || "Custom"} ${params.name}`}
        getDwld={() => getData()}
        id={tid}
        data={dataMain}
        type={type}
      />
      <DeleteModal
        title="Are you sure you want to remove the
        download?"
        open={delModal}
        onDelete={() => handleDelete()}
        onClose={() => setDelModal(false)}
      />
    </Grid>
  );
};

export default Download;
