import { Box, Grid } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import ClientComp from "../../../../../client/settings/competitors/Competitors";
import { api } from "../../../../../../redux/api/api";
import HeaderText from "../../../../../projectSettings/HeaderText";
import CompetitorsCard from "./CompetitorsCard";
import CustomButton from "../../../../../ui/button/CustomButton";
import { useParams } from "react-router";
const CreativeCompetitiveOverview = (props) => {
  const params = useParams();
  const [comp, setComp] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);
  const [toolData, setToolData] = useState(props?.td);
  const pd = useSelector((state) => state.project?.project);
  useEffect(() => {
    let competitors = toolData?.data;
    let compArray = [];
    if (competitors) {
      let projectCompetitors = pd && pd.competitors;
      projectCompetitors &&
        projectCompetitors.map((item) => {
          let findIndex = competitors.findIndex((a) => a.uuid === item.uuid);
          if (findIndex !== -1) {
            competitors[findIndex].logo = item.logo;
            competitors[findIndex].name = item.name;
            compArray.push(competitors[findIndex]);
          } else {
            item.review = "";
            compArray.push(item);
          }
        });
    } else {
      compArray = pd && pd.competitors;
      compArray &&
        compArray.map((item) => {
          item.review = "";
        });
    }
    setComp(compArray);
  }, [pd, toolData]);

  const onDel = async (i) => {
    const newArr = [...comp];
    newArr.splice(i, 1);
    setComp(() => newArr);
    await onUpdate(newArr);
  };
  const changeData = (review, idx, change) => {
    const newArr = [...comp];
    newArr[idx][review] = change;
    setComp(() => {
      return newArr;
    });
  };
  const onUpdate = async () => {
    setBtnLoading(true);
    let competitors = { competitors: comp };
    const res = await api(
      `tools/${params.id}`,
      competitors,
      "patch",
      "",
      "",
      "Workshop updated!"
    );
    if (res && res.success) {
      setToolData(res);
      setBtnLoading(false);
      return true;
    } else {
      setBtnLoading(false);
      return false;
    }
  };

  return pd.useClientCompetitorsSettings ? (
    <ClientComp hide={true} />
  ) : (
    <>
      <HeaderText
        name="Competitors"
        btn={
          comp?.length ? (
            <Box ml="auto" mr="0" mt={2}>
              <CustomButton
                loading={btnLoading}
                name="Save"
                size="medium"
                onclick={() => onUpdate()}
              />
            </Box>
          ) : null
        }
      />
      <Grid container spacing={2}>
        {comp &&
          comp.map((a, i) => (
            <CompetitorsCard
              key={i}
              {...a}
              onDel={() => onDel(i)}
              changeData={(n, d) => changeData(n, i, d)}
              onUpdate={() => onUpdate()}
            />
          ))}
      </Grid>
    </>
  );
};

export default CreativeCompetitiveOverview;
