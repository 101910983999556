import { GET_ALL_TEMPLATE } from "../../constants";

const initState = {
  template: [],
};

const templateReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_ALL_TEMPLATE:
      return { ...state, template: action.payload.data };

    default:
      return state;
  }
};

export default templateReducer;
