import { useEffect } from "react";
import ProjectComponent from "../../../components/project/ProjectComponent";
import { setHTitle } from "../../../utils/SetHeaderFunction";

const Projects = () => {
  useEffect(() => {
    setHTitle("", false);
  }, []);
  return <ProjectComponent />;
};

export default Projects;
