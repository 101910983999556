import { useTheme } from "@mui/material";
import { Outlet } from "react-router";
import Container from "../ui/container/Container";
import PublicHeader from "./PublicHeader";

const PublicRoute = () => {
  const fontFamily = useTheme().typography.fontFamily;
  return (
    <div
      style={{
        fontFamily: fontFamily,
      }}
    >
      <PublicHeader />
      <Container>
        <Outlet />
      </Container>
    </div>
  );
};

export default PublicRoute;
