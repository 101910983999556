import { api } from "../../api/api";
import {
  SET_CLIENT_ID,
  SET_PROJECT_ID,
  TOGGLECLIENTVIEW,
  SET_LIST_USERS,
  GET_CLIENT_DASHBOARD,
  SET_PROJECT_LOADER,
} from "../../constants";
import { store } from "../../store";

export const setClientView = (view) => {
  return async (dispatch) => {
    dispatch({
      type: TOGGLECLIENTVIEW,
      payload: { data: view },
    });
  };
};

export const setPID = (id) => {
  return async (dispatch) => {
    dispatch({
      type: SET_PROJECT_ID,
      payload: { data: id },
    });
  };
};

export const setCID = (id) => {
  return async (dispatch) => {
    dispatch({
      type: SET_CLIENT_ID,
      payload: { data: id },
    });
  };
};

export const setUsers = (data = []) => {
  // const user = store?.getState()?.auth.user;
  // if (user.id) {
  //   data.push({ uuid: user.id, name: user.name });
  // }
  return async (dispatch) => {
    dispatch({
      type: SET_LIST_USERS,
      payload: { data: data },
    });
  };
};

export const getDashboardData = () => {
  const aid = store?.getState()?.auth?.user?.agencies?.uuid || "";
  return async (dispatch) => {
    if (aid) {
      const res = await api(
        `clients/dashboard?agencyId=${aid}&sort=dateLastActivity&order=desc&active=true`,
        "",
        "get"
      );
      if (res && res.success && res.clients && res.clients.length) {
        dispatch({
          type: GET_CLIENT_DASHBOARD,
          payload: {
            data: res.clients,
          },
        });
      }
    }
  };
};

export const getClientListData = () => {
  return async (dispatch) => {
    try {
      const res = await api(
        `clients?sort=dateLastActivity&order=desc&active=true`,
        "",
        "get"
      );
      if (res && res.success && res.clients && res.clients.length) {
        dispatch({
          type: "GET_CLIENT_LIST_DATA",
          payload: { data: res.clients },
        });
      }
    } catch (error) {}
  };
};
export const getProjectListData = (id) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: SET_PROJECT_LOADER,
        payload: { data: true },
      });
      const res = await api(
        `projects?clientId=${id}&sort=dateLastActivity&order=desc&active=true`,
        "",
        "get"
      );
      if (res && res.success) {
        dispatch({
          type: "GET_PROJECT_LIST_DATA",
          payload: {
            data: res.projects || [],
          },
        });
      }
      dispatch({
        type: SET_PROJECT_LOADER,
        payload: { data: true },
      });
    } catch (error) {}
  };
};
export const setFromIds = (data) => {
  return (dispatch) => {
    dispatch({ type: "SET_FROM_IDS", payload: { data: data } });
  };
};
export const getSubsDetails = (id) => {
  return async (dispatch) => {
    try {
      // const res = await api(`agencies/${id}/subscription`, "", "get");
      // if (res && res.success) {
      //   dispatch({
      //     type: "GET_SUBS_DETAILS",
      //     payload: { data: res },
      //   });
      // }
    } catch (error) {}
  };
};
