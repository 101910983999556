import { Box } from "@mui/system";

const HeaderText = ({ name, btn, gp }) => {
  return (
    <Box
      fontWeight="800"
      display="flex"
      fontSize="18px"
      mb="14px"
      color="secondary.main"
      justifyContent={gp ? "flex-start" : "space-between"}
      gap="14px"
    >
      <Box m="auto 0">{name}</Box>
      <Box>{btn}</Box>
    </Box>
  );
};

export default HeaderText;
