import { ButtonBase, DialogContent, Grid, Stack } from "@mui/material";
import { ArrowForwardIos } from "@mui/icons-material";
import CatImg from "../../../assets/images/catimg.png";
import CustomButton from "../button/CustomButton";
import ModalContainer from "./ModalContainer";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import FormGroupTextField from "../formgroup/FormGroupTextField";
import { useSelector } from "react-redux";
import { api } from "../../../redux/api/api";
import { useEffect } from "react";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "20px",
    color: theme.palette.secondary.main,
    marginBottom: "20px",
    fontWeight: 600,
  },
  endIcon: {
    marginLeft: "auto !important",
  },
  btnContainer: { width: "40%" },
  cancelBtn: {
    margin: "auto 0",
    textDecoration: "underline !important",
    fontFamily: theme.typography.fontFamily,
    fontSize: "16px",
  },
  dialogContent: {
    height: "500px",
  },
  subTitle: {
    marginBottom: "7px",
    fontWeight: 400,
    fontSize: "14px !important",
  },
  name: {
    marginBottom: "16px",
  },
}));
const CloneTemplate = ({ open, onClose, name, id, type }) => {
  const classes = useStyles();
  const [cloneName, setCloneName] = useState("");
  const [loading, setLoading] = useState(false);
  const templateRe = useSelector((state) => state.template?.template);
  const agenciesUUID = useSelector((state) => state.auth.user?.agencies?.uuid);

  const handleSave = async () => {
    setLoading(true);
    const res = await api(
      `templates/${id}`,
      { name: cloneName, agencyId: agenciesUUID },
      "post",
      "",
      "",
      "Template Clone Success!"
    );
    if (res && res.success) {
      templateRe.push(res);
      onClose();
    }
    setLoading(false);
  };
  useEffect(() => {
    setCloneName("");
  }, [open]);
  return (
    <ModalContainer
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth={true}
    >
      <DialogContent className={classes.dialogContent}>
        <Grid container mt={2}>
          <Grid item lg={7} md={7} margin="auto" p={4} mt={6}>
            <div className={classes.title}>Clone Template</div>
            <div className={classes.subTitle}>Template Type & Name</div>
            <div className={classes.name}>
              {type} / {name}
            </div>
            <FormGroupTextField
              label="Heading /Question"
              value={cloneName}
              onChange={(e) => setCloneName(e)}
              required={true}
            />
            <Stack direction="row" mt={3} gap="20px">
              <div className={classes.btnContainer}>
                <CustomButton
                  variant="contained"
                  size="large"
                  name={"Copy Now"}
                  endIcon={<ArrowForwardIos />}
                  fullWidth={true}
                  iconClass={classes.endIcon}
                  disabled={cloneName ? false : true}
                  loading={loading}
                  onclick={handleSave}
                />
              </div>
              <ButtonBase onClick={onClose} className={classes.cancelBtn}>
                Cancel
              </ButtonBase>
            </Stack>
          </Grid>
          <Grid item lg={5} md={5}>
            <img src={CatImg} alt={CatImg} height="383px" />
          </Grid>
        </Grid>
      </DialogContent>
    </ModalContainer>
  );
};

export default CloneTemplate;
