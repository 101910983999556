import { Box, Stack } from "@mui/material";
import { useState } from "react";
import Carousel from "react-material-ui-carousel";
import { useSelector } from "react-redux";
import ModalContainer from "../../../../ui/modals/ModalContainer";

const enums = {
  welcome: "Welcome",
  competitors: "Competitors",
  "meeting-customer-needs": "Meeting customer needs",
  "brand-archetypes": "Brand archetypes",
  "strengths-and-weaknesses": "Strengths and weaknesses",
  "brand-personality": "Brand personality",
  "brand-values": "Brand values",
  "brand-essence": "Brand essence",
  "brand-proof-points": "Brand proof points",
  "elevator-pitch": "Elevator pitch",
  "competitor-mapping": "Competitor mapping",
  market: "Market data",
};
const OpeningScreen = ({
  img = "",
  title = "",
  description = "",
  bestPratice = "",
  bestEx = [],
  name = "",
}) => {
  const full = useSelector((state) => state.project.workshop);
  const [open, setOpen] = useState(false);

  return (
    <Stack gap="30px">
      <Box
        color="secondary.main"
        fontSize="20px"
        fontWeight="800px"
        display="flex"
      >
        {title}{" "}
        {bestPratice && bestEx && bestEx.length ? (
          <span className="best-pratice-ex" onClick={() => setOpen(true)}>
            Best Practice Examples
          </span>
        ) : null}
      </Box>
      {full && img ? (
        <Box mb={2}>
          <img
            src={
              img
                ? img.includes("static/openingScreens")
                  ? `/${img}`
                  : img
                : ""
            }
            alt="img"
            width="100%"
            height="auto"
          />
        </Box>
      ) : (
        <Box mb={2} fontSize="18px">
          {description}
        </Box>
      )}
      <ModalContainer
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="md"
        fullWidth={true}
        title={`Best Practice Examples - ${enums[name] || ""}`}
      >
        <Carousel 
          className="bestPracticeExamples"
          autoPlay="false"
          navButtonsAlwaysVisible="true"
          indicatorIconButtonProps={{
            style: {
              paddingLeft: '10px',
              paddingRight: '10px',
              paddingBottom: '20px',
              zIndex: 100
            }
          }}
          height={444}>
          {bestEx &&
            bestEx.length &&
            bestEx.map((a) => (
              <img
                src={
                  a ? (a.includes("static/openingScreens") ? `/${a}` : a) : ""
                }
                alt="img"
                width="100%"
                height="auto"
              />
            ))}
        </Carousel>
      </ModalContainer>
    </Stack>
  );
};

export default OpeningScreen;
