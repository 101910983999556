import { SET_HEADER_TITLE } from "../../constants";

const initState = {
  title: "",
  isTitle: false,
  subTitle: "",
  subTitlePosition: "top",
};

const titleReducer = (state = initState, action) => {
  switch (action.type) {
    case SET_HEADER_TITLE:
      return { ...state, ...action.payload.data };
    default:
      return state;
  }
};

export default titleReducer;
