import { Box, Stack } from "@mui/material";
import WorkshopStrengthsAndWeaknessesImg from "../../../../../../../assets/images/workshop-strengths-and-weaknesses.png";

const Step2 = (props) => {
  return (
    <Stack>
      <Box>
        <Box color="secondary.main" fontSize="20px" fontWeight="800px">
          Differentiated Brand Value Proposition
        </Box>
        <Box mt={4} mb={2} fontSize="14px">
          <Box color="secondary.main" fontSize="20px" fontWeight="800px">
            An overview
          </Box>
          <br />
          <br /> Your differentiated brand value proposition is found at the
          intersection of what you do better than anyone else, what your
          customers care deeply about, and what your competitors do not have a
          strength in. <br />
          <br />
          <br />
          <Box textAlign={"center"}>
            <img src={WorkshopStrengthsAndWeaknessesImg} alt="" />
          </Box>
        </Box>
      </Box>
    </Stack>
  );
};

export default Step2;
