import { Stack, Box } from "@mui/material";
import { getMarginFromValue } from "../../../../../../../utils/getMarginFromValue";
import { config } from "../../../../../../../config";

const Step9 = ({
  competitorPositioningMatrixC = [],
  competitorPositioningMatrixD = [],
  competitorPositioningAxes,
  displayGraphLast,
}) => {
  return (
    <Stack justifyContent="space-between">
      <Box flex="1">
        <div className="y-axis-label" textAlign="center">
          {competitorPositioningAxes[5]?.logo && (
            <img
              alt="Logo Img"
              src={`${config.dwdPath}${competitorPositioningAxes[5].logo}`}
              style={{ width: "75px", height: "45px" }}
            />
          )}
          <Box>{competitorPositioningAxes[5]?.name || ""}</Box>
        </div>

        <div className="matrix-wrapper">
          <div className="matrix">
            <div className="y-axis"></div>
            <div className="x-axis" textAlign="center">
              {competitorPositioningAxes[4]?.logo && (
                <img
                  alt="Logo Img"
                  src={`${config.dwdPath}${competitorPositioningAxes[4].logo}`}
                  style={{ width: "75px", height: "45px" }}
                />
              )}
              <Box>{competitorPositioningAxes[4]?.name || ""}</Box>
            </div>
            {competitorPositioningMatrixC && competitorPositioningMatrixC.length
              ? competitorPositioningMatrixC.map((a) => (
                  <span
                    className="matrix-cell"
                    style={{
                      top: getMarginFromValue(a.differentiator6 || 0),
                      left: getMarginFromValue(a.differentiator5 || 0),
                    }}
                  >
                    {a?.logo && (
                      <img
                        alt="Logo Img"
                        src={`${config.dwdPath}${a.logo}`}
                        style={{ width: "75px", height: "45px" }}
                      />
                    )}
                    <span>{a.name}</span>
                  </span>
                ))
              : null}
          </div>
        </div>
        <Stack gap={1}>
          <Box display="flex" gap="20px">
            <Box mt="auto" mb="auto">
              X-axis
            </Box>
            <Box p="10px" border="1px solid grey" textAlign="center">
              {competitorPositioningAxes[4]?.logo && (
                <img
                  alt="Logo Img"
                  src={`${config.dwdPath}${competitorPositioningAxes[4].logo}`}
                  style={{ width: "75px", height: "45px" }}
                />
              )}
              <Box>{competitorPositioningAxes[4]?.name || ""}</Box>
            </Box>
          </Box>
          <Box display="flex" gap="20px">
            <Box mt="auto" mb="auto">
              Y-axis
            </Box>
            <Box p="10px" border="1px solid grey" textAlign="center">
              {competitorPositioningAxes[5]?.logo && (
                <img
                  alt="Logo Img"
                  src={`${config.dwdPath}${competitorPositioningAxes[5].logo}`}
                  style={{ width: "75px", height: "45px" }}
                />
              )}
              <Box>{competitorPositioningAxes[5]?.name || ""}</Box>
            </Box>
          </Box>
        </Stack>
      </Box>
      {displayGraphLast ? (
        <Box flex="1">
          <div className="y-axis-label" textAlign="center">
            {competitorPositioningAxes[7]?.logo && (
              <img
                alt="Logo Img"
                src={`${config.dwdPath}${competitorPositioningAxes[7].logo}`}
                style={{ width: "75px", height: "45px" }}
              />
            )}
            <Box>{competitorPositioningAxes[7]?.name || ""}</Box>
          </div>

          <div className="matrix-wrapper">
            <div className="matrix">
              <div className="y-axis"></div>
              <div className="x-axis" textAlign="center">
                {competitorPositioningAxes[6]?.logo && (
                  <img
                    alt="Logo Img"
                    src={`${config.dwdPath}${competitorPositioningAxes[6].logo}`}
                    style={{ width: "75px", height: "45px" }}
                  />
                )}
                <Box>{competitorPositioningAxes[6]?.name || ""}</Box>
              </div>
              {competitorPositioningMatrixD &&
              competitorPositioningMatrixD.length
                ? competitorPositioningMatrixD.map((a) => (
                    <span
                      className="matrix-cell"
                      style={{
                        top: getMarginFromValue(a.differentiator8 || 0),
                        left: getMarginFromValue(a.differentiator7 || 0),
                      }}
                    >
                      {a?.logo && (
                        <img
                          alt="Logo Img"
                          src={`${config.dwdPath}${a.logo}`}
                          style={{ width: "75px", height: "45px" }}
                        />
                      )}
                      <span>{a.name}</span>
                    </span>
                  ))
                : null}
            </div>
          </div>
          <Stack gap={1}>
            <Box display="flex" gap="20px">
              <Box mt="auto" mb="auto">
                X-axis
              </Box>
              <Box p="10px" border="1px solid grey" textAlign="center">
                {competitorPositioningAxes[6]?.logo && (
                  <img
                    alt="Logo Img"
                    src={`${config.dwdPath}${competitorPositioningAxes[6].logo}`}
                    style={{ width: "75px", height: "45px" }}
                  />
                )}
                <Box>{competitorPositioningAxes[6]?.name || ""}</Box>
              </Box>
            </Box>
            <Box display="flex" gap="20px">
              <Box mt="auto" mb="auto">
                Y-axis
              </Box>
              <Box p="10px" border="1px solid grey" textAlign="center">
                {competitorPositioningAxes[7]?.logo && (
                  <img
                    alt="Logo Img"
                    src={`${config.dwdPath}${competitorPositioningAxes[7].logo}`}
                    style={{ width: "75px", height: "45px" }}
                  />
                )}
                <Box>{competitorPositioningAxes[7]?.name || ""}</Box>
              </Box>
            </Box>
          </Stack>
        </Box>
      ) : null}
    </Stack>
  );
};

export default Step9;
