import { Avatar, Divider, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CustomSelect from "../../../ui/formgroup/CustomSelect";
import FormGroupTextField from "../../../ui/formgroup/FormGroupTextField";
import SelectButton from "../../../ui/button/SelectButton";
import { ToolStatusOptions, ToolType } from "../../../../utils/AllOptions";
import { api } from "../../../../redux/api/api";
import { useState, useEffect } from "react";
import moment from "moment";
import { updateRp } from "../../../../utils/handleRPUpdate";
import { useSelector } from "react-redux";
import DropDownMenu from "../../../ui/menus/DropDownMenu";

const useStyles = makeStyles((theme) => ({
  divider: {
    backgroundColor: "#FFED71",
  },
  avatar: {
    fontFamily: theme.typography.fontFamily,
    background: `#D40841 !important`,
    color: "white !important",
    fontSize: "16px !important",
  },
  mySelect: {
    backgroundColor: "#f5f5f5 !important",
    borderRadius: "10px !important",
    borderColor: "#f5f5f5 !important",
    "& fieldset": {
      borderColor: "#f5f5f5",
      borderRadius: "10px !important",
    },
  },
}));
function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
  };
}
const PTList = (props) => {
  const classes = useStyles();
  const [dueDate, setDueDate] = useState(props.dueDate || "");
  const [status, setStatus] = useState(props.status || "");
  const [leaderId, setLeaderId] = useState(props.leaderId || "");
  const [enchorEl, setEnchorEl] = useState(null);
  const [openP, setOpenP] = useState(false);
  const users = useSelector((state) => state.client.users);
  const [udata, setUdata] = useState([]);

  useEffect(() => {
    if (users && users.length) {
      setUdata(() =>
        users.map((a) => ({
          uuid: a.uuid,
          name: `${a.firstName} ${a.lastName}`,
        }))
      );
    }
  }, [users]);

  const findIdx =
    users && users.length ? users.findIndex((a) => a.uuid === leaderId) : -1;
  const handleApi = async (data) => {
    if (props.uuid) {
      const res = await api(
        `tools/${props.uuid}`,
        data,
        "patch",
        "",
        "",
        "Tool updated!"
      );
      if (res && res.success) {
        if (data.dueDate) {
          setDueDate(() => data.dueDate);
          updateRp(
            "dueDate",
            data.dueDate,
            props.projectId,
            "tool",
            props.uuid
          );
        } else if (data.leaderId) {
          setLeaderId(data.leaderId);
          updateRp(
            "leaderId",
            data.leaderId,
            props.projectId,
            "tool",
            props.uuid
          );
        } else {
          setStatus(() => data.status);
          updateRp("status", data.status, props.projectId, "tool", props.uuid);
        }
      }
    }
  };
  return (
    <>
      <Grid item lg={4} md={3} sm={3} xs={3} margin="auto 0">
        <div className={props.generalClass.res} onClick={props.openLoc}>
          {props.type ? ToolType[props.type] : "-"}
        </div>
        <div className={props.generalClass.nameDate}>{props.name || "-"}</div>
      </Grid>
      <Grid item lg={2} md={1.5} sm={1.5} xs={1.5} margin="auto 0">
        <div className={props.generalClass.provider}>
          {props.template || "-"}
        </div>
      </Grid>
      <Grid item lg={2} md={2.5} sm={2.5} xs={2.5} margin="auto 0">
        <div style={{ width: "90%" }}>
          <FormGroupTextField
            size="small"
            type="date"
            tf={true}
            value={dueDate ? moment(dueDate).format("YYYY-MM-DD") : ""}
            onChange={(e) =>
              handleApi({ dueDate: moment(e).format("YYYY-MM-DD[T]HH:mm:ss") })
            }
            other={{
              InputProps: {
                inputProps: { min: moment().format("YYYY-MM-DD") },
              },
            }}
          />
        </div>
      </Grid>
      <Grid item lg={1} md={1} sm={1} xs={1} margin="auto 0">
        {findIdx !== -1 && (
          <Avatar
            {...stringAvatar(
              `${users[findIdx].firstName.trim()} ${users[
                findIdx
              ].lastName.trim()}`
            )}
            onClick={(event) => {
              setEnchorEl(event.currentTarget);
              setOpenP(true);
            }}
          />
        )}
        <DropDownMenu
          enchorEl={enchorEl}
          openP={openP}
          handleClose={() => {
            setEnchorEl(null);
            setOpenP(false);
          }}
          options={udata}
          val={leaderId}
          handleSelect={(v) => {
            setEnchorEl(null);
            setOpenP(false);
            handleApi({ leaderId: v.uuid });
          }}
        />
      </Grid>
      <Grid item lg={2} md={2} sm={2} xs={2} margin="auto 0" pr={2}>
        <CustomSelect
          customClass={classes.mySelect}
          size="small"
          options={ToolStatusOptions}
          value={status}
          onChange={(e) => handleApi({ status: e })}
        />
      </Grid>
      <Grid item lg={1} md={2} sm={2} xs={2} margin="auto 0">
        <SelectButton
          title="Open"
          titleClick={props.openLoc}
          color="yellow"
          dropClick={(e) =>
            props.handleClick(e, {
              id: props.uuid || "",
              name: props.name || "",
              type: props.type || "",
            })
          }
        />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} mb={2} mt={2}>
        <Divider variant="fullWidth" className={classes.divider} />
      </Grid>
    </>
  );
};

export default PTList;
