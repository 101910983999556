import { Grid, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/styles";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import useBindActionCreator from "../../../../hooks/useBindActionCreator";
import { getTemplates } from "../../../../redux/actions/template/templateActions";
import { SET_ACTIVE_SIDEBAR_SECTION } from "../../../../redux/constants";
import { ToolType, ToolTypeRev } from "../../../../utils/AllOptions";
import CommonTitle from "../../../common/CommonTitle";
import Loader from "../../../ui/loader/Loader";
import CustomTool from "../../../ui/modals/CustomTool";
import IntialToolDetails from "../../../ui/modals/IntialToolDetails";
import ATCard from "./ATCard";

const tools = [
  {
    title: "Brand Strategy",
    desc: "To get the most out of your brand strategy workshop werecommend you run a preliminary Discovery Session with your client. This is best done in person, but can also be facilitated by phone, video conference or even email.",
    commingSoon: false,
  },
  {
    title: "Creative Brief",
    desc: "Outline the strategy of the brand and creative requirements for your project.",
    commingSoon: false,
  },
  {
    title: "Media Brief",
    desc: "Outline the strategy of the brand and the media requirements for your project.",
    commingSoon: false,
  },
  {
    title: "Creative Competitive Overview",
    desc: "A great visualization tool to upload competitive brand work, whether websites, social media, digital, TVC's or press ads. Upload the creative and write your review.",
    commingSoon: false,
  },
  {
    title: "Competitive Mapping",
    desc: "A great analysis and visualization tool to bench mark the brand against competitors.",
    commingSoon: false,
  },
  {
    title: "Script Template",
    desc: "A template structure designed for writing radio ads, scripts, or script guidance for influencers.",
    commingSoon: false,
  },
  {
    title: "Custom Tool",
    desc: "Create your own tool. Using a custom template for your custom tool opens up a whole new way to pre-fill strategy workshop with your client and gain the details you need fora successful brand.",
    commingSoon: false,
  },
  // {
  //   title: "Public Relations (PR)",
  //   desc: "Gain a competitive edge for your clients by harnessing the incredible data power of Impact Score. Use lookalike target audiences to pin point the very people your clients want to engage with.",
  //   commingSoon: true,
  // },
  {
    title: "Feedback Pro",
    desc: "Upload your artwork PDF to gain feedback and approval from the client directly. They can mark up your files, provide feedback where you can comment, then gain approval all in the one tool.",
    commingSoon: false,
  },
];

const ATRightComponent = () => {
  const theme = useTheme();
  const ptools = useSelector((state) => state.project?.project?.tools);
  const matches = useMediaQuery(theme.breakpoints.down("lg"));
  const actions = useBindActionCreator({ getTemplates });
  const loc = useNavigate();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [brandOpen, setBrandOpen] = useState(false);
  const [customToolOpen, setCustomToolOpen] = useState(false);
  const [toolTitle, setTitle] = useState("");

  const getData = async () => {
    setLoading(true);
    await actions.getTemplates();
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const handleClick = (title) => {
    setTitle(title);
    switch (title) {
      case tools[0].title:
        setBrandOpen(true);
        break;

      case tools[1].title:
        setBrandOpen(true);
        break;

      case tools[2].title:
        setBrandOpen(true);
        break;

      case tools[3].title:
        setBrandOpen(true);
        break;

      case tools[4].title:
        setBrandOpen(true);
        break;

      case tools[5].title:
        setBrandOpen(true);
        break;

      case tools[6].title:
        // loc("/templates/custom-template");
        setCustomToolOpen(true);
        break;

      case tools[7].title:
        dispatch({
          type: SET_ACTIVE_SIDEBAR_SECTION,
          payload: { data: "Feedback Pro" },
        });
        loc("/projects/artwork-people");
        break;

      case tools[8].title:
        loc("/tools/pr-tools");
        break;
      default:
        break;
    }
  };
  const checkExist = (type) => {
    let exist = false;
    const tTOc = ToolType["BRAND_STRATEGY"] === type && ToolTypeRev[type];
    if (ptools && ptools.length && tTOc) {
      const find = ptools.findIndex((a) => a.type === tTOc);
      if (find !== -1) {
        exist = true;
      }
    }
    return exist;
  };
  return loading ? (
    <Loader />
  ) : (
    <Grid
      pt={"3rem !important"}
      pl={matches ? 4 : 6}
      container
      item
      lg={10}
      md={10}
      sm={10}
      xs={10}
      textAlign="left"
    >
      <CommonTitle />
      {tools.map((a) => (
        <ATCard
          key={a.title}
          {...a}
          handleClick={() => handleClick(a.title)}
          checkExist={checkExist(a.title)}
        />
      ))}
      <IntialToolDetails
        open={brandOpen}
        onClose={() => setBrandOpen(false)}
        toolTitle={toolTitle}
      />
      <CustomTool
        open={customToolOpen}
        onClose={() => setCustomToolOpen(false)}
      />
    </Grid>
  );
};

export default ATRightComponent;
