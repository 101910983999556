import { Box, Grid } from "@mui/material";
import { useRef } from "react";
import { Delete } from "@mui/icons-material";
import { v4 as uuidv4 } from "uuid";
import CustomButton from "../../../../../../ui/button/CustomButton";
import FormGroupTextField from "../../../../../../ui/formgroup/FormGroupTextField";
import { config } from "../../../../../../../config";

const DragDrop = (props) => {
  const dragItem = useRef();
  const dragOverItem = useRef();
  const dragStart = (e, position) => {
    dragItem.current = position;
  };

  const dragEnter = (e, position) => {
    dragOverItem.current = position;
  };

  const drop = (left) => {
    const copyListItems = [...props.data];
    const dragItemContent = copyListItems[dragItem.current];
    if (left && dragItemContent.loaded === false && dragItemContent.name) {
      dragItemContent.loaded = true;
      copyListItems.splice(dragItem.current, 1);
      copyListItems.splice(dragOverItem.current, 0, dragItemContent);
      dragItem.current = null;
      dragOverItem.current = null;
      props.setItemOnDrag(copyListItems);
    } else if (
      !left &&
      dragItemContent.loaded === true &&
      dragItemContent.loaded
    ) {
      dragItemContent.loaded = false;
      copyListItems.splice(dragItem.current, 1);
      copyListItems.splice(dragOverItem.current, 0, dragItemContent);
      dragItem.current = null;
      dragOverItem.current = null;
      props.setItemOnDrag(copyListItems);
    }
  };

  return (
    <Grid container columnSpacing={2} my={2}>
      <Grid item lg={6} md={6} sm={6} xs={6}>
        <Box textAlign="right" height="40px">
          {props?.leftText}
          <CustomButton
            name={props.btnText || ""}
            onclick={() => {
              const data = [...props.data];
              data.push({
                name: "",
                logo: "",
                uuid: uuidv4(),
                loaded: false,
              });
              props.setItemOnDrag(data);
            }}
          />
        </Box>{" "}
        <Box
          height="416px"
          overflow="auto"
          padding="10px"
          borderRadius="8px"
          mt={4}
          style={{
            background: "white",
          }}
          onDragEnd={() => drop(true)}
          onDragOver={(e) => e.preventDefault()}
        >
          {props.data && props.data.length
            ? props.data.map((a, index) => (
                <Box
                  className={`arrange-container`}
                  style={{
                    background: "#f5f5f5",
                  }}
                  onDragStart={(e) => dragStart(e, index)}
                  onDragEnter={(e) => dragEnter(e, index)}
                  onDragOver={(e) => e.preventDefault()}
                  key={index}
                  draggable
                >
                  <Box
                    margin="auto 0"
                    display="flex"
                    alignItems="center"
                    p={1}
                    gap={1}
                    width="100%"
                  >
                    <Box
                      mt="auto"
                      mb="auto"
                      width="100px"
                      height="50px"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      {a.logo ? (
                        <img
                          maxWidth="100%"
                          alt="Competitor Logo"
                          src={config.generatePathDownloadPath(a.logo)}
                        />
                      ) : (
                        index + 1
                      )}
                    </Box>
                    <FormGroupTextField
                      placeholder="insert text"
                      value={a.name}
                      size="small"
                      onChange={(e) => {
                        const data = [...props.data];
                        data[index].name = e;
                        props.setItemOnDrag(data);
                      }}
                    />
                  </Box>
                  <Box margin="auto 0" ml="auto" display="flex">
                    <Delete
                      className={"blac-iconarrow"}
                      onClick={() => {
                        const data = [...props.data];
                        data.splice(index, 1);
                        props.setItemOnDrag(data);
                      }}
                    />
                  </Box>
                </Box>
              ))
            : null}
        </Box>
      </Grid>
      <Grid item lg={6} md={6} sm={6} xs={6}>
        <Box height="40px" mb={2}>
          {props.rightText || ""}
        </Box>
        <Box
          height="416px"
          overflow="auto"
          padding="10px"
          borderRadius="8px"
          mt={4}
          style={{
            background: "white",
          }}
          onDragEnd={() => drop(false)}
          onDragOver={(e) => e.preventDefault()}
        >
          {" "}
          {props.data && props.data.length
            ? props.data.map((a, index) =>
                a.loaded ? (
                  <Box
                    className={`arrange-container`}
                    onDragStart={(e) => dragStart(e, index)}
                    onDragEnter={(e) => dragEnter(e, index)}
                    onDragOver={(e) => e.preventDefault()}
                    key={index}
                    draggable
                  >
                    <Box
                      mt="auto"
                      mb="auto"
                      width="50px"
                      height="40px"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      marginRight="16px"
                    >
                      {a.logo && (
                        <img
                          maxWidth="100%"
                          alt="Competitor Logo"
                          src={config.generatePathDownloadPath(a.logo)}
                        />
                      )}
                    </Box>
                    <Box margin="auto 0">{a.name || ""}</Box>
                  </Box>
                ) : null
              )
            : null}
        </Box>
      </Grid>
    </Grid>
  );
};

export default DragDrop;
