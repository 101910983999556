export const SidebargNavLayoutData = [
  // {
  //   name: "Project",
  //   path: "/projects",
  //   activePaths: ["/projects"],
  //   isChild: false,
  // },
  {
    name: "Research",
    path: "/projects/research",
    activePaths: ["/projects/research"],
    isChild: false,
  },
  {
    name: "Tools",
    path: "/projects/add-tool",
    activePaths: ["/projects/add-tool"],
    isChild: false,
  },
  {
    name: "Folders",
    path: "",
    activePaths: [],
    isChild: false,
  },
  {
    name: "Feedback Pro",
    path: "/projects/artwork-people",
    activePaths: [
      "/projects/artwork-people",
      "/projects/review-feedback",
      "/projects/approval",
    ],
    isChild: true,
    children: [
      {
        name: "Artwork & People",
        path: "/projects/artwork-people",
      },
      {
        name: "Review & Feedback",
        path: "/projects/review-feedback",
      },
      {
        name: "Approval",
        path: "/projects/approval",
      },
    ],
  },
  {
    name: "Settings",
    path: "/projects/settings",
    activePaths: [
      "/projects/settings",
      "/projects/settings/core-customers",
      "/projects/settings/research",
      "/projects/settings/competitors",
      "/projects/settings/user-access",
    ],
    isChild: true,
    children: [
      {
        name: "Overview",
        path: "/projects/settings",
      },
      {
        name: "Core Customers",
        path: "/projects/settings/core-customers",
      },
      {
        name: "Research",
        path: "/projects/settings/research",
      },
      {
        name: "Competitors",
        path: "/projects/settings/competitors",
      },
      {
        name: "User Access",
        path: "/projects/settings/user-access",
      },
    ],
  },
];
