import { makeStyles } from "@mui/styles";

const useStyle = makeStyles((theme) => ({
  footer: {
    width: "100%",
    textAlign: "left",
    margin: "auto",
    background: "#F4F4F4",
    height: "231px",
    minWidth: "1024px",
    marginTop: "10px",
  },
  footerInnerDiv: {
    padding: "40px 30px",
  },
}));
const PrivateFooter = () => {
  const classes = useStyle();

  return (
    <footer className={classes.footer}>
      <div className={classes.footerInnerDiv}>
        {" "}
        Copyright {new Date().getFullYear()} Brandvas |{" "}
        <a
          href="https://www.brandvas.io/resources"
          target="_blank"
          rel="noreferrer"
        >
          Resources
        </a>{" "}
        |{" "}
        <a href="https://www.brandvas.io/" target="_blank" rel="noreferrer">
          Visit our website
        </a>
      </div>
    </footer>
  );
};

export default PrivateFooter;
