import { Box, Grid, IconButton, Stack } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useEffect } from "react";
import { useState } from "react";
import CustomButton from "../../../../../ui/button/CustomButton";
import FormGroupTextField from "../../../../../ui/formgroup/FormGroupTextField";
import TextAutoResize from "../../../../../ui/formgroup/TextAutoResize";
import OpeningScreen from "../OpeningScreen";
import { v4 as uuidv4 } from "uuid";

const BrandProofPoints = (props) => {
  const [profOfPointData, setProfOfPointData] = useState([]);
  const [differentiatorsData, setDifferentiatorsData] = useState([]);
  const [essence, setEssence] = useState("");
  const [proposition, setProposition] = useState("");
  const [step, setStep] = useState(0);
  useEffect(() => {
    let n = 3;
    let brandDifferentiatorsArray = [];

    if (props.td && props.td.data) {
      if(props.td.data.brandProofPoints) { 
        setEssence(props.td.data.brandProofPoints.brandEssence);
        setProposition(props.td.data.brandProofPoints.brandValueProposition);
      } else {
        if(props.td.data.brandEssenceRefinement && props.td.data.brandEssenceRefinement.final && props.td.data.brandEssenceRefinement.final.length > 0) {
          setEssence(props.td.data.brandEssenceRefinement.final[0].name);
        }
        if(props.td.data.differentiatedBrandValueProposition) {
          setProposition(props.td.data.differentiatedBrandValueProposition);
        }
      }
    }
    if (
      (props.td && props.td.data && props.td.data.brandProofPoints === null) ||
      (props.td &&
        props.td.data &&
        props.td.data.brandProofPoints &&
        props.td.data.brandProofPoints.brandDifferentiators &&
        props.td.data.brandProofPoints.brandDifferentiators.length === 0)
    ) {

      if(props.td.data && props.td.data.brandEssence && props.td.data.brandEssence.brandDifferentiators && props.td.data.brandEssence.brandDifferentiators.length > 0) {
        for(var i = 0; i < props.td.data.brandEssence.brandDifferentiators.length; i++) {
          if(props.td.data.brandEssence.brandDifferentiators[i].name != "") {
            var name = props.td.data.brandEssence.brandDifferentiators[i].name;
            var uuid = props.td.data.brandEssence.brandDifferentiators[i].uuid;
            
            brandDifferentiatorsArray.push({
              name: name,
              uuid: uuid
            }); 
          }
        }
      } else {
        for (let i = 1; i <= n; i++) {
          brandDifferentiatorsArray.push({
            name: "",
            uuid: uuidv4(),
          });
        }
      }
    } else {
      let tData =
        props.td &&
        props.td.data &&
        props.td.data.brandProofPoints &&
        props.td.data.brandProofPoints.brandDifferentiators;
      brandDifferentiatorsArray = tData;
      let len = tData.length;
      for (let i = 1; i <= n - len; i++) {
        brandDifferentiatorsArray.push({
          name: "",
          uuid: uuidv4(),
        });
      }
    }
    setDifferentiatorsData(brandDifferentiatorsArray);

    let n1 = 1;
    let brandProofPointsArray = [];
    if (
      (props.td && props.td.data && props.td.data.brandProofPoints === null) ||
      (props.td &&
        props.td.data &&
        props.td.data.brandProofPoints &&
        props.td.data.brandProofPoints.brandDifferentiators &&
        props.td.data.brandProofPoints.brandProofPoints.length === 0)
    ) {


      for (let i = 1; i <= n1; i++) {
        brandProofPointsArray.push({
          name: "",
          uuid: uuidv4(),
        });
      }
    } else {
      let tData =
        props.td &&
        props.td.data &&
        props.td.data.brandProofPoints &&
        props.td.data.brandProofPoints.brandProofPoints;
      brandProofPointsArray = tData;
      let lenn = tData.length;
      for (let i = 1; i <= n1 - lenn; i++) {
        brandProofPointsArray.push({
          name: "",
          uuid: uuidv4(),
        });
      }
    }
    setProfOfPointData(brandProofPointsArray);
  }, [step]);

  const handleDifferentiatorsData = (idx, key, val) => {
    let array = [...differentiatorsData];
    array[idx][key] = val;
    setDifferentiatorsData(array);
  };

  const handleProfOfPointData = (idx, key, val) => {
    let array = [...profOfPointData];
    array[idx][key] = val;
    setProfOfPointData(array);
  };

  const addAnotherBrandProofPoint = (idx, key, val) => {
    let array = [...profOfPointData];
    array.push({
      name: "",
      uuid: uuidv4(),
    });
    setProfOfPointData(array);
  };

  return (
    <>
      {step === 0 ? (
        <OpeningScreen
          title="Your Brand Proof Points"
          description={
            <>
              To demonstrate your commitment to the chosen brand essence, values
              and personality, write a list of the things you will do to bring
              these to life.
            </>
          }
          img={props?.sas?.img || ""}
          bestPratice={props?.sas?.workshop || false}
          bestEx={props?.sas?.workshopImg || []}
          name={props?.sas?.name || ""}
        />
      ) : (
        <Stack>
          <Box>
            <Box color="secondary.main" fontSize="20px" fontWeight="800px">
              Your Brand Proof Points
            </Box>
            <Grid container pt={4}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box marginTop={"30px !important"}>
                  To demonstrate your commitment to the chosen brand essence,
                  values and personality, write a list of the things you will do
                  to bring these to life. Try to provide between four and eight
                  proof points.
                </Box>
                <br />
                <Box>
                  <TextAutoResize
                    label="Your brand essence"
                    other={{ minRows: 6, maxRows: 6 }}
                    value={essence}
                    onChange={(e) => setEssence(e)}
                  />
                </Box>
                <br />
                <Box
                  fontSize="14px !important"
                  fontFamily="Rubik"
                  fontWeight="400"
                  marginBottom="12px"
                >
                  Your brand differentiators
                </Box>

                <Stack
                  border="1px solid"
                  borderRadius={"10px"}
                  gap="10px"
                  padding={2}
                >
                  {differentiatorsData &&
                    differentiatorsData.map((item, index) => (
                      <FormGroupTextField
                        placeholder="Your brand differentiators"
                        type="text"
                        value={item.name}
                        onChange={(e) =>
                          handleDifferentiatorsData(index, "name", e)
                        }
                      />
                    ))}
                </Stack>
                <br />
                <Box>
                  <TextAutoResize
                    label="Your brand value proposition"
                    other={{ minRows: 6, maxRows: 6 }}
                    value={proposition}
                    onChange={(e) => setProposition(e)}
                  />
                </Box>
                <br />
                <Box
                  fontSize="14px !important"
                  fontFamily="Rubik"
                  fontWeight="400"
                  marginBottom="12px"
                >
                  Your brand proof points
                </Box>
                <Box>
                  {profOfPointData &&
                    profOfPointData.map((item, index) => (
                      <>
                        <TextAutoResize
                          placeholder="Your brand proof points"
                          other={{ minRows: 6, maxRows: 6 }}
                          value={item.name}
                          onChange={(e) =>
                            handleProfOfPointData(index, "name", e)
                          }
                        />
                        <br />
                      </>
                    ))}

                  <Box
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      addAnotherBrandProofPoint();
                    }}
                  >  
                    <IconButton edge="end">     
                      {" "}
                      <AddIcon />
                    </IconButton>{" "}
                    &nbsp; Add brand proof point
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Stack>
      )}
      <br />
      <Box display="flex" justifyContent="space-between" mt="auto" mb="0">
        <CustomButton
          name="<   Prev"
          loading={props.btnLoading}
          onclick={async () => {
            if (step === 0) {
              props.prvStep();
            } else {
              await props.callApi({
                brandProofPoints: {
                  brandEssence: essence,
                  brandDifferentiators:
                    differentiatorsData &&
                    differentiatorsData.filter((item) => item.name !== ""),
                  brandValueProposition: proposition,
                  brandProofPoints:
                    profOfPointData &&
                    profOfPointData.filter((item) => item.name !== ""),
                },
              });
              setStep(0);
            }
          }}
        />
        <CustomButton
          name="Next   >"
          onclick={async () => {
            if (step === 1) {
              props.callApi(
                {
                  brandProofPoints: {
                    brandEssence: essence,
                    brandDifferentiators:
                      differentiatorsData &&
                      differentiatorsData.filter((item) => item.name !== ""),
                    brandValueProposition: proposition,
                    brandProofPoints:
                      profOfPointData &&
                      profOfPointData.filter((item) => item.name !== ""),
                  },
                },
                true,
                true
              );
            } else {
              setStep(1);
            }
          }}
          loading={props.btnLoading}
        />
      </Box>
    </>
  );
};

export default BrandProofPoints;
