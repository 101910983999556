import { Avatar, Box, Grid, Stack } from "@mui/material";
import { useState } from "react";
import { ToolOptions, ToolStatusOptions } from "../../../utils/AllOptions";
import SelectButton from "../../ui/button/SelectButton";
import DropDownMenu from "../../ui/menus/DropDownMenu";
import { updateRp } from "../../../utils/handleRPUpdate";
import { api } from "../../../redux/api/api";
import React from "react";
import moment from "moment";
import { useNavigate } from "react-router";
import RenameTool from "../../ui/modals/RenameTool";
import CopyToolToOtherProject from "../../ui/modals/CopyToolToOtherProject";
import DeleteModal from "../../ui/modals/DeleteModal";
import { useSelector } from "react-redux";

const ToolCard = (props) => {
  const loc = useNavigate();
  const ptools = useSelector((state) => state.project?.project?.tools);

  const [enchorEl, setEnchorEl] = useState(null);
  const [openP, setOpenP] = useState(false);

  const [enchorElT, setEnchorElT] = useState(null);
  const [openPT, setOpenPT] = useState(false);
  const [menuVal, setMenuVal] = useState("Open Tool");

  const [delModal, setDelModal] = useState(false);
  const [renameModal, setRenameModal] = useState(false);
  const [copyModal, setCopyModal] = useState(false);
  const [selTool, setSelTool] = useState({ id: "", name: "", type: "" });

  const handleRouting = () => {
    props?.handleRightSelect();
    loc(`/projects/tool/${props?.name}/${props?.type}/${props?.uuid}`);
  };

  const handleSelect = (v) => {
    handleCloseT();
    switch (v) {
      case ToolOptions[0]:
        handleRouting();
        break;
      case ToolOptions[1]:
        setRenameModal(true);
        break;
      case ToolOptions[2]:
        setDelModal(true);
        break;
      case ToolOptions[3]:
        setCopyModal(true);
        break;
      default:
        break;
    }
  };
  const handleClickT = (event, data) => {
    setSelTool(() => data);
    setEnchorElT(event.currentTarget);
    setOpenPT(true);
  };
  const handleCloseT = () => {
    setEnchorElT(null);
    setOpenPT(false);
  };
  const handleCloseWithNewName = (s, name) => {
    if (s && name) {
      updateRp("name", name, props.projectId, "tool", props.uuid);
      props.updateToolName(name);
      setSelTool({ name: "", id: "", type: "" });
      setRenameModal(false);
      props.updateState();
    } else {
      setSelTool({ name: "", id: "", type: "" });
      setRenameModal(false);
    }
  };
  const handleRemoveFromTool = async () => {
    setDelModal(false);
    if (selTool.id) {
      const res = await api(
        `tools/${selTool.id}`,
        "",
        "delete",
        "",
        "",
        "Tool removed!"
      );
      if (res && res.success) {
        if (ptools) {
          const idx = ptools.findIndex((a) => a.uuid == props.uuid);
          if (idx !== -1) {
            ptools.splice(idx, 1);
          }
        }
        props.handleRemoveTool();
      }
      setSelTool({ name: "", id: "", type: "" });
    }
  };

  const [lead, setLead] = useState(false);
  const [aLead, setALead] = useState(props.leaderId || "");
  const [status, setStatus] = useState(props.status || "");
  const handleClick = (lead, event) => {
    setLead(lead);
    setEnchorEl(event.currentTarget);
    setOpenP(true);
  };
  const handleClose = () => {
    setOpenP(() => false);
    setEnchorEl(() => null);
  };
  const handleUpdate = async (data) => {
    if (props.uuid) {
      const res = await api(
        `tools/${props.uuid}`,
        data,
        "patch",
        "",
        "",
        "Tool updated!"
      );
      if (res && res.success) {
        if (data.leaderId) {
          setALead(() => data.leaderId);

          updateRp(
            "leaderId",
            data.leaderId,
            props.projectId,
            "tool",
            props.uuid
          );
        } else {
          setStatus(() => data.status);

          updateRp("status", data.status, props.projectId, "tool", props.uuid);
        }
      }
    }
  };
  const findName = () => {
    let name = "";
    if (aLead && props.udata) {
      let a = props.udata.find((a) => a.uuid === aLead)?.name;
      if (a) {
        name = a;
      }
    }
    return name;
  };

  return (
    <Grid item lg={3} md={4} sm={4} xs={4}>
      <Box
        border="1px solid #FFED71"
        borderRadius="10px"
        padding="10px 20px"
        margin="4px"
        color="secondary.main"
        minHeight="230px"
      >
        <Stack direction="row" justifyContent="space-between" mb={1}>
          <Box fontSize="20px" margin="auto 0">
            {props.name || "-"}
          </Box>
          <SelectButton
            title="Open"
            titleClick={() => handleRouting()}
            color="yellow"
            dropClick={(e) =>
              handleClickT(e, {
                id: props.uuid || "",
                name: props.name || "",
                type: props.type || "",
              })
            }
          />
        </Stack>
        <Stack gap="4px">
          <Box>{props.template || "-"}</Box>
          <Box display="flex" gap="10px">
            <Box margin="auto 0">Status:</Box>
            <Box
              margin="auto 0"
              style={{ cursor: "pointer" }}
              onClick={(e) => handleClick(false, e)}
            >
              {status || "-"}{" "}
            </Box>
          </Box>
          <Box display="flex" gap="10px">
            <Box margin="auto 0">Lead:</Box>
            <Box
              margin="auto 0"
              style={{ cursor: "pointer" }}
              onClick={(e) => handleClick(true, e)}
            >
              {findName() || "-"}
            </Box>
          </Box>
          <Box>
            Updated{" "}
            {props.dateModified
              ? moment(props.dateModified).format("YYYY-MM-DD")
              : "-"}
          </Box>
        </Stack>
        <Stack gap="6px" direction="row" mt={3} mb={3}>
          {findName() ? (
            <Avatar
              sx={{
                background: `#1D2D91 !important`,
                color: "white !important",
                fontSize: "16px !important",
              }}
            >
              {`${findName().split(" ")?.[0]?.[0] || ""}${
                findName().split(" ")?.[1]?.[0] || ""
              }`}
            </Avatar>
          ) : null}
        </Stack>
      </Box>
      <DropDownMenu
        enchorEl={enchorEl}
        openP={openP}
        handleClose={handleClose}
        options={lead ? props.udata : ToolStatusOptions}
        val={lead ? props.lead || "" : props.status || ""}
        handleSelect={(v) => {
          handleClose();
          handleUpdate(lead ? { leaderId: v.uuid } : { status: v });
        }}
      />
      <DropDownMenu
        enchorEl={enchorElT}
        openP={openPT}
        handleClose={handleCloseT}
        options={ToolOptions}
        val={menuVal}
        handleSelect={(v, id) => handleSelect(v, id)}
      />
      <RenameTool
        open={renameModal}
        onClose={handleCloseWithNewName}
        {...selTool}
      />
      <CopyToolToOtherProject
        open={copyModal}
        onClose={() => {
          setCopyModal(false);
          setSelTool({ name: "", id: "", type: "" });
        }}
        {...selTool}
        handleForceUpdate={props.handleForceUpdate}
        projectId={props.projectId}
      />
      <DeleteModal
        title="Are you sure you want to remove this tool
        from the project?"
        open={delModal}
        onClose={() => {
          setDelModal(false);
          setSelTool({ name: "", id: "", type: "" });
        }}
        onDelete={handleRemoveFromTool}
      />
    </Grid>
  );
};

export default React.memo(ToolCard);
