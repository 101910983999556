import { Box, Card, CardContent, Grid, Stack, Typography } from "@mui/material";

const Step3 = (props) => {
  let brandValues =
    props &&
    props.td &&
    props.td.data &&
    props.td.data.brandEssence &&
    props.td.data.brandEssence.brandValues;
  let final =
    props &&
    props.td &&
    props.td.data &&
    props.td.data.brandEssenceRefinement &&
    props.td.data.brandEssenceRefinement.final;

  return (
    <Stack mb={2}>
      <Box>
        <Box color="secondary.main" fontSize="20px" fontWeight="800px">
          Your Brand Essence
        </Box>
        <Grid container pt={4}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box>
              Now you've finalized your brand essence discuss the ways it can
              bring your brand values to life in both the behaviors and actions
              it should encourage (on-brand) and the behaviors and actions it
              should discourage (off brand).
            </Box>
            <br />
          </Grid>
          <Grid container>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Card
                sx={{
                  marginLeft: "10px",
                  marginRight: "20px",
                  height: "150px",
                }}
              >
                <CardContent>
                  <Typography
                    sx={{ fontSize: 14 }}
                    color="text.secondary"
                    gutterBottom
                    className="essence"
                  >
                    <strong>{brandValues && brandValues[0] && brandValues[0].name}</strong>
                    {brandValues && brandValues[0] && brandValues[0].value}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Card
                sx={{
                  marginLeft: "20px",
                  marginRight: "10px",
                  height: "150px",
                  textAlign: "right",
                }}
              >
                <CardContent>
                  <Typography
                    sx={{ fontSize: 14 }}
                    color="text.secondary"
                    gutterBottom
                    className="essence"
                  >
                    <strong>{brandValues && brandValues[1] && brandValues[1].name}</strong>
                    {brandValues && brandValues[1] && brandValues[1].value}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item lg={3} md={3} sm={3} xs={3}></Grid>
            <Grid item lg={6} md={6} sm={6} xs={6} style={{ zIndex: "1" }}>
              <Card
                sx={{
                  marginTop: "-50px",
                  height: "150px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: 14,
                    marginTop: "49px",
                    padding: "20px",
                    textAlign: "center",
                  }}
                  color="text.secondary"
                  gutterBottom
                >
                  {final && final[0].name}
                </Typography>
              </Card>
            </Grid>
            <Grid item lg={3} md={3} sm={3} xs={3}></Grid>
          </Grid>
          <Grid container>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Card
                sx={{
                  marginLeft: "10px",
                  marginRight: "20px",
                  marginTop: "-50px",
                  height: "150px",
                  textAlign: "bottom",
                }}
              >
                <Typography
                  sx={{
                    fontSize: 14,
                    marginTop: "79px",
                    padding: "20px",
                    textAlign: "left",
                  }}
                  color="text.secondary"
                  className="essence"
                  gutterBottom
                >
                  <strong>{brandValues && brandValues[2] && brandValues[2].name}</strong>
                  {brandValues && brandValues[2] && brandValues[2].value}
                </Typography>
              </Card>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Card
                sx={{
                  marginLeft: "20px",
                  marginRight: "10px",
                  marginTop: "-50px",
                  height: "150px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: 14,
                    marginTop: "79px",
                    padding: "20px",
                    textAlign: "right",
                  }}
                  color="text.secondary"
                  gutterBottom
                  className="essence"
                >
                  <strong>{brandValues && brandValues[3] && brandValues[3].name}</strong>
                  {brandValues && brandValues[3] && brandValues[3].value}
                </Typography>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Stack>
  );
};

export default Step3;
