import { Grid } from "@mui/material";
import CustomButton from "../../../ui/button/CustomButton";
import ImgDelContainer from "../../../ui/imgDelContainer/ImgDelContainer";
import SettingSlideExpanded from "./SettingSlideExpanded";
import Spiner from "react-spinners/ClipLoader";

import { makeStyles } from "@mui/styles";
import CustomDropzone from "../../../ui/dropzone/CustomDropzone";
import { useState } from "react";
import { awsUpload } from "../../../../utils/s3upload";
import { useRef } from "react";

const useStyles = makeStyles((theme) => {
  return {
    formTitle: {
      fontSize: "14px",
      marginBottom: "12px",
    },
    LargeImg: {
      width: "100%",
      height: "auto",
    },
    saveBtn: {
      width: "190px",
      marginTop: "12px !important",
    },
    img: {
      width: "200px",
      height: "130px",
    },
    smallImgContainer: {
      display: "flex",
      gap: "12px",
      flexWrap: "wrap",
    },
    img: {
      maxHeight: "568px",
      maxWidth: "90%",
      margin: "auto",
      flex: "1",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: "20px",
      borderWidth: "2px",
      borderRadius: "2px",
      borderColor: "#eeeeee",
      borderStyle: "dashed",
      backgroundColor: "#fafafa",
      color: "#bdbdbd",
      outline: "none",
      transition: "border 0.24s ease-in-out",
    },
  };
});

const BrandArch = ({ data, enums, handleUpdate, loading }) => {
  const classes = useStyles();
  const inputFile = useRef(null);

  const [lLoading, setLLoding] = useState(false);
  const [rLoading, setRLoading] = useState(false);

  const handleDrop = async (e) => {
    if (e && e[0]) {
      setLLoding(true);
      const fileData = new FormData();
      fileData.append("file", e[0]);
      const fd = await awsUpload(fileData);
      if (fd) {
        handleUpdate({ img: fd });
        setLLoding(false);
      }
    }
  };

  const handleFileUpload = async (e) => {
    const { files } = e.target;
    if (files && files.length) {
      setRLoading(true);
      const fileData = new FormData();
      fileData.append("file", files[0]);
      const fd = await awsUpload(fileData);
      if (fd) {
        const dd = [...data.workshopImg];
        handleUpdate({ workshopImg: dd });
        setRLoading(false);
      }
    }
  };

  const onButtonClick = () => {
    inputFile.current.click();
  };

  return (
    <SettingSlideExpanded
      title={enums[data.name] || ""}
      includes={data.include}
      handleIncludes={(e) => handleUpdate({ include: e })}
      loading={loading}
    >
      <Grid
        item
        lg={data.workshop ? 4 : 12}
        md={data.workshop ? 6 : 12}
        sm={data.workshop ? 6 : 12}
        xs={data.workshop ? 6 : 12}
      >
        <div className={classes.formTitle}>
          Replace with your own introduction image. (1800x1200px)
        </div>
        {lLoading ? (
          <Spiner />
        ) : data.img ? (
          <ImgDelContainer
            src={
              data.img
                ? data.img.includes("static/openingScreens")
                  ? `/${data.img}${data.name === "market" ? ".png" : ""}`
                  : data.img
                : ""
            }
            className={classes.LargeImg}
            small={data.workshop ? true : false}
            handleDel={() => handleUpdate({ img: "" })}
          />
        ) : (
          <CustomDropzone
            isMulti={false}
            maxSize={1000000}
            handleDrop={handleDrop}
            accept={{
              "image/png": [".png", ".jpg"],
            }}
            component={
              <div className="dropzone" style={{ width: "90%" }}>
                <p>Drag 'n' drop some files here, or click to select files</p>
              </div>
            }
          />
        )}
        {/* <CustomButton
          name="Save"
          className={classes.saveBtn}
          onclick={() => onButtonClick()}
        /> */}
      </Grid>
      {data.workshop ? (
        <Grid item lg={8} md={6} sm={6} xs={6} pl={3}>
          <input
            type="file"
            id="file"
            ref={inputFile}
            onChange={handleFileUpload}
            style={{ display: "none" }}
          />
          <div className={classes.formTitle}>
            Replace with your own {enums[data.name] || ""} examples.
            (1800x1200px)
          </div>
          <div className={classes.smallImgContainer}>
            {rLoading ? (
              <Spiner />
            ) : data.workshopImg && data.workshopImg.length ? (
              data.workshopImg.map((a, idx) => (
                <ImgDelContainer
                  newSmall={true}
                  src={
                    a ? (a.includes("static/openingScreens") ? `/${a}` : a) : ""
                  }
                  className={classes.img}
                  handleDel={() => {
                    const d = [...data.workshopImg];
                    d.splice(idx, 1);
                    handleUpdate({ workshopImg: d });
                  }}
                />
              ))
            ) : null}
          </div>
          <CustomButton
            name="Add Another"
            className={classes.saveBtn}
            onclick={onButtonClick}
          />
        </Grid>
      ) : null}
    </SettingSlideExpanded>
  );
};

export default BrandArch;
