import { List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { SET_ACTIVE_SIDEBAR_SECTION } from "../../redux/constants";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { ToolType } from "../../utils/AllOptions";

const useStyle = makeStyles((theme) => ({
  sidebarListItem: {
    paddingLeft: "3rem !important",
    "&:hover": {
      backgroundColor: "#E2E3EC !important",
    },
  },
  sidebarListItemActive: {
    backgroundColor: "#E2E3EC !important",
  },
  sidebarListItemText: {
    fontSize: "14px !important",
    color: "#1D2D91 !important",
    fontFamily: "Rubik !important",
  },
  sidebarListSubItem: {
    paddingLeft: "3rem !important",
    paddingTop: "0px !important",
    paddingBottom: "0px !important",
    fontSize: "12px !important",
    "&:hover": {
      backgroundColor: "#F5F5F5 !important",
    },
  },
  sidebarListSubItemText: {
    fontSize: "12px !important",
    color: "#1D2D91 !important",
    fontFamily: "Rubik !important",
  },
  sidebarListItemWorkShopSection: {
    fontSize: "12px !important",
    marginBottom: "6px !important",
    background: "#F8F3CD !important",
    "&:hover": {
      backgroundColor: "#F8F3CD !important",
    },
  },
  sidebarListSubItemActive: {
    fontWeight: 600,
  },
}));
const addToolPathName = "/projects/add-tool";

const SideBarComponent = (props) => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const activeSidebarSection = useSelector(
    (state) => state.sideBar.activeSidebarSection
  );

  const { subTitle } = useSelector((state) => state.titleH, shallowEqual);

  const sidebarLayoutData = useSelector(
    (state) => state.sideBar.sidebarLayoutData
  );
  const loc = useLocation().pathname;
  const history = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const aid = searchParams.get("aid");

  useEffect(() => {
    if (
      activeSidebarSection === "Settings" &&
      loc !== "/projects/settings" &&
      loc === "/projects"
    ) {
      history("/projects/settings");
    }
  }, [activeSidebarSection, loc, history]);

  return (
    <List>
      {sidebarLayoutData?.map((item, index) => (
        <>
          {!props.full && (
            <ListItem key={item.name} disablePadding>
              <ListItemButton
                onClick={(event) => {
                  dispatch({
                    type: SET_ACTIVE_SIDEBAR_SECTION,
                    payload: { data: item.name },
                  });
                  if (item.path === "/projects/settings") {
                    history("/projects/settings");
                  } else {
                    history("/projects");
                  }
                }}
                className={
                  item.name === activeSidebarSection
                    ? classes.sidebarListItem +
                      " " +
                      classes.sidebarListItemActive
                    : classes.sidebarListItem
                }
              >
                <ListItemText
                  disableTypography
                  className={classes.sidebarListItemText}
                  primary={item.name}
                />
              </ListItemButton>
            </ListItem>
          )}

          {!props.full &&
            item.isChild === true &&
            item?.activePaths?.includes(loc?.replaceAll("%20", " ")) && (
              <>
                {props?.steps && (
                  <ListItem key={subTitle} disablePadding>
                    <ListItemButton
                      className={
                        classes.sidebarListItemWorkShopSection +
                        " " +
                        classes.sidebarListItem
                      }
                    >
                      <ListItemText
                        disableTypography
                        className={classes.sidebarListSubItemText}
                        primary={subTitle}
                      />
                    </ListItemButton>
                  </ListItem>
                )}

                {loc !== addToolPathName &&
                  item?.children?.map((cItem, cIndex) => {
                    return activeSidebarSection === "Feedback Pro" ? (
                      cItem.name === "Artwork & People" ||
                      (aid && cItem.name === "Review & Feedback") ||
                      (aid && cItem.name === "Approval") ? (
                        <ListItem key={cItem.name} disablePadding>
                          <ListItemButton
                            onClick={(event) => {
                              if (aid) {
                                history(`${cItem.path}?aid=${aid}`);
                              } else {
                                history(cItem.path);
                              }
                            }}
                            className={classes.sidebarListSubItem}
                          >
                            <ListItemText
                              disableTypography
                              className={
                                cItem.path === loc?.replaceAll("%20", " ")
                                  ? classes.sidebarListSubItemText +
                                    " " +
                                    classes.sidebarListSubItemActive
                                  : classes.sidebarListSubItemText
                              }
                              primary={cItem.name}
                            />
                          </ListItemButton>
                        </ListItem>
                      ) : (
                        ""
                      )
                    ) : (
                      <ListItem key={cItem.name} disablePadding>
                        <ListItemButton
                          onClick={(event) => {
                            history(cItem.path);
                          }}
                          className={classes.sidebarListSubItem}
                        >
                          <ListItemText
                            disableTypography
                            className={
                              cItem.path === loc?.replaceAll("%20", " ")
                                ? classes.sidebarListSubItemText +
                                  " " +
                                  classes.sidebarListSubItemActive
                                : classes.sidebarListSubItemText
                            }
                            primary={cItem.name}
                          />
                        </ListItemButton>
                      </ListItem>
                    );
                  })}
              </>
            )}
          {loc.includes("workshop") &&
            props?.steps &&
            Object.keys(props?.steps).length !== 0 &&
            item?.activePaths?.includes(loc?.replaceAll("%20", " ")) && (
              <>
                <br />
                <ListItem
                  key={
                    props?.toolParams?.type === "BRAND_STRATEGY"
                      ? "Workshop Sections"
                      : `${ToolType[props?.toolParams?.type]} Steps`
                  }
                  disablePadding
                >
                  <ListItemButton
                    className={
                      classes.sidebarListItemWorkShopSection +
                      " " +
                      classes.sidebarListItem
                    }
                  >
                    <ListItemText
                      disableTypography
                      className={classes.sidebarListSubItemText}
                      primary={
                        props?.toolParams?.type === "BRAND_STRATEGY"
                          ? "Workshop Sections"
                          : `${ToolType[props?.toolParams?.type]} steps`
                      }
                    />
                  </ListItemButton>
                </ListItem>
                {props?.steps?.map((sItem, cIndex) => (
                  <>
                    <ListItem key={sItem.name} disablePadding>
                      <ListItemButton
                        onClick={(event) => {
                          props?.setSelWorkShop(sItem);
                        }}
                        className={classes.sidebarListSubItem}
                      >
                        <ListItemText
                          disableTypography
                          className={
                            sItem?.key === props?.selWorkshop?.key
                              ? classes.sidebarListSubItemText +
                                " " +
                                classes.sidebarListSubItemActive
                              : classes.sidebarListSubItemText
                          }
                          primary={sItem.name}
                        />
                      </ListItemButton>
                    </ListItem>
                  </>
                ))}
                <br />
                <br />
                <br />
              </>
            )}
        </>
      ))}
    </List>
  );
};

export default SideBarComponent;
