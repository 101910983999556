import CustomButton from "../../../ui/button/CustomButton";
import ProjectRightHeader from "../ProjectRightHeader";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import PRHeader from "./PTHeader";
import PRList from "./PTList";
import DropDownMenu from "../../../ui/menus/DropDownMenu";
import { useState } from "react";
import CollapseWithGrid from "../../../ui/customCollapse/CollapseWithGrid";
import { useNavigate } from "react-router";
import RenameTool from "../../../ui/modals/RenameTool";
import CopyToolToOtherProject from "../../../ui/modals/CopyToolToOtherProject";
import DeleteModal from "../../../ui/modals/DeleteModal";
import { ToolOptions } from "../../../../utils/AllOptions";
import EmptyList from "../../../ui/emptyList/EmptyList";
import { useSelector } from "react-redux";
import { api } from "../../../../redux/api/api";

const ProjectToolsSections = (props) => {
  const loc = useNavigate();

  const ptools = useSelector((state) => state.project?.project?.tools);
  const checkPid = useSelector((state) => state.client.projectId?.id);

  const [enchorEl, setEnchorEl] = useState(null);
  const [openP, setOpenP] = useState(false);
  const [menuVal, setMenuVal] = useState("Open Tool");
  const [expand, setExpand] = useState(true);
  const [delModal, setDelModal] = useState(false);
  const [renameModal, setRenameModal] = useState(false);
  const [copyModal, setCopyModal] = useState(false);
  const [selTool, setSelTool] = useState({ id: "", name: "", type: "" });

  const handleSelect = (v) => {
    handleClose();
    switch (v) {
      case ToolOptions[0]:
        loc(`/projects/tool/${selTool?.name}/${selTool?.type}/${selTool?.id}`);
        break;
      case ToolOptions[1]:
        setRenameModal(true);
        break;
      case ToolOptions[2]:
        setDelModal(true);
        break;
      case ToolOptions[3]:
        setCopyModal(true);
        break;
      default:
        break;
    }
  };
  const handleClick = (event, data) => {
    setSelTool(() => data);
    setEnchorEl(event.currentTarget);
    setOpenP(true);
  };
  const handleClose = () => {
    setEnchorEl(null);
    setOpenP(false);
  };
  const handleCloseWithNewName = (s, name) => {
    if (s && ptools && name && selTool.id) {
      for (let i = 0; i < ptools.length; i++) {
        if (ptools[i].uuid === selTool.id) {
          ptools[i].name = name;
          break;
        }
      }
      setSelTool({ name: "", id: "", type: "" });
      setRenameModal(false);
    } else {
      setSelTool({ name: "", id: "", type: "" });
      setRenameModal(false);
    }
  };
  const handleRemoveFromTool = async () => {
    setDelModal(false);
    if (selTool.id && ptools) {
      const res = await api(
        `tools/${selTool.id}`,
        "",
        "delete",
        "",
        "",
        "Tool removed!"
      );
      if (res && res.success) {
        const idx = ptools.findIndex((a) => a.uuid == selTool.id);
        if (idx !== -1) {
          ptools.splice(idx, 1);
        }
      }
      setSelTool({ name: "", id: "", type: "" });
    }
  };
  return (
    <>
      <ProjectRightHeader
        title="Tools"
        btn={
          <CustomButton
            name="Add"
            classNameI={"yellow"}
            size="small"
            endIcon={<AddCircleOutlineRoundedIcon />}
            onclick={() => loc("/projects/add-tool")}
          />
        }
        mt="2rem"
        isHideExpend={true}
        setCollsapn={(a) => setExpand(a)}
      />
      <CollapseWithGrid expand={expand}>
        {ptools && ptools.length ? (
          <PRHeader generalClass={props.generalClass} />
        ) : null}
        {ptools && ptools.length ? (
          ptools.map((a) => (
            <PRList
              {...a}
              handleClick={handleClick}
              openLoc={() =>
                loc(`/projects/tool/${a?.name}/${a?.type}/${a?.uuid}`)
              }
              generalClass={props.generalClass}
            />
          ))
        ) : (
          <EmptyList
            color="#FFED71"
            text="Add tools that gather information, translate it and produce incredible reports."
          />
        )}
      </CollapseWithGrid>
      <DropDownMenu
        enchorEl={enchorEl}
        openP={openP}
        handleClose={handleClose}
        options={ToolOptions}
        val={menuVal}
        handleSelect={(v, id) => handleSelect(v, id)}
      />
      <RenameTool
        open={renameModal}
        onClose={handleCloseWithNewName}
        {...selTool}
      />
      <CopyToolToOtherProject
        open={copyModal}
        onClose={() => {
          setCopyModal(false);
          setSelTool({ name: "", id: "", type: "" });
        }}
        {...selTool}
        projectId={checkPid}
      />
      <DeleteModal
        title="Are you sure you want to remove this tool
        from the project?"
        open={delModal}
        onClose={() => {
          setDelModal(false);
          setSelTool({ name: "", id: "", type: "" });
        }}
        onDelete={handleRemoveFromTool}
      />
    </>
  );
};

export default ProjectToolsSections;
