import { Box, Stack } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import CustomButton from "../../../../../ui/button/CustomButton";
import TextAutoResize from "../../../../../ui/formgroup/TextAutoResize";

const AdvertisingTiming = (props) => {
  const [advertisingTiming, setAdvertisingTiming] = useState("");
  const data = props?.td?.data;
  useEffect(() => {
    if (
      data &&
      data.advertisingTiming &&
      typeof data.advertisingTiming === "string"
    ) {
      setAdvertisingTiming(data.advertisingTiming);
    }
  }, [props]);

  return (
    <>
      <Stack direction="row" gap="30px">
        <Box flex="0.2" display="flex" gap="10px" flexDirection="column">
          <Box color="secondary.main" fontSize="20px" fontWeight="800px">
            Advertising Timing
          </Box>
          <Box mb={2} fontSize="14px"></Box>
        </Box>
        <Box flex="0.8">
          <TextAutoResize
            placeholder="Advertising Timing"
            other={{ minRows: 6, maxRows: 6 }}
            value={advertisingTiming}
            onChange={(e) => setAdvertisingTiming(e)}
          />
        </Box>
      </Stack>
      <Box display="flex" justifyContent="space-between" mt="auto" mb="0">
        <CustomButton
          name="<   Prev"
          onclick={() => props.callApi({ advertisingTiming }, true, false)}
          loading={props.btnLoading}
        />
        <CustomButton
          name="Next   >"
          disabled={!advertisingTiming}
          onclick={() => props.callApi({ advertisingTiming }, true, true)}
          loading={props.btnLoading}
        />
      </Box>
    </>
  );
};

export default AdvertisingTiming;
