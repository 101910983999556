import { SET_PROJECT_DATA, SET_PROJECT_LOADER } from "../../constants";

const initState = {
  project: "",
  loading: false,
  projects: [],
  clients: [],
  fromid: "",
  allProjects: [],
  allTools: [],
  clientsDash: null,
  workshop: false,
  clientProjectSearch: [],
};

const projectReducer = (state = initState, action) => {
  switch (action.type) {
    case "GET_ALL_SEARCH_PROJECT":
      return { ...state, clientProjectSearch: action.payload.data };
    case "TOGGLE_WORKSHOP_EXP":
      return { ...state, workshop: action.payload.data };
    case SET_PROJECT_DATA:
      return { ...state, project: action.payload.data };
    case SET_PROJECT_LOADER:
      return { ...state, loading: action.payload.data };
    case "GET_CLIENT_LIST_DATA":
      return {
        ...state,
        clients: action.payload.data,
      };
    case "GET_PROJECT_LIST_DATA":
      return {
        ...state,
        projects: action.payload.data,
      };
    case "SET_FROM_IDS":
      return {
        ...state,
        fromid: action.payload.data,
      };
    case "LOGOUT": {
      return {
        project: "",
        loading: false,
        projects: [],
        clients: [],
        fromid: "",
        allProjects: [],
        allTools: [],
        clientsDash: null,
      };
    }
    case "GET_ALL_PROJECT_LIST_DATA":
      return { ...state, allProjects: action.payload.data };
    case "GET_ALL_TOOL_LIST_DATA":
      return { ...state, allTools: action.payload.data };        
    case "GET_ALL_DASH_CLIENT_LIST":
      return { ...state, clientsDash: action.payload.data };
    default:
      return state;
  }
};

export default projectReducer;
