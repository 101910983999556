import { ButtonBase, DialogContent, Divider, Grid, Stack } from "@mui/material";
import { ArrowForwardIos } from "@mui/icons-material";
import Img1 from "../../../assets/images/planeImg.png";

import CustomButton from "../button/CustomButton";
import ModalContainer from "./ModalContainer";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "20px",
    color: theme.palette.secondary.main,
    marginBottom: "10px",
    fontWeight: 600,
  },
  endIcon: {
    marginLeft: "auto !important",
  },
  btnContainer: { width: "40%" },
  cancelBtn: {
    margin: "auto 0",
    textDecoration: "underline !important",
    fontFamily: theme.typography.fontFamily,
    fontSize: "16px",
  },
  dialogContent: {
    height: "500px",
  },
  fullWidth: {
    width: "100%",
  },
  noteDiv: { fontSize: "12px" },
  noteDiv2: { fontSize: "12px", marginBottom: "14px" },
  noteDiv3: { fontSize: "12px", marginBottom: "6px" },
  descNote: { marginBottom: "14px" },

  title2: {
    fontSize: "20px",
    color: theme.palette.secondary.main,
    marginBottom: "14px",
  },
  img1: { paddingTop: "110px", maxHeight: "240px" },
  price: {
    fontWeight: 600,
  },
}));
const UpdateResearch = ({ open, onClose }) => {
  const classes = useStyles();

  return (
    <ModalContainer
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth={true}
    >
      <DialogContent className={classes.dialogContent}>
        <Grid container mt={0}>
          <Grid item lg={8} md={8} margin="auto">
            <Stack gap="8px">
              <div className={classes.title}>Research Update Available</div>
              <div className={classes.noteDiv}>Research Provider & Name</div>
              <div>Roy Morgan / Teenagers in Victoria</div>
              <Divider variant="fullWidth" style={{ margin: "10px 0" }} />
              <div style={{ display: "flex", gap: "40px" }}>
                <div style={{ flex: 0.4 }}>
                  <div className={classes.title2}>
                    Why having up to date research is vital
                  </div>
                  <div className={classes.noteDiv2}>
                    This update ensures the research you are using is the latest
                    available.
                  </div>
                  <div className={classes.price}>Update: $200.00</div>
                </div>
                <div style={{ flex: 0.3 }}>
                  <div className={classes.noteDiv3}>Market Region</div>
                  <div className={classes.descNote}>Australia</div>

                  <div className={classes.noteDiv3}>Market Level 1</div>
                  <div className={classes.descNote}>Alcoholic</div>

                  <div className={classes.noteDiv3}>Market Level 3</div>
                  <div className={classes.descNote}>Entertained</div>
                </div>
                <div style={{ flex: 0.3 }}>
                  <div className={classes.noteDiv3}>Market Type</div>
                  <div className={classes.descNote}>B2C</div>

                  <div className={classes.noteDiv3}> Market Level2</div>
                  <div className={classes.descNote}>
                    Activities in the Beverages last 3 months
                  </div>

                  <div className={classes.noteDiv3}>Market Level 4</div>
                  <div className={classes.descNote}>Held at a dinner party</div>
                </div>
              </div>

              <Divider variant="fullWidth" style={{ margin: "10px 0" }} />
            </Stack>

            <Stack direction="row" mt={3} gap="20px">
              <div className={classes.btnContainer}>
                <CustomButton
                  variant="contained"
                  size="large"
                  name={"Update Now"}
                  endIcon={<ArrowForwardIos />}
                  fullWidth={true}
                  iconClass={classes.endIcon}
                />
              </div>
              <ButtonBase onClick={onClose} className={classes.cancelBtn}>
                Cancel
              </ButtonBase>
            </Stack>
          </Grid>
          <Grid item lg={3} md={3}>
            <div>
              <img src={Img1} alt={Img1} className={classes.img1} />
            </div>
          </Grid>
        </Grid>
      </DialogContent>
    </ModalContainer>
  );
};

export default UpdateResearch;
