import { shallowEqual, useSelector } from "react-redux";
import { ThemeProvider } from "@mui/material";
import { theme as myTheme } from "./assets/theme";
import Routes from "./router/Routes";
import { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactGA from 'react-ga4';

const App = () => {
    const theme = useSelector((state) => state.theme, shallowEqual);
    useEffect(() => {}, [theme]);
    useEffect(() => {

    if(window.location.hostname === "app.brandvas.io") {
      const TRACKING_ID = "G-RSGPBBJPJH"; 
      ReactGA.initialize(TRACKING_ID);
    }

    if (
      window.location.hostname === "localhost" ||
      window.location.hostname === "app.staging.brandvas.io"
    ) {
      //@ts-ignore
      window.onUsersnapCXLoad = function (api) {
        api.init();
      };
      let script = document.createElement("script");
      //@ts-ignore
      script.async = 1;
      script.src =
        "https://widget.usersnap.com/load/965a6d51-7111-48b6-89f1-5cc330b1a962?onload=onUsersnapCXLoad";
      document.getElementsByTagName("head")[0].appendChild(script);
    }
  }, []);
  return (
    <ThemeProvider theme={myTheme()}>
      <Routes />
      <ToastContainer position="bottom-right" theme="colored" />
    </ThemeProvider>
  );
};

export default App;
