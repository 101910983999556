import { Box, Grid, IconButton, Stack } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import FormGroupTextField from "../../../../../../ui/formgroup/FormGroupTextField";
import TextAutoResize from "../../../../../../ui/formgroup/TextAutoResize";

const Step1 = (props) => {
  return (
    <Stack>
      <Box>
        <Box color="secondary.main" fontSize="20px" fontWeight="800px">
          Your Brand Essence
        </Box>
        <Grid container pt={4}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box>
              Using the brand values and differentiators as a starting point,
              weave together the most 2 or 3 into a concise, over-arching brand
              essence.
            </Box>
          </Grid>
          <Grid item lg={5.5} md={5.5} sm={5.5} xs={5.5}>
            <br />
            <br />
            <Box> Your Brand Differentiators </Box>
            <br />
            <Stack
              maxHeight={"400px"}
              minHeight="400px"
              overflow="auto"
              gap={2}
              pr="2px"
            >
              {props.brandDifferentiatorsData &&
                props.brandDifferentiatorsData.map((item, index) => (
                  <FormGroupTextField
                    key={index}
                    placeholder="Your Brand Differentiators"
                    type="text"
                    value={item.name}
                    onChange={(e) =>
                      props.handleBrandDifferentiation(index, "name", e)
                    }
                  />
                ))}
            </Stack>
          </Grid>
          <Grid item lg={1} md={1} sm={1} xs={1}></Grid>
          <Grid item lg={5.5} md={5.5} sm={5.5} xs={5.5}>
            <br />
            <br />
            <Box> Your Brand Values </Box>
            <br />
            <Stack
              maxHeight={"400px"}
              minHeight="400px"
              overflow="auto"
              gap={2}
              pr="2px"
            >
              
              {props.brandValuesData &&
                props.brandValuesData.map((item, index) => (
                  <FormGroupTextField
                    placeholder="Your Brand Values"
                    type="text"
                    value={item.name}
                    onChange={(e) =>
                      props.handleBrandValuesData(index, "name", e)
                    }
                    key={index}
                  />
                ))}
            </Stack>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <br />
            <br />
            <Box
              fontSize="14px !important"
              fontFamily="Rubik"
              fontWeight="400"
              marginBottom="12px"
            >
              Draft Brand Essences
            </Box>
            <Box>
              {props.draftBrandEssencesData &&
                props.draftBrandEssencesData.map((item, index) => (
                  <>
                    <TextAutoResize
                      placeholder="Draft Brand Essences"
                      other={{ minRows: 6, maxRows: 6 }}
                      value={item.name}
                      onChange={(e) =>
                        props.handleDraftBrandEssencesData(index, "name", e)
                      }
                    />
                    {props.draftBrandEssencesData.length !== index + 1 && (
                      <br />
                    )}
                  </>
                ))}
            </Box>
            <Box
              style={{
                cursor: "pointer",
              }}
              onClick={() => {
                props.addMoreBrandEssence();
              }}
            >
              <IconButton edge="end">
                {" "}
                <AddIcon />
              </IconButton>{" "}
              &nbsp; Add draft brand essence
            </Box>
            <br />
            <br />
          </Grid>
        </Grid>
      </Box>
    </Stack>
  );
};

export default Step1;
