import { Avatar, Box, Divider, Menu, Stack, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import { useSelector } from "react-redux";
import Loader from "react-spinners/ClipLoader";
import { ReactComponent as MyLogo } from "../../../assets/images/downloadIcon.svg";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  menuClass: {
    background: "#f5f5f5 !important",
    fontFamily: theme.typography.fontFamily,
    borderRadius: "10px",
    height: "500px",
    backgroundColor: "white !important",
    width: "300px",
    top: "260px !important",
    border: `1px solid ${theme.palette.secondary.main}`,
    padding: "0 !important",
    overflow: "hidden !important",
  },
  title: {
    color: theme.palette.secondary.main,
    fontSize: "20px",
    fontWeight: 600,
  },
  subTitle: {
    color: theme.palette.secondary.main,
    fontSize: "20px",
  },
  icon: {
    fill: theme.palette.secondary.main,
    height: "24px",
    width: "24px",
  },
  red: {
    backgroundColor: "#F5f5f5 !important",
    borderRadius: "30px",
    width: "24px",
    padding: "14px",
    fontSize: "16px",
    display: "flex",
    justifyContent: "center",
    marginLeft: "auto",
  },
  textField: {
    backgroundColor: "#F5F5F5 !important",
    borderRadius: "10px !important",
    borderColor: "#F5F5F5 !important",
    marginBottom: "20px !important",
    "& .MuiOutlinedInput-root": {
      borderRadius: "10px !important",
      borderColor: "#F5F5F5 !important",
    },
    "& fieldset": {
      borderColor: "#F5F5F5",
      borderRadius: "10px !important",
    },
  },
  people: {
    height: "70px",
    backgroundColor: theme.palette.secondary.main,
    textAlign: "left",
    padding: "10px 14px",
  },
  peopleTitle: {
    color: "white",
    fontSize: "12px",
  },
  paddingZero: {
    padding: "0 !important",
    overflow: "hidden !important",
  },
  avatar: {
    fontFamily: theme.typography.fontFamily,
    background: `orange !important`,
    color: "white !important",
    fontSize: "16px !important",
  },
  comentDiv: {
    height: "195px",
    overflowX: "auto",
    padding: "0 20px",
  },
  cText: { margin: "auto 6px" },
  cTime: { fontSize: "12px" },
}));
function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
  };
}

const CommentMenu = ({
  enchorEl,
  openP,
  handleClose,
  loading,
  addComment,
  data = [],
  per = [],
}) => {
  const classes = useStyles();
  const projectName = useSelector((state) => state.client?.projectId?.name);
  const clientName = useSelector((state) => state.client?.clientId?.name);
  const users = useSelector((state) => state.client?.users);

  const handleAvatar = () => {
    const arr = [];

    if (per && per.length && users && users.length) {
      per.forEach((a) => {
        const idx = users.findIndex((b) => b.uuid === a);
        if (idx !== -1) {
          arr.push(
            `${users[idx]?.firstName?.[0] || ""} ${
              users[idx]?.lastName?.[0] || ""
            }`
          );
        }
      });
    }
    return arr;
  };
  const [input, setInput] = useState("");
  return (
    <Menu
      anchorEl={enchorEl}
      open={openP}
      onClose={handleClose}
      transformOrigin={{ horizontal: "center", vertical: "bottom" }}
      anchorOrigin={{ horizontal: "center", vertical: "top" }}
      classes={{ paper: classes.menuClass, list: classes.paddingZero }}
    >
      <Stack m={2} mt={1} mb={0.2}>
        <Stack direction={"row"} mb={1}>
          <div>
            <div className={classes.title}>{clientName}</div>
            <div className={classes.subTitle}>{projectName}</div>
          </div>
          <div className={classes.red}>
            {" "}
            <MyLogo className={classes.icon} />
          </div>
        </Stack>
        <TextField
          multiline
          rows={4}
          color="secondary"
          fullWidth
          placeholder="Add a comment here.
            Press enter to save."
          classes={{ root: classes.textField }}
          value={input}
          onChange={(e) => setInput(() => e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              setInput(() => "");
              addComment(input);
            }
          }}
        />
      </Stack>

      <div className={classes.comentDiv}>
        {loading ? (
          <Box textAlign="center">
            <Loader />
          </Box>
        ) : data && data.length ? (
          data.map((a) => (
            <Stack key={a.uuid} gap="8px" pb={1} fontSize="14px">
              <div>{a.comment || "-"}</div>
              <Stack direction="row">
                <Avatar
                  {...stringAvatar(
                    `${
                      a.userName
                        ? `${a.userName.split(" ")?.[0]?.[0]} ${
                            a.userName.split(" ")?.[0]?.[0]
                          }`
                        : "n n"
                    }`
                  )}
                />
                <div className={classes.cText}>
                  <div>{a.userName || "-"}</div>
                  <div className={classes.cTime}>
                    {moment(a?.dateCreated || "").fromNow()}
                  </div>
                </div>
              </Stack>
              <Divider variant="fullWidth" />
            </Stack>
          ))
        ) : (
          <Box textAlign="center">No comment found!</Box>
        )}
      </div>
      <div className={classes.people}>
        <div className={classes.peopleTitle}>People not up to date</div>
        <Stack gap="6px" direction="row" mt={1}>
          {handleAvatar().map((a) => (
            <Avatar {...stringAvatar(a)} />
          ))}
        </Stack>
      </div>
    </Menu>
  );
};

export default CommentMenu;
