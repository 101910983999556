import { Box, Stack } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import CustomButton from "../../../../../ui/button/CustomButton";
import TextAutoResize from "../../../../../ui/formgroup/TextAutoResize";
const DatePlan = (props) => {
  const [datePlan, setDatePlan] = useState("");
  const data = props?.td?.data;
  useEffect(() => {
    if (data && data.datePlan && typeof data.datePlan === "string") {
      setDatePlan(data.datePlan);
    }
  }, [props]);

  return (
    <>
      <Stack direction="row" gap="30px">
        <Box flex="0.2" display="flex" gap="10px" flexDirection="column">
          <Box color="secondary.main" fontSize="20px" fontWeight="800px">
            Date Plan Required
          </Box>
          <Box mb={2} fontSize="14px"></Box>
        </Box>
        <Box flex="0.8">
          <TextAutoResize
            other={{ minRows: 6, maxRows: 6 }}
            value={datePlan}
            placeholder="Date Plan"
            onChange={(e) => setDatePlan(e)}
          />
        </Box>
      </Stack>
      <Box display="flex" justifyContent="space-between" mt="auto" mb="0">
        <CustomButton
          name="<   Prev"
          onclick={() => props.callApi({ datePlan }, true, false)}
          loading={props.btnLoading}
        />
        <CustomButton
          name="Next   >"
          disabled={!datePlan}
          onclick={() => props.callApi({ datePlan }, true, true)}
          loading={props.btnLoading}
        />
      </Box>
    </>
  );
};

export default DatePlan;
