import DragDrop from "../DragDrop/DragDrop";

const Step2 = ({ competitorStrengths, setCompetitorStrengths }) => {
  return (
    <>
      Think about the competitors you identified. On what dimensions do they
      compete or perform? What do they do to a high standard? We’ll assign them
      scores in a moment.
      <DragDrop
        data={competitorStrengths || []}
        btnText="Add new strength"
        rightText="Drag and drop the most important strengths into this shortlist box - ideally between 6-8, but at least 3"
        setItemOnDrag={(data) => setCompetitorStrengths(data)}
      />
    </>
  );
};

export default Step2;
