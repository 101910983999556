import { Box, Stack } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import CustomButton from "../../../../../ui/button/CustomButton";
import FormGroupTextField from "../../../../../ui/formgroup/FormGroupTextField";
import TextAutoResize from "../../../../../ui/formgroup/TextAutoResize";

const TargetAudienceProposition = (props) => {
  const [targetAudienceAndProposition, setTargetAudienceAndProposition] =
    useState("");
  const data = props?.td?.data;
  useEffect(() => {
    if (
      data &&
      data.targetAudienceAndProposition &&
      typeof data.targetAudienceAndProposition === "string"
    ) {
      setTargetAudienceAndProposition(data.targetAudienceAndProposition);
    }
  }, [props]);
  return (
    <>
      <Stack direction="row" gap="30px">
        <Box flex="0.2" display="flex" gap="10px" flexDirection="column">
          <Box color="secondary.main" fontSize="20px" fontWeight="800px">
            Target Audience And Proposition
          </Box>
          <Box mb={2} fontSize="14px">
            What reason do we have for the target audience to believe this
            proposition?
          </Box>
        </Box>
        <Box flex="0.8">
          <TextAutoResize
            other={{ minRows: 6, maxRows: 6 }}
            value={targetAudienceAndProposition}
            onChange={(e) => setTargetAudienceAndProposition(e)}
          />
        </Box>
      </Stack>
      <Box display="flex" justifyContent="space-between" mt="auto" mb="0">
        <CustomButton
          name="<   Prev"
          onclick={() =>
            props.callApi({ targetAudienceAndProposition }, true, false)
          }
          loading={props.btnLoading}
        />
        <CustomButton
          name="Next   >"
          disabled={!targetAudienceAndProposition}
          onclick={() =>
            props.callApi({ targetAudienceAndProposition }, true, true)
          }
          loading={props.btnLoading}
        />
      </Box>
    </>
  );
};

export default TargetAudienceProposition;
