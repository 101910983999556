import { ButtonBase, DialogContent, Grid, Stack } from "@mui/material";
import { ArrowForwardIos } from "@mui/icons-material";
import CatImg from "../../../assets/images/catimg.png";
import FormGroupTextField from "../formgroup/FormGroupTextField";
import CustomButton from "../button/CustomButton";
import ModalContainer from "./ModalContainer";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import { api } from "../../../redux/api/api";
import { useSelector } from "react-redux";
import { useEffect } from "react";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "20px",
    color: theme.palette.secondary.main,
    marginBottom: "20px",
    fontWeight: 600,
  },
  endIcon: {
    marginLeft: "auto !important",
  },
  btnContainer: { width: "40%" },
  cancelBtn: {
    margin: "auto 0",
    textDecoration: "underline !important",
    fontFamily: theme.typography.fontFamily,
    fontSize: "16px",
  },
  dialogContent: {
    height: "500px",
  },
  subTitle: {
    marginBottom: "7px",
    fontWeight: 400,
    fontSize: "14px !important",
  },
  name: {
    marginBottom: "16px",
  },
}));

const RenameTemplate = ({ open, onClose, name, id }) => {
  const classes = useStyles();
  const [newName, setNewName] = useState("");
  const [loading, setLoading] = useState(false);
  const templateRe = useSelector((state) => state.template?.template);

  const handleEdit = async () => {
    setLoading(true);
    if (newName && id) {
      const res = await api(
        `templates/${id}`,
        { name: newName },
        "patch",
        "",
        "",
        "Template Updated!"
      );
      if (res && res.success) {
        for (let i = 0; i < templateRe.length; i++) {
          if (templateRe[i].uuid === id) {
            templateRe[i].name = newName;
            break;
          }
        }
        onClose();
      }
    }
    setLoading(false);
  };
  useEffect(() => {
    setNewName("");
  }, [id, open]);
  return (
    <ModalContainer
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth={true}
    >
      <DialogContent className={classes.dialogContent}>
        <Grid container mt={2}>
          <Grid item lg={7} md={7} margin="auto" p={4} mt={6}>
            <div className={classes.title}>Rename Template</div>
            <div className={classes.subTitle}>
              Creative Brief template current name
            </div>
            <div className={classes.name}>{name}</div>
            <FormGroupTextField
              label="Change name to"
              value={newName}
              onChange={(e) => setNewName(e)}
            />
            <Stack direction="row" mt={3} gap="20px">
              <div className={classes.btnContainer}>
                <CustomButton
                  variant="contained"
                  size="large"
                  name={"Rename"}
                  disabled={newName ? false : true}
                  endIcon={<ArrowForwardIos />}
                  fullWidth={true}
                  iconClass={classes.endIcon}
                  onclick={handleEdit}
                  loading={loading}
                />
              </div>
              <ButtonBase onClick={onClose} className={classes.cancelBtn}>
                Cancel
              </ButtonBase>
            </Stack>
          </Grid>
          <Grid item lg={5} md={5}>
            <img src={CatImg} alt={CatImg} height="383px" />
          </Grid>
        </Grid>
      </DialogContent>
    </ModalContainer>
  );
};

export default RenameTemplate;
