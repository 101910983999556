import { Box, Stack } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import { awsUpload } from "../../../../../utils/s3upload";
import CustomButton from "../../../../ui/button/CustomButton";
import CustomCheckBox from "../../../../ui/checkbox/CustomCheckBox";
import CustomDropzone from "../../../../ui/dropzone/CustomDropzone";
import CustomSelect from "../../../../ui/formgroup/CustomSelect";
import TextAutoResize from "../../../../ui/formgroup/TextAutoResize";

const Custom = (props) => {
  const [data, setData] = useState({});
  useEffect(() => {
    if (props.td && props.td.data && props.td.data.custom) {
      setData(props.td.data.custom);
    }
  }, [props]);
  const handleData = (id, dataIn) => {
    const dd = { ...data };
    dd[id] = dataIn;
    setData(() => dd);
  };
  const handleSelect = (type, p) => {
    
    switch (type) {
      case "Text Only":
        var val = data[p.uuid] != undefined ? data[p.uuid] : p.description ? p.description : '';
        return (
          <TextAutoResize
            label={p.name}
            other={{ minRows: 6.5, maxRows: 6.5 }}
            value={val}
            onChange={(e) => handleData(p.uuid, e)}
          />
        );
      case "Question Yes/No": {
        const isYes = data[p.uuid] && data[p.uuid] === "Yes" ? true : false;
        return (
          <Box>
            <Box>{p.name || ""}</Box>
            <CustomCheckBox
              checked={isYes}
              label="Yes"
              onchange={() => handleData(p.uuid, "Yes")}
            />
            <CustomCheckBox
              checked={!isYes}
              label="No"
              onchange={() => handleData(p.uuid, "No")}
            />
          </Box>
        );
      }
      case "Question - Select one": {
        const options = p["Question - Select one"]
          ? Object.values(p["Question - Select one"]).filter((a) => a)
          : [];
        if (options) {
          return (
            <CustomSelect
              label={p.name || ""}
              options={options}
              value={data[p.uuid] || ""}
              onChange={(e) => handleData(p.uuid, e)}
            />
          );
        } else return <></>;
      }
      case "Question Multi Choice": {
        const options = p["Question Multi Choice"]
          ? Object.values(p["Question Multi Choice"]).filter((a) => a)
          : [];
        if (options) {
          return (
            <Box>
              <Box>{p.name || ""}</Box>
              {options.map((a, idx) =>
                a ? (
                  <CustomCheckBox
                    checked={data[p.uuid] && data[p.uuid][idx]}
                    label={a}
                    onchange={(e) =>
                      handleData(p.uuid, { ...data[p.uuid], [idx]: e })
                    }
                  />
                ) : null
              )}
            </Box>
          );
        } else {
          return <></>;
        }
      }
      case "Full Width Image":
        return (
          <Box>
            <Box mb={2}>{p.name || ""}</Box>
            <Box>
              <CustomDropzone
                isMulti={false}
                maxSize={1000000}
                handleDrop={(e) => handleDrop(e, p.uuid)}
                accept={{
                  "image/png": [".png", ".jpg"],
                }}
                component={
                  data[p.uuid] ? (
                    <img src={data[p.uuid]} alt={data[p.uuid]} width="100%" />
                  ) : (
                    <div className="dropzone" style={{ width: "90%" }}>
                      <p>
                        Drag 'n' drop some files here, or click to select files
                      </p>
                    </div>
                  )
                }
              />
            </Box>
          </Box>
        );
      default:
        return null;
    }
  };
  const handleDrop = async (e, id) => {
    if (e && e[0]) {
      const fileData = new FormData();
      fileData.append("file", e[0]);
      const fd = await awsUpload(fileData);
      if (fd) {
        handleData(id, fd);
      }
    }
  };
  return !props?.tk?.questions ? (
    <Box m="auto" fontSize={54} color="secondary.main">
      Thank You
    </Box>
  ) : (
    <>
      <Stack direction="row" gap="30px">
        <Box flex="0.2" display="flex" gap="10px" flexDirection="column">
          <Box color="secondary.main" fontSize="20px" fontWeight="800px">
            {props?.tk?.name || ""}
          </Box>
          <Box mb={2} fontSize="14px">
            {props?.tk?.description || ""}
          </Box>
        </Box>
        <Box flex="0.8">
          <Stack gap="20px">
            {props?.tk?.questions
              ? props.tk.questions.map((a) => handleSelect(a.title, a))
              : null}
          </Stack>
        </Box>
      </Stack>
      {props?.tk?.questions && (
        <Box display="flex" justifyContent="space-between" mt="auto" mb="0">
          <CustomButton
            name="<   Prev"
            loading={props.btnLoading}
            onclick={() => props.callApi({ custom: data }, true, false)}
          />
          <CustomButton
            name="Next   >"
            onclick={() => props.callApi({ custom: data }, true, true)}
            loading={props.btnLoading}
          />
        </Box>
      )}
    </>
  );
};

export default Custom;
