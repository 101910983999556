import { Box, Stack } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import { ClipLoader } from "react-spinners";
import { config } from "../../../../../../config";
import { ReactComponent as Upload } from "../../../../../../assets/images/upload.svg";
import { makeStyles } from "@mui/styles";
import { awsUpload } from "../../../../../../utils/s3upload";
import CustomButton from "../../../../../ui/button/CustomButton";
import CustomDropzone from "../../../../../ui/dropzone/CustomDropzone";
import TextAutoResize from "../../../../../ui/formgroup/TextAutoResize";
import ImgDelContainer from "../../../../../ui/imgDelContainer/ImgDelContainer";

const useStyle = makeStyles((theme) => ({
  profileContainer: {
    background: "#F5F5F5",
    padding: "20px",
    opacity: 1,
    borderRadius: "10px",
  },
  icon: {
    fill: theme.palette.secondary.main,
    height: "84px",
    width: "84px",
    margin: "auto",
  },
  imgContainer: {
    display: "flex",
    width: "100%",
    height: "190px",
    background: "#F2D9E7",
    borderRadius: "10px",
  },
  img: {
    maxWidth: "100%",
    maxHeight: "190px",
    margin: "auto",
  },
  imgc: {
    height: "188px !important",
    maxWidth: "100% !important",
    maxHeight: "188px !important",
    display: "flex",
    borderRadius: "10px",
    [theme.breakpoints.down("lg")]: {
      maxWidth: "260px",
    },
  },
  mediaLabel: {
    display: "block",
    marginBottom: "8px",
  },
  textArea: {
    marginTop: "20px !important",
  },
}));
const MediaTargetAudience = (props) => {
  const [targetAudience, setTargetAudience] = useState({
    name: "",
    targetAudienceFile: "",
  });
  const classes = useStyle();
  const [loading, setLoading] = useState(false);

  const data = props?.td?.data;
  const handleDrop = async (file) => {
    setLoading(true);
    const fileData = new FormData();
    fileData.append("file", file);
    const fd = await awsUpload(fileData, true);
    if (fd) {
      setTargetAudience((old) => ({ ...old, targetAudienceFile: fd }));
    }
    setLoading(false);
  };
  useEffect(() => {
    if (data && data.targetAudience) {
      setTargetAudience(data.targetAudience);
    }
  }, [props]);
  return (
    <>
      <Stack direction="row" gap="30px">
        <Box flex="0.2" display="flex" gap="10px" flexDirection="column">
          <Box color="secondary.main" fontSize="20px" fontWeight="800px">
            Target Audience <br />
            (Demographics, Lifestyle, Insights)
          </Box>
        </Box>
        <Box flex="0.8" mb={2}>
          <Stack gap="20px">
            <TextAutoResize
              placeholder="Target Audience"
              other={{ minRows: 6, maxRows: 6 }}
              value={targetAudience.name}
              onChange={(e) =>
                setTargetAudience((old) => ({ ...old, name: e }))
              }
            />
            <Box>
              {loading ? (
                <ClipLoader />
              ) : (
                <>
                  <label className={classes.mediaLabel}>
                    Upload a file or drag and drop file
                  </label>
                  <CustomDropzone
                    isMulti={false}
                    maxSize={1000000}
                    handleDrop={(e) => {
                      if (e && e[0]) handleDrop(e[0]);
                    }}
                    accept={{
                      "image/png": [".png", ".jpg"],
                    }}
                    component={
                      targetAudience.targetAudienceFile ? (
                        <div className={classes.imgContainer1}>
                          <ImgDelContainer
                            src={config.generatePathDownloadPath(
                              targetAudience.targetAudienceFile
                            )}
                            newSmall={true}
                            bottom={true}
                            className={classes.imgc}
                            handleDel={() =>
                              setTargetAudience((old) => ({
                                ...old,
                                targetAudienceFile: "",
                              }))
                            }
                          />
                        </div>
                      ) : (
                        <div className={classes.imgContainer}>
                          <Upload className={classes.icon} />
                        </div>
                      )
                    }
                  />
                </>
              )}
            </Box>
          </Stack>
        </Box>
      </Stack>
      <Box display="flex" justifyContent="space-between" mt="auto" mb="0">
        <CustomButton
          name="<   Prev"
          onclick={() => props.callApi({ targetAudience }, true, false)}
          loading={props.btnLoading}
        />
        <CustomButton
          name="Next   >"
          disabled={targetAudience.name ? false : true}
          onclick={() => props.callApi({ targetAudience }, true, true)}
          loading={props.btnLoading}
        />
      </Box>
    </>
  );
};

export default MediaTargetAudience;
