import { ButtonBase, DialogContent, Grid, Stack } from "@mui/material";
import { ArrowForwardIos } from "@mui/icons-material";
import { useParams } from "react-router";
import { jsPDF } from "jspdf";
import Pdf from "../../../assets/images/pdf.png";
import GDoc from "../../../assets/images/gdoc.png";

import CustomButton from "../button/CustomButton";
import ModalContainer from "./ModalContainer";
import { makeStyles } from "@mui/styles";
import CustomSelect from "../formgroup/CustomSelect";
import CustomCheckBox from "../checkbox/CustomCheckBox";
import { useState } from "react";
import { api } from "../../../redux/api/api";
import { getProjectListData } from "../../../redux/actions/client/ClientAction";
import useBindActionCreator from "../../../hooks/useBindActionCreator";
import { useSelector } from "react-redux";
import { WorkshopPDFhtml } from "../../../utils/WorkshopPDF";

import { convertImagesToBase64 } from "../../../utils/toBased64";

const imgs = [
  /*{ name: "Microsoft Word", img: MWL, id: "WORD" },*/
  { name: "Microsoft Word", img: GDoc, id: "DOC" },
  { name: "Adobe PDF", img: Pdf, id: "PDF" },
  /*{ name: "PowerPoint", img: Ppoint, id: "PP" },*/
];
const rImgs = [
  /*{ name: "Microsoft Word", img: MWL, id: "WORD" },*/
  { name: "Microsoft Word", img: GDoc, id: "DOC" },
  // { name: "Adobe PDF", img: Pdf, id: "PDF" },
  /*{ name: "PowerPoint", img: Ppoint, id: "PP" },*/
];

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "20px",
    color: theme.palette.secondary.main,
    marginBottom: "20px",
    fontWeight: 600,
  },
  endIcon: {
    marginLeft: "auto !important",
  },
  btnContainer: { width: "40%" },
  cancelBtn: {
    margin: "auto 0",
    textDecoration: "underline !important",
    fontFamily: theme.typography.fontFamily,
    fontSize: "16px",
  },
  dialogContent: {
    height: "500px",
  },
  imgContainer: {
    width: "144px",
    height: "134px",
    borderRadius: "10px",
    opacity: 1,
    backgroundColor: "white",
    display: "flex",
  },
  img: {
    maxWidth: "134px",
    margin: "auto",
  },
  labelName: {
    marginTop: "12px",
    fontWeight: 400,
    fontSize: "14px !important",
    fontFamily: theme.typography.fontFamily,
  },
}));

const DownloadModal = ({ open, onClose, opvalue, getDwld, id, data, type }) => {

  
  const classes = useStyles();
  const client = useSelector((state) => state.client);
  const pd = useSelector((state) => state.project.project);
  const clientListing = useSelector((state) => state.project.clients);
  const params = useParams();

  const [format, setFormat] = useState("");
  const [loading, setLoading] = useState(false);
  const actions = useBindActionCreator({ getProjectListData });

  const genDwd = async () => {
    setLoading(true);
    if (format === "PDF") {
      downloadPDF();
    } else {
      await api(
        `projects/${id}/reports/generate/${format}`,
        "",
        "post",
        "",
        "",
        "",
        true,
        false,
        true,
        false
      );
      const oldCount = await getDwld();
      let isLoop = true;
      while (isLoop) {
        const newCount = await getDwld();
        if (newCount > oldCount) {
          onClose();
          if (client?.clientId?.id)
            await actions.getProjectListData(client?.clientId?.id);
          setLoading(false);
          isLoop = false;
          break;
        }
      }
    }
  };

  const downloadPDF = async () => {

    const tools = pd?.tools?.filter(x => x.uuid == id);
    if(tools?.length > 0) {
      data = tools[0].data;
    }

    const doc = new jsPDF("l", "px", [842, 595]);
    var BrandvasReport = WorkshopPDFhtml(data, client, pd, clientListing);

    BrandvasReport = await convertImagesToBase64(BrandvasReport);

    // doc.html(BrandvasReport, {
    //   callback: async (doc) => {
    //     var pageCount = doc.getNumberOfPages();
    //     doc.deletePage(pageCount);
    //     // doc.output('dataurlnewwindow');
    //     window.open(doc.output('bloburl'), '_blank');
    //     setLoading(false);
    //     // onClose();
    //   },
    // });
    doc.html(BrandvasReport, {
      callback: async (doc) => {
        var pageCount = doc.getNumberOfPages();
        doc.deletePage(pageCount);
        const payload = new FormData();
        const newFile = new File([doc.output("blob")], opvalue, {
          type: doc.output("blob").type,
          lastModified: doc.output("blob").lastModified,
        });
        payload.append("file", newFile);
        await api(
          `projects/${id}/reports/upload/PDF`,
          payload,
          "postMultipart",
          "",
          "",
          "PDF Uploaded Sucessfully!"
        );
        // doc.save("Brandvas_Report.pdf");
        setLoading(false);
        await getDwld();
        onClose();
      },
    });
  };

  return (
    <ModalContainer
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth={true}
    >
      <DialogContent className={classes.dialogContent}>
        <Grid container>
          <Grid item lg={12} md={12} pr={3} pl={3}>
            <Stack gap="10px">
              <div className={classes.title}>Generate Download</div>
              {type === "tool" && (
                <div style={{ width: "60%" }}>
                  <CustomSelect
                    fullWidth={true}
                    label="Tools & Name"
                    options={[opvalue]}
                    value={opvalue}
                    disabled
                  />
                </div>
              )}
              <div className={classes.labelName}>Select Formats</div>
              <Stack direction={"row"} gap="20px">
                {type === "tool"
                  ? imgs.map((a) => {
                      if (a.id === "PDF") {
                        if (params.type === "BRAND_STRATEGY") {
                          return (
                            <ImgCheck
                              {...a}
                              key={a.name}
                              val={format}
                              onChange={(e) => setFormat(e)}
                            />
                          );
                        } else {
                          return null;
                        }
                      }
                      return (
                        <ImgCheck
                          {...a}
                          key={a.name}
                          val={format}
                          onChange={(e) => setFormat(e)}
                        />
                      );
                    })
                  : rImgs.map((a) => {
                      return (
                        <ImgCheck
                          {...a}
                          key={a.name}
                          val={format}
                          onChange={(e) => setFormat(e)}
                        />
                      );
                    })}
              </Stack>
              <Stack direction="row" mt={3} gap="20px">
                <div className={classes.btnContainer}>
                  <CustomButton
                    variant="contained"
                    size="large"
                    name="Generate"
                    endIcon={<ArrowForwardIos />}
                    fullWidth={true}
                    iconClass={classes.endIcon}
                    disabled={format ? false : true}
                    onclick={() => genDwd()}
                    loading={loading}
                  />
                </div>
                <ButtonBase onClick={onClose} className={classes.cancelBtn}>
                  Cancel
                </ButtonBase>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </DialogContent>
    </ModalContainer>
  );
};

export default DownloadModal;

const ImgCheck = ({ img, name, val, onChange, id }) => {
  const classes = useStyles();
  return (
    <div onClick={() => onChange(id)}>
      <div className={classes.imgContainer}>
        <img src={img} alt={name} className={classes.img} />
      </div>
      <div style={{ marginTop: "10px" }}>
        <CustomCheckBox
          label={name}
          checked={val === id}
          onchange={() => onChange(id)}
        />
      </div>
    </div>
  );
};
