import { Box } from "@mui/material";
import RightComponent2 from "./RightComponent2";

const HomeComponent = () => {
  return (
    <Box display="flex">
      <RightComponent2 />
    </Box>
  );
};

export default HomeComponent;
