const initState = {
  currentCommentData: null,
  drageAction: "",
  removeFun: () => {},
  cmtLength: 1,
  isReady: false,
};

const artReducer = (state = initState, action) => {
  switch (action.type) {
    case "SET_ART_DRAGE":
      return { ...state, drageAction: action.payload.data };
    case "SET_ART_CURR_DATA":
      return { ...state, currentCommentData: action.payload.data };
    case "ART_REMOVE_FUNCTION":
      return { ...state, removeFun: action.payload.data };
    case "ART_ADD_COMMENT_LENGTH":
      return { ...state, cmtLength: action.payload.data };
    case "ART_SET_PREP":
      return { ...state, isReady: action.payload.data };
    default:
      return state;
  }
};

export default artReducer;
