import { Grid, Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router";
import CustomButton from "../../ui/button/CustomButton";
const useStyle = makeStyles((theme) => ({
  container: {
    padding: "20px",
    borderRadius: "10px",
    border: "1px solid #D40841",
    minHeight: "310px",
    transition: "background-color 500ms ease-out 50ms",
    "&:hover": {
      backgroundColor: "#FBE9EE !important",
    },
    [theme.breakpoints.down("lg")]: {
      minHeight: "300px",
    },
  },
  title: {
    margin: "auto 0",
    fontSize: "20px",
    fontWeight: 600,
    color: `${theme.palette.secondary.main} !important`,
  },
  subTitle: {
    margin: "auto 0",
    fontSize: "20px",
    color: `${theme.palette.secondary.main} !important`,
  },
  desc: {
    fontSize: "18px",
    color: "#466682",
    minHeight: "6em"
  },
  ProjectImgContainer: {
    height: "122px",
    borderRadius: "10px",
    opacity: 1,
    backgroundColor: "white",
    display: "flex",
    margin: "20px 0",
    justifyContent: "center",
  },
  ProjectImg: {
    maxWidth: "200px",
    margin: "auto",
    [theme.breakpoints.down("lg")]: {
      maxWidth: "140px",
    },
  },
}));
const RCard = (props) => {
  const classes = useStyle();
  const loc = useNavigate();
  return (
    <Grid
      lg={4}
      md={6}
      sm={6}
      xs={6}
      p={2}
      pt={0}
      pl={0}
      // onClick={() =>
      //   !props.commingSoon
      //     ? loc(`/research/` + props.viewPath, { replace: true })
      //     : {}
      // }
    >
      <div className={classes.container}>
        <Stack direction="row" justifyContent="space-between">
          <div>
            <div className={classes.title}>{props.title}</div>
            <div className={classes.subTitle}>{props.subTitle}</div>
          </div>{" "}
          {!props.commingSoon && (
            <CustomButton
              name={props.btn}
              size="large"
              classNameI={"red"}
              onclick={() =>
                props.viewPath
                  ? loc(`/research/` + props.viewPath)
                  : props.onBtnClick()
              }
            />
          )}{" "}
        </Stack>
        <div className={classes.ProjectImgContainer}>
          <img
            src={props.img}
            alt={props.img}
            className={classes.ProjectImg}
            style={props.title === "Nielsen" ? { margin: "0" } : {}}
          />
        </div>
        <div className={classes.desc}>{props.desc}</div>
      </div>
    </Grid>
  );
};

export default RCard;
