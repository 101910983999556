import { Divider, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  divider: {
    backgroundColor: "#F19C7E",
  },
}));

const PDHeader = ({ generalClass }) => {
  const classes = useStyles();

  return (
    <>
      <Grid item lg={9} md={7} sm={7} xs={7} className={generalClass.title}>
        Download & Name
      </Grid>
      <Grid item lg={2} md={3} sm={3} xs={3} className={generalClass.title}>
        Date & Format
      </Grid>
      <Grid item lg={1} md={2} sm={2} xs={2} className={generalClass.title}>
        Actions
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} mb={2} mt={2}>
        <Divider variant="fullWidth" className={classes.divider} />
      </Grid>
    </>
  );
};

export default PDHeader;
