import { useEffect } from "react";
import HomeComponent from "../../../components/PrivateHome/HomeComponent";
import useBindActionCreator from "../../../hooks/useBindActionCreator";
import { setTitle } from "../../../redux/actions/theme/themeActions";

const Home = () => {
  const actions = useBindActionCreator({ setTitle });
  useEffect(() => {
    actions.setTitle({ title: "", isTitle: false });
  }, []);
  return <HomeComponent />;
};

export default Home;
