import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import useBindActionCreator from "../../hooks/useBindActionCreator";
import { getTemplates } from "../../redux/actions/template/templateActions";
import Loader from "../ui/loader/Loader";
import TLeft from "./TLeft/TLeft";
import TRight from "./TRight/TRight";

const TemplateComponent = () => {
  const actions = useBindActionCreator({ getTemplates });

  const [loading, setLoading] = useState(true);

  const getData = async () => {
    setLoading(true);
    await actions.getTemplates();
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  return loading ? (
    <Loader />
  ) : (
    <>
      <Grid container mt={1}>
        <TLeft />
        <TRight />
      </Grid>
    </>
  );
};

export default TemplateComponent;
