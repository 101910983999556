import { Box, Stack } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { api } from "../../../../../../redux/api/api";
import CustomButton from "../../../../../ui/button/CustomButton";
import Loader from "../../../../../ui/loader/Loader";
import CustomPersonaDetails from "./CustomPersonaDetails";
import OpeningScreen from "../OpeningScreen";
import AustrailaPost from "../../../../../../assets/images/AustrailaPost.svg";
import { toast } from "react-toastify";
import CustomerCoreFromPersona from "./CustomerCoreFromPersona";

const imgs = {
  AUSTRALIA_POST: AustrailaPost,
};
const CustomerPersonas = (props) => {
  const resData = useSelector((state) => state.project?.project?.researches);
  const pd = useSelector((state) => state.project.project);

  const [index, setIndex] = useState(-1);
  const [data, setData] = useState([]);

  const [personas, setPersonas] = useState([]);
  const [loading, setLoading] = useState(false);

  const [customerData, setCustomerData] = useState({
    includeSecondary: false,
    age: "",
    gender: "",
    occupation: "",
    familyStatus: "",
    location: "",
    interests: "",
    personalityAttributes: "",
    mediaUse: "",
    defines: "",
    frustrations: "",
    motivations: "",
    improve: "",
    secondaryAge: "",
    secondaryGender: "",
    secondaryOccupation: "",
    secondaryFamilyStatus: "",
    secondaryLocation: "",
    secondaryInterests: "",
    secondaryPersonalityAttributes: "",
    secondaryMediaUse: "",
    secondaryDefines: "",
    secondaryFrustrations: "",
    secondaryMotivations: "",
    secondaryImprove: "",
  });

  const loadData = async (idx) => {
    setLoading(true);
    setIndex(idx);
    if (idx !== data.length && idx !== -1) {
      const p = data[idx].data;
      if (p && p.length) {
        const apis = [];
        for (let i = 0; i < p.length; i++) {
          apis.push(api(`insights/personas/${p[i]}`, "", "get"));
        }
        const apisData = await Promise.allSettled(apis);
        const pd2 = [];
        apisData.forEach((a) => {
          if (a.status === "fulfilled" && a.value) {
            pd2.push(a.value);
          }
        });
        setPersonas(pd2);
      }
      setLoading(false);
    }
  };
  const setData2 = (field, value) =>
    setCustomerData((old) => ({ ...old, [field]: value }));
  useEffect(() => {
    if (
      props &&
      props.td &&
      props.td.data &&
      props.td.data.selectedResearch &&
      props.td.data.selectedResearch.length &&
      resData &&
      resData.length
    ) {
      const sr = props.td.data.selectedResearch;
      const d = [];
      for (let i = 0; i < sr.length; i++) {
        if (sr[i].checked) {
          const findRes = resData.find((a) => a.uuid === sr[i].uuid);
          if (
            findRes &&
            findRes.data &&
            findRes.data.selectedCustomerPersonas &&
            findRes.data.selectedCustomerPersonas.length
          ) {
            d.push({
              name: findRes.name,
              uuid: findRes.uuid,
              type: findRes.researchType,
              data: findRes.data.selectedCustomerPersonas || [],
            });
          }
        }
      }
      setData(d);
    }
  }, [props.td]);
  useEffect(() => {
    if (pd?.coreCustomer) {
      setCustomerData(pd?.coreCustomer);
    }
  }, [pd]);

  return (
    <>
      {index === -1 ? (
        <OpeningScreen
          title="Understanding Your Audience"
          description={`It’s important to understand who your audience is as it directs all your brand, marketing and advertising decisions as well as product development and other key business decisions.`}
        />
      ) : index !== data.length ? (
        <Stack direction="row" gap="30px">
          <Box flex="0.2" display="flex" gap="10px" flexDirection="column">
            <Box color="secondary.main" fontSize="20px" fontWeight="800px">
              Understanding Your Audience
            </Box>
            <Box mb={2} fontSize="14px">
              <Box color="secondary.main" fontSize="20px" fontWeight="800px">
                {data[index]?.name || ""}
              </Box>
              <br />
              <img
                src={imgs[data[index]?.type || ""] || ""}
                alt="research provider image"
              />
            </Box>
          </Box>
          {loading ? (
            <Loader />
          ) : (
            <Box
              flex="0.8"
              mb={2}
              style={{
                background: "white",
                padding: "20px",
                borderRadius: "10px",
              }}
            >
              {personas && personas.length ? (
                personas.map((a) => <CustomPersonaDetails {...a} />)
              ) : (
                <Box>No Persona Found!</Box>
              )}
            </Box>
          )}
        </Stack>
      ) : (
        <Stack direction="row" gap="30px">
          <Box flex="0.2" display="flex" gap="10px" flexDirection="column">
            <Box color="secondary.main" fontSize="20px" fontWeight="800px">
              Defining Your Key Audiences
            </Box>
            <Box mb={2} fontSize="14px">
              After discussing the most relevant personas for the brand,
              identify the brand’s core customer or client and where relevant
              the secondary customers or clients.
            </Box>
          </Box>
          <Box flex="0.8" mb={2}>
            <CustomerCoreFromPersona
              customerData={customerData}
              setData2={setData2}
            />
          </Box>
        </Stack>
      )}
      <Box display="flex" justifyContent="space-between" mt="auto" mb="0">
        <CustomButton
          name="<   Prev"
          loading={props.btnLoading}
          onclick={async () => {
            if (index === -1) {
              props.prvStep();
            } else {
              if (index === data.length) {
                await props.callApi({ coreCustomer: customerData });
              }
              loadData(index - 1);
            }
          }}
        />
        <CustomButton
          name="Next   >"
          loading={props.btnLoading}
          onclick={async () => {
            if (index === data.length) {
              if (customerData.age < 0) return toast.error("Please check age!");
              const res = await api(
                `projects/${pd.uuid}`,
                { coreCustomer: customerData },
                "patch",
                "",
                "",
                "Details updated!"
              );
              if (res && res.success) {
                pd.coreCustomer = customerData;
              }
              props.nextStep();
            } else {
              loadData(index + 1);
            }
          }}
        />
      </Box>
    </>
  );
};

export default CustomerPersonas;
