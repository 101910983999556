import DragDrop from "../DragDrop/DragDrop";

const Step1 = ({ brandStrengths, setBrandStrengths }) => {
  return (
    <>
      What are your brand strengths? What are the things that you do or can do
      better than your competitors?
      <DragDrop
        data={brandStrengths || []}
        btnText="Add new strength"
        rightText="Drag and drop the most important strengths into this shortlist box - ideally between 6-8, but at least 3"
        setItemOnDrag={(data) => setBrandStrengths(data)}
      />
    </>
  );
};

export default Step1;
