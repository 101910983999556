import axios from "axios";
import { logout, setToken } from "../actions/auth/authActions";
import { store } from "../store";
import jwt_decode from "jwt-decode";
import { toast } from "react-toastify";
import { ERRORMSG, SET_LOGIN_USER } from "../constants";

const getMyAccessToken = async (retry) => {
  const ud = store?.getState()?.auth?.token;
  if (ud && ud.token) {
    const decode = jwt_decode(ud.token);

    if (decode) {
      if (retry) {
        const headers = {
          "Content-Type": "application/json",
          authorization: ud.token,
        };
        try {
          const res = await axios.put(
            `${process.env.REACT_APP_MAIN_URL}/tokens/${ud.refreshToken}`,
            ud.refreshToken,
            { headers }
          );
          if (res.data.token) {
            store.dispatch(setToken(res.data));
            const userJwt = jwt_decode(res.data.token);
            if (userJwt) {
              const userData = {
                firstName: userJwt.firstName,
                lastName: userJwt.lastName,
                actionsRequired: userJwt.actionsRequired,
                role: userJwt.role,
                agencies: userJwt.agencies[0] || "",
                email: userJwt.sub,
                name: userJwt.firstName + " " + userJwt.lastName,
                id: userJwt.identifier,
              };
              store.dispatch({
                type: SET_LOGIN_USER,
                payload: { data: userData },
              });
            }
            return res.data.token;
          } else {
            store.dispatch(logout());
            return "";
          }
        } catch (error) {
          if (error?.response?.status === 401) {
            store.dispatch(logout());
          }
        }
      } else {
        return ud.token;
      }
    }
  } else {
    store.dispatch(logout());
    return "";
  }
};
const checkForMsg = (type) => {
  if (type === "postWithoutToken") {
    return true;
  } else {
    if (store.getState().auth.user) {
      return true;
    } else {
      return false;
    }
  }
};
export const api = async (
  endpoint,
  data,
  type,
  cancelToken = "",
  jwttoken = "",
  msg = "",
  retry = true,
  doRefresh = false,
  hideMsg = false,
  susMsg = true
) => {
  let response = { data: {} };
  let token;
  if (jwttoken) {
    token = jwttoken;
  } else {
    token = await getMyAccessToken(doRefresh);
  }
  let headers = { "Content-Type": "application/json" };
  try {
    switch (type) {
      case "post":
        headers["authorization"] = token;
        response = await axios.post(
          `${process.env.REACT_APP_MAIN_URL}/${endpoint}`,
          { ...data },
          {
            headers,
            cancelToken: cancelToken,
          }
        );
        break;
      case "postWithoutToken":
        response = await axios.post(
          `${process.env.REACT_APP_MAIN_URL}/${endpoint}`,
          { ...data },
          { headers }
        );
        break;
      case "postMultipart":
        headers["Content-Type"] = "multipart/form-data";
        headers["authorization"] = token;
        response = await axios.post(`${process.env.REACT_APP_MAIN_URL}/${endpoint}`, data, {
          headers,
        });
        break;
      case "putMultipart":
        headers["Content-Type"] = "multipart/form-data";
        headers["authorization"] = token;
        response = await axios.put(`${process.env.REACT_APP_MAIN_URL}/${endpoint}`, data, {
          headers,
        });
        break;
      case "get":
        headers["authorization"] = token;
        response = await axios.get(`${process.env.REACT_APP_MAIN_URL}/${endpoint}`, {
          headers,
          cancelToken: cancelToken,
        });
        break;
      case "put":
        headers["authorization"] = token;
        response = await axios.put(`${process.env.REACT_APP_MAIN_URL}/${endpoint}`, data, {
          headers,
        });
        break;
      case "patch":
        headers["authorization"] = token;
        response = await axios.patch(`${process.env.REACT_APP_MAIN_URL}/${endpoint}`, data, {
          headers,
        });
        break;
      case "patchMultipart":
        headers["Content-Type"] = "multipart/form-data";
        headers["authorization"] = token;
        response = await axios.patch(`${process.env.REACT_APP_MAIN_URL}/${endpoint}`, data, {
          headers,
        });
        break;
      case "delete":
        headers["authorization"] = token;
        response = await axios.delete(`${process.env.REACT_APP_MAIN_URL}/${endpoint}`, {
          data,
          headers,
        });
        break;
      default:
        return true;
    }
    if (susMsg && typeof response.data === "object")
      response.data.success = true;
    if (type !== "get" && msg) {
      toast.success(msg);
    }
  } catch (error) {
    if (
      error &&
      error.response &&
      error.response.status === 401 &&
      retry === true
    ) {
      return api(
        endpoint,
        data,
        type,
        cancelToken,
        jwttoken,
        msg,
        false,
        true,
        hideMsg,
        susMsg
      );
    } else {
      response.data.success = false;
      if (error && error.response) {
        if (!hideMsg && checkForMsg(type))
          toast.error(error.response?.data?.message || ERRORMSG);
        if (error.response.status === 401 || error.response.status === 503) {
          await store.dispatch(logout());
          response = error.response;
          if (response.data) response.data.success = false;
        } else {
          response = error.response;
          if (response.data) response.data.success = false;
        }
      } else {
        if (!hideMsg && checkForMsg(type)) toast.error(ERRORMSG);
      }
    }
  }
  return response?.data || response;
};
