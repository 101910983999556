import { Grid } from "@mui/material";
import PrivateSidebar from "../../layout/PrivateSidebar";
import ATRightComponent from "./ATRightComponent/ATRightComponent";

const AddToolsToProjectComponent = () => {
  return (
    <Grid container>
      <PrivateSidebar />
      <ATRightComponent />
    </Grid>
  );
};

export default AddToolsToProjectComponent;
