import { makeStyles } from "@mui/styles";
import DeleteButton from "../button/DeleteButton";

const useStyles = makeStyles((theme) => {
  return {
    imgContainer: {
      position: "relative",
      width: "90%",
    },
    small: {
      position: "relative",
    },
    newSmall: {
      width: "306px",
      position: "relative",
    },
    imgDel: {
      position: "absolute",
      bottom: "28px",
      right: "20px",
      backgroundColor: "#D40841",
      width: "24px",
      height: "24px",
      padding: "8px",
      borderRadius: "10px",
      color: "white",
      cursor: "pointer",
    },
    newImgDel: {
      position: "absolute",
      bottom: "34px",
      right: "20px",
      backgroundColor: "#D40841",
      width: "24px",
      height: "24px",
      padding: "8px",
      borderRadius: "10px",
      color: "white",
      cursor: "pointer",
    },
    bottom: {
      bottom: "13px",
    },
  };
});

const ImgDelContainer = (props) => {
  const classes = useStyles();
  return (
    <div
      className={
        props.newSmall
          ? classes.newSmall
          : props.small
          ? classes.small
          : classes.imgContainer
      }
    >
      <img
        className={props.className || ""}
        src={props.src || ""}
        alt={props.src || ""}
      />
      <DeleteButton
        className={
          props.newSmall
            ? `${classes.newImgDel} ${props.bottom ? classes.bottom : ""}`
            : classes.imgDel
        }
        onclick={props.handleDel}
      />
    </div>
  );
};

export default ImgDelContainer;
