import { Suspense } from "react";
import { useSelector } from "react-redux";
import { ErrorBoundary } from "react-error-boundary";
import { Navigate, useLocation, useNavigate } from "react-router";
import LoadingComponent from "../components/ui/loader/Loader";
import ErrorFallback from "../components/ui/Error/ErrorFallBack";
import { Grid } from "@mui/material";
import { privateSidebarNavOp } from "../utils/NavBarConstants";

const RedirectAuth = (props) => {
  const auth = useSelector((state) => state.auth.token);
  const history = useNavigate();
  let location = useLocation();
  const loc = useLocation();
  let checkCustomSidebar = privateSidebarNavOp.find((a) => a === loc.pathname);
  const sidebarLayoutData = useSelector(
    (state) => state.sideBar.sidebarLayoutData
  );
  if (!checkCustomSidebar) {
    sidebarLayoutData?.map((item) => {
      if (item.name === "Tools") {
        checkCustomSidebar = item?.activePaths?.find(
          (a) => a === loc?.pathname?.replaceAll("%20", " ")
        );
      }
    });
  }
  return !props.role || props.role === "all" || auth ? (
    props.notLazy ? (
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onReset={() => history("/", { replace: "true" })}
      >
        {props.children}
      </ErrorBoundary>
    ) : (
      <Suspense
        fallback={
          <Grid pt={checkCustomSidebar ? "4rem !important" : "0px"}>
            <LoadingComponent />
          </Grid>
        }
      >
        <ErrorBoundary
          FallbackComponent={ErrorFallback}
          onReset={() => history("/", { replace: "true" })}
        >
          {props.children}
        </ErrorBoundary>
      </Suspense>
    )
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default RedirectAuth;
