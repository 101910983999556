import { Stack, Box } from "@mui/material";
import { getMarginFromValue } from "../../../../../../../utils/getMarginFromValue";
import { config } from "../../../../../../../config";

const Step8 = ({
  competitorPositioningMatrixA = [],
  competitorPositioningMatrixB = [],
  competitorPositioningAxes,
}) => {
  return (
    <Stack justifyContent="space-between" p="30px">
      <Box flex="1">
        <div className="y-axis-label" textAlign="center">
          {competitorPositioningAxes[1]?.logo && (
            <img
              alt="Logo Img"
              src={`${config.dwdPath}${competitorPositioningAxes[1].logo}`}
              style={{ width: "75px", height: "45px" }}
            />
          )}
          <Box>{competitorPositioningAxes[1]?.name || ""}</Box>
        </div>

        <div className="matrix-wrapper">
          <div className="matrix">
            <div className="y-axis"></div>
            <div className="x-axis" textAlign="center">
              {competitorPositioningAxes[0]?.logo && (
                <img
                  alt="Logo Img"
                  src={`${config.dwdPath}${competitorPositioningAxes[0].logo}`}
                  style={{ width: "75px", height: "45px" }}
                />
              )}
              <Box>{competitorPositioningAxes[0]?.name || ""}</Box>
            </div>
            {competitorPositioningMatrixA && competitorPositioningMatrixA.length
              ? competitorPositioningMatrixA.map((a) => (
                  <span
                    className="matrix-cell"
                    style={{
                      top: getMarginFromValue(a.differentiator2 || 0),
                      left: getMarginFromValue(a.differentiator1 || 0),
                    }}
                  >
                    {a?.logo && (
                      <img
                        alt="Logo Img"
                        src={`${config.dwdPath}${a.logo}`}
                        style={{ width: "75px", height: "45px" }}
                      />
                    )}
                    <span>{a.name}</span>
                  </span>
                ))
              : null}
          </div>
        </div>

        <Stack gap={1}>
          <Box display="flex" gap="20px">
            <Box mt="auto" mb="auto">
              X-axis
            </Box>
            <Box p="10px" border="1px solid grey" textAlign="center">
              {competitorPositioningAxes[0]?.logo && (
                <img
                  alt="Logo Img"
                  src={`${config.dwdPath}${competitorPositioningAxes[0].logo}`}
                  style={{ width: "75px", height: "45px" }}
                />
              )}
              <Box>{competitorPositioningAxes[0]?.name || ""}</Box>
            </Box>
          </Box>
          <Box display="flex" gap="20px">
            <Box mt="auto" mb="auto">
              Y-axis
            </Box>
            <Box p="10px" border="1px solid grey" textAlign="center">
              {competitorPositioningAxes[1]?.logo && (
                <img
                  alt="Logo Img"
                  src={`${config.dwdPath}${competitorPositioningAxes[1].logo}`}
                  style={{ width: "75px", height: "45px" }}
                />
              )}
              <Box>{competitorPositioningAxes[1]?.name || ""}</Box>
            </Box>
          </Box>
        </Stack>
      </Box>
      <Box flex="1">
        <div className="y-axis-label" textAlign="center">
          {competitorPositioningAxes[3]?.logo && (
            <img
              alt="Logo Img"
              src={competitorPositioningAxes[3].logo}
              style={{ width: "75px", height: "45px" }}
            />
          )}
          <Box>{competitorPositioningAxes[3]?.name || ""}</Box>
        </div>

        <div className="matrix-wrapper">
          <div className="matrix">
            <div className="y-axis"></div>
            <div className="x-axis" textAlign="center">
              {competitorPositioningAxes[2]?.logo && (
                <img
                  alt="Logo Img"
                  src={`${config.dwdPath}${competitorPositioningAxes[2].logo}`}
                  style={{ width: "75px", height: "45px" }}
                />
              )}
              <Box>{competitorPositioningAxes[2]?.name || ""}</Box>
            </div>
            {competitorPositioningMatrixB && competitorPositioningMatrixB.length
              ? competitorPositioningMatrixB.map((a) => (
                  <span
                    className="matrix-cell"
                    style={{
                      top: getMarginFromValue(a.differentiator4 || 0),
                      left: getMarginFromValue(a.differentiator3 || 0),
                    }}
                  >
                    {a?.logo && (
                      <img
                        alt="Logo Img"
                        src={`${config.dwdPath}${a.logo}`}
                        style={{ width: "75px", height: "45px" }}
                      />
                    )}
                    <span>{a.name || ""}</span>
                  </span>
                ))
              : null}
          </div>
        </div>
        <Stack gap={1}>
          <Box display="flex" gap="20px">
            <Box mt="auto" mb="auto">
              X-axis
            </Box>
            <Box p="10px" border="1px solid grey" textAlign="center">
              {competitorPositioningAxes[2]?.logo && (
                <img
                  alt="Logo Img"
                  src={`${config.dwdPath}${competitorPositioningAxes[2].logo}`}
                  style={{ width: "75px", height: "45px" }}
                />
              )}
              <Box>{competitorPositioningAxes[2]?.name || ""}</Box>
            </Box>
          </Box>
          <Box display="flex" gap="20px">
            <Box mt="auto" mb="auto">
              Y-axis
            </Box>
            <Box p="10px" border="1px solid grey" textAlign="center">
              {competitorPositioningAxes[3]?.logo && (
                <img
                  alt="Logo Img"
                  src={`${config.dwdPath}${competitorPositioningAxes[3].logo}`}
                  style={{ width: "75px", height: "45px" }}
                />
              )}
              <Box>{competitorPositioningAxes[3]?.name || ""}</Box>
            </Box>
          </Box>
        </Stack>
      </Box>
    </Stack>
  );
};

export default Step8;
