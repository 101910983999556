import { Navigate, Route, Routes as MyRoutes, useLocation } from "react-router";
import { shallowEqual, useSelector } from "react-redux";
import { routes } from "./RoutePaths";
import PrivateRoute from "../components/layout/PrivateRoute";
import PublicRoute from "../components/layout/PublicRoute";
import PrivateHome from "../pages/private/home/Home";
import Page404 from "../pages/public/error/Page404";
import RedirectAuth from "./RedirectAuth";
import Login from "../pages/public/auth/Login";
import Register from "../pages/public/auth/Register";
import ResetPassword from "../components/ui/modals/ResetPassword";
import { useEffect } from "react";
import useBindActionCreator from "../hooks/useBindActionCreator";
import { getSubsDetails } from "../redux/actions/client/ClientAction";
import PaymentPage from "../pages/private/payment/PaymentPage";
import SubscriptionModal from "../components/ui/modals/SubscriptionModal";
import moment from "moment";
import ForgotPassword from "../pages/public/auth/ForgotPassword";

const Routes = () => {
  const auth = useSelector((state) => state.auth?.token, shallowEqual);
  const agencyId = useSelector(
    (state) => state?.auth?.user?.agencies?.uuid,
    shallowEqual
  );
  const roles = useSelector((state) => state?.auth?.user?.role, shallowEqual);

  const package_details = useSelector(
    (state) => state?.client?.package_details,
    shallowEqual
  );

  const loc = useLocation().pathname;

  const passReset = useSelector(
    (state) => state.auth?.user?.actionsRequired?.passwordReset
  );

  const actions = useBindActionCreator({ getSubsDetails });

  const getSubDetails = () => {
    if (agencyId) actions.getSubsDetails(agencyId);
  };

  const checkForPlanModal = () => {
    let display = false;
    if (
      !roles?.find((a) => a === "ADMIN") &&
      ((auth && !loc.includes("payment") && !package_details) ||
        (!package_details.plan && !package_details.freeTrial) ||
        (!package_details.freeTrialEnd && !package_details.endDate) ||
        (package_details.freeTrialEnd &&
          moment(package_details.freeTrialEnd) < moment()) ||
        (package_details.endDate && moment(package_details.endDate) < moment()))
    ) {
      display = true;
    }
    return display;
  };

  useEffect(() => {
    getSubDetails();
  }, [loc]);
  return (
    <>
      <MyRoutes>
        <Route path="/" element={auth ? <PrivateRoute /> : <PublicRoute />}>
          <Route path="*" element={<Page404 />} />
          <Route
            path="/"
            element={auth ? <PrivateHome /> : <Navigate to="/login" replace />}
          />
          <Route
            path="/login"
            element={auth ? <Navigate to="/" replace /> : <Login />}
          />
          <Route
            path="/register"
            element={auth ? <Navigate to="/" replace /> : <Register />}
          />
          <Route
            path="/forgot-password"
            element={auth ? <Navigate to="/" replace /> : <ForgotPassword />}
          />
          <Route
            path="/success-payment"
            element={<PaymentPage rejectPage={false} />}
          />
          <Route
            path="/cancel-payment"
            element={<PaymentPage rejectPage={true} />}
          />
          {routes.map((r) =>
            r.subRouting ? (
              <Route
                key={r.path}
                path={r.path}
                element={
                  <RedirectAuth role={r.role} notLazy={r?.notLazy}>
                    <r.element title={r.name || ""} />
                  </RedirectAuth>
                }
              >
                {r.subRoute.map((e) => (
                  <Route
                    path={e.path}
                    key={e.path}
                    element={
                      <RedirectAuth role={r.role} notLazy={e?.notLazy}>
                        <e.element title={e.name || ""} />
                      </RedirectAuth>
                    }
                  />
                ))}
              </Route>
            ) : (
              <Route
                key={r.path}
                path={r.path}
                element={
                  <RedirectAuth role={r.role} notLazy={r?.notLazy}>
                    <r.element title={r.name || ""} />
                  </RedirectAuth>
                }
              />
            )
          )}
        </Route>
      </MyRoutes>
      {passReset && <ResetPassword open={passReset ? true : false} />}
      {/* //TODO Subscription */}
      {/* {auth && !loc.includes("payment") && !passReset && (
        <SubscriptionModal
          open={checkForPlanModal()}
          closable={false}
          getSubs={() => getSubDetails()}
        />
      )} */}
    </>
  );
};

export default Routes;
