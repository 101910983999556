import ProjectRightHeader from "../ProjectRightHeader";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import FolderIcon from "@mui/icons-material/Folder";
import CustomButton from "../../../ui/button/CustomButton";
import { useRef, useState, Children, useEffect } from "react";
import PUHeader from "./PUHeader";
import DropDownMenu from "../../../ui/menus/DropDownMenu";
import PUList from "./PUList";
import CollapseWithGrid from "../../../ui/customCollapse/CollapseWithGrid";
import EmptyList from "../../../ui/emptyList/EmptyList";
import { api } from "../../../../redux/api/api";
import { awsUpload } from "../../../../utils/s3upload";
import { useSelector } from "react-redux";
import { Box, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import AddProjectFolderModal from "./../../../ui/modals/AddProjectFolderModal";
import Loader from "../../../ui/loader/Loader";

const useStyles = makeStyles((theme) => ({
  folderOuter: {
    margin: "0",
    padding: "0",
    display: "block",
    listStyleType: "none",
  },
  folderInner: {
    display: "flex",
    alignItems: "center",
    marginBottom: "10px",
    cursor: "pointer",
  },
}));

const UploadSection = (props) => {
  const inputFile = useRef(null);
  const pid = useSelector((state) => state.project?.project);
  const classes = useStyles();

  const [pageLoading, setPageLoading] = useState(true);
  const [filesLoading, setFilesLoading] = useState(false);
  const [enchorEl, setEnchorEl] = useState(null);
  const [openP, setOpenP] = useState(false);
  const [menuVal, setMenuVal] = useState("Download");
  const [expand, setExpand] = useState(true);
  const [loading, setLoading] = useState(false);
  const [folderActive, setFolderActive] = useState({ name: "", uuid: "" });
  const [isFolderModalOpen, setIsFolderModalOpen] = useState(false);
  const [folderNames, setFolderNames] = useState([]);
  const [pUplods, setPUploads] = useState([]);
  const [loadFolderData, setLoadFolderData] = useState(null);
  const [selFolder, setSelFolder] = useState({
    id: "",
    fileName: "",
    uploadDate: "",
    fileFormat: "",
  });

  useEffect(() => {
    getFolders();
  }, [pid?.uuid]);

  const getFolders = (action, folderNewData) => {
    if (pid?.uuid) {
      api(`projects/${pid?.uuid}/folder`, {}, "get", "", "", "")
        .then((res) => {
          if (res && res.success) {
            setFolderNames(res.folders);
            if (res?.folders?.length) {
              if (action === "add") {
                setPUploads([]);
                setFolderActive(folderNewData);
              } else if (action === "edit") {
                setFolderActive(folderNewData);
              } else {
                setFolderActive(res.folders[0]);
                getFiles(res.folders[0]["uuid"]);
              }
            }
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };

  const getFiles = (folderID) => {
    setFilesLoading(true);
    if (pid?.uuid && folderID) {
      api(
        `projects/${pid?.uuid}/upload/list?folderId=${folderID}`,
        {},
        "get",
        "",
        "",
        ""
      )
        .then((res) => {
          if (res && res.success) {
            setPUploads(res);
            setPageLoading(false);
            setFilesLoading(false);
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };

  const deleteFile = (fileId) => {
    setFilesLoading(true);
    api(`projects/upload/${fileId}`, {}, "delete", "", "", "File deleted!")
      .then((res) => {
        if (res && res.success) {
          const index = pUplods.findIndex((el) => el.uuid === fileId);
          if (index > -1) {
            pUplods.splice(index, 1);
            setPUploads(pUplods);
            setFilesLoading(false);
          }
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handleSelect = (v) => {
    handleClose();
    switch (v) {
      case "Download":
        downloadFile(selFolder.fileUrl);
        break;
      case "Delete file":
        deleteFile(selFolder.fileId);
        break;
      default:
        break;
    }
  };

  const downloadFile = (url) => {
    window.open(url);
  };

  const handleClick = (event, val) => {
    setSelFolder(() => val);
    setEnchorEl(event.currentTarget);
    setOpenP(true);
  };

  const handleClose = () => {
    setEnchorEl(null);
    setOpenP(false);
  };

  const handleFileUpload = async (e) => {
    const { files } = e.target;
    if (files && files.length) {
      setLoading(true);
      const filename = files[0].name;
      var parts = filename.split(".");
      const fileType = parts[parts.length - 1];
      const fileData = new FormData();
      fileData.append("file", files[0]);
      const fd = await awsUpload(fileData);
      if (fd) {
        const res = await api(
          "projects/upload",
          {
            fileName: filename,
            fileFormat: fileType,
            projectId: pid.uuid,
            fileUrl: fd,
            folderId: folderActive.uuid,
          },
          "post",
          "",
          "",
          "File uploaded successfully"
        );
        if (res && res.success) {
          setPUploads([...pUplods, res]);
        }
        setLoading(false);
      }
    }
  };

  const onButtonClick = () => {
    if (pid?.uuid) inputFile.current.click();
  };

  const handleFolderClick = (folderData) => {
    setFolderActive(folderData);
    getFiles(folderData.uuid);
  };

  const handleFolderDoubleClick = (folderData) => {
    setLoadFolderData(folderData);
    setIsFolderModalOpen(true);
  };

  return pageLoading ? (
    <Loader />
  ) : (
    <>
      <Grid
        item
        lg={3}
        md={3}
        sm={3}
        xs={3}
        mt="2rem"
        style={{ paddingRight: "20px", borderRight: "1px solid #a7a7a7" }}
      >
        <ProjectRightHeader
          title="Folders"
          btn={
            <CustomButton
              name="Add"
              classNameI={"grey2"}
              size="small"
              endIcon={<AddCircleOutlineRoundedIcon />}
              onclick={() => {
                setLoadFolderData(null);
                setIsFolderModalOpen(true);
              }}
              loading={loading}
            />
          }
          mt={0}
          setCollsapn={(a) => setExpand(a)}
          isHideExpend={true}
        />
        {folderNames && folderNames.length ? (
          <Box>
            <ul className={classes.folderOuter}>
              {Children.toArray(
                folderNames.map((folder) => {
                  return (
                    <>
                      <li
                        className={classes.folderInner}
                        style={{
                          color:
                            folderActive?.uuid === folder.uuid
                              ? "#1D2D91"
                              : "#7a7a7a",
                        }}
                        onClick={() => handleFolderClick(folder)}
                        onDoubleClick={() => handleFolderDoubleClick(folder)}
                      >
                        <FolderIcon />{" "}
                        <span style={{ marginLeft: "10px" }}>
                          {folder.name}
                        </span>
                      </li>
                      {folder && folder.children && folder.children.length ? (
                        <ul
                          className={classes.folderOuter}
                          style={{ paddingLeft: "30px" }}
                        >
                          {Children.toArray(
                            folder.children.map((subFolder) => {
                              return (
                                <li
                                  className={classes.folderInner}
                                  style={{
                                    color:
                                      folderActive?.uuid === subFolder.uuid
                                        ? "#1D2D91"
                                        : "#7a7a7a",
                                  }}
                                  onClick={() => handleFolderClick(subFolder)}
                                  onDoubleClick={() =>
                                    handleFolderDoubleClick(subFolder)
                                  }
                                >
                                  <FolderIcon />{" "}
                                  <span style={{ marginLeft: "10px" }}>
                                    {subFolder.name}
                                  </span>
                                </li>
                              );
                            })
                          )}
                        </ul>
                      ) : null}
                    </>
                  );
                })
              )}
            </ul>
          </Box>
        ) : null}
      </Grid>
      <Grid
        item
        lg={9}
        md={9}
        sm={9}
        xs={9}
        mt="2rem"
        style={{ paddingLeft: "30px" }}
      >
        <input
          type="file"
          id="file"
          ref={inputFile}
          onChange={handleFileUpload}
          style={{ display: "none" }}
        />
        <ProjectRightHeader
          title={folderActive.name}
          fid={folderActive.uuid}
          btn={
            <CustomButton
              name="Upload"
              classNameI={"grey2"}
              size="small"
              endIcon={<AddCircleOutlineRoundedIcon />}
              onclick={() => onButtonClick()}
              loading={loading}
            />
          }
          mt={0}
          isHideExpend={true}
          setCollsapn={(a) => setExpand(a)}
        />
        <CollapseWithGrid expand={expand}>
          {pUplods && pUplods.length ? (
            <PUHeader generalClass={props.generalClass} />
          ) : null}
          {filesLoading ? (
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              textAlign="center"
              paddingTop="100px"
              margin="auto 0"
            >
              <Loader inside={true} />
            </Grid>
          ) : pUplods && pUplods.length ? (
            Children.toArray(
              pUplods.map((a) => (
                <PUList
                  {...a}
                  handleClick={handleClick}
                  generalClass={props.generalClass}
                  downloadFile={downloadFile}
                />
              ))
            )
          ) : (
            <EmptyList color="#D6D6D6" text="No Upload Found." />
          )}
        </CollapseWithGrid>
        <DropDownMenu
          enchorEl={enchorEl}
          openP={openP}
          handleClose={handleClose}
          options={["Download", "Delete file"]}
          val={menuVal}
          handleSelect={(v, id) => handleSelect(v, id)}
        />
      </Grid>
      <AddProjectFolderModal
        open={isFolderModalOpen}
        onClose={() => setIsFolderModalOpen(false)}
        toolTitle={
          loadFolderData
            ? "Project Files: Edit Folder"
            : "Project Files: Add Folder"
        }
        folderOptions={folderNames}
        getFolders={getFolders}
        loadFolderData={loadFolderData}
      />
    </>
  );
};

export default UploadSection;
