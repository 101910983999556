import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ToolDet from "./ToolDet";
// import OpeningScreen from "./OpeningScreen";
// import BestPraticeEx from "./BestPraticeEx";
import BrandArch from "./BrandArch";
import { useState } from "react";

const enums = {
  welcome: "Welcome",
  competitors: "Competitors",
  "meeting-customer-needs": "Meeting customer needs",
  "brand-archetypes": "Brand archetypes",
  "strengths-and-weaknesses": "Strengths and weaknesses",
  "brand-personality": "Brand personality",
  "brand-values": "Brand values",
  "brand-essence": "Brand essence",
  "brand-proof-points": "Brand proof points",
  "elevator-pitch": "Elevator pitch",
  "competitor-mapping": "Competitor mapping",
  market: "Market data",
};

const useStyles = makeStyles((theme) => {
  return {
    title: {
      color: theme.palette.secondary.main,
      marginBottom: "8px",
      fontWeight: 600,
      fontSize: "20px",
    },
    toolDetContainer: {
      minHeight: "200px",
      backgroundColor: "#F5F5F5",
      borderRadius: "10px",
      padding: "25px",
    },
    gTitle: {
      color: theme.palette.secondary.main,
      fontSize: "20px",
      marginBottom: "8px",
    },
    gDesc: {
      fontSize: "14px",
    },
    formTitle: {
      fontSize: "14px",
      marginBottom: "12px",
    },
    LargeImg: {
      maxWidth: "100%",
      height: "auto",
    },
  };
});
const SettingsSlides = ({ data, handleData, btnLoading, name, template }) => {
  const classes = useStyles();
  const [selIdx, setSelIdx] = useState("");
  return (
    <Grid container item lg={12} md={12} sm={12} xs={12}>
      <Grid item lg={12} md={12} sm={12} xs={12} mb={3}>
        <div className={classes.title}>Settings & Slides</div>
      </Grid>
      <ToolDet classes={classes} name={name} template={template} />
      {/* <OpeningScreen classes={classes} />
      <BestPraticeEx classes={classes} /> */}
      {data && data.length
        ? data.map((a, idx) => (
            <BrandArch
              key={a.key}
              data={a}
              enums={enums}
              loading={btnLoading && selIdx === idx}
              handleUpdate={(d) => {
                const dd = [...data];
                dd[idx] = { ...dd[idx], ...d };
                setSelIdx(() => idx);
                handleData(dd);
              }}
            />
          ))
        : null}
    </Grid>
  );
};

export default SettingsSlides;
