import { groupBy } from "lodash";
import { api } from "../../api/api";

export const getAllProjects = (id, active = true) => {
  return async (dispatch) => {
    try {
      const res = await api(
        `projects/lite/${id}?sort=dateLastActivity&order=desc&active=${active}`,
        "",
        "get"
      );
      if (res && res.success) {
        dispatch({
          type: "GET_ALL_PROJECT_LIST_DATA",
          payload: { data: res.projects },
        });
      }
    } catch (error) {}
  };
};

export const getAllTools = (id, active = true) => {
  return async (dispatch) => {
    try {
      const res = await api(
        `tools/?sort=dateLastActivity&order=desc&active=${active}`,
        "",
        "get"
      );
      if (res && res.success) {
        dispatch({
          type: "GET_ALL_TOOL_LIST_DATA",
          payload: { data: res.tools },
        });
      }
    } catch (error) {}
  };
};

export const getClientPageClients = () => {
  return async (dispatch) => {
    try {
      const res = await api(
        `clients?sort=dateLastActivity&order=desc&active=true`,
        "",
        "get"
      );
      if (res && res.success) {
        dispatch({
          type: "GET_ALL_DASH_CLIENT_LIST",
          payload: {
            data: res.clients,
          },
        });
      }
    } catch (error) {}
  };
};

export const searchFromProjects = (search) => {
  return async (dispatch) => {
    try {
      const res = await api(
        `search/project?keyword=${search}&active=true`,
        "",
        "get"
      );

      if (res && res.success) {
        const newD = groupBy(res.projects, "clientId");

        dispatch({
          type: "GET_ALL_SEARCH_PROJECT",
          payload: {
            data: Object.keys(newD).map((a) => newD[a][0]),
          },
        });
      }
    } catch (error) {}
  };
};
