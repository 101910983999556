import { Box, Slider, Stack } from "@mui/material";
import FormGroupTextField from "../../../../../../ui/formgroup/FormGroupTextField";
import TextAutoResize from "../../../../../../ui/formgroup/TextAutoResize";

const Step1 = ({ data, setData, otherDescriptors, setOtherDescriptors }) => {
  const handleDelete = (idx1, idx2) => {
    const d = [...data];
    if (d[idx1]?.personalities?.length > 1) {
      d[idx1].personalities.splice(idx2, 1);
    }
    setData(d);
  };
  const makeData = () => {
    let str = "";
    if (data && data.length) {
      const f = data.filter((a) => a.score > 3).sort((a, b) => b.score - a.score);
      const d = [];
      f.forEach((a) => {
        if (a.personalities) {
          d.push(...a.personalities);
        }
      });
      str = d.join(", ");
    }
    return str;
  };
  return (
    <>
      <Stack gap="20px" direction="row">
        <Box flex="0.6" display="flex" gap="10px" flexDirection="column">
          {data && data.length
            ? data.map((d, idx) => (
                <Box display="flex" gap="16px">
                  <Box flex="0.6">
                    {idx + 1}.{" "}
                    {d.personalities && d.personalities.length
                      ? d.personalities.map((a, idx2) => (
                          <span>
                            {a}{" "}
                            <span
                              style={{
                                fontWeight: 600,
                                marginLeft: "4px",
                                cursor: "pointer",
                              }}
                              onClick={() => handleDelete(idx, idx2)}
                            >
                              X
                            </span>
                            {d.personalities.length - 1 === idx2 ? "" : ","}{" "}
                          </span>
                        ))
                      : null}{" "}
                  </Box>
                  <Box flex="0.4">
                    <Slider
                      color="secondary"
                      value={d.score || 0}
                      min={0}
                      max={5}
                      valueLabelDisplay="auto"
                      step={1}
                      onChange={(_, e) => {
                        const d = [...data];
                        d[idx].score = e;
                        setData(d);
                      }}
                    />
                  </Box>
                </Box>
              ))
            : null}
        </Box>
        <Box flex="0.4" display="flex" flexDirection="column" gap="10px">
          <Box color="secondary.main" fontSize="26px" fontWeight="800px">
            High scoring words
          </Box>
          <Box>
            {makeData()}
            {otherDescriptors && typeof otherDescriptors === "string"
              ? ', '+ otherDescriptors.split(" ").join(", ")
              : null}
          </Box>
          <Box>
            Add any other personality descriptors you feel are important
          </Box>
          <Box>
            <TextAutoResize
              value={otherDescriptors}
              onChange={(e) => setOtherDescriptors(e)}
              other={{ minRows: 6, maxRows: 6 }}
            />
          </Box>
        </Box>
      </Stack>
    </>
  );
};

export default Step1;
