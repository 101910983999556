import { Box, Grid, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomButton from "../../ui/button/CustomButton";
import CustomExpand from "../../ui/customCollapse/CustomExpand";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import CollapseWithGrid from "../../ui/customCollapse/CollapseWithGrid";
import CustomSelect from "../../ui/formgroup/CustomSelect";
import FormGroupTextField from "../../ui/formgroup/FormGroupTextField";
import ToolCard from "./ToolCard";
import { StatusOptions } from "../../../utils/AllOptions";
import { useDispatch, useSelector } from "react-redux";
import ReasearchCard from "./ReasearchCard";
import DownloadCard from "./DownloadCard";
import { useNavigate } from "react-router";
import { updateRp } from "../../../utils/handleRPUpdate";
import moment from "moment";
import { api } from "../../../redux/api/api";
import {
  SET_ACTIVE_SIDEBAR_SECTION,
  SET_PROJECT_DATA,
} from "../../../redux/constants";
import {
  getClientListData,
  setFromIds,
  setPID,
  setCID,
} from "../../../redux/actions/client/ClientAction";
import { getAllProjects } from "../../../redux/actions/project/ProjectAction";
import useBindActionCreator from "../../../hooks/useBindActionCreator";
import { SidebargNavLayoutData } from "../../../utils/SideBarConstants";

const ProjectContainer = (props) => {
  const loc = useNavigate();
  const dispatch = useDispatch();
  const handleRoute = (where) => {
    dispatch({ type: SET_PROJECT_DATA, payload: { data: props } });
    dispatch(
      setFromIds({
        pdata: { id: props.uuid, name: props.name },
        cdata: { id: props.clientId, name: props.clientName },
      })
    );
    if (where) {
      dispatch({
        type: SET_ACTIVE_SIDEBAR_SECTION,
        payload: { data: SidebargNavLayoutData[0].name },
      });
      loc("/projects");
    } else {
      dispatch({
        type: SET_ACTIVE_SIDEBAR_SECTION,
        payload: { data: SidebargNavLayoutData[4].name },
      });
      loc("/projects/settings");
    }
  };
  const { projectId, clientId } = useSelector((state) => state.client);
  const actions = useBindActionCreator({
    getAllProjects,
    getClientListData,
    setPID,
  });
  const [expanded, setExpanded] = useState(true);
  const handleExpandClick = () => {
    setExpanded(() => !expanded);
  };

  const users = useSelector((state) => state.client.users);
  const [udata, setUdata] = useState([]);
  useEffect(() => {
    if (users && users.length) {
      setUdata(() =>
        users.map((a) => ({
          uuid: a.uuid,
          name: `${a.firstName} ${a.lastName}`,
        }))
      );
    }
  }, [users]);
  const [, updateState] = useState(false);
  const agenciesUUID = useSelector((state) => state.auth.user?.agencies?.uuid);

  const handleRemoveTool = (idx) => {
    props.tools.splice(idx, 1);
    updateState((old) => !old);
  };
  const handleRemoveRes = (idx) => {
    props.researches.splice(idx, 1);
    updateState((old) => !old);
  };

  const [pdata, setPData] = useState({
    status: "",
    dueDate: "",
  });
  const handleUpdateApi = async (data) =>
    await api(
      `projects/${props.uuid}`,
      data,
      "patch",
      "",
      "",
      "Project Details Updated!"
    );
  useEffect(() => {
    if (props) {
      setPData(() => ({
        status: props.status || "",
        dueDate: props.dueDate
          ? moment(props.dueDate).format("YYYY-MM-DD")
          : "",
      }));
    }
  }, [props]);

  const setData = async (field, value) => {
    setPData((old) => ({ ...old, [field]: value }));
    if (props.uuid) {
      if (field === "status" && value === "Archived") {
        const res = await handleUpdateApi({
          archived: true,
        });
        if (res && res.success) {
          if (projectId && projectId.id === props.uuid) {
            await actions.setPID("");
            dispatch({ type: SET_PROJECT_DATA, payload: { data: "" } });
          }
          props.removeProject();
          Promise.allSettled([
            actions.getClientListData(),
            actions.getAllProjects(agenciesUUID),
          ]);
        }
      } else {
        const res = await handleUpdateApi({
          [field]:
            field === "dueDate"
              ? moment(value).format("YYYY-MM-DD[T]HH:mm:ss")
              : value,
        });
        if (res && res.success) {
          actions.getAllProjects(agenciesUUID);
          updateRp(field, value, props.uuid);
        }
      }
    }
  };
  const handleProjectSelect = () => {
    dispatch({ type: SET_PROJECT_DATA, payload: { data: props } });
    dispatch(
      setFromIds({
        pdata: { id: props.uuid, name: props.name },
        cdata: { id: props.clientId, name: props.clientName },
      })
    );
    if (!projectId || projectId.id !== props.uuid) {
      if (!clientId || clientId.id !== props.clientId) {
        dispatch(setCID({ id: props.clientId, name: props.clientName }));
      }
      dispatch(setPID({ id: props.uuid, name: props.name }));
    }
  };
  const selectComp = (a, id, type) => {
    switch (type) {
      case "tool":
        return (
          <ToolCard
            key={a.uuid}
            {...a}
            handleRightSelect={() => {
              handleProjectSelect();
              dispatch({
                type: SET_ACTIVE_SIDEBAR_SECTION,
                payload: { data: SidebargNavLayoutData[1].name },
              });
            }}
            udata={udata}
            clientId={props.clientId}
            handleRemoveTool={() => handleRemoveTool(id)}
            updateState={() => updateState((old) => !old)}
            updateToolName={(name) => {
              props.tools[id].name = name;
            }}
            handleForceUpdate={props.handleForceUpdate}
          />
        );
      case "download":
        return (
          <DownloadCard
            key={a.uuid}
            {...a}
            clientId={props.clientId}
            handleRightSelect={() => {
              handleProjectSelect();
              dispatch({
                type: SET_ACTIVE_SIDEBAR_SECTION,
                payload: { data: SidebargNavLayoutData[2].name },
              });
            }}
          />
        );
      case "research":
        return (
          <ReasearchCard
            key={a.uuid}
            {...a}
            handleRightSelect={() => {
              handleProjectSelect();
              dispatch({
                type: SET_ACTIVE_SIDEBAR_SECTION,
                payload: { data: SidebargNavLayoutData[0].name },
              });
            }}
            clientId={props.clientId}
            handleRemoveRes={() => handleRemoveRes(id)}
            updateState={() => updateState((old) => !old)}
            updateResName={(name) => {
              props.researches[id].name = name;
            }}
            handleForceUpdate={props.handleForceUpdate}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Grid
        container
        item
        padding="10px 20px"
        bgcolor="#f5f5f5"
        borderRadius="10px"
        mb={1}
        mt={1}
      >
        <Grid item lg={6} md={4.5} sm={4.5} xs={4.5} margin="auto">
          <Box fontSize="20px" color="secondary.main">
            {props.name}
          </Box>
        </Grid>
        <Grid item lg={2} md={2} sm={2} xs={2} pr={2} margin="auto">
          <CustomSelect
            size="small"
            options={StatusOptions}
            value={pdata.status}
            onChange={(e) => setData("status", e)}
          />
        </Grid>
        <Grid item lg={1.5} md={2} sm={2} xs={2} pr={2} margin="auto">
          <FormGroupTextField
            size="small"
            type="date"
            value={pdata.dueDate}
            onChange={(e) => setData("dueDate", e)}
          />
        </Grid>
        <Grid item lg={2.5} md={3.5} sm={3.5} xs={3.5} textAlign="right">
          <Stack direction="row" gap="10px">
            <Box margin="auto" mr="0">
              <CustomButton
                name="Open"
                size="medium"
                classNameI="grey"
                onclick={() => handleRoute(true)}
              />
            </Box>
            <Box margin="auto 0">
              <CustomButton
                name="Settings"
                size="medium"
                classNameI="grey"
                onclick={() => handleRoute(false)}
              />
            </Box>

            <CustomExpand
              expanded={expanded}
              handleExpandClick={handleExpandClick}
              margin={true}
            >
              {expanded ? (
                <RemoveIcon
                  onClick={handleExpandClick}
                  fontSize="large"
                  color="secondary"
                />
              ) : (
                <AddIcon
                  onClick={handleExpandClick}
                  fontSize="large"
                  color="secondary"
                />
              )}
            </CustomExpand>
          </Stack>
        </Grid>
      </Grid>
      <CollapseWithGrid expand={expanded}>
        {props?.tools?.map((a, id) => selectComp(a, id, "tool"))}
        {props?.researches?.map((a, id) => selectComp(a, id, "research"))}
        {props?.downloads?.map((a, id) => selectComp(a, id, "download"))}
      </CollapseWithGrid>
    </>
  );
};

export default React.memo(ProjectContainer);
