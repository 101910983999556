import CustomButton from "../../../ui/button/CustomButton";
import ProjectRightHeader from "../ProjectRightHeader";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import PRHeader from "./PRHeader";
import PRList from "./PRList";
import DropDownMenu from "../../../ui/menus/DropDownMenu";
import { useState } from "react";
import CollapseWithGrid from "../../../ui/customCollapse/CollapseWithGrid";
import { useNavigate } from "react-router";
import RenameResearch from "../../../ui/modals/RenameResearch";
import UpdateResearch from "../../../ui/modals/UpdateResearch";
import DeleteModal from "../../../ui/modals/DeleteModal";
import UseResToAnotherProject from "../../../ui/modals/UseResToAnotherProject";
import { ResearchOption } from "../../../../utils/AllOptions";
import EmptyList from "../../../ui/emptyList/EmptyList";
import { useSelector } from "react-redux";
import { api } from "../../../../redux/api/api";

const ProjectResearchSection = (props) => {
  const resData = useSelector((state) => state.project?.project?.researches);
  const checkPid = useSelector((state) => state.client.projectId?.id);

  const [enchorEl, setEnchorEl] = useState(null);
  const loc = useNavigate();

  const [openP, setOpenP] = useState(false);
  const [menuVal] = useState("Open Research");
  const [expand, setExpand] = useState(true);

  const [delModal, setDelModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [renameModal, setRenameModal] = useState(false);
  const [copyModal, setCopyModal] = useState(false);
  const [selRes, setSelRes] = useState({ id: "", name: "", type: "" });
  const handleSelect = (v) => {
    handleClose();
    switch (v) {
      case ResearchOption[0]:
        if (selRes.type === "DIY") {
          loc(`/research/view-Diy/${selRes.id}`);
        } else {
          loc(`/research/view-research/${selRes.id}`);
        }
        break;
      case ResearchOption[1]:
        setRenameModal(true);
        break;
      // case ResearchOption[2]:
      //   setUpdateModal(true);
      //   break;
      case ResearchOption[2]:
        setDelModal(true);
        break;
      case ResearchOption[3]:
        setCopyModal(true);
        break;
      default:
        break;
    }
  };

  const handleClick = (event, data) => {
    setSelRes(() => data);
    setEnchorEl(event.currentTarget);
    setOpenP(true);
  };

  const handleClose = () => {
    setEnchorEl(null);
    setOpenP(false);
  };

  const handleCloseWithNewName = (s, name) => {
    if (s && resData && name && selRes.id) {
      for (let i = 0; i < resData.length; i++) {
        if (resData[i].uuid === selRes.id) {
          resData[i].name = name;
          break;
        }
      }
      setSelRes({ name: "", id: "", type: "" });
      setRenameModal(false);
    } else {
      setSelRes({ name: "", id: "", type: "" });
      setRenameModal(false);
    }
  };
  const handleRemoveFromTool = async () => {
    setDelModal(false);
    if (selRes.id && resData) {
      const res = await api(
        `research/${selRes.id}`,
        "",
        "delete",
        "",
        "",
        "Research removed!"
      );
      if (res && res.success) {
        const idx = resData.findIndex((a) => a.uuid == selRes.id);
        if (idx !== -1) {
          resData.splice(idx, 1);
        }
      }
      setSelRes({ name: "", id: "", type: "" });
    }
  };
  return (
    <>
      <ProjectRightHeader
        title="Research"
        btn={
          <CustomButton
            name="Add"
            classNameI={"red"}
            size="small"
            endIcon={<AddCircleOutlineRoundedIcon />}
            onclick={() => loc("/projects/research")}
          />
        }
        mt="2rem"
        setCollsapn={(a) => setExpand(a)}
        isHideExpend={true}
      />
      <CollapseWithGrid expand={expand}>
        {resData && resData.length ? (
          <PRHeader generalClass={props.generalClass} />
        ) : null}
        {resData && resData.length ? (
          resData.map((a) => (
            <PRList
              {...a}
              handleClick={handleClick}
              generalClass={props.generalClass}
            />
          ))
        ) : (
          <EmptyList
            color="#D40841"
            text="Add research to your project which help validate your project and recommendations."
          />
        )}
      </CollapseWithGrid>
      <DropDownMenu
        enchorEl={enchorEl}
        openP={openP}
        handleClose={handleClose}
        options={ResearchOption}
        val={menuVal}
        handleSelect={(value) => handleSelect(value)}
      />
      <RenameResearch
        open={renameModal}
        onClose={handleCloseWithNewName}
        {...selRes}
      />
      <UpdateResearch
        open={updateModal}
        onClose={() => setUpdateModal(false)}
      />
      <DeleteModal
        title="Are you sure you want to remove this
research from the project?"
        open={delModal}
        onClose={() => {
          setDelModal(false);
          setSelRes({ name: "", id: "", type: "" });
        }}
        onDelete={handleRemoveFromTool}
      />
      <UseResToAnotherProject
        open={copyModal}
        onClose={() => {
          setCopyModal(false);
          setSelRes({ name: "", id: "", type: "" });
        }}
        {...selRes}
        projectId={checkPid}
      />
    </>
  );
};

export default ProjectResearchSection;
