import { Box, Stack } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import CustomButton from "../../../../../ui/button/CustomButton";
const BrandPositioning = (props) => {
  const [brandPositioning, setBrandPositioning] = useState({
    brandValueOne: "",
    brandValueTwo: "",
    brandValueThree: "",
    brandValueFour: "",
    brandEssence: "",
  });
  useEffect(() => {
    if (props?.td?.data?.brandPositioning) {
      setBrandPositioning(props.td.data.brandPositioning);
    }
  }, [props]);
  return (
    <>
      <Stack direction="row" gap="30px">
        <Box flex="0.2" display="flex" gap="10px" flexDirection="column">
          <Box color="secondary.main" fontSize="20px" fontWeight="800px">
            Brand positioning
          </Box>
          <Box mb={2} fontSize="14px">
            What is the brand essence and values?
          </Box>
        </Box>
        <Box flex="0.8">
          <Stack gap="20px" padding="10px" minHeight="450px">
            <Box className="container">
              <textarea
                className="form-control form-control-sm essence"
                value={brandPositioning.brandEssence}
                style={{ fontFamily: "Rubik" }}
                onChange={(e) =>
                  setBrandPositioning((old) => ({
                    ...old,
                    brandEssence: e.target.value,
                  }))
                }
              ></textarea>
              <textarea
                className="form-control form-control-sm value left"
                value={brandPositioning.brandValueOne}
                style={{ fontFamily: "Rubik" }}
                onChange={(e) =>
                  setBrandPositioning((old) => ({
                    ...old,
                    brandValueOne: e.target.value,
                  }))
                }
              ></textarea>
              <textarea
                className="form-control form-control-sm value"
                value={brandPositioning.brandValueTwo}
                style={{ fontFamily: "Rubik" }}
                onChange={(e) =>
                  setBrandPositioning((old) => ({
                    ...old,
                    brandValueTwo: e.target.value,
                  }))
                }
              ></textarea>
              <textarea
                className="form-control form-control-sm value left bottom"
                value={brandPositioning.brandValueThree}
                style={{ fontFamily: "Rubik" }}
                onChange={(e) =>
                  setBrandPositioning((old) => ({
                    ...old,
                    brandValueThree: e.target.value,
                  }))
                }
              ></textarea>

              <textarea
                className="form-control form-control-sm value bottom"
                value={brandPositioning.brandValueFour}
                onChange={(e) =>
                  setBrandPositioning((old) => ({
                    ...old,
                    brandValueFour: e.target.value,
                  }))
                }
              ></textarea>
            </Box>
          </Stack>
        </Box>
      </Stack>
      <Box display="flex" justifyContent="space-between" mt="auto" mb="0">
        <CustomButton
          name="<   Prev"
          onclick={() =>
            props.callApi({ brandPositioning: brandPositioning }, true, false)
          }
          loading={props.btnLoading}
        />
        <CustomButton
          name="Next   >"
          disabled={
            brandPositioning
              ? Object.values(brandPositioning).filter((a) => a).length < 1
              : false
          }
          onclick={() =>
            props.callApi({ brandPositioning: brandPositioning }, true, true)
          }
          loading={props.btnLoading}
        />
      </Box>
    </>
  );
};

export default BrandPositioning;
