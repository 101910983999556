import { Divider, Grid } from "@mui/material";
import moment from "moment";
import { makeStyles } from "@mui/styles";
import { researchMenuOption } from "../../../../utils/AllOptions";
import CustomCheckBox from "../../../ui/checkbox/CustomCheckBox";
import { useNavigate } from "react-router";

const useStyles = makeStyles((theme) => ({
  divider: {
    backgroundColor: "#D40841",
  },
  res: {
    fontSize: "18px",
    color: theme.palette.secondary.main,
    textDecoration: "underline",
    cursor: "pointer",
  },
  nameDate: {
    fontSize: "16px",
    marginTop: "4px",
    paddingRight: "4px",
  },
  provider: {
    fontSize: "18px",
  },
}));

const RTList = (props) => {
  const classes = useStyles();
  const loc = useNavigate();
  const handleOpen = () =>
    props?.data?.researchType === "CUSTOM"
      ? loc(`/research/view-Diy/${props.uuid}`)
      : loc(`/research/view-research/${props.uuid}`);

  return (
    <>
      <Grid item lg={4} md={3} sm={3} xs={3} margin="auto 0">
        <div className={classes.res} onClick={handleOpen}>
          {props.name}
        </div>
        <div className={classes.nameDate}>
          {(props.data &&
            props.data.researchType &&
            researchMenuOption[props.data.researchType]) ||
            "-"}
        </div>
      </Grid>
      <Grid item lg={3.7} md={3} sm={3} xs={3} margin="auto 0">
        <div className={classes.provider}>{props.projectName || "-"}</div>
        <div className={classes.nameDate}>{props.clientName || "-"}</div>
      </Grid>
      <Grid item lg={3} md={4} sm={4} xs={4} margin="auto 0">
        <div className={classes.provider}>
          {(props.data &&
            props.data.researchType &&
            researchMenuOption[props.data.researchType]) ||
            "-"}
        </div>
        <div className={classes.nameDate}>
          {" "}
          {props.dateCreated
            ? moment(props.dateCreated).format("D MMM YY")
            : "-"}
        </div>
      </Grid>
      <Grid item lg={1.3} md={2} sm={2} xs={2} margin="auto 0">
        <CustomCheckBox
          checked={props.checked}
          onchange={(e) => props.handleSelect(e)}
        />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} mb={2} mt={2}>
        <Divider variant="fullWidth" className={classes.divider} />
      </Grid>
    </>
  );
};

export default RTList;
