import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Loader from "react-spinners/ClipLoader";

const useStyles = makeStyles((theme) => ({
  orange: {
    backgroundColor: "#F19C7E !important",
    borderColor: "#F19C7E",
  },
  red: {
    backgroundColor: "#D40841 !important",
    borderColor: "#D40841",
    color: `white !important`,
  },
  grey: {
    backgroundColor: "#D6D6D6 !important",
    borderColor: "#D6D6D6 !important",
    color: "#7A7A7A !important",
  },
  grey2: {
    backgroundColor: "#D6D6D6 !important",
    borderColor: "#D6D6D6 !important",
    color: `${theme.palette.secondary.main} !important`,
  },
  grey3: {
    backgroundColor: "#6D6D6D !important",
    borderColor: "#6D6D6D !important",
    color: `white !important`,
  },
  yellow: {
    backgroundColor: "#FFED71 !important",
    borderColor: "#FFED71",
    color: `${theme.palette.secondary.main} !important`,
  },
  mainBtn: {
    textTransform: "none !important",
    fontWeight: "400 !important",
    borderRadius: "10px !important",
  },
  pink: {
    backgroundColor: "#F2D9E7 !important",
    borderColor: "#F2D9E7",
    color: `${theme.palette.secondary.main} !important`,
  },
}));

const CustomButton = ({
  endIcon,
  name,
  className,
  classNameI,
  onclick,
  loading = false,
  fullWidth = false,
  variant = "contained",
  styled = {},
  size = "small",
  primary = false,
  disabled = false,
  iconClass = "",
}) => {
  const classes = useStyles();

  return (
    <Button
      classes={{ endIcon: iconClass }}
      className={`${variant !== "text" && classes.mainBtn} ${
        className || classes[classNameI]
      }`}
      disabled={disabled}
      style={styled}
      color={primary ? "primary" : "secondary"}
      variant={variant}
      size={size}
      onClick={loading ? null : onclick}
      fullWidth={fullWidth}
      endIcon={endIcon}
    >
      {loading ? <Loader color="white" size="18px" /> : name}
    </Button>
  );
};

export default CustomButton;
