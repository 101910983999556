import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => {
  return {
    title: {
      color: theme.palette.secondary.main,
      marginBottom: "20px",
      fontWeight: 600,
      fontSize: "20px",
    },
    subTitle: {
      color: "#466682",
      marginBottom: "20px",
      fontSize: "20px",
    },
    sT: {
      color: "#466682",
      marginBottom: "10px",
    },
  };
});
const Intro = () => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.title}>What is discovery?</div>
      <div className={classes.subTitle}>
        To get the most out of your brand strategy workshop we recommend you run
        a preliminary Discovery Session with your client. This is best done in
        person, but can also be facilitated by phone, video conference or even
        email.
      </div>
      <div className={classes.sT}>
        A Discovery Session provides the opportunity for you to discuss and
        discover the information that will form the basis for the brand Strategy
        workshop.
      </div>
      <div className={classes.sT}>
        Having completed this preparation, the workshop Will run more smoothly
        and the client will usually have a greater sense of confidence in the
        agency and value of the process.
      </div>
      <div className={classes.sT}>
        The following pages walk you through the questionsto ask and pre-fill
        that information for the workshop. Make sure you read through and
        understand what you collect as part of your prep for the workshop
        itself.
      </div>
    </>
  );
};

export default Intro;
