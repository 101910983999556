import { useState } from "react";
import EmptyList from "../../../ui/emptyList/EmptyList";
import DropDownMenu from "../../../ui/menus/DropDownMenu";
import RLTitle from "../RITitle";
import ETHeader from "./ETHeader";
// import ETList from "./ETList";

const ExternalRes = () => {
  const [enchorEl, setEnchorEl] = useState(null);
  const [openP, setOpenP] = useState(false);
  const [menuVal, setMenuVal] = useState("Open Research");

  const handleClick = (event, val) => {
    setEnchorEl(event.currentTarget);
    setOpenP(true);
  };

  const handleClose = () => {
    setEnchorEl(null);
    setOpenP(false);
  };
  return (
    <>
      <RLTitle
        title="External Research Resources"
        subTitle="Gather your research outside of Brandvas with these popular resources then use the DIY research option to bring it back into Brandvas."
      />
      <ETHeader />
      {/* {["", "", "", "", "", "", ""].map((a) => (
        <ETList handleClick={handleClick} />
      ))} */}
      <EmptyList color="#D40841" text="Coming Soon!" />
      <DropDownMenu
        enchorEl={enchorEl}
        openP={openP}
        handleClose={handleClose}
        options={[
          "Open Research",
          "Rename Research",
          "Update Research",
          "Remove from Project",
          "Copy to another Project",
        ]}
        val={menuVal}
      />
    </>
  );
};

export default ExternalRes;
