import { Box, Divider, Grid, Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import RemoveIcon from "@mui/icons-material/Remove";
import { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import CustomExpand from "../../ui/customCollapse/CustomExpand";
import CollapseWithGrid from "../../ui/customCollapse/CollapseWithGrid";

const TLeft = () => {
  const [expanded, setExpanded] = useState(true);
  const handleExpandClick = () => {
    setExpanded(() => !expanded);
  };

  return (
    <Grid item lg={1.2} md={2} sm={2} xs={2} textAlign="left">
      <Stack>
        <Box fontWeight={600} marginBottom="20px" marginTop="10px">
          What are templates?
        </Box>
        <CollapseWithGrid expand={expanded}>
          <Box fontSize="14px" marginBottom="20px">
            Templates provide an easy way to streamline setting up workshops,
            media briefs and creative to suit either your client or business
            needs.
            <br />
            You can then easily use a template against a new project saving time
            and effort.
          </Box>
        </CollapseWithGrid>
        <Divider variant="fullWidth" />
        <Stack direction="row">
          <Box
            fontWeight={600}
            fontSize="14px"
            margin="auto"
            marginLeft="0"
            color="secondary.main"
          >
            {expanded ? "HIDE" : "SHOW"} DETAILS
          </Box>
          <CustomExpand
            expanded={expanded}
            handleExpandClick={handleExpandClick}
          >
            {expanded ? (
              <RemoveIcon fontSize="large" color="secondary" />
            ) : (
              <AddIcon fontSize="large" color="secondary" />
            )}
          </CustomExpand>
        </Stack>
        <Divider variant="fullWidth" />
      </Stack>
    </Grid>
  );
};

export default TLeft;
