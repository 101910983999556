import { Box, Stack, TextField } from "@mui/material";
import { useRef, useState } from "react";
import { isValidPhoneNumber } from "mui-tel-input";
import { Link } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import { agencyType } from "../../utils/AllOptions";
import CustomPhone from "../ui/formgroup/CustomPhone";
import CustomSelect from "../ui/formgroup/CustomSelect";
import CustomButton from "../ui/button/CustomButton";
import CustomCheckBox from "../ui/checkbox/CustomCheckBox";
import ReCAPTCHA from "react-google-recaptcha";
import { config } from "../../config";
import { api } from "../../redux/api/api";
import { isEmpty } from "lodash";
import { v4 as uuidv4 } from "uuid";
import { login } from "../../redux/actions/auth/authActions";
import useBindActionCreator from "../../hooks/useBindActionCreator";

const staffOps = [
  "Sole trader",
  "2 - 4",
  "5 - 10",
  "11 - 20",
  "21 - 40",
  "more than 40",
];

const RegisterComponent = () => {
  const simpleValidator = useRef(
    new SimpleReactValidator({
      validators: {
        phoneNum: {
          required: true,
          message: "Please upload a valid mobile number.",
          rule: (val) => {
            return isValidPhoneNumber(val);
          },
        },
      },
    })
  );
  const actions = useBindActionCreator({ login });
  const captcha = useRef();
  const setData = (field, data) =>
    setLoginData((old) => {
      return { ...old, [field]: data };
    });

  const [, setDisplayError] = useState(false);
  const [loading, setLoading] = useState(false);
  const blurSetup = async (field) => {
    await setDisplayError(() => false);
    simpleValidator.current.showMessageFor(field);
    await setDisplayError(() => true);
  };
  const [loginData, setLoginData] = useState({
    emailAddress: "",
    agencyName: "",
    typeOfAgency: agencyType[0],
    numberOfStaff: staffOps[3],
    terms: "",
    recaptcha: "",
    firstName: "",
    lastName: "",
    phone: "",
    phoneNumber: {
      number: "",
      internationalNumber: "",
      nationalNumber: "",
      e164Number: "",
      countryCode: "IN",
      dialCode: "+91",
    },
  });
  const phoneNumGet = (val, info) => {
    setData("phone", val);
    const phoneNumber = {
      number: info.nationalNumber,
      internationalNumber: info.numberValue,
      nationalNumber: info.nationalNumber,
      e164Number: info.numberValue,
      countryCode: info.countryCode,
      dialCode: "+" + info.countryCallingCode,
    };
    setData("phoneNumber", phoneNumber);
  };
  const register = async () => {
    if (simpleValidator.current.allValid()) {
      setLoading(true);
      const res = await api(
        "register",
        loginData,
        "postWithoutToken",
        "",
        "",
        "Register Success!"
      );
      if (res && res && res.success) {
        const cid = isEmpty(localStorage.getItem("client_id"))
          ? uuidv4()
          : localStorage.getItem("client_id");
        localStorage.setItem("client_id", cid);
        const data = {
          emailAddress: loginData.emailAddress,
          password: res.password,
          clientId: cid,
        };
        await actions.login(data);
      }
    } else {
      simpleValidator.current.showMessages();
      setDisplayError((old) => !old);
    }
    setData("recaptcha", "");
    captcha.current?.reset();
    setLoading(false);
  };
  return (
    <Stack
      width="100%"
      maxWidth="570px"
      fontSize="20px"
      mt="100px !important"
      fontWeight="300"
      margin="auto"
      textAlign="left"
      gap="14px"
    >
      <Box color={"secondary.main"} fontSize="34px" mb="30px">
        Welcome to Brandvas
      </Box>
      <Box mb="20px">
        Ready to turbo-boost your productivity and profitability? <br /> Sign up
        now to be ahead of the game. Already have an account?{" "}
        <Link to="/login">Log in</Link>
      </Box>
      <Box color="red" mb="30px">
        If you have a promotional code, you will be able to apply it after you
        have created your agency.
      </Box>
      <div className="fg">
        <div className="fgmt">Agency name*</div>
        <div className="fgmt2">
          <TextField
            type={"text"}
            size="small"
            variant="outlined"
            color="secondary"
            placeholder="Agency name"
            value={loginData.agencyName}
            onChange={(e) => setData("agencyName", e.target.value)}
            onBlur={() => blurSetup("Agency name")}
            fullWidth
          />
          {simpleValidator.current.message(
            "Agency name",
            loginData.agencyName,
            "required",
            {
              className: "errorClass1",
            }
          )}
        </div>
      </div>
      <div className="fg">
        <div className="fgmt">Type of Agency*</div>
        <div className="fgmt2">
          <CustomSelect
            fullWidth
            value={loginData.typeOfAgency}
            options={agencyType}
            onChange={(e) => setData("typeOfAgency", e)}
            customClass="mySelect"
            size="small"
          />
          {simpleValidator.current.message(
            "Type of Agency*",
            loginData.typeOfAgency,
            "required",
            {
              className: "errorClass1",
            }
          )}
        </div>
      </div>
      <div className="fg">
        <div className="fgmt">First name*</div>
        <div className="fgmt2">
          <TextField
            type={"text"}
            size="small"
            variant="outlined"
            color="secondary"
            placeholder="First name"
            value={loginData.firstName}
            onChange={(e) => setData("firstName", e.target.value)}
            onBlur={() => blurSetup("First name")}
            fullWidth
          />
          {simpleValidator.current.message(
            "First name",
            loginData.firstName,
            "required",
            {
              className: "errorClass1",
            }
          )}
        </div>
      </div>
      <div className="fg">
        <div className="fgmt">Last name*</div>
        <div className="fgmt2">
          <TextField
            type={"text"}
            size="small"
            variant="outlined"
            color="secondary"
            placeholder="Last name"
            value={loginData.lastName}
            onChange={(e) => setData("lastName", e.target.value)}
            onBlur={() => blurSetup("Last name")}
            fullWidth
          />
          {simpleValidator.current.message(
            "Last name",
            loginData.lastName,
            "required",
            {
              className: "errorClass1",
            }
          )}
        </div>
      </div>
      <div className="fg">
        <div className="fgmt">Phone*</div>
        <div className="fgmt2">
          <CustomPhone
            customClass="mySelect"
            value={loginData.phone}
            size="small"
            onChange={(e, info) => phoneNumGet(e, info)}
            onBlur={() => blurSetup("Phone")}
          />
          {simpleValidator.current.message(
            "Phone",
            loginData.phone,
            "required|phoneNum",
            {
              className: "errorClass1",
            }
          )}
        </div>
      </div>
      <div className="fg">
        <div className="fgmt">Your Email*</div>
        <div className="fgmt2">
          <TextField
            type={"text"}
            size="small"
            variant="outlined"
            color="secondary"
            placeholder="Your Email"
            value={loginData.emailAddress}
            onChange={(e) => setData("emailAddress", e.target.value)}
            onBlur={() => blurSetup("Your Email")}
            fullWidth
          />
          {simpleValidator.current.message(
            "Your Email",
            loginData.emailAddress,
            "required",
            {
              className: "errorClass1",
            }
          )}
        </div>
      </div>
      <div className="fg">
        <div className="fgmt">Number of staff</div>
        <div className="fgmt2">
          <CustomSelect
            fullWidth
            value={loginData.numberOfStaff}
            options={staffOps}
            onChange={(e) => setData("numberOfStaff", e)}
            customClass="mySelect"
            size="small"
          />
        </div>
      </div>
      <Stack direction="row" mt="30px">
        <Box mt="0">
          <CustomCheckBox
            value={loginData.terms}
            onchange={(e) => setData("terms", e)}
            onBlur={() => blurSetup("Terms & condition")}
          />
        </Box>
        <Box>
          I have read and agree to both the Brandvas{" "}
          <a
            href="https://www.brandvas.io/privacy-policy"
            target="_blank"
            className="notlink"
            rel="noreferrer"
          >
            Privacy policy
          </a>{" "}
          and{" "}
          <a
            href="https://www.brandvas.io/terms-conditions"
            target="_blank"
            className="notlink"
            rel="noreferrer"
          >
            Terms and conditions
          </a>
        </Box>
      </Stack>
      {simpleValidator.current.message(
        "Terms & condition",
        loginData.terms ? "yes" : null,
        "required",
        {
          className: "errorClass1",
        }
      )}
      <Box>
        <ReCAPTCHA
          ref={captcha}
          sitekey={config.RECAPTCHA_SITE_KEY}
          onChange={(e) => setData("recaptcha", e)}
          onError={() => setData("recaptcha", "")}
          onExpired={() => setData("recaptcha", "")}
        />
        {simpleValidator.current.message(
          "Recaptcha",
          loginData.recaptcha,
          "required",
          {
            className: "errorClass1",
          }
        )}
      </Box>
      <Box ml="auto">
        <CustomButton
          name={"Let's Go!"}
          size="midum"
          onclick={register}
          loading={loading}
        />
      </Box>
    </Stack>
  );
};

export default RegisterComponent;
