import { Box, Stack } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import CustomButton from "../../../../../ui/button/CustomButton";
import FormGroupTextField from "../../../../../ui/formgroup/FormGroupTextField";
import TextAutoResize from "../../../../../ui/formgroup/TextAutoResize";

const ToneVoice = (props) => {
  const [toneOfVoice, setToneOfVoice] = useState("");
  const data = props?.td?.data;
  useEffect(() => {
    if (data && data.toneOfVoice && typeof data.toneOfVoice === "string") {
      setToneOfVoice(data.toneOfVoice);
    }
  }, [props]);
  return (
    <>
      <Stack direction="row" gap="30px">
        <Box flex="0.2" display="flex" gap="10px" flexDirection="column">
          <Box color="secondary.main" fontSize="20px" fontWeight="800px">
            Tone Of Voice
          </Box>
          <Box mb={2} fontSize="14px">
            What is the tone of voice for the creative campaign?
          </Box>
        </Box>
        <Box flex="0.8">
          <TextAutoResize
            other={{ minRows: 6, maxRows: 6 }}
            value={toneOfVoice}
            onChange={(e) => setToneOfVoice(e)}
          />
        </Box>
      </Stack>
      <Box display="flex" justifyContent="space-between" mt="auto" mb="0">
        <CustomButton
          name="<   Prev"
          onclick={() => props.callApi({ toneOfVoice }, true, false)}
          loading={props.btnLoading}
        />
        <CustomButton
          name="Next   >"
          disabled={!toneOfVoice}
          onclick={() => props.callApi({ toneOfVoice }, true, true)}
          loading={props.btnLoading}
        />
      </Box>
    </>
  );
};

export default ToneVoice;
