import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useLocation } from "react-router";

const useStyle = makeStyles((theme) => ({
  title: {
    fontSize: "28px",
    color: `${theme.palette.secondary.main} !important`,
    marginBottom: "10px",
  },
  subTitle: {
    fontSize: "18px",
  },
}));

const RTitle = () => {
  const classes = useStyle();
  const loc = useLocation().pathname;
  return (
    <Grid lg={12} md={12} sm={12} xs={12} mt={0} mb={4}>
      <div className={classes.title}>
        {" "}
        {loc.includes("projects")
          ? "Recommended from the Research Marketplace"
          : "Integrated Research Options"}{" "}
      </div>
      <div className={classes.subTitle}>
        {loc.includes("projects")
          ? "Based on this project's settings, we recommend using the below research options. You view research options before committing."
          : "Access our integrated options or use your own."}
      </div>
    </Grid>
  );
};

export default RTitle;
