import { Avatar, Box, Grid, Stack } from "@mui/material";
import React, { useState } from "react";
import { researchMenuOption, ResearchOption } from "../../../utils/AllOptions";
import SelectButton from "../../ui/button/SelectButton";
import moment from "moment";
import RenameResearch from "../../ui/modals/RenameResearch";
import UpdateResearch from "../../ui/modals/UpdateResearch";
import UseResToAnotherProject from "../../ui/modals/UseResToAnotherProject";
import { useNavigate } from "react-router";
import { updateRp } from "../../../utils/handleRPUpdate";
import { useSelector } from "react-redux";
import { api } from "../../../redux/api/api";
import DropDownMenu from "../../ui/menus/DropDownMenu";
import DeleteModal from "../../ui/modals/DeleteModal";

const ReasearchCard = (props) => {
  const [enchorEl, setEnchorEl] = useState(null);
  const loc = useNavigate();
  const resData = useSelector((state) => state.project?.project?.researches);

  const [openP, setOpenP] = useState(false);

  const [delModal, setDelModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [renameModal, setRenameModal] = useState(false);
  const [copyModal, setCopyModal] = useState(false);

  const handleOpen = () => {
    props?.handleRightSelect();
    return props?.data?.researchType === "CUSTOM"
      ? loc(`/research/view-Diy/${props.uuid}`)
      : loc(`/research/view-research/${props.uuid}`);
  };
  const handleSelect = (v) => {
    handleClose();
    switch (v) {
      case ResearchOption[0]:
        handleOpen();
        break;
      case ResearchOption[1]:
        setRenameModal(true);
        break;
      // case ResearchOption[2]:
      //   setUpdateModal(true);
      //   break;
      case ResearchOption[2]:
        setDelModal(true);
        break;
      case ResearchOption[3]:
        setCopyModal(true);
        break;
      default:
        break;
    }
  };

  const handleClick = (event) => {
    setEnchorEl(event.currentTarget);
    setOpenP(true);
  };

  const handleClose = () => {
    setEnchorEl(null);
    setOpenP(false);
  };

  const handleCloseWithNewName = (s, name) => {
    if (s && name && props.uuid) {
      props.updateResName(name);
      updateRp("name", name, props.projectId, "research", props.uuid);
      setRenameModal(false);
      props.updateState();
    } else {
      setRenameModal(false);
    }
  };
  const handleRemoveFromTool = async () => {
    setDelModal(false);
    if (props.uuid && resData) {
      const res = await api(
        `research/${props.uuid}`,
        "",
        "delete",
        "",
        "",
        "Research removed!"
      );
      if (res && res.success) {
        if (resData) {
          const idx = resData.findIndex((a) => a.uuid === props.uuid);
          if (idx !== -1) {
            resData.splice(idx, 1);
          }
        }
        props.handleRemoveRes();
      }
    }
  };

  return (
    <Grid item lg={3} md={4} sm={4} xs={4}>
      <Box
        border="1px solid #D40841"
        borderRadius="10px"
        padding="10px 20px"
        margin="4px"
        color="secondary.main"
        minHeight="230px"
      >
        <Stack direction="row" justifyContent="space-between" mb={1}>
          <Box color="#D40841" fontSize="20px" margin="auto 0">
            {props.researchType
              ? researchMenuOption[props.researchType] || "-"
              : "-"}{" "}
          </Box>
          <SelectButton
            title="Open"
            titleClick={handleOpen}
            color="red"
            dropClick={(e) => handleClick(e)}
          />
        </Stack>
        <Stack gap="4px">
          <Box color="#D40841">{props.name} </Box>
          <Box display="flex" gap="10px">
            <Box margin="auto 0">Provider:</Box>
            <Box margin="auto 0">
              {props.researchType
                ? researchMenuOption[props.researchType] || "-"
                : "-"}{" "}
            </Box>
          </Box>
          <Box display="flex" gap="10px">
            <Box margin="auto 0">Date:</Box>
            <Box margin="auto 0">
              {props.dateCreated
                ? moment(props.dateCreated).format("YYYY-MM-DD")
                : "-"}
            </Box>
          </Box>
        </Stack>
        <Stack gap="6px" direction="row" mt={3} mb={3}>
          {props.lastModifiedBy && (
            <Avatar
              sx={{
                background: `#1D2D91 !important`,
                color: "white !important",
                fontSize: "16px !important",
              }}
            >
              {`${props.lastModifiedBy.split(" ")?.[0]?.[0] || ""}${
                props.lastModifiedBy.split(" ")?.[1]?.[0] || ""
              }`}
            </Avatar>
          )}
        </Stack>
      </Box>
      <DropDownMenu
        enchorEl={enchorEl}
        openP={openP}
        handleClose={handleClose}
        options={ResearchOption}
        val={"Open Research"}
        handleSelect={(value) => handleSelect(value)}
      />
      <RenameResearch
        open={renameModal}
        onClose={handleCloseWithNewName}
        {...{
          id: props.uuid,
          name: props.name,
          type: researchMenuOption[props.researchType] || "-",
        }}
      />
      <UpdateResearch
        open={updateModal}
        onClose={() => setUpdateModal(false)}
      />
      <DeleteModal
        title="Are you sure you want to remove this
research from the project?"
        open={delModal}
        onClose={() => {
          setDelModal(false);
        }}
        onDelete={handleRemoveFromTool}
      />
      <UseResToAnotherProject
        open={copyModal}
        onClose={() => {
          setCopyModal(false);
        }}
        {...{
          id: props.uuid,
          name: props.name,
          type: researchMenuOption[props.researchType] || "-",
        }}
        handleForceUpdate={props.handleForceUpdate}
        projectId={props.projectId}
      />
    </Grid>
  );
};

export default ReasearchCard;
