import { Box, Grid, Stack, Table, TableBody } from "@mui/material";
import ArrangeContainer from "./Other/ArrangeContainer";

const Step2 = (props) => {
  return (
    <Stack>
      <Box>
        <Box color="secondary.main" fontSize="20px" fontWeight="800px">
          Your Brand Essence
        </Box>
        <Grid container pt={4}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box>
              Click & Drag the strongest two brand essence drafts into the Brand
              Essence Shortlist boxes. Discuss and refine.
            </Box>
          </Grid>
          <Grid item lg={6} md={7} sm={7} xs={7}>
            <br />
            <br />
            <Box> Brand Essence Refinement </Box>
            <br />
            <Box maxHeight={"400px"} overflow="auto">
              <ArrangeContainer
                placeholder="Brand Essence Refinement"
                handleData={props.handleLeftBrandDifferentiation}
                slides={props.leftSlide}
                setItemOnDrag={(i) => props.setLeftSlide(() => i)}
                switchBox={() => props.switchBox(props.lidx, "left")}
                selIdx={props.lidx}
                indentifyStart={props.indentifyStart}
                setIndentifyStart={(e) => props.setIndentifyStart(e)}
                left={"left"}
                setThisSide={() => props.setThisSide(() => "left")}
                thisSide={props.thisSide}
                selectBox={(idx) => props.setLidx(() => idx)}
                onMoveUp={(idx) => props.onMoveUp(idx, "left")}
                onMoveDown={(idx) => props.onMoveDown(idx, "left")}
              />
            </Box>
          </Grid>
          <Grid item lg={1} md={1} sm={1} xs={1}></Grid>
          <Grid item lg={5} md={4} sm={4} xs={4}>
            <br />
            <br />
            <Box> Brand Essence Shortlist </Box>
            <br />
            <Box maxHeight={"400px"} overflow="auto">
              <ArrangeContainer
                placeholder="Brand Essence Shortlist"
                handleData={props.handleRightBrandDifferentiation}
                selIdx={props.ridx}
                slides={props.rightSlide}
                left={"right"}
                indentifyStart={props.indentifyStart}
                setIndentifyStart={(e) => props.setIndentifyStart(e)}
                setThisSide={() => props.setThisSide(() => "right")}
                thisSide={props.thisSide}
                switchBox={() => props.switchBox(props.ridx, "right")}
                setItemOnDrag={(i) => props.setRightSlide(() => i)}
                selectBox={(idx) => props.setRidx(() => idx)}
                onMoveUp={(idx) => props.onMoveUp(idx, "right")}
                onMoveDown={(idx) => props.onMoveDown(idx, "right")}
              />
            </Box>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <br />
            <br />
            <Box>Final Brand Essence</Box>
            <br />
            <Box>
              Click & Drag the strongest brand essence on the shortlist into the
              Final Brand Essence box below. Discuss and refine.
            </Box>
            <br />
            <Box maxHeight={"200px !important"} overflow="auto">
              <ArrangeContainer
                placeholder="Final Brand Essence"
                height={"130px"}
                handleData={props.handleDownBrandDifferentiation}
                selIdx={props.didx}
                slides={props.downSlide}
                left={"down"}
                indentifyStart={props.indentifyStart}
                setIndentifyStart={(e) => props.setIndentifyStart(e)}
                setThisSide={() => props.setThisSide(() => "down")}
                thisSide={props.thisSide}
                switchBox={() => props.switchBox(props.didx, "down")}
                setItemOnDrag={(i) => props.setDownSlide(() => i)}
                selectBox={(idx) => props.setDidx(() => idx)}
                onMoveUp={(idx) => props.onMoveUp(idx, "down")}
                onMoveDown={(idx) => props.onMoveDown(idx, "down")}
              />
            </Box>
            <br />
            <br />
          </Grid>
        </Grid>
      </Box>
    </Stack>
  );
};

export default Step2;
