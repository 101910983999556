import { Box, Grid, Stack } from "@mui/material";
import WorkshopBrandVulnerabilities from "../../../../../../../assets/images/workshop-brand-vulnerabilities.png";
import { makeStyles } from "@mui/styles";
import FormGroupTextField from "../../../../../../ui/formgroup/FormGroupTextField";

const useStyle = makeStyles((theme) => ({
  imgc: {
    display: "block",
    margin: "auto",
    maxWidth: "50%",
  },
}));

const Step4 = (props) => {
  const classes = useStyle();

  return (
    <Stack>
      <Box>
        <Box color="secondary.main" fontSize="20px" fontWeight="800px">
          Your Brand Vulnerabilities
        </Box>
        <Grid container pt={4}>
          <Grid item lg={7} md={8} sm={8} xs={8}>
            <Box marginTop={"30px !important"}>
              {" "}
              The overlap between your customers' most important needs and your
              competitors' greatest strengths.{" "}
            </Box>
            <br />
            <Box>
              {" "}
              Are there any others that you think should be added to this list?{" "}
            </Box>
            <br />
            <br />
            <Stack maxHeight={"400px"} overflow="auto" gap={2} pr={2}>
              {props.brandVulnerabilitieData &&
                props.brandVulnerabilitieData.map((item, index) => (
                  <FormGroupTextField
                    key={item.uuid}
                    placeholder="Brand vulnerability"
                    type="text"
                    value={item.name}
                    onChange={(e) =>
                      props.handleBrandVulnerabilitieData(index, "name", e)
                    }
                  />
                ))}
            </Stack>
            <br />
            <br />
            <Box>
              {" "}
              By identifying these vulnerabilities we can understand how our
              competitors may best position their brands, and how we should
              accordingly position our brand to compete on our own strengths. We
              should not be drawn into competing on the strengths of our
              competitors.{" "}
            </Box>
            <br />
          </Grid>
          <Grid item lg={5} md={4} sm={4} xs={4}>
            <img
              className={classes.imgc}
              src={WorkshopBrandVulnerabilities}
              alt=""
            />
          </Grid>
        </Grid>
      </Box>
    </Stack>
  );
};

export default Step4;
