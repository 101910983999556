import { Grid, Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import RemoveIcon from "@mui/icons-material/Remove";
import { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import CustomExpand from "../../ui/customCollapse/CustomExpand";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "24px",
    color: theme.palette.secondary.main,
    marginTop: "auto",
  },
}));

const ProjectRightHeader = (props) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(true);
  const handleExpandClick = () => {
    props.setCollsapn(!expanded);
    setExpanded(() => !expanded);
  };

  return (
    <Grid item lg={12} md={12} sm={12} xs={12} mb={3} mt={props.mt || 0}>
      <Stack direction={"row"} gap="16px">
        <div className={classes.title}>{props.title}</div>
        <div style={{ marginTop: "auto" }}>{props.btn}</div>
        {props.isHideExpend ? null : (
          <CustomExpand
            expanded={expanded}
            handleExpandClick={handleExpandClick}
          >
            {expanded ? (
              <RemoveIcon
                onClick={handleExpandClick}
                fontSize="large"
                color="secondary"
              />
            ) : (
              <AddIcon
                onClick={handleExpandClick}
                fontSize="large"
                color="secondary"
              />
            )}
          </CustomExpand>
        )}
      </Stack>
    </Grid>
  );
};

export default ProjectRightHeader;
