import {
  Grid,
  IconButton,
  InputAdornment,
  Pagination,
  OutlinedInput,
} from "@mui/material";
import RLTitle from "../RITitle";
import EXTHeader from "./EXTHeader";
import { makeStyles } from "@mui/styles";
import SearchIcon from "@mui/icons-material/Search";
import EXTList from "./EXTList";
import DropDownMenu from "../../../ui/menus/DropDownMenu";
import { useCallback, useState } from "react";
import CollapseWithGrid from "../../../ui/customCollapse/CollapseWithGrid";
import CustomExpand from "../../../ui/customCollapse/CustomExpand";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import RenameResearch from "../../../ui/modals/RenameResearch";
import UpdateResearch from "../../../ui/modals/UpdateResearch";
import DeleteModal from "../../../ui/modals/DeleteModal";
import UseResToAnotherProject from "../../../ui/modals/UseResToAnotherProject";
import { useNavigate } from "react-router";
import { ResearchOption } from "../../../../utils/AllOptions";
import EmptyList from "../../../ui/emptyList/EmptyList";
import { api } from "../../../../redux/api/api";
import { useEffect } from "react";
import _debounce from "lodash/debounce";
import Loader from "../../../ui/loader/Loader";
import { useSelector } from "react-redux";
import { getProjectListData } from "../../../../redux/actions/client/ClientAction";
import useBindActionCreator from "../../../../hooks/useBindActionCreator";
const useStyles = makeStyles(() => ({
  pageContainer: {
    display: "flex",
    justifyContent: "center",
  },
  textField: {
    backgroundColor: "#F5F5F5 !important",
    borderRadius: "10px !important",
    borderColor: "#F5F5F5 !important",
    "& .MuiOutlinedInput-root": {
      borderRadius: "10px !important",
      borderColor: "#F5F5F5 !important",
    },
    "& fieldset": {
      borderColor: "#F5F5F5",
      borderRadius: "10px !important",
    },
  },
}));
const pageSize = 10;
const ExstingRes = () => {
  const classes = useStyles();
  const checkCid = useSelector((state) => state.client.clientId?.id);
  const checkPid = useSelector((state) => state.client.projectId?.id);

  const actions = useBindActionCreator({
    getProjectListData,
  });
  const [resData, setResData] = useState([]);
  const [enchorEl, setEnchorEl] = useState(null);
  const loc = useNavigate();

  const [openP, setOpenP] = useState(false);
  const [menuVal, setMenuVal] = useState("Open Research");
  const [loading, setLoading] = useState(false);
  const [delModal, setDelModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [renameModal, setRenameModal] = useState(false);
  const [copyModal, setCopyModal] = useState(false);
  const [selRes, setSelRes] = useState({ id: "", name: "", type: "" });
  const [page, setPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [expand, setExpand] = useState(true);
  const [search, setSearch] = useState("");

  const callApi = async (p) => {
    setLoading(() => true);
    const res = await api(
      `research/?sort=dateCreated&order=desc&page=${p}&pageSize=${pageSize}`,
      "",
      "get"
    );
    if (res && res.success) {
      setResData(res.researches);
      setTotalCount(res.total);
      let tp = 0;
      if (res.total) {
        tp = Math.ceil(res.total / pageSize);
      }
      setTotalCount(tp);
    }
    setLoading(() => false);
  };
  useEffect(() => {
    callApi(page);
  }, []);
  const handleSelect = (v) => {
    handleClose();
    switch (v) {
      case ResearchOption[0]:
        if (selRes.type === "DIY") {
          loc(`/research/view-Diy/${selRes.id}`);
        } else {
          loc("/projects/research");
        }
        break;
      case ResearchOption[1]:
        setRenameModal(true);
        break;
      // case ResearchOption[2]:
      //   setUpdateModal(true);
      //   break;
      case ResearchOption[2]:
        setDelModal(true);
        break;
      case ResearchOption[3]:
        setCopyModal(true);
        break;
      default:
        break;
    }
  };
  const handleExpandClick = () => {
    setExpand(() => !expand);
  };
  const handleClick = (event, data) => {
    setSelRes(() => data);
    setEnchorEl(event.currentTarget);
    setOpenP(true);
  };
  const handleClose = () => {
    setEnchorEl(null);
    setOpenP(false);
  };
  const handleCloseWithNewName = async (s, name) => {
    if (s && resData && name && selRes.id) {
      const dd = [...resData];
      for (let i = 0; i < dd.length; i++) {
        if (dd[i].uuid === selRes.id) {
          dd[i].name = name;
          if (checkCid && dd[i].clientId && checkCid === dd[i].clientId) {
            await actions.getProjectListData(checkCid);
          }
          break;
        }
      }
      setResData(() => dd);
      setSelRes({ name: "", id: "", type: "" });
      setRenameModal(false);
    } else {
      setSelRes({ name: "", id: "", type: "" });
      setRenameModal(false);
    }
  };
  const handleRemoveFromTool = async () => {
    setDelModal(false);
    setLoading(true);
    if (selRes.id && resData) {
      const res = await api(
        `research/${selRes.id}`,
        "",
        "delete",
        "",
        "",
        "Research removed!"
      );
      if (res && res.success) {
        const idx = resData.findIndex((a) => a.uuid == selRes.id);
        if (idx !== -1) {
          if (
            checkCid &&
            resData[idx].clientId &&
            checkCid === resData[idx].clientId
          ) {
            await actions.getProjectListData(checkCid);
          }
          const dd = [...resData];
          dd.splice(idx, 1);
          setResData(() => dd);
        }
      }
      setSelRes({ name: "", id: "", type: "" });
    }
    setLoading(false);
  };
  const handlePageChange = async (p) => {
    if (p - 1 !== page) {
      setPage(() => p - 1);
      await callApi(p - 1);
    }
  };
  const callSearch = async (a) => {
    setLoading(() => true);
    const res = await api(`search/research?keyword=${a}`, "", "get");
    if (res && res.success) {
      delete res.success;
      setResData(res);
    }
    setLoading(() => false);
  };
  const handleDebounceFn = async (inputValue) => {
    if (inputValue) {
      setTotalCount(1);
      setPage(0);
      await callSearch(inputValue);
    } else {
      await callApi(page);
    }
  };
  const debounceFn = useCallback(_debounce(handleDebounceFn, 1000), []);
  function handleChange(event) {
    setSearch(event.target.value);
    debounceFn(event.target.value);
  }

  return (
    <>
      <RLTitle
        title="Or use existing research"
        subTitle="Any research (purchased or free) is displayed here for use in any project."
        mb={2}
      />
      <Grid container item lg={12} md={12} mb={2}>
        <Grid item lg={5} md={6}>
          <OutlinedInput
            fullWidth
            color="secondary"
            classes={{ root: classes.textField }}
            size="small"
            value={search}
            onChange={handleChange}
            endAdornment={
              <InputAdornment position="end">
                <IconButton edge="end">
                  {" "}
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            }
            placeholder="Search research provider, project or client"
          />
        </Grid>
        <Grid item lg={7} md={6} textAlign="right">
          <CustomExpand expanded={expand} handleExpandClick={handleExpandClick}>
            {expand ? (
              <RemoveIcon
                onClick={handleExpandClick}
                fontSize="large"
                color="secondary"
              />
            ) : (
              <AddIcon
                onClick={handleExpandClick}
                fontSize="large"
                color="secondary"
              />
            )}
          </CustomExpand>
        </Grid>
      </Grid>
      <CollapseWithGrid expand={expand}>
        <EXTHeader />
        {loading ? (
          <Loader />
        ) : resData && resData.length ? (
          resData.map((a) => <EXTList {...a} handleClick={handleClick} />)
        ) : (
          <EmptyList
            color="#D40841"
            text="Add research to your project which help validate your project and recommendations."
          />
        )}
        <Grid item lg={12} md={12} mt={2}>
          <div className={classes.pageContainer}>
            <Pagination
              count={totalCount}
              page={page + 1}
              variant="outlined"
              shape="rounded"
              color="secondary"
              size="large"
              onChange={(_, p) => handlePageChange(p)}
            />
          </div>
        </Grid>
      </CollapseWithGrid>
      <DropDownMenu
        enchorEl={enchorEl}
        openP={openP}
        handleClose={handleClose}
        options={ResearchOption}
        val={menuVal}
        handleSelect={(value) => handleSelect(value)}
      />
      <RenameResearch
        open={renameModal}
        onClose={handleCloseWithNewName}
        {...selRes}
      />
      <UpdateResearch
        open={updateModal}
        onClose={() => setUpdateModal(false)}
      />
      <DeleteModal
        title="Are you sure you want to remove this
research from the project?"
        open={delModal}
        onClose={() => {
          setDelModal(false);
          setSelRes({ name: "", id: "", type: "" });
        }}
        onDelete={handleRemoveFromTool}
      />
      <UseResToAnotherProject
        open={copyModal}
        onClose={() => {
          setCopyModal(false);
          setSelRes({ name: "", id: "", type: "" });
        }}
        {...selRes}
        projectId={checkPid}
      />
    </>
  );
};

export default ExstingRes;
