import { Box, Divider, Grid, Stack } from "@mui/material";
import CustomButton from "../../../../../ui/button/CustomButton";
import FormGroupTextField from "../../../../../ui/formgroup/FormGroupTextField";
import TextAutoResize from "../../../../../ui/formgroup/TextAutoResize";

const CustomerCoreFromPersona = ({
  customerData,
  setData2,
  border = false,
  callApi,
  btnLoading,
}) => {
  return (
    <>
      <Box fontSize="20px" mb={2}>
        Your Core Customer/Client
      </Box>
      <Grid
        item
        lg={10}
        md={9}
        sm={9}
        xs={9}
        pl={border ? 0 : 2}
        container
        spacing={3}
      >
        <Grid item lg={6} md={6} sm={6} xs={6}>
          <FormGroupTextField
            label="Age"
            border={border}
            type="number"
            value={customerData.age}
            inputProps2={{
              min: 0,
              max: 9,
            }}
            onChange={(e) => setData2("age", e)}
          />
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={6}>
          <FormGroupTextField
            border={border}
            label="Gender"
            value={customerData.gender}
            onChange={(e) => setData2("gender", e)}
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <FormGroupTextField
            label="Occupation"
            border={border}
            value={customerData.occupation}
            onChange={(e) => setData2("occupation", e)}
          />
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={6}>
          <FormGroupTextField
            label="Family Status"
            border={border}
            value={customerData.familyStatus}
            onChange={(e) => setData2("familyStatus", e)}
          />
          <Box mt={2}>
            <TextAutoResize
              label="Lives"
              tf={border}
              other={{ minRows: 2, maxRows: 2 }}
              value={customerData.location}
              onChange={(e) => setData2("location", e)}
            />
          </Box>
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={6}>
          <TextAutoResize
            label="Interests/ Passions"
            tf={border}
            other={{ minRows: 6.5, maxRows: 6.5 }}
            value={customerData.interests}
            onChange={(e) => setData2("interests", e)}
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <TextAutoResize
            label="Personality Attributes"
            other={{ minRows: 3, maxRows: 3 }}
            tf={border}
            value={customerData.personalityAttributes}
            onChange={(e) => setData2("personalityAttributes", e)}
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <TextAutoResize
            label="Media & Tech Use"
            other={{ minRows: 3, maxRows: 3 }}
            tf={border}
            value={customerData.mediaUse}
            onChange={(e) => setData2("mediaUse", e)}
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <TextAutoResize
            label="What defines them relative to this category?"
            other={{ minRows: 3, maxRows: 3 }}
            value={customerData.defines}
            tf={border}
            onChange={(e) => setData2("defines", e)}
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <TextAutoResize
            label="What are their most significant frustrations?"
            other={{ minRows: 3, maxRows: 3 }}
            tf={border}
            value={customerData.frustrations}
            onChange={(e) => setData2("frustrations", e)}
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <TextAutoResize
            label="What are their key motivations?"
            tf={border}
            other={{ minRows: 3, maxRows: 3 }}
            value={customerData.motivations}
            onChange={(e) => setData2("motivations", e)}
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <TextAutoResize
            label="What do they wish to achieve/improve from their interaction with this category?"
            other={{ minRows: 3, maxRows: 3 }}
            value={customerData.improve}
            tf={border}
            onChange={(e) => setData2("improve", e)}
          />
        </Grid>
        {customerData.includeSecondary ? (
          <>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Divider variant="fullWidth" />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <FormGroupTextField
                label="Secondary Age"
                type="number"
                border={border}
                value={customerData.secondaryAge}
                onChange={(e) => setData2("secondaryAge", e)}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <FormGroupTextField
                label="Secondary Gender"
                border={border}
                value={customerData.secondaryGender}
                onChange={(e) => setData2("secondaryGender", e)}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <FormGroupTextField
                label="Secondary Occupation"
                border={border}
                value={customerData.secondaryOccupation}
                onChange={(e) => setData2("secondaryOccupation", e)}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <FormGroupTextField
                label="Secondary Family Status"
                border={border}
                value={customerData.secondaryFamilyStatus}
                onChange={(e) => setData2("secondaryFamilyStatus", e)}
              />
              <Box mt={2}>
                <TextAutoResize
                  label="Secondary Lives"
                  other={{ minRows: 2, maxRows: 2 }}
                  tf={border}
                  value={customerData.secondaryLocation}
                  onChange={(e) => setData2("secondaryLocation", e)}
                />
              </Box>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <TextAutoResize
                label="Secondary Interests/ Passions"
                tf={border}
                other={{ minRows: 6.5, maxRows: 6.5 }}
                value={customerData.secondaryInterests}
                onChange={(e) => setData2("secondaryInterests", e)}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <TextAutoResize
                label="Secondary Personality Attributes"
                other={{ minRows: 3, maxRows: 3 }}
                tf={border}
                value={customerData.secondaryPersonalityAttributes}
                onChange={(e) => setData2("secondaryPersonalityAttributes", e)}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <TextAutoResize
                label="Secondary Media & Tech Use"
                other={{ minRows: 3, maxRows: 3 }}
                tf={border}
                value={customerData.secondaryMediaUse}
                onChange={(e) => setData2("secondaryMediaUse", e)}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <TextAutoResize
                label="Secondary What defines them relative to this category?"
                other={{ minRows: 3, maxRows: 3 }}
                tf={border}
                value={customerData.secondaryDefines}
                onChange={(e) => setData2("secondaryDefines", e)}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <TextAutoResize
                label="Secondary What are their most significant frustrations?"
                other={{ minRows: 3, maxRows: 3 }}
                tf={border}
                value={customerData.secondaryFrustrations}
                onChange={(e) => setData2("secondaryFrustrations", e)}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <TextAutoResize
                label="Secondary What are their key motivations?"
                other={{ minRows: 3, maxRows: 3 }}
                tf={border}
                value={customerData.secondaryMotivations}
                onChange={(e) => setData2("secondaryMotivations", e)}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <TextAutoResize
                label="Secondary What do they wish to achieve/improve from their interaction with this category?"
                other={{ minRows: 3, maxRows: 3 }}
                tf={border}
                value={customerData.secondaryImprove}
                onChange={(e) => setData2("secondaryImprove", e)}
              />
            </Grid>
          </>
        ) : null}
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Stack direction="row" gap="14px">
            <Box>
              <CustomButton
                name={
                  customerData.includeSecondary
                    ? "Remove Secondary Audience"
                    : "Add Secondary Audience"
                }
                size="medium"
                onclick={() =>
                  setData2("includeSecondary", !customerData.includeSecondary)
                }
              />
            </Box>
            {callApi && (
              <CustomButton
                name="Save"
                onclick={() => callApi({ coreCustomer: customerData })}
                loading={btnLoading}
              />
            )}
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};

export default CustomerCoreFromPersona;
