import { ButtonBase, DialogContent, Grid, Stack, Box } from "@mui/material";
import { ArrowForwardIos } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import SimpleReactValidator from "simple-react-validator";
import FormGroupTextField from "../formgroup/FormGroupTextField";
import CustomButton from "../button/CustomButton";
import ModalContainer from "./ModalContainer";
import CustomSelect from "../formgroup/CustomSelect";
import { api } from "../../../redux/api/api";
import Img from "../../../assets/images/IntialTool.png";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "20px",
    color: theme.palette.secondary.main,
    marginBottom: "10px",
  },
  endIcon: {
    marginLeft: "auto !important",
  },
  btnContainer: { width: "40%" },
  cancelBtn: {
    margin: "auto 0",
    textDecoration: "underline !important",
    fontFamily: theme.typography.fontFamily,
    fontSize: "16px",
  },
  dialogContent: {
    height: "500px",
  },
  subTitle: {
    fontWeight: 600,
  },
}));

let intialData = {
  folderName: "",
  parentFolderName: "None",
};

const AddProjectFolderModal = ({
  open,
  onClose,
  toolTitle,
  folderOptions,
  getFolders,
  loadFolderData,
}) => {
  const classes = useStyles();
  const projectId = useSelector((state) => state.project?.project?.uuid);
  const simpleValidator = useRef(new SimpleReactValidator());
  const isEdit = loadFolderData && Object.keys(loadFolderData).length;

  const [, setC] = useState();
  const [folderData, setFolderData] = useState(intialData);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isEdit) {
      setFolderData({
        folderName: loadFolderData?.name,
        uuid: loadFolderData?.uuid,
      });
    } else {
      setFolderData(intialData);
    }
  }, [loadFolderData?.name, loadFolderData?.uuid]);

  useEffect(() => {
    simpleValidator.current.hideMessages();
  }, [open]);

  const setData = (field, value) => {
    setFolderData((old) => ({ ...old, [field]: value }));
  };

  const handleAdd = async () => {
    setC((old) => !old);
    if (simpleValidator.current.allValid()) {
      setLoading(true);
      const data = {
        folderName: folderData.folderName,
      };

      if (folderData.parentFolderName !== "None") {
        data["parentFolderId"] = folderData.parentFolderName;
      }

      let res;

      if (isEdit) {
        res = await api(
          `projects/${folderData.uuid}/folder`,
          { name: folderData.folderName },
          "patch",
          "",
          "",
          "Folder updated!"
        );
      } else {
        res = await api(
          `projects/${projectId}/folder`,
          data,
          "post",
          "",
          "",
          "Folder created!"
        );
      }

      if (res && res.success) {
        setFolderData(intialData);
        getFolders(isEdit ? "edit" : "add", res);
        onClose();
      }
      setLoading(false);
    } else {
      simpleValidator.current.showMessages();
    }
    setC((old) => !old);
  };

  return (
    <ModalContainer
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth={true}
      yellowBack={true}
    >
      <DialogContent className={classes.dialogContent}>
        <Grid container height={"100%"}>
          <Grid item lg={7} md={7} sm={7} xs={7} p={4} pt={0}>
            <Stack gap="12px">
              <div className={classes.title}>
                <span className={classes.subTitle}>{toolTitle}</span>
              </div>
              <FormGroupTextField
                label="Folder Name"
                value={folderData.folderName}
                onChange={(e) => setData("folderName", e)}
                required={true}
                validator={simpleValidator.current.message(
                  "Folder Name",
                  folderData.folderName,
                  "required",
                  {
                    className: "errorClass",
                  }
                )}
              />
              {isEdit ? null : (
                <Stack direction="row" gap="20px">
                  <Box width={"100%"}>
                    <CustomSelect
                      id={true}
                      isDefaultNone={true}
                      fullWidth={true}
                      label="Place Under Folder"
                      options={folderOptions}
                      value={folderData.parentFolderName}
                      onChange={(e) => {
                        setData("parentFolderName", e);
                      }}
                      required={true}
                      validator={simpleValidator.current.message(
                        "Parent Folder",
                        folderData.parentFolderName,
                        "required",
                        {
                          className: "errorClass",
                        }
                      )}
                    />
                  </Box>
                </Stack>
              )}
              <Stack direction="row" gap="20px">
                <div className={classes.btnContainer}>
                  <CustomButton
                    variant="contained"
                    size="large"
                    name={isEdit ? "Update Folder" : "Add Folder"}
                    classNameI="yellow"
                    endIcon={<ArrowForwardIos />}
                    fullWidth={true}
                    iconClass={classes.endIcon}
                    loading={loading}
                    onclick={handleAdd}
                  />
                </div>
                <ButtonBase onClick={onClose} className={classes.cancelBtn}>
                  Cancel
                </ButtonBase>
              </Stack>
            </Stack>
          </Grid>
          <Grid item lg={5} md={5} margin="auto" textAlign="center">
            <img src={Img} alt={Img} height="383px" />
          </Grid>
        </Grid>
      </DialogContent>
    </ModalContainer>
  );
};

export default AddProjectFolderModal;
