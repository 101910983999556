import { useEffect } from "react";
import { useLocation } from "react-router";
import ResearchComponent from "../../../components/research/ResearchComponent";
import { setHTitle } from "../../../utils/SetHeaderFunction";

const Research = (props) => {
  const loc = useLocation().pathname;
  useEffect(() => {
    setHTitle(props.title, true);
  }, [loc]);
  return <ResearchComponent />;
};

export default Research;
