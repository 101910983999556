import { ButtonBase, DialogContent, Grid, Stack, Box } from "@mui/material";
import { ArrowForwardIos } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";

import Img from "../../../assets/images/IntialTool.png";
import FormGroupTextField from "../formgroup/FormGroupTextField";
import CustomButton from "../button/CustomButton";
import ModalContainer from "./ModalContainer";
import CustomSelect from "../formgroup/CustomSelect";
import SimpleReactValidator from "simple-react-validator";
import { ToolTypeRev } from "../../../utils/AllOptions";
import { api } from "../../../redux/api/api";
import moment from "moment";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "20px",
    color: theme.palette.secondary.main,
    marginBottom: "10px",
  },
  endIcon: {
    marginLeft: "auto !important",
  },
  btnContainer: { width: "40%" },
  cancelBtn: {
    margin: "auto 0",
    textDecoration: "underline !important",
    fontFamily: theme.typography.fontFamily,
    fontSize: "16px",
  },
  dialogContent: {
    height: "500px",
  },
  subTitle: {
    fontWeight: 600,
  },
}));
let intialData = {
  name: "",
  leaderId: "",
  dueDate: "",
};
const IntialToolDetails = ({ open, onClose, toolTitle }) => {
  let isShowTemplateId = false;
  if (
    toolTitle === "Brand Strategy" ||
    toolTitle === "Creative Brief" ||
    toolTitle === "Media Brief"
  ) {
    isShowTemplateId = true;
    intialData.templateId = "";
  }
  const classes = useStyles();

  const loc = useNavigate();

  const ptools = useSelector((state) => state.project?.project);
  const uuid = useSelector((state) => state.project?.project?.uuid);

  const pname = useSelector((state) => state.project?.project?.name);
  const users = useSelector((state) => state.client?.users);
  const [udata, setUdata] = useState([]);
  useEffect(() => {
    if (users && users.length) {
      setUdata(() =>
        users.map((a) => ({
          uuid: a.uuid,
          name: `${a.firstName} ${a.lastName}`,
        }))
      );
    }
  }, [users]);
  const templateRe = useSelector((state) => state.template?.template);

  const simpleValidator = useRef(new SimpleReactValidator());
  const [, setC] = useState();
  const [toolData, setToolData] = useState(intialData);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    simpleValidator.current.hideMessages();
    setToolData(intialData);
  }, [open]);

  const setData = (field, value) =>
    setToolData((old) => ({ ...old, [field]: value }));

  const handleAdd = async () => {
    setLoading(true);
    setC((old) => !old);
    if (simpleValidator.current.allValid() && uuid) {
      const data = {
        ...toolData,
        leaderId: toolData.leaderId,
        projectId: uuid,
        type: ToolTypeRev[toolTitle],
        dueDate: moment(toolData.dueDate).format("YYYY-MM-DD[T]HH:mm:ss"),
      };
      if (isShowTemplateId) {
        data.templateId = toolData.templateId.uuid;
      }
      const res = await api(
        "tools",
        data,
        "post",
        "",
        "",
        "Tool added to this project!"
      );
      if (res && res.success) {
        if (ptools.tools) {
          ptools.tools.push(res);
        } else {
          ptools.tools = [res];
        }
        setToolData(intialData);
        loc(
          `/projects/tool/${toolData.name}/${ToolTypeRev[toolTitle]}/${res?.uuid}`
        );
        onClose();
      }
    } else {
      simpleValidator.current.showMessages();
    }
    setC((old) => !old);
    setLoading(false);
  };
  return (
    <ModalContainer
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth={true}
      yellowBack={true}
    >
      <DialogContent className={classes.dialogContent}>
        <Grid container height={"100%"}>
          <Grid item lg={7} md={7} sm={7} xs={7} p={4} pt={0}>
            <Stack gap="12px">
              <div className={classes.title}>
                {" "}
                Add the <span className={classes.subTitle}>
                  {toolTitle}
                </span>{" "}
                tool to the <span className={classes.subTitle}>{pname} </span>
                Project
              </div>
              <FormGroupTextField
                label="Tool Name"
                value={toolData.name}
                onChange={(e) => setData("name", e)}
                required={true}
                validator={simpleValidator.current.message(
                  "Tool Name",
                  toolData.name,
                  "required",
                  {
                    className: "errorClass",
                  }
                )}
                onKeyPress={(e) => {
                  if (e.key === "/") {
                    toast.error("You can not enter '/' sign");
                    e.preventDefault();
                  }
                }}
              />
              {isShowTemplateId && (
                <Box width={"100%"}>
                  <CustomSelect
                    fullWidth={true}
                    label="Template to use"
                    options={
                      templateRe.length
                        ? templateRe.filter(
                            (a) => a.type === ToolTypeRev[toolTitle]
                          )
                        : []
                    }
                    value={toolData.templateId}
                    onChange={(e) => setData("templateId", e)}
                    required={true}
                    validator={simpleValidator.current.message(
                      "Template to use",
                      toolData.templateId,
                      "required",
                      {
                        className: "errorClass",
                      }
                    )}
                  />
                </Box>
              )}
              <Stack direction="row" gap="20px">
                <Box width={"100%"}>
                  <CustomSelect
                    fullWidth={true}
                    label="Leader"
                    id={true}
                    options={udata}
                    value={toolData.leaderId}
                    onChange={(e) => setData("leaderId", e)}
                    required={true}
                    validator={simpleValidator.current.message(
                      "Leader",
                      toolData.leaderId,
                      "required",
                      {
                        className: "errorClass",
                      }
                    )}
                  />
                </Box>
                <Box width={"100%"}>
                  <FormGroupTextField
                    fullWidth={true}
                    type="date"
                    label="Due Date"
                    value={toolData.dueDate}
                    onChange={(e) => setData("dueDate", e)}
                    required={true}
                    other={{
                      InputProps: {
                        inputProps: { min: moment().format("YYYY-MM-DD") },
                      },
                    }}
                    validator={simpleValidator.current.message(
                      "Due Date",
                      toolData.dueDate,
                      "required",
                      {
                        className: "errorClass",
                      }
                    )}
                  />
                </Box>
              </Stack>
              <Box fontSize={"12px"} mt={3} mb={2}>
                Note: You can edit more tool settings once the tool is added.
              </Box>
              <Stack direction="row" gap="20px">
                <div className={classes.btnContainer}>
                  <CustomButton
                    variant="contained"
                    size="large"
                    name={"Add Tool"}
                    classNameI="yellow"
                    endIcon={<ArrowForwardIos />}
                    fullWidth={true}
                    iconClass={classes.endIcon}
                    loading={loading}
                    onclick={handleAdd}
                  />
                </div>
                <ButtonBase onClick={onClose} className={classes.cancelBtn}>
                  Cancel
                </ButtonBase>
              </Stack>
            </Stack>
          </Grid>
          <Grid item lg={5} md={5} margin="auto" textAlign="center">
            <img src={Img} alt={Img} height="383px" />
          </Grid>
        </Grid>
      </DialogContent>
    </ModalContainer>
  );
};

export default IntialToolDetails;
