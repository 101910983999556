import { Box, DialogContent, Grid, Stack } from "@mui/material";
import { ArrowForwardIos } from "@mui/icons-material";
import Img1 from "../../../assets/images/planeImg.png";
import FormGroupTextField from "../formgroup/FormGroupTextField";
import CustomButton from "../button/CustomButton";
import ModalContainer from "./ModalContainer";
import { makeStyles } from "@mui/styles";
import CustomSelect from "../formgroup/CustomSelect";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SimpleReactValidator from "simple-react-validator";
import { CLIENT_PROJECT_ADD_MSG } from "../../../redux/constants";
import { api } from "../../../redux/api/api";
import lodash from "lodash";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "20px",
    color: theme.palette.secondary.main,
    marginBottom: "10px",
    fontWeight: 600,
  },
  endIcon: {
    marginLeft: "auto !important",
  },
  btnContainer: { width: "60%", marginTop: "10px" },
  dialogContent: {
    height: "500px",
  },
  fullWidth: {
    width: "100%",
  },
  noteDiv: { fontSize: "12px" },
  img1: {
    position: "absolute",
    bottom: "90px",
    right: "70px",
    height: "393px",
  },
  img2: { paddingTop: "60px", height: "300px" },
}));
const intialData = {
  name: "",
  leaderId: "",
  jobNumber: "",
  projectName: "",
};
const AddClientWithProject = ({ open, onClose }) => {
  const classes = useStyles();

  const agenciesUUID = useSelector((state) => state.auth.user?.agencies?.uuid);
  const cdata = useSelector((state) => state.project.clients);

  const dispatch = useDispatch();

  useEffect(() => {
    simpleValidator.current.hideMessages();
  }, [open]);

  const users = useSelector((state) => state.client.users);
  const [udata, setUdata] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);
  useEffect(() => {
    if (users && users.length) {
      setUdata(() =>
        users.map((a) => ({
          uuid: a.uuid,
          name: `${a.firstName} ${a.lastName}`,
        }))
      );
    }
  }, [users]);
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, setC] = useState();

  const [projectData, setProjectData] = useState(intialData);

  const setData = (field, value) => {
    setProjectData((old) => ({ ...old, [field]: value }));
  };

  const addClick = async () => {
    setC((old) => !old);
    setBtnLoading(() => true);
    if (simpleValidator.current.allValid() && agenciesUUID) {
      const pdata = {
        agencyId: agenciesUUID,
        name: projectData.name,
        leaderId: projectData?.leaderId?.uuid || "",
        jobNumber: projectData.jobNumber,
        projectName: projectData.projectName,
        active: true,
        example: false,
      };
      const res = await api(
        "clients",
        pdata,
        "post",
        "",
        "",
        CLIENT_PROJECT_ADD_MSG
      );

      if (res && res.success) {
        let cdata2 = [];
        if (cdata) {
          cdata2 = [res, ...lodash.cloneDeep(cdata)];
        } else {
          cdata2 = [res];
        }
        dispatch({
          type: "GET_CLIENT_LIST_DATA",
          payload: {
            data: cdata2,
          },
        });
        dispatch({
          type: "GET_ALL_DASH_CLIENT_LIST",
          payload: {
            data: cdata2,
          },
        });
        setProjectData(intialData);
        onClose();
      }
    } else {
      simpleValidator.current.showMessages();
    }
    setBtnLoading(() => false);
    setC((old) => !old);
  };

  return (
    <ModalContainer
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth={true}
      classPrimary={true}
    >
      <DialogContent className={classes.dialogContent}>
        <Grid container mt={0}>
          <Grid item lg={6.5} md={6.5} margin="auto">
            <Stack gap="18px">
              <div className={classes.title}>
                Add new client and their first project
              </div>
              <FormGroupTextField
                label="Client Name"
                value={projectData.name}
                onChange={(e) => setData("name", e)}
                required={true}
                validator={simpleValidator.current.message(
                  "Client Name",
                  projectData.name,
                  "required",
                  {
                    className: "errorClass",
                  }
                )}
              />
              <FormGroupTextField
                label="Project Name"
                value={projectData.projectName}
                onChange={(e) => setData("projectName", e)}
                required={true}
                validator={simpleValidator.current.message(
                  "Project Name",
                  projectData.projectName,
                  "required",
                  {
                    className: "errorClass",
                  }
                )}
              />
              <Stack direction="row" gap="10px">
                <Box minWidth="160px">
                  <CustomSelect
                    label="Leader"
                    options={udata}
                    value={projectData.leaderId}
                    onChange={(e) => setData("leaderId", e)}
                    required={true}
                    validator={simpleValidator.current.message(
                      "Leader",
                      projectData.leaderId,
                      "required",
                      {
                        className: "errorClass",
                      }
                    )}
                  />
                </Box>
                <div className={classes.fullWidth}>
                  <FormGroupTextField
                    label="Job Number (we'll add one if not sure)"
                    value={projectData.jobNumber}
                    onChange={(e) => setData("jobNumber", e)}
                  />
                </div>
              </Stack>
              <div className={classes.noteDiv}>
                Note: You can edit more project settings once the project is
                created.
              </div>
              <div className={classes.btnContainer}>
                <CustomButton
                  variant="contained"
                  size="large"
                  name={"Add Client & Project"}
                  endIcon={<ArrowForwardIos />}
                  fullWidth={true}
                  iconClass={classes.endIcon}
                  onclick={addClick}
                  loading={btnLoading}
                />
              </div>
            </Stack>
          </Grid>
          <Grid item lg={4.5} md={4.5}>
            <img src={Img1} alt={Img1} height="383px" />
          </Grid>
        </Grid>
      </DialogContent>
    </ModalContainer>
  );
};

export default AddClientWithProject;
