import {
  SET_CLIENT_ID,
  SET_LIST_USERS,
  SET_PROJECT_ID,
  TOGGLECLIENTVIEW,
  GET_CLIENT_DASHBOARD,
} from "../../constants";

const initState = {
  view: true,
  projectId: "",
  clientId: "",
  users: [],
  selClientId: "",
  package_details: "",
};

const clientReducer = (state = initState, action) => {
  switch (action.type) {
    case TOGGLECLIENTVIEW:
      return { ...state, view: action.payload.data };
    case "SET_CLIENT_FOR_SETTING":
      return { ...state, selClientId: action.payload.data };
    case SET_CLIENT_ID:
      return {
        ...state,
        clientId: action.payload.data,
      };
    case SET_PROJECT_ID:
      return {
        ...state,
        projectId: action.payload.data,
      };
    case SET_LIST_USERS:
      return {
        ...state,
        users: action.payload.data,
      };
    case GET_CLIENT_DASHBOARD:
      return {
        ...state,
        dashboardData: action.payload.data,
      };
    case "GET_SUBS_DETAILS":
      return { ...state, package_details: action.payload.data };

    case "LOGOUT":
      return {
        view: true,
        projectId: "",
        clientId: "",
        users: [],
        selClientId: "",
        package_details: "",
      };
    default:
      return state;
  }
};

export default clientReducer;
