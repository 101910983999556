import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import Img from "../../../assets/images/public-background.png";
import {
  privateSidebarNavOp,
  publicNavOp,
} from "../../../utils/NavBarConstants";
const useStyle = makeStyles((theme) => ({
  upperContainer: {
    padding: "2em 0px 4em 0px",
    textAlign: "center",
    margin: "auto",
    minWidth: "1024px !important",
  },
  upperContainer1: {
    padding: "2em 0px 4em 0px",
    textAlign: "center",
    margin: "auto",
    minHeight: "700px",
  },
  upperContainer2: {
    padding: "0px 0px 4em 0px",
    textAlign: "center",
    margin: "auto",
    minWidth: "1024px !important",
  },
  img: {
    position: "absolute",
    right: 0,
    top: 260,
    maxWidth: "400px",
    [theme.breakpoints.down("lg")]: {
      width: "260px",
    },
  },
}));

const Container = (props) => {
  const classes = useStyle();
  const loc = useLocation();
  const check =
    !loc.pathname.includes("public") &&
    publicNavOp.find((a) => a === loc.pathname);
  let checkCustomSidebar = privateSidebarNavOp.find((a) => a === loc.pathname);
  const checkAUpost = loc.pathname.includes("view-research");
  const sidebarLayoutData = useSelector(
    (state) => state.sideBar.sidebarLayoutData
  );
  if (!checkCustomSidebar) {
    sidebarLayoutData?.map((item) => {
      if (item.name === "Tools") {
        checkCustomSidebar = item?.activePaths?.find(
          (a) => a === loc?.pathname?.replaceAll("%20", " ")
        );
      }
    });
  }
  const removeClass = loc.pathname.includes("public");
  return (
    <div
      className={
        removeClass
          ? ""
          : checkCustomSidebar || checkAUpost
          ? classes.upperContainer2
          : check
          ? classes.upperContainer1
          : classes.upperContainer
      }
    >
      <Box
        m={
          removeClass
            ? ""
            : checkCustomSidebar || checkAUpost
            ? "0 2% 0 0"
            : "0 2%"
        }
      >
        {" "}
        {props.children}{" "}
      </Box>
      {check && <img src={Img} alt={Img} className={classes.img} />}
    </div>
  );
};

export default Container;
