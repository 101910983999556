import { Divider, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => {
  return {
    title: {
      fontSize: "18px",
      color: theme.palette.secondary.main,
      marginTop: "auto",
    },
  };
});

const TableHeader = () => {
  const classes = useStyles();

  return (
    <>
      <Grid item lg={4} md={4} sm={4} xs={4} className={classes.title}>
        Section
      </Grid>
      {/* <Grid item lg={2} md={3} sm={3} xs={3} className={classes.title}>
        Use Client Settings
      </Grid> */}
      <Grid item lg={7} md={6} sm={6} xs={6} className={classes.title}>
        % Completed
      </Grid>
      <Grid item lg={1} md={2} sm={2} xs={2} className={classes.title}>
        Actions
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} mb={2} mt={2}>
        <Divider variant="fullWidth" />
      </Grid>
    </>
  );
};

export default TableHeader;
