import { useEffect } from "react";
import LoginComponent from "../../../components/login/LoginComponent";
import { setHTitle } from "../../../utils/SetHeaderFunction";

const Login = () => {
  useEffect(() => {
    setHTitle("", false, "");
  }, []);

  return <LoginComponent />;
};

export default Login;
