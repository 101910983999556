import { Box, useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import { Outlet } from "react-router";
import Container from "../ui/container/Container";
import PrivateFooter from "./PrivateFooter";
import PrivateHeader from "./PrivateHeader";

const PrivateRoute = () => {
  const fontFamily = useTheme().typography.fontFamily;
  const full = useSelector((state) => state.project.workshop);

  return (
    <div
      style={{
        fontFamily: fontFamily,
        minWidth: "1024px !important",
      }}
    >
      <Box display={full ? "none" : ""}>
        {" "}
        <PrivateHeader />
      </Box>
      <Container>
        <Outlet />
      </Container>
      <Box display={full ? "none" : ""}>
        <PrivateFooter />
      </Box>
    </div>
  );
};

export default PrivateRoute;
