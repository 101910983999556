import { Box, Stack } from "@mui/material";
import { useState } from "react";
import CustomButton from "../../../../../../ui/button/CustomButton";
import FormGroupTextField from "../../../../../../ui/formgroup/FormGroupTextField";
import { v4 as uuidv4 } from "uuid";
import { useRef } from "react";

const Step1 = ({ brandValues, setBrandValue }) => {
  const [value, setValue] = useState("");

  const dragItem = useRef();
  const dragOverItem = useRef();

  const dragStart = (e, position) => {
    dragItem.current = position;
  };
  const dragEnter = (e, position) => {
    dragOverItem.current = position;
  };
  const drop = (left) => {
    const copyListItems = [...brandValues];
    const dragItemContent = copyListItems[dragItem.current];

    if (
      left &&
      dragItemContent.selected === false &&
      brandValues?.filter((a) => a.selected === true).length < 8
    ) {
      dragItemContent.selected = true;
      copyListItems.splice(dragItem.current, 1);
      copyListItems.splice(dragOverItem.current, 0, dragItemContent);
      dragItem.current = null;
      dragOverItem.current = null;
      setBrandValue(copyListItems);
    } else if (!left && dragItemContent.selected) {
      dragItemContent.selected = false;
      copyListItems.splice(dragItem.current, 1);
      copyListItems.splice(dragOverItem.current, 0, dragItemContent);
      dragItem.current = null;
      dragOverItem.current = null;
      setBrandValue(copyListItems);
    }
  };
  const handleAdd = () => {
    if (value) {
      const d = {
        default: false,
        name: value,
        selected: false,
        loaded: false,
        uuid: uuidv4(),
      };
      setBrandValue([...brandValues, d]);
      setValue("");
    }
  };
  const handleDelete = (idx) => {
    const d = [...brandValues];
    d.splice(idx, 1);
    setBrandValue(d);
  };
  return (
    <>
      <Stack direction="row" gap="40px">
        <Stack flex="0.3" gap="10px">
          <Box>
            Identify your top 8 foremost brand values either from the workshop
            discussion to-date or using the list opposite
          </Box>
          <Box
            width="100%"
            minHeight="400px"
            padding="10px"
            border="1px solid black"
            display="flex"
            flexDirection="column"
            gap="10px"
            onDragEnd={() => drop(false)}
            onDragOver={(e) => e.preventDefault()}
          >
            {brandValues && brandValues.length
              ? brandValues.map((a, index) =>
                  a.selected ? (
                    <Box
                      padding="6px"
                      style={
                        a.default
                          ? { background: "grey" }
                          : { background: "white" }
                      }
                      color={a.default ? "white" : "black"}
                      border={a.default ? "" : "1px solid black"}
                      onDragStart={(e) => dragStart(e, index)}
                      onDragEnter={(e) => dragEnter(e, index)}
                      onDragOver={(e) => e.preventDefault()}
                      key={index}
                      draggable
                    >
                      {a.name}
                    </Box>
                  ) : null
                )
              : null}
          </Box>
          <Box>
            {" "}
            {brandValues && brandValues.length
              ? brandValues.filter((a) => a.selected === true).length
              : 0}{" "}
            /8 candidate values selected
          </Box>
        </Stack>
        <Box
          flex="0.7"
          onDragEnd={() => drop(true)}
          onDragOver={(e) => e.preventDefault()}
        >
          <Box display="flex" gap="4px" flexWrap="wrap">
            {brandValues && brandValues.length
              ? brandValues.map((a, idx) =>
                  !a.selected ? (
                    <Box
                      padding="6px"
                      style={
                        a.default
                          ? { background: "grey" }
                          : { background: "white" }
                      }
                      color={a.default ? "white" : "black"}
                      border={a.default ? "" : "1px solid black"}
                      onDragStart={(e) => dragStart(e, idx)}
                      onDragEnter={(e) => dragEnter(e, idx)}
                      onDragOver={(e) => e.preventDefault()}
                      key={idx}
                      draggable
                    >
                      {a.name}
                      <span
                        style={{ fontWeight: 600, marginLeft: "4px" }}
                        onClick={() => handleDelete(idx)}
                      >
                        X
                      </span>
                    </Box>
                  ) : null
                )
              : null}
          </Box>
          <Box width="40%" mr="0" ml="auto" mt={2}>
            <FormGroupTextField
              label="Add new brand value"
              value={value}
              onChange={(e) => setValue(e)}
            />
            <CustomButton
              name="Add +"
              styled={{
                marginTop: "10px",
                marginLeft: "auto",
                marginRight: "0",
              }}
              onclick={() => handleAdd()}
            />
          </Box>
        </Box>
      </Stack>
    </>
  );
};

export default Step1;
