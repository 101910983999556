import { store } from "../redux/store";

export const updateRp = (
  field,
  data,
  projectId = "",
  type = "default",
  thatid = ""
) => {
  const cdata = store?.getState().project?.projects;
  const pid = projectId || store?.getState().client?.projectId?.id;
  if (pid && cdata.length) {
    const cd = cdata.find((a) => a.uuid === pid);
    if (cd) {
      switch (type) {
        case "tool":
          if (thatid && cd.tools && cd.tools.length) {
            const td = cd.tools.find((a) => a.uuid === thatid);
            if (td) {
              td[field] = data;
            }
          }
          break;
        case "research":
          if (thatid && cd.researches && cd.researches.length) {
            const td = cd.researches.find((a) => a.uuid === thatid);
            if (td) {
              td[field] = data;
            }
          }
          break;
        case "default":
          cd[field] = data;
          break;
      }
    }
  }
};
