import { Box, Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import { useNavigate } from "react-router";
import Carousel from "react-material-ui-carousel";
import RoyMorgan from "../../assets/images/RoyMorgan.svg";
import AustrailaPost from "../../assets/images/AustrailaPost.svg";
import Smrtr from "../../assets/images/Smrtr.svg";
import fonto from "../../assets/images/fonto.svg";
import nielson from "../../assets/images/nielsen-vector-logo.svg";
import DIY from "../../assets/images/DIY.png";
import ABS from "../../assets/images/ABS.png";
import Shopfully from "../../assets/images/Shopfully.png";
import Pure from "../../assets/images/Purepng.png";

import AddClientWithProject from "../ui/modals/AddClientWithProject";
import OutboundOutlinedIcon from "@mui/icons-material/OutboundOutlined";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import { useDispatch } from "react-redux";
import { SET_ACTIVE_SIDEBAR_SECTION } from "../../redux/constants";

const useStyles = makeStyles((theme) => ({
  ProjectImgContainer: {
    height: "122px",
    borderRadius: "10px",
    opacity: 1,
    backgroundColor: "white",
    display: "flex",
    margin: "20px 0",
    justifyContent: "center",
  },
  ProjectImg: {
    maxWidth: "200px",
    margin: "auto",
    [theme.breakpoints.down("lg")]: {
      maxWidth: "140px",
    },
  },
  container: {
    width: "70%",
    margin: "auto",
    [theme.breakpoints.down("lg")]: {
      width: "80%",
    },
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
}));

const steps = [
  {
    title: "Step 1",
    subTitle: (
      <>
        Add your first
        <br /> project
      </>
    ),
    desc: (
      <>
        <b>Use a real project, or make one up to test the waters.</b> You will
        also be asked to create your first client for the project too.
      </>
    ),
    btnName: "Add Project",
    modal: true,
    color: "#1D2D91",
  },
  {
    title: "Step 2",
    subTitle: (
      <>
        Research <br />
        marketplace
      </>
    ),
    img: [
      RoyMorgan,
      AustrailaPost,
      Smrtr,
      fonto,
      nielson,
      DIY,
      ABS,
      Shopfully,
      Pure,
    ],
    btnName: "View Research Providers",
    path: "/research",
    color: "#d40841",
  },
  {
    title: "Step 3",
    subTitle: <>Feedback Pro</>,
    desc: (
      <>
        <b>Easy artwork and document feedback for you and your clients.</b>{" "}
        Upload your PDF with one or many pages and clients can comments, draw
        and approve!
      </>
    ),
    btnName: "Start Now",
    color: "#f19c7e",
    path: "/projects",
  },
];
const cards = [
  {
    name: "Team Management",
    icon: true,
    path: "/profile/user-management",
    desc: `Manage your team and assign people to the brands/clients they can access. Have full control on what your team and access.`,
  },
  {
    name: "White Label Brandvas",
    icon: true,
    path: "/profile/white-label",
    desc: `Turn the Brandvas platform into your own. Brandvas offers the ability to change colours and fonts, turning into your own platform.`,
  },
  {
    name: "Custom Workshops",
    icon: false,
    path: "",
    desc: `Customise your Brand Strategy workshop or create new ones. Brandvas provides full functionality to do what you need to do.`,
  },
  {
    name: "Example Templates",
    icon: true,
    path: "/templates",
    desc: `Completed brand projects ready for you to explore, edit and learn from. Wink Wink. Clone and use as one of your upcoming projects to make your job even easier.`,
  },
  {
    name: "Feedback Pro",
    icon: true,
    path: "/projects",
    desc: `You’ve created an amazing campaign/brand concept, however once the brand idea is approved there is the roll out stage. Streamline it.`,
  },
  {
    name: "File Management",
    icon: false,
    path: "",
    desc: `Easy file management for every project/job all in one place.`,
  },
  {
    name: "Zapier Integrations",
    icon: true,
    path: "/profile/integrations",
    desc: `Our integration with Zapier helps you to easily push data to and from Brandvas to a range of services you use. Helping to reduce your tech stack.`,
  },
  {
    name: "Export as PDF, DOC, PPT",
    icon: false,
    path: "",
    desc: `Easily export your documents to the format you need.`,
  },
  {
    name: "Presentation Mode",
    icon: false,
    path: "",
    desc: `Stay in or jump in and out of presentation mode in your Brand Strategy workshop or other workshops you create.`,
  },
];
const RightComponent2 = () => {
  const loc = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  return (
    <Box className={classes.container}>
      <Stack direction="row" gap="20px" flexWrap="wrap">
        {steps.map((a) => (
          <Box
            height="335px"
            width="31%"
            flexGrow="1"
            style={{
              background: a.color,
              border: `1px solid ${a.color}`,
            }}
            borderRadius="10px"
          >
            {/* <Box
              style={{
                background: "white",
                border: `1px solid ${a.color}`,
              }}
              height="70px"
              p={1}
              pl={2}
              textAlign="left"
              fontSize="30px"
              fontWeight="300"
              borderRadius="10px 10px 0 0"
              color={a.title === "Step 2" ? a.color : "#1D2D91"}
              display="flex"
            >
              <Box margin="auto 0">{a.title}</Box>
            </Box> */}
            <Box
              style={{
                border: `1px solid ${a.color}`,
              }}
              p={2}
              textAlign="left"
              borderRadius="10px"
              color={"white"}
              mt={2}
            >
              <Box
                margin="auto 0"
                fontSize="26px"
                height="60px"
                fontWeight="bold"
              >
                {a.subTitle}
              </Box>
              {a.img && a.img.length > 0 ? (
                <Box>
                  <Carousel indicators={false} height={149}>
                    {a.img.map((el, idx) => (
                      <div className={classes.ProjectImgContainer} key={el}>
                        <img
                          alt={a.subTitle}
                          src={el}
                          className={classes.ProjectImg}
                          style={idx === 4 ? { margin: 0 } : {}}
                        />
                      </div>
                    ))}
                  </Carousel>
                </Box>
              ) : (
                <Box mt={3} fontSize="18px" height="122px">
                  {a.desc}
                </Box>
              )}
              <Box
                mt={3}
                style={{ background: "white", cursor: "pointer" }}
                borderRadius="10px"
                display="flex"
                justifyContent="space-between"
                px={1.5}
                py={1}
                color={a.color}
                fontSize="18px"
                onClick={() => {
                  if (a.title === "Step 1") {
                    setOpen(true);
                  } else if (a.title === "Step 2") {
                    loc(a.path);
                  } else {
                    dispatch({
                      type: SET_ACTIVE_SIDEBAR_SECTION,
                      payload: { data: "Feedback Pro" },
                    });
                    loc(a.path);
                  }
                }}
              >
                {a.btnName}
                {a.title !== "Step 2" && <AddCircleOutlineRoundedIcon />}
              </Box>
            </Box>
          </Box>
        ))}
      </Stack>
      <Box
        mt={3}
        mb={3}
        color="secondary.main"
        textAlign="left"
        fontSize="24px"
        fontWeight="600"
      >
        There's so much more to Brandvas.
      </Box>
      <Stack direction="row" gap="20px" flexWrap="wrap">
        {cards.map((a) => (
          <Box
            height="180px"
            width="31%"
            flexGrow="1"
            style={{
              background: "#F4F4F4",
            }}
            borderRadius="10px"
            border="1px solid #466682"
            textAlign="left"
          >
            <Box p={2} color="secondary.main" fontWeight="600" display="flex">
              <span style={{ margin: "auto 0", marginRight: "10px" }}>
                {" "}
                {a.name}
              </span>{" "}
              {a.icon && (
                <span
                  onClick={() => {
                    loc(a.path);
                    if (a.name === "Feedback Pro") {
                      dispatch({
                        type: SET_ACTIVE_SIDEBAR_SECTION,
                        payload: { data: "Feedback Pro" },
                      });
                    }
                    if (a.name === "Example Projects") {
                      dispatch({
                        type: SET_ACTIVE_SIDEBAR_SECTION,
                        payload: { data: "Research" },
                      });
                    }
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <OutboundOutlinedIcon />
                </span>
              )}
            </Box>
            <Box p={2} pt={0}>
              {a.desc}
            </Box>
          </Box>
        ))}
      </Stack>
      <AddClientWithProject open={open} onClose={() => setOpen(false)} />
    </Box>
  );
};

export default RightComponent2;
