import { Stack } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { makeStyles } from "@mui/styles";
const useStyles = makeStyles((theme) => ({
  general: {
    borderRadius: "10px 0 0 10px",
    // width: "100%",
    padding: "10px 15px",
    fontSize: "16px",
    textAlign: "center",
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
  },
  pink: {
    backgroundColor: `#F2D9E7 !important`,
    borderColor: `#F2D9E7 !important`,
  },
  selIcon: {
    background: "#f5f5f5",
    borderRadius: "0 10px 0 0",
    display: "flex",
    padding: "8px",
    cursor: "pointer",
  },
  red: {
    backgroundColor: "#D40841 !important",
    borderColor: `#D40841 !important`,
    color: "white !important",
  },
  yellow: {
    color: theme.palette.secondary.main,
    backgroundColor: "#FFED71 !important",
    borderColor: `#FFED71 !important`,
  },
  sufron: {
    color: theme.palette.secondary.main,
    backgroundColor: "#F19C7E !important",
    borderColor: `#F19C7E !important`,
  },
  grey: {
    color: theme.palette.secondary.main,
    backgroundColor: "#D6D6D6 !important",
    borderColor: `#D6D6D6 !important`,
  },
}));
const SelectButton = ({
  title = "",
  color = "",
  titleClick = () => {},
  dropClick = () => {},
}) => {
  const classes = useStyles();

  return (
    <Stack direction={"row"} gap="3px" maxHeight="40px">
      <div
        className={`${classes.general} ${classes[color]}`}
        onClick={titleClick}
      >
        {title}
      </div>
      <div className={classes.selIcon} onClick={(e) => dropClick(e)}>
        <KeyboardArrowDownIcon />
      </div>
    </Stack>
  );
};

export default SelectButton;
