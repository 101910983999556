import { Box, DialogContent, Grid, Stack } from "@mui/material";
import { ArrowForwardIos } from "@mui/icons-material";
import Img from "../../../assets/images/IntialTool.png";
import CustomButton from "../button/CustomButton";
import CustomSelect from "../formgroup/CustomSelect";
import ModalContainer from "./ModalContainer";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import FormGroupTextField from "../formgroup/FormGroupTextField";
import { useEffect, useRef, useState } from "react";
import SimpleReactValidator from "simple-react-validator";
import { api } from "../../../redux/api/api";
import moment from "moment";

const intialData = {
  name: "",
  leaderId: "",
  templateId: "",
  dueDate: "",
};

const CustomTool = ({ open, onClose }) => {
  const templateRe = useSelector((state) => state.template?.template);

  const simpleValidator = useRef(new SimpleReactValidator());
  const [, setC] = useState();
  const [toolData, setToolData] = useState(intialData);
  const [loading, setLoading] = useState(false);

  const ptools = useSelector((state) => state.project?.project);
  const uuid = useSelector((state) => state.project?.project?.uuid);
  const pname = useSelector((state) => state.project?.project?.name);
  const users = useSelector((state) => state.client?.users);
  const [udata, setUdata] = useState([]);

  useEffect(() => {
    if (users && users.length) {
      setUdata(() =>
        users.map((a) => ({
          uuid: a.uuid,
          name: `${a.firstName} ${a.lastName}`,
        }))
      );
    }
  }, [users]);

  useEffect(() => {
    simpleValidator.current.hideMessages();
    setToolData(intialData);
  }, [open]);

  const setData = (field, value) =>
    setToolData((old) => ({ ...old, [field]: value }));

  const handleAdd = async () => {
    setLoading(true);
    setC((old) => !old);
    if (simpleValidator.current.allValid() && uuid) {
      const data = {
        ...toolData,
        leaderId: toolData.leaderId,
        templateId: toolData.templateId.uuid,
        projectId: uuid,
        type: "CUSTOM",
        dueDate: moment(toolData.dueDate).format("YYYY-MM-DD[T]HH:mm:ss"),
      };
      const res = await api(
        "tools",
        data,
        "post",
        "",
        "",
        "Tool added to this project!"
      );
      if (res && res.success) {
        if (ptools.tools) {
          ptools.tools.push(res);
        } else {
          ptools.tools = [res];
        }
        setToolData(intialData);
        onClose();
      }
    } else {
      simpleValidator.current.showMessages();
    }
    setC((old) => !old);
    setLoading(false);
  };
  return (
    <ModalContainer
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth={true}
      yellowBack={true}
    >
      <DialogContent
        style={{
          height: "500px",
        }}
      >
        <Grid container height={"100%"}>
          <Grid item lg={7} md={7} sm={7} xs={7} p={4} pt={0} m="auto">
            <Stack gap="12px">
              <Box
                fontSize="20px"
                fontWeight={600}
                color="secondary.main"
                marginBottom="10px"
              >
                Add Custom Tool
              </Box>
              <FormGroupTextField
                label="Tool Name"
                value={toolData.name}
                onChange={(e) => setData("name", e)}
                required={true}
                validator={simpleValidator.current.message(
                  "Tool Name",
                  toolData.name,
                  "required",
                  {
                    className: "errorClass",
                  }
                )}
              />
              <Box width={"100%"}>
                <CustomSelect
                  fullWidth={true}
                  label="Select Custom Template"
                  options={
                    templateRe.length
                      ? templateRe.filter((a) => a.type === "CUSTOM")
                      : []
                  }
                  value={toolData.templateId}
                  onChange={(e) => setData("templateId", e)}
                  required={true}
                  validator={simpleValidator.current.message(
                    "Template to use",
                    toolData.templateId,
                    "required",
                    {
                      className: "errorClass",
                    }
                  )}
                />
              </Box>

              <Stack direction="row" gap="20px">
                <Box width={"100%"}>
                  <CustomSelect
                    fullWidth={true}
                    label="Leader"
                    id={true}
                    options={udata}
                    value={toolData.leaderId}
                    onChange={(e) => setData("leaderId", e)}
                    required={true}
                    validator={simpleValidator.current.message(
                      "Leader",
                      toolData.leaderId,
                      "required",
                      {
                        className: "errorClass",
                      }
                    )}
                  />
                </Box>
                <Box width={"100%"}>
                  <FormGroupTextField
                    fullWidth={true}
                    type="date"
                    label="Due Date"
                    value={toolData.dueDate}
                    onChange={(e) => setData("dueDate", e)}
                    required={true}
                    other={{
                      InputProps: {
                        inputProps: { min: moment().format("YYYY-MM-DD") },
                      },
                    }}
                    validator={simpleValidator.current.message(
                      "Due Date",
                      toolData.dueDate,
                      "required",
                      {
                        className: "errorClass",
                      }
                    )}
                  />
                </Box>
              </Stack>
              <Box fontSize={"12px"} mt={3} mb={2}>
                Note: you need to have set up{" "}
                <NavLink to="/templates">custom templates</NavLink> here to use
                this tool.
              </Box>
              <Box width="40%">
                <CustomButton
                  variant="contained"
                  size="large"
                  name={"Add Tool"}
                  classNameI="yellow"
                  endIcon={<ArrowForwardIos />}
                  fullWidth={true}
                  iconClass={"mla"}
                  onclick={handleAdd}
                  loading={loading}
                />
              </Box>
            </Stack>
          </Grid>
          <Grid item lg={5} md={5} margin="auto" textAlign="center">
            <img src={Img} alt={Img} height="383px" />
          </Grid>
        </Grid>
      </DialogContent>
    </ModalContainer>
  );
};

export default CustomTool;
