import { Badge, Box, ButtonBase } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import NotificationCards from "./NotificationCards";
import HeaderMenu from "../menus/HeaderMenu";
import { useSelector } from "react-redux";
import Loader from "react-spinners/ClipLoader";
import { api } from "../../../redux/api/api";
import { useEffect } from "react";

const sxObj = {
  mt: 1,
  width: "420px",
  borderRadius: "18px",
  maxHeight: "500px",
};

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing.unit * 2,
  },
  customBadge: {
    backgroundColor: "red",
    color: "white",
    top: "4px !important",
    right: "6px !important",
  },
  container: {
    margin: "auto 25px auto 25px",
  },
  menuName: {
    fontSize: "12px",
    color: theme.palette.secondary.main,
    margin: "10px",
    fontFamily: theme.typography.fontFamily,
    display: "flex",
    justifyContent: "space-between",
  },
}));

const NotificationComponent = () => {
  const classes = useStyles();
  const [enchorEl, setEnchorEl] = useState(null);
  const [openP, setOpenP] = useState(false);
  const user = useSelector((state) => state.auth.user);
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(user?.actionsRequired?.hasNewNotifications ? true : false);
  }, [user]);

  const getData = async () => {
    setLoader(true);
    const res = await api(`users/notification/${user.id}`, "", "get");
    if (res && res.success) {
      setData(res.notifications);
    }
    setLoader(false);
  };

  const handleClick = (event) => {
    setEnchorEl(event.currentTarget);
    setOpenP(true);
    getData();
  };

  const handleClose = () => {
    setEnchorEl(null);
    setOpenP(false);
  };

  const clearNoti = async () => {
    setLoader(true);

    const res = await api(`users/notification/${user.id}`, "", "delete");
    if (res && res.success) {
      setShow(false);
      await getData();
    }
    setLoader(false);
  };

  return (
    <div className={classes.container}>
      <ButtonBase onClick={handleClick}>
        <Badge
          classes={{ badge: classes.customBadge }}
          className={classes.margin}
          variant="dot"
          invisible={show ? false : true}
        >
          <NotificationsIcon fontSize="large" color="secondary" />
        </Badge>
      </ButtonBase>
      <HeaderMenu
        openP={openP}
        enchorEl={enchorEl}
        handleClose={handleClose}
        sxObj={sxObj}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <div className={classes.menuName}>
          <div> Latest Notifications</div>{" "}
          <div onClick={() => clearNoti()}>Clear</div>
        </div>

        {loader ? (
          <Box height={"200px"} width="100%" textAlign="center">
            <Loader color="black" />
          </Box>
        ) : data && data.length ? (
          data.map((e, idx) => <NotificationCards key={idx} {...e} />)
        ) : (
          <Box p={1}>You have no notifications</Box>
        )}
      </HeaderMenu>
    </div>
  );
};

export default NotificationComponent;
