import { IconButton, styled } from "@mui/material";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const CustomExpand = (props) => {
  return (
    <div
      style={
        props.margin
          ? { margin: "auto 0" }
          : props.no
          ? {}
          : { marginLeft: "auto" }
      }
    >
      <ExpandMore
        expand={props.expanded}
        onClick={props.handleExpandClick}
        aria-expanded={props.expanded}
        aria-label="show more"
      >
        {props.children}
      </ExpandMore>
    </div>
  );
};

export default CustomExpand;
