import { useTheme } from "@mui/material";
import Spiner from "react-spinners/HashLoader";
import Container from "../container/Container";

const Loader = ({ inside = false }) => {
  const color = useTheme().palette.secondary.main;

  return inside ? (
    <Spiner color={color} css={{ margin: "auto" }} />
  ) : (
    <Container>
      <Spiner color={color} css={{ margin: "auto" }} />
    </Container>
  );
};

export default Loader;
