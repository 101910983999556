import { config } from "../config";

export const convertToBase64 = (url) => {
  return fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    headers: {
      Origin: window.location.origin,
    },
  })
    .then((response) => response.blob())
    .then(
      (blob) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        })
    )
    .catch((err) => {
      console.log("error: ", err);
    })
    .finally(() => "");
};

export const convertImagesToBase64 = async (html) => {
  const parser = new DOMParser();
  const document = parser.parseFromString(html, "text/html");
  const images = document.querySelectorAll("img");

  for (var i = 0; i < images.length; i++) {
    var image = images[i];
    if (image.src.startsWith("replace:")) {
      const base64 = await convertToBase64(
        process.env.REACT_APP_FILES_PATH + image.src.replace("replace:", "")
      );
      html = html.replace(image.src.replace("replace:", "REPLACE:"), base64);
    }
  }
  return html;
};
