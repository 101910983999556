import { makeStyles } from "@mui/styles";
import Dropzone from "react-dropzone";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  dropContainer: {
    margin: "auto",
  },
  dropzone: {
    margin: "auto",
    "& p": {
      fontFamily: theme.typography.fontFamily,
    },
  },
  textContainer: {
    width: 200,
    margin: "auto",
    marginTop: "22px",
    "& p": {
      fontFamily: theme.typography.fontFamily,
    },
  },
}));
const CustomDropzone = ({
  handleDrop,
  isMulti,
  maxSize,
  accept,
  component,
  textComponent,
}) => {
  const classes = useStyles();

  return (
    <Dropzone
      multiple={isMulti}
      maxSize={maxSize}
      onDrop={(files) => handleDrop(files)}
      accept={accept}
      onDropRejected={(e) =>
        toast.error(
          e && e[0] && e[0].errors && e[0].errors[0] && e[0].errors[0].code
            ? e[0].errors[0].code
            : "Please Check Your File"
        )
      }
      useFsAccessApi={false}
    >
      {({ getRootProps, getInputProps }) => (
        <div className={classes.dropContainer}>
          <div
            {...getRootProps({
              className: classes.dropzone,
              // onDrop: (event) => event.stopPropagation(),
            })}
          >
            {component}

            <input {...getInputProps()} />
            <p className={classes.textContainer}>{textComponent}</p>
          </div>
        </div>
      )}
    </Dropzone>
  );
};

export default CustomDropzone;
