import { Grid, Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CollapseWithGrid from "../../../ui/customCollapse/CollapseWithGrid";
import RemoveIcon from "@mui/icons-material/Remove";
import { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import CustomExpand from "../../../ui/customCollapse/CustomExpand";
import CustomCheckBox from "../../../ui/checkbox/CustomCheckBox";
import Loader from "../../../ui/loader/Loader";

const useStyles = makeStyles((theme) => {
  return {
    title: {
      fontSize: "20px",
      margin: "auto 0",
    },
    container: {
      padding: "16px",
      borderRadius: "10px",
      border: "1px solid black",
    },
    subT: {
      margin: "auto",
      marginRight: 0,
    },
    subFormText: {
      paddingRight: "10px",
    },
  };
});
const SettingSlideExpanded = (props) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const handleExpandClick = () => {
    setExpanded(() => !expanded);
  };

  return (
    <Grid
      item
      lg={12}
      md={12}
      sm={12}
      xs={12}
      mt={2}
      className={classes.container}
      style={expanded ? { backgroundColor: "#f5f5f5" } : {}}
    >
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Stack direction={"row"} gap="16px">
          <div className={classes.title}>{props.title}</div>
          <div className={classes.subT}>
            <span className={classes.subFormText}>Include in workshop</span>{" "}
            <CustomCheckBox
              checked={props.includes}
              onchange={(e) => props.handleIncludes(e)}
            />
          </div>
          <CustomExpand
            no={true}
            expanded={expanded}
            handleExpandClick={handleExpandClick}
          >
            {expanded ? (
              <RemoveIcon
                onClick={handleExpandClick}
                fontSize="large"
                color="secondary"
              />
            ) : (
              <AddIcon
                onClick={handleExpandClick}
                fontSize="large"
                color="secondary"
              />
            )}
          </CustomExpand>
        </Stack>
      </Grid>
      <CollapseWithGrid expand={expanded}>
        {props.loading ? <Loader /> : props.children}
      </CollapseWithGrid>
    </Grid>
  );
};

export default SettingSlideExpanded;
