import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import moment from "moment";
import { hexToRgb } from "../../../utils/HexToRgba";
import { makeStyles } from "@mui/styles";
import { useEffect } from "react";
import { Box, useTheme } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { setFromIds } from "../../../redux/actions/client/ClientAction";

const useStyles = makeStyles((theme) => ({
  daycolor: {
    padding: "10px !important",

    "& .fc-daygrid-day-frame": {
      background: hexToRgb(theme.palette.primary.main, 0.4),
      borderRadius: "10px",
      padding: "8px",
    },
  },
  event: {
    color: theme.palette.secondary.main,
    fontSize: "18px",
    cursor: "pointer",
    textDecoration: "underline",
    whiteSpace: "normal",
    textAlign: "left",
    fontWeight: "bold",
    fontFamily: "Rubik",
  },
}));

const ClientCalender = () => {
  const classes = useStyles();
  const theme = useTheme();
  const data = useSelector((state) => state.project?.allTools);
  const loc = useNavigate();
  const dispatch = useDispatch();
  const [parray, setParray] = useState([]);

  useEffect(() => {
    const toolDiv = document.getElementsByClassName("fc-header-toolbar")[0];
    if (toolDiv) {
      const toolBarTitleDiv =
        document.getElementsByClassName("fc-toolbar-title")[0];
      if (toolBarTitleDiv) {
        toolBarTitleDiv.style.color = theme.palette.secondary.main;
        toolBarTitleDiv.style.fontFamily = theme.typography.fontFamily;
      }
      toolDiv.style.backgroundColor = hexToRgb(theme.palette.primary.main, 0.4);
      toolDiv.style.borderRadius = "10px";
      toolDiv.style.padding = "10px 20px";
      toolDiv.style.fontFamily = theme.typography.fontFamily;

      toolDiv.style.color = `${theme.palette.secondary.main} !important`;
      const fchid = toolDiv.firstElementChild?.firstElementChild;
      if (fchid) {
        fchid.style.fontWeight = 400;
      }
      const todayBtn = document.getElementsByClassName("fc-today-button")[0];
      if (todayBtn) {
        todayBtn.style.backgroundColor = theme.palette.secondary.main;
        todayBtn.style.textTransform = "capitalize";
      }
      const revBtn = document.getElementsByClassName("fc-prev-button")[0];
      if (revBtn) {
        revBtn.style.background = "none";
        revBtn.style.color = theme.palette.secondary.main;
        revBtn.style.border = "none";
      }
      const foBtn = document.getElementsByClassName("fc-next-button")[0];
      if (foBtn) {
        foBtn.style.background = "none";
        foBtn.style.color = theme.palette.secondary.main;
        foBtn.style.border = "none";
      }
    }
  }, []);
  useEffect(() => {
    if (data && data.length) {
      const newData = [];
      for (let i = 0; i < data.length; i++) {
        if (data[i].dueDate)
          newData.push({
            id: data[i].uuid,
            title: data[i].name,
            projectTitle: data[i].projectName,
            projectId: data[i].projectId,
            clientId: data[i].clientId,
            type: data[i].type,
            clientName: data[i].clientName,
            date: moment(data[i].dueDate).format("YYYY-MM-DD"),
          });
      }

      setParray(() => newData);
    }
  }, [data]);
  const handleClick = (id) => {
    const find = parray.find((a) => a.id === id);
    if (find) {
      dispatch(
        setFromIds({
          pdata: { id: find.projectId, name: find.projectTitle },
          cdata: { id: find.clientId, name: find.clientName },
        })
      );
      loc(`/projects/tool/${find.title}/${find.type}/${find.id}`);
    }
  };
  return (
    <FullCalendar
      plugins={[dayGridPlugin]}
      dayCellClassNames={classes.daycolor}
      firstDay={1}
      events={parray}
      eventContent={(e) => (
        <Box className={classes.event} onClick={() => handleClick(e.event.id)}>
          {e.event.title}
        </Box>
      )}
      views={{
        dayGridMonth: {
          dayHeaderFormat: {
            weekday: "long",
          },
        },
      }}
    />
  );
};

export default ClientCalender;
