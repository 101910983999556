import { Grid, Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import SideBarComponent from "../sidebar/SideBarComponent";

const useStyle = makeStyles((theme) => ({
  gridContainer: {
    background: "#F5F5F5",
  },
  title: {
    fontWeight: "600",
    fontSize: "14px",
    color: "#1D2D91 !important",
    fontFamily: "Rubik",
    paddingLeft: "3rem",
    paddingRight: "10px",
  },
  subTitle: {
    color: "#1D2D91",
    fontSize: "24px",
    marginTop: "auto",
    paddingLeft: "3rem",
    paddingRight: "10px",
    paddingBottom: "10px",
  },
}));
const PrivateSidebar = (props) => {
  const classes = useStyle();
  const pname = useSelector((state) => state.project?.project?.name);
  const cname = useSelector((state) => state.project?.project?.clientName);
  const full = useSelector((state) => state.project.workshop);

  return (
    <Grid
      pt={"3rem !important"}
      className={classes.gridContainer}
      item
      lg={2}
      md={2}
      sm={2}
      xs={2}
    >
      <Stack textAlign="left">
        {!full && (
          <>
            {" "}
            <div className={classes.title}>{pname}</div>
            <div className={classes.subTitle}>{cname}</div>
          </>
        )}
        <SideBarComponent
          steps={props?.steps}
          toolParams={props?.toolParams}
          selWorkshop={props?.selWorkshop}
          setSelWorkShop={props?.setSelWorkShop}
          full={full}
        />
      </Stack>
    </Grid>
  );
};

export default PrivateSidebar;
