import { Grid, useMediaQuery, useTheme } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { api } from "../../../redux/api/api";
import { ToolType, ToolTypeRev } from "../../../utils/AllOptions";
import EmptyList from "../../ui/emptyList/EmptyList";
import DropDownMenu from "../../ui/menus/DropDownMenu";
import CloneTemplate from "../../ui/modals/CloneTemplate";
import DeleteModal from "../../ui/modals/DeleteModal";
import RenameTemplate from "../../ui/modals/RenameTemplate";
import TRList from "./TRList";
import TRTitle from "./TRTitle";

const tempOp = [
  "Open Template",
  "Rename Template",
  "Clone Template",
  "Remove Template",
];
let bsdata = [];
let cbdata = [];
let mbdata = [];
let mtdata = [];
const TRight = () => {
  const loc = useNavigate();
  const templateRe = useSelector((state) => state.template?.template);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("lg"));

  const [enchorEl, setEnchorEl] = useState(null);
  const [openP, setOpenP] = useState(false);
  const [menuVal, setMenuVal] = useState("Open Template");

  const [delModal, setDelModal] = useState(false);
  const [cloneModal, setCloneModal] = useState(false);
  const [renameModal, setRenameModal] = useState(false);

  const [td, setTd] = useState({ id: "", name: "" });

  const handleSelect = (v) => {
    handleClose();
    if (td.id) {
      switch (v) {
        case tempOp[0]:
          if (td.custom) {
            loc(`/templates/custom-template/${td.id}`);
          } else {
            loc(`/templates/${td.id}`);
          }
          break;
        case tempOp[1]:
          setRenameModal(true);
          break;
        case tempOp[2]:
          setCloneModal(true);
          break;
        case tempOp[3]:
          setDelModal(true);
          break;
      }
    }
  };
  const handelDelete = async () => {
    setDelModal(() => false);
    if (td.id) {
      const res = await api(
        `templates/${td.id}`,
        "",
        "delete",
        "",
        "",
        "Template Deleted!"
      );
      if (res && res.success) {
        const idx = templateRe.findIndex((e) => e.uuid === td.id);
        if (idx !== -1) {
          templateRe.splice(idx, 1);
        }
      }
    }
    setTd(() => ({ id: "", name: "" }));
  };
  const handleClick = (event, val) => {
    setEnchorEl(() => event.currentTarget);
    setOpenP(() => true);
    setTd(() => val);
  };

  const handleClose = () => {
    setEnchorEl(null);
    setOpenP(false);
  };
  useEffect(() => {}, [templateRe]);
  if (templateRe && templateRe.length) {
    bsdata = templateRe.filter(
      (a) => a.type === ToolTypeRev[ToolType.BRAND_STRATEGY]
    );
    cbdata = templateRe.filter(
      (a) => a.type === ToolTypeRev[ToolType.CREATIVE_BRIEF]
    );
    mbdata = templateRe.filter(
      (a) => a.type === ToolTypeRev[ToolType.MEDIA_BRIEF]
    );
    mtdata = templateRe.filter((a) => a.type === "CUSTOM");
  }
  return (
    <Grid
      pl={matches ? 4 : 6}
      item
      lg={10.8}
      md={10}
      sm={10}
      xs={10}
      textAlign="left"
    >
      <TRTitle
        title="Brand Strategy"
        nmt={true}
        onclick={() => loc(`/templates/add-template/BRAND_STRATEGY`)}
      />
      {bsdata.length ? (
        bsdata.map((a) => (
          <TRList
            {...a}
            key={a.name}
            handleClick={(e) =>
              handleClick(e, {
                id: a.uuid,
                name: a.name,
                type: "Brand Strategy",
              })
            }
          />
        ))
      ) : (
        <EmptyList
          color={theme.palette.primary.main}
          text="No Brand Strategy Template Found!"
        />
      )}
      <TRTitle
        title="Creative Brief"
        onclick={() => loc("/templates/add-template/CREATIVE_BRIEF")}
      />
      {cbdata.length ? (
        cbdata.map((a) => (
          <TRList
            {...a}
            key={a.name}
            handleClick={(e) =>
              handleClick(e, {
                id: a.uuid,
                name: a.name,
                type: "Creative Brief",
              })
            }
          />
        ))
      ) : (
        <EmptyList
          color={theme.palette.primary.main}
          text="No Creative Brief Template Found!"
        />
      )}
      <TRTitle
        title="Media Brief"
        onclick={() => loc(`/templates/add-template/MEDIA_BRIEF`)}
      />
      {mbdata.length ? (
        mbdata.map((a) => (
          <TRList
            {...a}
            key={a.name}
            handleClick={(e) =>
              handleClick(e, {
                id: a.uuid,
                name: a.name,
                type: "Media Brief",
              })
            }
          />
        ))
      ) : (
        <EmptyList
          color={theme.palette.primary.main}
          text="No Media Brief Template Found!"
        />
      )}
      <TRTitle
        title="My Templates"
        onclick={() => loc("/templates/custom-template")}
      />
      {mtdata.length ? (
        mtdata.map((a) => (
          <TRList
            {...a}
            key={a.name}
            handleClick={(e) =>
              handleClick(e, {
                id: a.uuid,
                name: a.name,
                custom: true,
                type: "My Templates",
              })
            }
          />
        ))
      ) : (
        <EmptyList
          color={theme.palette.primary.main}
          text="Create your own custom templates."
        />
      )}
      <DropDownMenu
        enchorEl={enchorEl}
        openP={openP}
        handleClose={handleClose}
        options={tempOp}
        val={menuVal}
        handleSelect={handleSelect}
      />
      <CloneTemplate
        open={cloneModal}
        onClose={() => setCloneModal(false)}
        id={td.id || ""}
        name={td.name || ""}
        type={td.type || ""}
      />
      <DeleteModal
        open={delModal}
        onClose={() => setDelModal(false)}
        title="Are you sure you want to remove this
        template?"
        onDelete={handelDelete}
      />
      <RenameTemplate
        open={renameModal}
        onClose={() => {
          setRenameModal(() => false);
          setTd(() => ({ id: "", name: "" }));
        }}
        id={td.id || ""}
        name={td.name || ""}
      />
    </Grid>
  );
};

export default TRight;
