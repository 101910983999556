import { Box, Stack } from "@mui/material";
import { useEffect, useImperativeHandle } from "react";
import { memo, forwardRef, useState } from "react";
import { personalitySets } from "../../../../../../../utils/PersonalitySets";
import CustomButton from "../../../../../../ui/button/CustomButton";
import OpeningScreen from "../../OpeningScreen";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";

const BrandPersonality = (props, ref) => {
  const [steps, setSteps] = useState(0);

  const [data, setData] = useState(
    personalitySets.map((a) => ({ ...a, score: 0 }))
  );
  const [otherDescriptors, setOtherDescriptors] = useState([]);
  const [otherSummary, setOtherSummary] = useState("");
  useEffect(() => {
    if (props.td && props.td.data && props.td.data.brandPersonality) {
      if (props.td.data.brandPersonality.personalitySets)
        setData(props.td.data.brandPersonality.personalitySets);
      if (props.td.data.brandPersonality.otherDescriptors)
        setOtherDescriptors(props.td.data.brandPersonality.otherDescriptors);
      if (props.td.data.brandPersonality.otherSummary)
        setOtherSummary(props.td.data.brandPersonality.otherSummary);
    }
  }, [props]);

  useImperativeHandle(ref, () => ({
    descriptors: (value) => {
      if (value) {
        var descriptors = otherDescriptors;

        if(descriptors.indexOf(value) == -1) {
          descriptors += ' ' + value;
        }
        setOtherDescriptors(descriptors);
      }
    }
  }));

  const handlePage = async (n) => {
    switch (steps) {
      case 0:
        if (n) {
          setSteps((old) => old + 1);
        } else {
          props.prvStep();
        }
        break;
      case 1:
        await props.callApi({
          brandPersonality: {
            personalitySets: data,
            otherDescriptors: otherDescriptors,
            otherSummary: otherSummary,
          },
        });
        if (n) {
          setSteps((old) => old + 1);
        } else {
          setSteps((old) => old - 1);
        }

        break;
      case 2:
        await props.callApi({
          brandPersonality: {
            personalitySets: data,
            otherDescriptors: otherDescriptors,
            otherSummary: otherSummary,
          },
        });
        if (n) {
          setSteps((old) => old + 1);
        } else {
          setSteps((old) => old - 1);
        }

        break;
      case 3:
        if (n) {
          props.nextStep();
        } else {
          setSteps((old) => old - 1);
        }

        break;
      default:
        break;
    }
  };
  const handleSelect = () => {
    switch (steps) {
      case 1:
        return (
          <Step1
            data={data}
            setData={(e) => setData(e)}
            otherDescriptors={otherDescriptors}
            setOtherDescriptors={(e) => setOtherDescriptors(e)}
          />
        );
      case 2: {
        let d = [];
        if (data && data.length) {
          d = data.sort((a, b) => b.score - a.score);
        }
        return (
          <Step2
            data={d}
            setData={(e) => setData(e)}
            otherDescriptors={otherDescriptors || ""}
            otherSummary={otherSummary}
            setOtherSummary={(e) => setOtherSummary(e)}
          />
        );
      }
      case 3:
        return (
          <Step3
            data={data?.filter((a) => a.score) || []}
            otherDescriptors={otherDescriptors || ""}
            otherSummary={otherSummary || ""}
          />
        );
      default:
        return <></>;
    }
  };
  const handleValidate = () => {
    let disable = false;

    if (steps === 1) {
      if (data && data.length && data.filter((a) => a.score).length) {
        disable = false;
      } else {
        disable = true;
      }
    }
    if (steps === 2) {
      if (data && data.length) {
        let c = data.filter((a) => a.score).length;
        if (c) disable = false;
        else disable = true;
      } else {
        disable = true;
      }
    }
    return disable;
  };
  return (
    <>
      {steps === 0 ? (
        <OpeningScreen
          title="Your Brand Personality"
          description={
            <>
              Your brand personality directs the way your brand expresses itself
              to its audiences through language (brand voice) and visual
              identity.
              <br />
              <br />
              A good example of how different brand personality is critical for
              positioning your brand is demonstrated in this example of Qantas
              and Virgin airlines.
              <br />
              <br />
              A Brand with a personality strong in attributes such as; Warm -
              Happy - Friendly - Cheerful should look and speak differently to a
              brand whose attributes include; Intelligent - Technical -
              Corporate - Serious.
              <br />
              <br />
              Every brand needs to have a clear understanding of the layers of
              attributes that make up the brand personality it must present to
              market.
              <br />
              <br />
              This exercise draws on the research of Professor Jennifer Aaker
            </>
          }
          img={props?.sas?.img || ""}
          bestPratice={props?.sas?.workshop || false}
          bestEx={props?.sas?.workshopImg || []}
          name={props?.sas?.name || ""}
        />
      ) : (
        <Stack direction="row" gap="30px">
          <Box flex="0.2" display="flex" gap="10px" flexDirection="column">
            <Box color="secondary.main" fontSize="20px" fontWeight="800px">
              Your Brand Personality
            </Box>
            <Box mb={2} fontSize="14px">
              {steps === 2
                ? "Select a word or phrase for each group of personality descriptors that best sums-up that attribute."
                : steps === 3
                ? "Personality attributes"
                : ""}
            </Box>
          </Box>
          <Box flex="0.8" mb={2}>
            {handleSelect()}
          </Box>
        </Stack>
      )}
      <Box display="flex" justifyContent="space-between" mt="auto" mb="0">
        <CustomButton
          name="<   Prev"
          loading={props.btnLoading}
          onclick={() => handlePage(false)}
        />
        <CustomButton
          name="Next   >"
          loading={props.btnLoading}
          disabled={handleValidate()}
          onclick={() => handlePage(true)}
        />
      </Box>
    </>
  );
};

export default memo(forwardRef(BrandPersonality));