import { getMarginFromValue } from "./getMarginFromValue";

import BlueImg from "../assets/images/background-blue.png";
import ManCompImg from "../assets/images/man-computer.jpg";
import BraStrImg from "../assets/images/brand-strengths.png";
import CatStkImg from "../assets/images/workshop-category-table-stakes.png";
import BrnVulImg from "../assets/images/workshop-brand-vulnerabilities.png";
import NoWinImg from "../assets/images/workshop-no-win-zone.png";
import BrnDiffImg from "../assets/images/workshop-brand-differentiation.png";
import RockImg from "../assets/images/nxt-rocket.png";
import BranLogoImg from "../assets/images/Back-Cover-Brandvas-logo.png";
import RoyMorganLogoImg from "../assets/images/roy-morgan-logo.png";
import AusPostLogoImg from "../assets/images/aus-post-logo.png";
import IiconImg from "../assets/images/i-icon.png";

import "./workshopPDF.css";

const intro = (client, logoImg) => {
  return `
    <div class="page start" size="A4" layout="landscape">
      <div class="inner">
        <h1>${client?.projectId?.name}</h1>
        <h2>Brand Strategy</h2>
      </div>
      <img class="blue-background-image" alt="" src="${BlueImg}" />
      <footer class="footer">
        <div class="footer-inner">
          <span>© ${
            client?.clientId?.name
          } 2023. Powered by Brandvas.</span>
          <img crossorigin="anonymous" src="${logoImg}" />
        </div>
      </footer>
    </div>
  `;
}
const brandReport = (client) => {
  return `
    <div class="page page2" size="A4" layout="landscape">
        <div class="yellow">
            <div
                class="inner-1"
                style="position: absolute; left: 47px; top: 48px; max-width: 577px"
            >
                <h1 style="margin-bottom: 19px">Your Brand Report</h1>
                <h2>Congratulations</h2>
                <h2>
                    By completing your brand strategy you’ve joined the ranks of leading
                    organisations across every category and market who have similarly
                    invested in this critical business discipline.
                </h2>
                <h2>
                    The purpose of this report is to provide a coherent branding
                    strategy for ${
                      client?.projectId?.name
                    } Private Equity. This report
                    provides clear direction for the positioning of
                    ${client?.projectId?.name} in your market.
                </h2>
            </div>
            <footer class="footer">
                <span>© ${
                  client?.clientId?.name
                } 2023. Powered by Brandvas.</span>
            </footer>
        </div>
    </div>
  `;
}
const businessInfo = (client, bdata, logoImg) => {

  if(bdata?.business && Object.keys(bdata?.business).length > 0) {
      return `
          <div class="page-group">
              <div
                  class="page content-innner-fixed"
                  size="A4"
                  layout="landscape"
              >
                  <div class="white">
                      <img
                          class="agency-logo-top-content"
                          crossorigin="anonymous"
                          src="${logoImg}"
                      />
                      <div class="content-page-inner">
                          <div class="d-flex">
                              <h3>Understanding your business</h3>
                          </div>
                          <div class="d-flex">
                              <div class="column">
                                  <p>
                                      <span>Market type</span>: ${bdata?.business?.marketType}
                                  </p>
                                  <p>
                                      <span>Category</span>: ${bdata?.business?.customCategory ? bdata?.business?.customCategory : bdata?.business?.category?.name}
                                  </p>
                                  <p>
                                      <span>Primary product or service</span>: ${bdata?.business?.primaryService}
                                  </p>
                                  <p>
                                      <span>Client location</span>: ${bdata?.business?.clientLocation}
                                  </p>
                                  <p>
                                      <span>Business goals</span>: ${bdata?.business?.businessGoals}
                                  </p>
                                  <p>
                                      <span>Are there future categories planned</span>: ${bdata?.business?.futureCategoryDecision ? 'Yes' : 'No'}
                                  </p>
                              </div>
                          </div>
                      </div>
                      <footer class="footer">
                          <span>© ${client?.clientId?.name} 2023. Powered by Brandvas.</span>
                      </footer>
                  </div>
              </div>
          </div>
      `;
  } else {
    return '';
  }
}
const businessMetrics = (client, bdata, logoImg) => {

  const d2 = bdata?.businessMetrics;

  if(!bdata || !bdata.businessMetrics) {
    return '';
  }

  const subscriptionContent = (bdata.businessMetrics.businessMetricsTemplate === "subscription") ? `
  
    <div class="column">
      <p>
        <span>Type</span>: Subscription
      </p>
      <p>
          <span>Revenue at maturity</span>: ${bdata.businessMetrics.revenueAtMaturity || "-"}
      </p>
      <p>
          <span>Subscribers</span>: ${bdata.businessMetrics.subscribers || "-"}
      </p>
      <p>
          <span>Average per month</span>: $${bdata.businessMetrics.averagePerMonth || "-"}
      </p>
      <p>
          <span>ARR</span>: $${bdata.businessMetrics.subscribers * bdata.businessMetrics.averagePerMonth}
      </p>
    </div>` : '';

  const b2cRetailChannelContent  = (bdata.businessMetrics && bdata.businessMetrics.businessMetricsTemplate === "b2cRetailChannel") ? `
    <div class="column">
      <p>
          <span>Market type</span>: ${bdata?.business?.marketType}
      </p>
      <p>
          <span>Revenue at maturity</span>: ${bdata.businessMetrics.revenueAtMaturity || "-"}
      </p>
      <p>
          <span>Sales</span>: ${bdata.businessMetrics.salesInUnits || "-"}
      </p>
      <p>
          <span>Average unit price</span>: $${bdata.businessMetrics.averageUnitPrice || "-"}
      </p>
      <p>
          <span>Wholesale</span>: $${parseInt(bdata.businessMetrics.salesInUnits) * parseInt(bdata.businessMetrics.averageUnitPrice) || "-"}
      </p>
    </div>
  ` : '';

  const b2cRetailDirectContent = (bdata.businessMetrics && bdata.businessMetrics.businessMetricsTemplate === "b2cRetailDirect") ? `
    <div class="column">
      <p>
          <span>Market type</span>: ${bdata?.business?.marketType}
      </p>
      <p>
          <span>Revenue at maturity</span>: ${bdata.businessMetrics.revenueAtMaturity || "-"}
      </p>
      <p>
          <span>Sales</span>: ${bdata.businessMetrics.salesInUnits || "-"}
      </p>
      <p>
          <span>Average unit price</span>: $${bdata.businessMetrics.averageUnitPrice || "-"}
      </p>
      <p>
          <span>Retail markup</span>: ${bdata.businessMetrics.retailMarkup || "-"}%
      </p>
      <p>
          <span>Wholesale</span>: $${parseInt(bdata.businessMetrics.salesInUnits) * parseInt(bdata.businessMetrics.averageUnitPrice) || "-"}
      </p>
      <p>
          <span>Retail</span>:  $${bdata.businessMetrics.retailMarkup > 0 ? (parseInt(bdata.businessMetrics.salesInUnits) * parseInt(bdata.businessMetrics.averageUnitPrice) + ((parseInt(bdata.businessMetrics.salesInUnits) * parseInt(bdata.businessMetrics.averageUnitPrice)) * parseInt(bdata.businessMetrics.retailMarkup) / 100)) 
            : (bdata.businessMetrics.retailMarkup == 0 && parseInt(bdata.businessMetrics.salesInUnits) * parseInt(bdata.businessMetrics.averageUnitPrice))}
      </p>
    </div>
  ` : '';
  
    return `
          <div class="page-group">
              <div
                  class="page content-innner-fixed"
                  size="A4"
                  layout="landscape"
              >
                  <div class="white">
                      <img
                          class="agency-logo-top-content"
                          crossorigin="anonymous"
                          src="${logoImg}"
                      />
                      <div class="content-page-inner">
                          <div class="d-flex">
                              <h3>Understanding your business</h3>
                          </div>
                          <div class="d-flex">
                      
                              ${subscriptionContent}
                              ${b2cRetailChannelContent}
                              ${b2cRetailDirectContent}
                      
                          </div>
                      </div>
                      <footer class="footer">
                          <span>© ${client?.clientId?.name} 2023. Powered by Brandvas.</span>
                      </footer>
                  </div>
              </div>
          </div>
      `;
}
const marketData = (client, finalData, logoImg) => { 
  let marketDataContent = "";
  if (finalData && finalData.length > 0) {
    marketDataContent =
      marketDataContent +
      `
        <div
            class="page page2"
            size="A4"
            layout="landscape"
        >
            <div class="yellow">
                <div
                    class="inner-1"
                    style="position: absolute; left: 47px; top: 48px; max-width: 577px"
                >
                    <h1 style="margin-bottom: 19px">Understanding Your Market</h1>
                    <h2>
                        Once we understand the business case for the project, we delve into
                        understanding the market you operate in.
                    </h2>
                </div>
                <footer class="footer">
                    <span>© ${client?.clientId?.name} 2023. Powered by Brandvas.</span>
                </footer>
            </div>
        </div>`;

    finalData?.forEach((researchTool) => {
      marketDataContent =
        marketDataContent +
        `
       <div class="page-group">
        <div
            class="page-group"
        >`;
      if (
        researchTool.type === "AUSTRALIA_POST" ||
        researchTool.type === "ROY_MORGAN"
      ) {
        marketDataContent =
          marketDataContent +
          `<div>
            <div
                class="page-group"
            >
                <div
                    class="page content-innner-fixed"
                    size="A4"
                    layout="landscape"
                >
                <div class="white">
                    <img
                        class="agency-logo-top-content"
                        crossorigin="anonymous"
                        src="${logoImg}"
                    />
                    <div
                        class="logos"
                    >
                    <img
                        src="${
                          researchTool.type === "AUSTRALIA_POST"
                            ? AusPostLogoImg
                            : RoyMorganLogoImg
                        }"
                        alt=""
                    />
                    </div>
                    <div class="content-page-inner">
                    <div class="d-flex" style="margin-bottom: 42px">
                        <h3>${researchTool.name}</h3>
                    </div>

                  <div class="d-flex">`;
        if (researchTool?.data && researchTool.data.length > 0) {
          marketDataContent =
            marketDataContent +
            `<table>
                <tr style="height: 63px">
                    <th style="padding-left: 18px;width: 305px;">
                        <img
                            style="width: 16px; margin-right: 1px"
                            src="${IiconImg}"
                            tooltip=${
                              researchTool.type === "AUSTRALIA_POST"
                                ? "Share of item is % of the product consumed by the group. Share of item will sum to 100%"
                                : "Share of attribute is % of group that consumes the product. Share of item is % of the product consumed by the group. Share of item will sum to 100%"
                            }
                        />
                        ${researchTool?.dataPointName}
                    </th>
                    <th class="population">
                        <span class="full">Population</span
                        ><span class="abbreviation">Pop.</span>
                    </th>`;
          if (researchTool.type === "ROY_MORGAN") {
            marketDataContent =
              marketDataContent +
              `<th class="share-of-attribute">
                <span class="full">Share of attribute</span>
                <span class="abbreviation">Attr.</span>
              </th>`;
          }

          marketDataContent =
            marketDataContent +
            `<th class="share-of-item">
                <span class="full">Share of item</span
                ><span class="abbreviation">Item</span>
            </th>
            </tr>`;
          researchTool?.data &&
            researchTool.data.length > 0 &&
            researchTool.data.forEach((elt, ind) => {
              if (ind < 13) {
                marketDataContent =
                  marketDataContent +
                  `<tr>
                <td class="name" style="width: 305px;">${elt?.attributeName}</td>
                <td class="population">
                    ${elt?.attributePopulation}
                </td>`;
                if (researchTool.type === "ROY_MORGAN") {
                  marketDataContent =
                    marketDataContent +
                    `<td
                    class="share-of-attribute"
                >
                    ${elt?.shareOfAttribute}%
                </td>`;
                }

                marketDataContent =
                  marketDataContent +
                  `<td class="share-of-item">
                        ${elt?.shareOfItem.toFixed(2)}%
                    </td>
                </tr>`;
              }
            });
          marketDataContent = marketDataContent + `</table>`;
        }

        marketDataContent =
          marketDataContent +
          `</div>
                </div>
                <footer class="footer">
                  <span>© ${client?.clientId?.name} 2023. Powered by Brandvas.</span>
                </footer>
              </div>
            </div>
            
          </div>
        </div>`;
      }
      marketDataContent =
        marketDataContent +
        `
       </div>
    </div>
      `;

    if (researchTool?.data?.length > 13) {
        marketDataContent =
          marketDataContent +
          `
        <div class="page-group">
            <div
                class="page-group"
            >`;
        if (
          researchTool.type === "AUSTRALIA_POST" ||
          researchTool.type === "ROY_MORGAN"
        ) {
          marketDataContent =
            marketDataContent +
            `<div>
                <div
                    class="page-group"
                >
                    <div
                        class="page content-innner-fixed"
                        size="A4"
                        layout="landscape"
                    >
                    <div class="white">
                        <img
                            class="agency-logo-top-content"
                            crossorigin="anonymous"
                            src="${logoImg}"
                        />
                        <div
                            class="logos"
                        >
                        <img
                            src="${
                              researchTool.type === "AUSTRALIA_POST"
                                ? AusPostLogoImg
                                : RoyMorganLogoImg
                            }"
                            alt=""
                        />
                        </div>
                        <div class="content-page-inner">
                        <div class="d-flex" style="margin-bottom: 42px">
                            <h3>${researchTool.name}</h3>
                        </div>

                    <div class="d-flex">`;
          if (researchTool?.data && researchTool.data.length > 0) {
            marketDataContent =
              marketDataContent +
              `<table>
                    <tr style="height: 63px">
                        <th style="padding-left: 18px;width: 305px;">
                            <img
                                style="width: 16px; margin-right: 1px"
                                src="${IiconImg}"
                                tooltip=${
                                  researchTool.type === "AUSTRALIA_POST"
                                    ? "Share of item is % of the product consumed by the group. Share of item will sum to 100%"
                                    : "Share of attribute is % of group that consumes the product. Share of item is % of the product consumed by the group. Share of item will sum to 100%"
                                }
                            />
                            ${researchTool?.dataPointName}
                        </th>
                        <th class="population">
                            <span class="full">Population</span
                            ><span class="abbreviation">Pop.</span>
                        </th>`;
            if (researchTool.type === "ROY_MORGAN") {
              marketDataContent =
                marketDataContent +
                `<th class="share-of-attribute">
                    <span class="full">Share of attribute</span>
                    <span class="abbreviation">Attr.</span>
                </th>`;
            }

            marketDataContent =
              marketDataContent +
              `<th class="share-of-item">
                    <span class="full">Share of item</span
                    ><span class="abbreviation">Item</span>
                </th>
                </tr>`;
            researchTool?.data &&
              researchTool.data.length > 0 &&
              researchTool.data.forEach((elt, ind) => {
                if (ind > 12) {
                  marketDataContent =
                    marketDataContent +
                    `<tr>
                    <td class="name" style="width: 305px;">${elt?.attributeName}</td>
                    <td class="population">
                        ${elt?.attributePopulation}
                    </td>`;
                  if (researchTool.type === "ROY_MORGAN") {
                    marketDataContent =
                      marketDataContent +
                      `<td
                        class="share-of-attribute"
                    >
                        ${elt?.shareOfAttribute}%
                    </td>`;
                  }

                  marketDataContent =
                    marketDataContent +
                    `<td class="share-of-item">
                            ${elt?.shareOfItem.toFixed(2)}%
                        </td>
                    </tr>`;
                }
              });
            marketDataContent = marketDataContent + `</table>`;
          }

          marketDataContent =
            marketDataContent +
            `</div>
                    </div>
                    <footer class="footer">
                    <span>© ${client?.clientId?.name} 2023. Powered by Brandvas.</span>
                    </footer>
                </div>
                </div>
                
            </div>
            </div>`;
        }
        marketDataContent =
          marketDataContent +
          `
        </div>
        </div>
      `;
      }

      
    });
  }
  return marketDataContent;
}
const audience = (client, bdata, ManCompImg, logoImg) => {
  var audience = `
        <div
            class="page page2"
            size="A4"
            layout="landscape"
        >
            <div class="light-pink">
                <div class="background-intro">
                    <div class="intro-section">
                        <h1>Your Audience</h1>
                        <p>
                            It's important to understand who your audience is as it directs
                            all your brand, marketing and advertising decisions as well as
                            product development and other key business decisions.<br />
                            We find that many businesses have not had the time, resources or
                            budget to accurately define their customers/clients and are
                            operating on assumptions.
                        </p>
                    </div>
                <div class="background-section">
                    <img src="${ManCompImg}" />
                </div>
                </div>
                <footer class="footer">
                    <span>© ${
                      client?.clientId?.name
                    } 2023. Powered by Brandvas.</span>
                </footer>
            </div>
        </div>`;

  if(bdata?.coreCustomer) {
    
    audience += `
    
      <div
        class="page content-innner-fixed"
        size="A4"
        layout="landscape"
        >
        <div class="white">
            <img
                class="agency-logo-top-content"
                crossorigin="anonymous"
                src="${logoImg}"
            />
            <div class="content-page-inner">

              <div class="d-flex">
                  <h3>Core customer - Primary audience</h3>
              </div>
              <div class="d-flex">
                  <div class="column">
                      <p>
                          <span>Age</span>: ${bdata?.coreCustomer?.age}
                      </p>
                      <p>
                          <span>Gender</span>: ${bdata?.coreCustomer?.gender}
                      </p>
                      <p>
                          <span>Occupation</span>: ${bdata?.coreCustomer?.occupation}
                      </p>
                      <p>
                          <span>Family status</span>: ${bdata?.coreCustomer?.familyStatus}
                      </p>
                      <p>
                          <span>Interests / Passion</span>: ${bdata?.coreCustomer?.interests}
                      </p>
                      <p>
                          <span>Personality attributes</span>: ${bdata?.coreCustomer?.personalityAttributes}
                      </p>
                      <p>
                          <span>Media & Tech use</span>: ${bdata?.coreCustomer?.mediaUse}
                      </p>
                      <p>
                          <span>What defined them relative to this category?</span>: ${bdata?.coreCustomer?.defines}
                      </p>
                      <p>
                          <span>What are their most signigicant frustrations?</span>: ${bdata?.coreCustomer?.frustrations}
                      </p>
                      <p>
                          <span>What are their key motivations?</span>: ${bdata?.coreCustomer?.motivations}
                      </p>
                  </div>
              </div>
            </div>
            <footer class="footer">
                <span>© ${client?.clientId?.name} 2023. Powered by Brandvas.</span>
            </footer>
        </div>
      </div>`;
  }

  if(bdata?.coreCustomer && bdata?.coreCustomer.includeSecondary) {
    audience += `
    
      <div
        class="page content-innner-fixed"
        size="A4"
        layout="landscape"
        >
        <div class="white">
            <img
                class="agency-logo-top-content"
                crossorigin="anonymous"
                src="${logoImg}"
            />
            <div class="content-page-inner">

              <div class="d-flex">
                  <h3>Core customer - Secondary audience</h3>
              </div>
              <div class="d-flex">
                  <div class="column">
                      <p>
                          <span>Age</span>: ${bdata?.coreCustomer?.secondaryAge}
                      </p>
                      <p>
                          <span>Gender</span>: ${bdata?.coreCustomer?.secondaryGender}
                      </p>
                      <p>
                          <span>Occupation</span>: ${bdata?.coreCustomer?.secondaryOccupation}
                      </p>
                      <p>
                          <span>Family status</span>: ${bdata?.coreCustomer?.secondaryFamilyStatus}
                      </p>
                      <p>
                          <span>Interests / Passion</span>: ${bdata?.coreCustomer?.secondaryInterests}
                      </p>
                      <p>
                          <span>Personality attributes</span>: ${bdata?.coreCustomer?.secondaryPersonalityAttributes}
                      </p>
                      <p>
                          <span>Media & Tech use</span>: ${bdata?.coreCustomer?.secondaryMediaUse}
                      </p>
                      <p>
                          <span>What defined them relative to this category?</span>: ${bdata?.coreCustomer?.secondaryDefines}
                      </p>
                      <p>
                          <span>What are their most signigicant frustrations?</span>: ${bdata?.coreCustomer?.secondaryFrustrations}
                      </p>
                      <p>
                          <span>What are their key motivations?</span>: ${bdata?.coreCustomer?.secondaryMotivations}
                      </p>
                  </div>
              </div>
            </div>
            <footer class="footer">
                <span>© ${client?.clientId?.name} 2023. Powered by Brandvas.</span>
            </footer>
        </div>
      </div>`;
  }

  return audience;
}
const competitors = (client, bdata, logoImg) => {


  let compet = "";
  bdata?.competitors &&
    bdata?.competitors.length > 0 &&
    bdata.competitors.forEach((comp) => {
      compet =
        compet +
        `<div class="competitors-list">
          ${
            comp?.logo
              ? `<span>
                  <img
                      src="REPLACE:${comp.logo}"
                  />
              </span>`
              : ""
          }
          <p>${comp.name}</p>
      </div>`;
    });

  return (bdata?.competitors && bdata.competitors.length > 0)
      ? `
      <div
          class="page page2"
          size="A4"
          layout="landscape"
      >
          <div class="yellow">
              <div
                  class="inner-1"
                  style="position: absolute; left: 47px; top: 48px; max-width: 577px"
              >
                  <h1 style="margin-bottom: 19px">Understanding Your Competitors</h1>
                  <h2>
                      All brands live in the context of their competitors. The way a
                      customers (client) understands a brand's positioning is relative to
                      how it's biggest competitors are positioned.
                  </h2>
                  <h2>
                      For example a prestige car may be priced at $70,000 based on
                      competitor set, where the equivalent size and spec'd Korean car may
                      be priced at only $30,000. Price is just one dimension of brand
                      positioning that sets the competitive context. Other dimensions
                      include quality, design, performance, reliability, etc.
                  </h2>
              </div>
              <footer class="footer">
                  <span>© ${client?.clientId?.name} 2023. Powered by Brandvas</span>
              </footer>
          </div>
      </div>

      <div
          class="page content-innner-fixed"
          size="A4"
          layout="landscape"
      >
          <div class="white">
              <img
                  class="agency-logo-top-content"
                  crossorigin="anonymous"
                  src="${logoImg}"
              />
              <div class="content-page-inner">
                  <div class="d-flex">
                      <h3>Understanding Your Competitors</h3>
                  </div>
                  <div>
                      <div class="competitor-block">
                          ${compet}
                      </div>
                  </div>
              </div>
              <footer class="footer">
                  <span>© ${client?.clientId?.name} 2023. Powered by Brandvas.</span>
              </footer>
          </div>
      </div>
      `
      : "";
}
const brandPositioning = (client, data, logoImg) => {


  let clientNeed = "";
  data?.customerNeeds &&
    data?.customerNeeds.length > 0 &&
    data.customerNeeds.forEach((comp) => {
      clientNeed =
        clientNeed +
        `<div>
            <p>
                <span>${comp.name}</span>
            </p>
        </div>`;
    });

  let brandStrength = "";
  data?.brandStrengths &&
    data?.brandStrengths.length > 0 &&
    data.brandStrengths.forEach((comp) => {
      brandStrength =
        brandStrength +
        `<div>
            <p>
                <span>${comp.name}</span>
            </p>
        </div>`;
    });

  let competitorStrength = "";
  data?.competitorStrengths &&
    data?.competitorStrengths.length > 0 &&
    data.competitorStrengths.forEach((comp) => {
      competitorStrength =
        competitorStrength +
        `<div>
            <p>
                <span>${comp.name}</span>
            </p>
        </div>`;
    });

  let competitorMapping = "";
  data?.competitorPositioningAxes &&
    data?.competitorPositioningAxes.length > 0 &&
    data.competitorPositioningAxes.forEach((comp) => {
      if (comp?.checked) {
        competitorMapping =
          competitorMapping +
          `<div>
                <p>
                    <span>${comp.name}</span>
                </p>
            </div>`;
      }
    });

    return `
    ${data?.customerNeeds && data.customerNeeds.length > 0
      ? `<div
              class="page page2"
              size="A4"
              layout="landscape"
          >
              <div class="pale-blue">
                  <div
                      class="inner-1"
                      style="position: absolute; left: 47px; top: 48px; max-width: 577px"
                  >
                  <h1 style="margin-bottom: 19px">Competitive Brand Positioning</h1>
                  <h2>
                      Achieving alignment between your brand and your customers requires
                      an understanding of what your customers value, and what your
                      customers are looking for.
                  </h2>
                  <h2>
                      You must also differentiate yourself from your competitors and their
                      brand positioning.
                  </h2>
                  </div>
                  <footer class="footer">
                      <span>© ${client?.clientId?.name} 2023. Powered by Brandvas.</span>
                  </footer>
              </div>
          </div>`
      : ""
  } 
  
  ${
    data?.customerNeeds && data.customerNeeds.length > 0
      ? `<div
              class="page content-innner-fixed"
              size="A4"
              layout="landscape"
          >
              <div class="white">
                  <img
                      class="agency-logo-top-content"
                      crossorigin="anonymous"
                      src="${logoImg}"
                  />
                  <div class="content-page-inner">
                      <div class="d-flex">
                          <h3>How you meet your customers/clients' needs?</h3>
                      </div>

                      <div class="d-flex">
                          <div class="column">
                              ${clientNeed}                                   
                          </div>
                          <div class="column"></div>
                      </div>
                  </div>
                  <footer class="footer">
                      <span>© ${client?.clientId?.name} 2023. Powered by Brandvas.</span>
                  </footer>
              </div>
          </div>`
      : ""
  }

  ${
    data?.brandStrengths && data.brandStrengths.length > 0
      ? `<div
              class="page content-innner-fixed"
              size="A4"
              layout="landscape"
          >
              <div class="white">
                  <img
                      class="agency-logo-top-content"
                      crossorigin="anonymous"
                      src="${logoImg}"
                  />
                  <div class="content-page-inner">
                      <div class="d-flex" style="max-width: 425px">
                          <h3>Your brand Strengths (what you do better than anyone else)?</h3>
                      </div>
                      <div class="d-flex">
                          <div class="column">
                              ${brandStrength}
                          </div>
                          <div class="column"></div>
                      </div>
                  </div>
                  <footer class="footer">
                      <span>© ${client?.clientId?.name} 2023. Powered by Brandvas.</span>
                  </footer>
              </div>
          </div>`
      : ""
  }

  ${
    data?.competitorStrengths && data.competitorStrengths.length > 0
      ? `<div
              class="page content-innner-fixed"
              size="A4"
              layout="landscape"
          >
              <div class="white">
                  <img
                      class="agency-logo-top-content"
                      crossorigin="anonymous"
                      src="${logoImg}"
                  />
                  <div class="content-page-inner">
                      <div class="d-flex" style="max-width: 425px">
                          <h3>Your competitors' brand strengths (what they do better than anyone
        else)?</h3>
                      </div>
                      <div class="d-flex">
                          <div class="column">
                              ${competitorStrength}
                          </div>
                          <div class="column"></div>
                      </div>
                  </div>
                  <footer class="footer">
                      <span>© ${client?.clientId?.name} 2023. Powered by Brandvas.</span>
                  </footer>
              </div>
          </div>`
      : ""
  }

  ${
    data?.competitorPositioningAxes &&
    data.competitorPositioningAxes.length > 0
      ? `<div
              class="page content-innner-fixed"
              size="A4"
              layout="landscape"
          >
              <div class="white">
                  <img
                      class="agency-logo-top-content"
                      crossorigin="anonymous"
                      src="${logoImg}"
                  />
                  <div class="content-page-inner">
                      <div class="d-flex" style="max-width: 425px">
                          <h3>Selected Competitor Mapping Criteria</h3>
                      </div>
                      <div class="d-flex">
                          <div class="column">
                              ${competitorMapping}
                          </div>
                          <div class="column"></div>
                      </div>
                  </div>
                  <footer class="footer">
                      <span>© ${client?.clientId?.name} 2023. Powered by Brandvas.</span>
                  </footer>
              </div>
          </div>`
      : ""
  }
  <!-- END Brand positioning -->
  `;
}
const competitorMapping = (client, data, logoImg) => {
  let competitorMapsContent = "";

  if (
    data?.competitorPositioningAxes &&
    data.competitorPositioningAxes.length > 0
  ) {
    competitorMapsContent =
      competitorMapsContent +
      `<div
            class="page page2"
            size="A4"
            layout="landscape"
        >
            <div class="pale-blue">
                <div
                    class="inner-1"
                    style="position: absolute; left: 47px; top: 48px; max-width: 577px"
                >
                    <h1 style="margin-bottom: 19px">Competitor maps</h1>
                    <h2>
                        Competitor maps show how brands are different from each other and
                        allow you to identify a unique space for your brand. It will also
                        show areas of risk of vulnerability
                    </h2>
                </div>
                <footer class="footer">
                    <span>© ${client?.clientId?.name}. Powered by Brandvas</span>
                </footer>
            </div>
        </div>`;

    if (
      data?.competitorPositioningMatrixA &&
      data?.competitorPositioningMatrixA.length > 0
    ) {
      competitorMapsContent =
        competitorMapsContent +
        `<div
            class="page content-innner-fixed"
            size="A4"
            layout="landscape"
        >
            <div class="white">
                <img
                    class="agency-logo-top-content"
                    crossorigin="anonymous"
                    src="${logoImg}"
                />
                <div class="content-page-inner content-page-inner-graph">

                <div class="d-flex" style="width: 500px">
                    <div class="col-12 col-lg-12">
                        <div class="y-axis-label">${data?.competitorPositioningAxes[1]?.name}</div>
                        <div class="matrix-wrapper">
                            <div class="matrix">
                                <div class="y-axis"></div>
                                <div class="x-axis">
                                    <span>${data?.competitorPositioningAxes[0]?.name}</span>
                                </div>`;

      data?.competitorPositioningMatrixA.forEach((a) => {
        competitorMapsContent =
          competitorMapsContent +
          `<span
                class="matrix-cell"
                style="
                    top: ${getMarginFromValue(a.differentiator2 || 0)};
                    left: ${getMarginFromValue(a.differentiator1 || 0)};
                "
            >`;
        if (a.logo) {
          competitorMapsContent =
            competitorMapsContent +
            `<img
                src="REPLACE:${a.logo}"
            />`;
        }
        if (a.name) {
          competitorMapsContent =
            competitorMapsContent + `<span>${a.name}</span>`;
        }
        competitorMapsContent = competitorMapsContent + `</span>`;
      });
      competitorMapsContent =
        competitorMapsContent +
        `</div>
              </div>
            </div>
          </div>
        </div>
        <footer class="footer">
          <span>© ${client?.clientId?.name} 2023. Powered by Brandvas.</span>
        </footer>
      </div>
    </div>`;

    }
    if (
      data?.competitorPositioningMatrixB &&
      data?.competitorPositioningMatrixB.length > 0
    ) {
      competitorMapsContent =
        competitorMapsContent +
        `<div
            class="page content-innner-fixed"
            size="A4"
            layout="landscape"
        >
            <div class="white">
                <img
                    class="agency-logo-top-content"
                    crossorigin="anonymous"
                    src="${logoImg}"
                />
                <div class="content-page-inner content-page-inner-graph">

                <div class="d-flex" style="width: 500px">
                    <div class="col-12 col-lg-12">
                        <div class="y-axis-label">${data?.competitorPositioningAxes[3]?.name}</div>
                        <div class="matrix-wrapper">
                            <div class="matrix">
                                <div class="y-axis"></div>
                                <div class="x-axis">
                                    <span>${data?.competitorPositioningAxes[2]?.name}</span>
                                </div>`;

      data?.competitorPositioningMatrixB.forEach((a) => {
        competitorMapsContent =
          competitorMapsContent +
          `<span
                class="matrix-cell"
                style="
                    top: ${getMarginFromValue(a.differentiator4 || 0)};
                    left: ${getMarginFromValue(a.differentiator3 || 0)};
                "
            >`;
        if (a.logo) {
          competitorMapsContent =
            competitorMapsContent +
            `<img
                src="REPLACE:${a.logo}"
            />`;
        }
        if (a.name) {
          competitorMapsContent =
            competitorMapsContent + `<span>${a.name}</span>`;
        }
        competitorMapsContent = competitorMapsContent + `</span>`;
      });
      competitorMapsContent =
        competitorMapsContent +
        `</div>
              </div>
            </div>
          </div>
        </div>
        <footer class="footer">
          <span>© ${client?.clientId?.name} 2023. Powered by Brandvas.</span>
        </footer>
      </div>
    </div>`;
    }
    if (
      data?.competitorPositioningMatrixC &&
      data?.competitorPositioningMatrixC.length > 0
    ) {
      competitorMapsContent =
        competitorMapsContent +
        `<div
            class="page content-innner-fixed"
            size="A4"
            layout="landscape"
        >
            <div class="white">
                <img
                    class="agency-logo-top-content"
                    crossorigin="anonymous"
                    src="${logoImg}"
                />
                <div class="content-page-inner content-page-inner-graph">

                <div class="d-flex" style="width: 500px">
                    <div class="col-12 col-lg-12">
                        <div class="y-axis-label">${data?.competitorPositioningAxes[5]?.name}</div>
                        <div class="matrix-wrapper">
                            <div class="matrix">
                                <div class="y-axis"></div>
                                <div class="x-axis">
                                    <span>${data?.competitorPositioningAxes[4]?.name}</span>
                                </div>`;

      data?.competitorPositioningMatrixC.forEach((a) => {
        competitorMapsContent =
          competitorMapsContent +
          `<span
                class="matrix-cell"
                style="
                    top: ${getMarginFromValue(a.differentiator6 || 0)};
                    left: ${getMarginFromValue(a.differentiator5 || 0)};
                "
            >`;
        if (a.logo) {
          competitorMapsContent =
            competitorMapsContent +
            `<img
                src="REPLACE:${a.logo}"
            />`;
        }
        if (a.name) {
          competitorMapsContent =
            competitorMapsContent + `<span>${a.name}</span>`;
        }
        competitorMapsContent = competitorMapsContent + `</span>`;
      });
      competitorMapsContent =
        competitorMapsContent +
        `</div>
              </div>
            </div>
          </div>
        </div>
        <footer class="footer">
          <span>© ${client?.clientId?.name} 2023. Powered by Brandvas.</span>
        </footer>
      </div>
    </div>`;
    }
    if (
      data?.competitorPositioningMatrixD &&
      data?.competitorPositioningMatrixD.length > 0
    ) {
      competitorMapsContent =
        competitorMapsContent +
        `<div
            class="page content-innner-fixed"
            size="A4"
            layout="landscape"
        >
            <div class="white">
                <img
                    class="agency-logo-top-content"
                    crossorigin="anonymous"
                    src="${logoImg}"
                />
                <div class="content-page-inner content-page-inner-graph">

                <div class="d-flex" style="width: 500px">
                    <div class="col-12 col-lg-12">
                        <div class="y-axis-label">${data?.competitorPositioningAxes[7]?.name}</div>
                        <div class="matrix-wrapper">
                            <div class="matrix">
                                <div class="y-axis"></div>
                                <div class="x-axis">
                                    <span>${data?.competitorPositioningAxes[6]?.name}</span>
                                </div>`;

      data?.competitorPositioningMatrixD.forEach((a) => {
        competitorMapsContent =
          competitorMapsContent +
          `<span
                class="matrix-cell"
                style="
                    top: ${getMarginFromValue(a.differentiator8 || 0)};
                    left: ${getMarginFromValue(a.differentiator7 || 0)};
                "
            >`;
        if (a.logo) {
          competitorMapsContent =
            competitorMapsContent +
            `<img
                src="REPLACE:${a.logo}"
            />`;
        }
        if (a.name) {
          competitorMapsContent =
            competitorMapsContent + `<span>${a.name}</span>`;
        }
        competitorMapsContent = competitorMapsContent + `</span>`;
      });
      competitorMapsContent =
        competitorMapsContent +
        `</div>
              </div>
            </div>
          </div>
        </div>
        <footer class="footer">
          <span>© ${client?.clientId?.name} 2023. Powered by Brandvas.</span>
        </footer>
      </div>
    </div>`;
    }
  }

  return competitorMapsContent;
}
const outro = (client, bdata, logoImg) => {


  return `
  <!-- What's Next -->
  <div class="page page2" size="A4" layout="landscape">
      <div class="pale-blue">
          <div class="background-intro">
              <div class="intro-section">
                  <h1>What's Next</h1>
              </div>
              <div class="background-section">
                  <img src="${RockImg}" />
              </div>
          </div>
          <footer class="footer">
              <span>© ${
                client?.clientId?.name
              } 2023. Powered by Brandvas.</span>
          </footer>
      </div>
  </div>
  <!-- END What's Next -->

  ${
    bdata?.competitors && bdata?.competitors.length > 0
      ? `<!-- Implementation -->
      <div
          class="page page2"
          size="A4"
          layout="landscape"
      >
          <div class="pale-blue">
              <div class="inner-1 large-copy">
                  <h1 style="margin-bottom: 19px">Implementation</h1>
                  <p>
                      This brand strategy is a valuable business tool, but by itself will
                      not produce results. This is the foundation for you to build an
                      engaging and powerful new brand through.
                  </p>
                  <ul style="margin-left: 0; padding-left: 0; margin-top: 13px">
                      <li>01. Brand Presence & Communication</li>
                      <li>02. Brand Engagement</li>
                      <li>03. Brand Application</li>
                  </ul>
              </div>
              <footer class="footer">
                  <span>© ${client?.clientId?.name} 2023. Powered by Brandvas.</span>
              </footer>
          </div>
      </div>
      <!-- END Implementation -->

      <!-- Brand Presence -->
      <div
          class="page page2"
          size="A4"
          layout="landscape"
      >
          <div class="light-pink">
              <div class="inner-1 large-copy">
                  <h1 style="margin-bottom: 19px">Brand Presence</h1>
                  <p>
                      Your brand presence is the way your brand connects with all of your
                      external audiences including; clients or customers and other
                      stakeholders. All brands have a presence, but most have not
                      identified and influenced what it is they are communicating through
                      these many touchpoints.
                  </p>
                  <p>
                      Your brand touchpoints include all of your above and below the line
                      advertising and marketing, social marketing, digital assets
                      including your web site, product packaging, signage and
                      environmental design, brochures and other brand communications.
                  </p>
                  <p>
                      The most powerful and effective brands are those that understand who
                      they are and communicate that proposition clearly and consistently
                      through everything they do. Your Brand Report (summarized in your
                      Brand Canvas) provides all the layers of definition you need to
                      direct the implementation of your brand across every one of your
                      touchpoints.
                  </p>
                  <!-- <p>We can help you by tracking and auditing your brand presence, providing
                      recommendations for how to most effectively translate your brand strategy
                      into business actions.</p> -->
              </div>
              <footer class="footer">
                  <span>© ${client?.clientId?.name} 2023. Powered by Brandvas.</span>
              </footer>
          </div>
      </div>
      <!-- END Brand Presence -->
  
      <!-- On-Brand Mindset -->
      <div
          class="page page2"
          size="A4"
          layout="landscape"
      >
          <div class="yellow">
              <div class="inner-1 large-copy">
                  <h1 style="margin-bottom: 19px">On-Brand Mindset</h1>
                  <p>
                      Nothing undermines a brand as quickly as when it promises one thing
                      and delivers another through its words and deeds. This Brand Report
                      provides the layers of direction required so that every part of your
                      business can have clarity on what and how they should be doing their
                      job. This clarity will provide a critical, consistent thread of
                      brand positioning.
                  </p>
                  <p>
                      We can help you with this by working with each level of the business
                      to define a set of proof points specifically to direct their
                      actions.
                  </p>
              </div>
              <footer class="footer">
                  <span>© ${client?.clientId?.name} 2023. Powered by Brandvas.</span>
              </footer>
          </div>
      </div>
      <!-- END On-Brand Mindset -->

      <!-- Internal Brand Engagement -->
      <div
          class="page page2"
          size="A4"
          layout="landscape"
      >
          <div class="pale-blue">
              <div class="inner-1 large-copy">
                  <h1 style="margin-bottom: 10px">Internal Brand Engagement</h1>
                  <p>
                      Your people are your most important brand asset. An organization
                      filled with staff who understand and are invested in its brand
                      strategy has a powerful advantage over their competitors.
                  </p>
                  <p>
                      Whilst staff operating in concert to present your brand positioning
                      to your audience adds value to all a business does, a workforce
                      without clear direction and understanding of your brand will have an
                      equally negative impact.
                  </p>
                  <p>
                      Engaging your internal stakeholders in the brand journey is an
                      important step in transforming your brand. Your staff should have a
                      clear understanding of the strategy behind your brand and be
                      inspired by the purpose that sits behind it. The vast majority of
                      staff are naturally motivated to do a good job, the role of your
                      brand strategy is to provide clear direction and a framework of
                      understanding to allow them to get-on and do a great job of building
                      your brand and business.
                  </p>
              </div>
              <footer class="footer">
                  <span>© ${client?.clientId?.name} 2023. Powered by Brandvas.</span>
              </footer>
          </div>
      </div>
      <!-- END Internal Brand Engagement -->

      <!-- Brand Messaging -->
      <div
          class="page page2"
          size="A4"
          layout="landscape"
      >
          <div class="light-pink">
              <div class="inner-1 large-copy">
                  <h1 style="margin-bottom: 19px">Brand Messaging</h1>
                  <p>
                      What a brand chooses to say (and not say) as well as the language
                      with which it speaks are another important layer of the way a brand
                      comes to life. These brand messages appear on web site, in marketing
                      materials, on packaging, sales materials, internal and external
                      newsletter, on bills and invoices, training manuals as well as
                      through customer support teams.
                  </p>
                  <p>
                      In most organisations the responsibility for brand communication is
                      shared by many staff across multiple teams. It is important for a
                      brand once it has a clear strategy to establish a unique and
                      consistent tone of voice and communications strategy.
                  </p>
                  <p>
                      We can help by translating your brand personality into a unique
                      brand voice and your brand proof points into key brand messages to
                      work across each and every part of your business.
                  </p>
              </div>
              <footer class="footer">
                  <span>© ${client?.clientId?.name} 2023. Powered by Brandvas.</span>
              </footer>
          </div>
      </div>
      <!-- END Brand Messaging -->

      <!-- background -->
      <div id="page10" class="page last-page" size="A4" layout="landscape">
          <img
              class="blue-background-image"
              alt=""
              src="${BlueImg}"
          />
          <div class="inner d-flex">
              <img
                  src="${BranLogoImg}"
                  alt="Brandvas logo"
              />
          </div>
          <footer class="footer">
              <span>© ${client?.clientId?.name}. Powered by Brandvas</span>
              <img crossorigin="anonymous" src="${logoImg}" />
          </footer>
      </div>
      <!-- END background --> `
      : ""
  }
  `;
}
export const WorkshopPDFhtml = (data, client, bdata, clientListing) => {
  const find = clientListing?.find((a) => a?.uuid === bdata?.clientId);
  const logoImg = find?.settings?.branding?.img || "";

  const finalData = [];
  const findSelectedRes =
    data?.selectedResearch?.filter((a) => a.checked) || [];
  for (let i = 0; i < findSelectedRes.length; i++) {
    const findRes =
      bdata?.researches?.find((a) => a.uuid === findSelectedRes[i].uuid) || "";
    if (findRes) {
      if (findRes.researchType === "CUSTOM") {
        const imgs = findRes.data.imgs.filter((a) => a.include);
        for (let j = 0; j < imgs.length; j++) {
          const d = {
            name: findRes.name,
            uuid: findRes.uuid,
            type: findRes.researchType,
            dataPointName: "DIY",
            data: imgs[j].img || [],
            img: true,
          };
          finalData.push(d);
        }
      } else if (findRes.data && findRes.data.dataPoint) {
        const dataPoints = findRes.data.dataPoint.filter((a) => a.include);
        for (let j = 0; j < dataPoints.length; j++) {
          const d = {
            name: findRes.name,
            uuid: findRes.uuid,
            type: findRes.researchType,
            dataPointName: dataPoints[j].name,
            data: dataPoints[j].data || [],
          };
          finalData.push(d);
        }
      }
    }
  }



  let brandArch =
    data?.brandArchetypes?.brandArchetypes?.length > 0
      ? data.brandArchetypes.brandArchetypes.find(
          (val) => val?.key === data?.brandArchetypes?.selected
        )
      : {};

  let catTabStk = "";
  data?.categoryTableStakes &&
    data?.categoryTableStakes.length > 0 &&
    data.categoryTableStakes.forEach((stk) => {
      if (stk?.name) {
        catTabStk =
          catTabStk +
          `<p>
                <span>${stk.name}</span>
            </p>`;
      }
    });

  let brandVul = "";
  data?.brandVulnerabilities &&
    data?.brandVulnerabilities.length > 0 &&
    data.brandVulnerabilities.forEach((vul) => {
      if (vul?.name) {
        brandVul =
          brandVul +
          `<p>
                <span>${vul.name}</span>
            </p>`;
      }
    });

  let noWinZon = "";
  data?.noWinZone &&
    data?.noWinZone.length > 0 &&
    data.noWinZone.forEach((win) => {
      if (win?.name) {
        noWinZon =
          noWinZon +
          `<p>
                <span>${win.name}</span>
            </p>`;
      }
    });

  let brandDiff = "";
  data?.brandDifferentiation?.brandDifferentiations &&
    data?.brandDifferentiation?.brandDifferentiations.length > 0 &&
    data.brandDifferentiation.brandDifferentiations.forEach((diff) => {
      if (diff?.name) {
        brandDiff =
          brandDiff +
          `<p>
                <span>${diff.name}</span>
            </p>`;
      }
    });

  let primaryAttributes = "";
  let secondaryAttributes = "";
  let firstPersonalitySummaries = "";
  let secondPersonalitySummaries = "";

  data?.brandPersonality?.personalitySets &&
    data.brandPersonality.personalitySets.length > 0 &&
    data.brandPersonality.personalitySets.forEach((pri) => {
      if (pri?.score === 5) {
        primaryAttributes =
          primaryAttributes +
          `<div>
                <p>
                    <span>${pri.personalities.join(", ")}</span>
                </p>
            </div>`;

        firstPersonalitySummaries += `${pri.personalities.join(", ")}<br />`

          // firstPersonalitySummaries =
        //   firstPersonalitySummaries +
        //   `<li
        //         style="margin-bottom: 10px"
        //     >
        //         <span>${pri.summary}</span>
        //     </li>`;
      }
      if (pri?.score === 4) {
        secondaryAttributes =
          secondaryAttributes +
          `<div>
                <p>
                    <span>${pri.personalities.join(", ")}</span>
                </p>
            </div>`;

          secondPersonalitySummaries +=
          `<div>
                <p>
                    <span>${pri.personalities.join(", ")}</span>
                </p>
            </div>`;
  
        // secondPersonalitySummaries =
        //   secondPersonalitySummaries +
        //   `<li
        //         style="margin-bottom: 10px"
        //     >
        //         <span>${pri.summary}</span>
        //     </li>`;
      }
    });

    firstPersonalitySummaries = 
      `<div>
          <p>
              <span>${firstPersonalitySummaries}</span>
          </p>
      </div>`;

  let bVal =
    data?.brandValues &&
    data.brandValues.length > 0 &&
    data.brandValues.filter((el) => el.loaded);

  let bDes = data?.brandValuesProof && Object.values(data.brandValuesProof);

  let brandValData = "";
  bVal &&
    bVal.length > 0 &&
    bVal.forEach((el, ind) => {
      brandValData =
        brandValData +
        `<p>
            <span>${el?.name}</span
            >: ${bDes[ind]}
        </p>`;
    });

  let brandEssData = "";
  data?.brandEssence?.brandValues &&
    data.brandEssence.brandValues.length > 0 &&
    data.brandEssence.brandValues.forEach((el, ind) => {
      if (ind < 4) {
        brandEssData =
          brandEssData +
          `<div
                class="value"
                style="top: ${ind < 2 ? "0" : "225px"}; ${
            ind % 2 === 0 ? "left" : "right"
          }: 0;"
            >
                ${el["name"]}
            </div>`;
      }
    });

  let customExercisesMain = [];
  if (data?.exercises && data?.custom) {
    let customExercises = data?.exercises.filter((x) => x.custom === true);
    customExercises.forEach((x) => {
      let dataCus = data?.custom[x.key];
      if (dataCus) {
        let customExercise = { name: x.name, questions: [] };

        x["questions"].forEach((element) => {
          let q = dataCus.questions.find((e) => e["uuid"] === element["uuid"]);
          if (q) customExercise["questions"].push(q);
        });
        customExercisesMain.push(customExercise);
      }
    });
  }

  let customExercisesContent = "";
  if (customExercisesMain && customExercisesMain.length > 0) {
    customExercisesContent =
      customExercisesContent + `<div class="page-group">`;

    customExercisesMain.forEach((el) => {
      customExercisesContent =
        customExercisesContent +
        `<div
            class="page content-innner-fixed"
            size="A4"
            layout="landscape"
        >
            <div class="white">
                <img
                    class="agency-logo-top-content"
                    crossorigin="anonymous"
                    src="${logoImg}"
                />
                <div class="content-page-inner">
                    <div class="d-flex">
                        <h3>${el.name}</h3>
                    </div>
                    <div class="d-flex">
                        <div class="column">`;
      el.questions.forEach((eel) => {
        customExercisesContent =
          customExercisesContent +
          `<div>
                <div class="questions">
                    <label>${eel.question}</label>`;

        if (eel.type !== "multi") {
          customExercisesContent =
            customExercisesContent +
            `<p>
                ${eel.answer}
            </p>`;
        }
        if (eel.type === "multi") {
          customExercisesContent =
            customExercisesContent +
            `<p>
                ${eel.answer.join(", ")}
            </p>`;
        }

        customExercisesContent =
          customExercisesContent +
          `</div>
            </div>`;
      });
      customExercisesContent =
        customExercisesContent +
        `</div>
                        <div class="column"></div>
                    </div>
                </div>
                <footer class="footer">
                    <span>© ${client?.clientId?.name} 2023. Powered by Brandvas.</span>
                </footer>
            </div>
        </div>`;
    });
    customExercisesContent = customExercisesContent + `</div>`;
  }

 

  let canvasCoreCustomerData = [];
  function pushToCanvasCoreCustomerData(dimension, noOfCharacters) {
    let limit = 1450;
    let headlineLength = dimension["headline"].length;
    let descriptionLength = dimension["description"].toString().length;
    noOfCharacters += headlineLength;

    if (noOfCharacters <= limit) {
      noOfCharacters += descriptionLength;
      if (noOfCharacters <= limit) {
        canvasCoreCustomerData.push(dimension);
      } else {
        let overLimit = noOfCharacters - limit;
        let endOfString = descriptionLength - overLimit;
        dimension["description"] =
          ["description"].toString().substring(0, endOfString) + "...";
        canvasCoreCustomerData.push(dimension);
      }
    }
    return noOfCharacters;
  }

  let noOfCharacters = 0;
  let limit = 1450;

  if (data?.coreCustomer) {
    if (data.coreCustomer["age"]) {
      let dimension = {};
      dimension["headline"] = "Age";
      dimension["description"] = data.coreCustomer["age"];
      noOfCharacters = pushToCanvasCoreCustomerData(dimension, noOfCharacters);
    }
    if (data.coreCustomer["gender"] && noOfCharacters < limit) {
      let dimension = {};
      dimension["headline"] = "Male/Female";
      dimension["description"] = data.coreCustomer["gender"];
      noOfCharacters = pushToCanvasCoreCustomerData(dimension, noOfCharacters);
    }
    if (data.coreCustomer["occupation"] && noOfCharacters < limit) {
      let dimension = {};
      dimension["headline"] = "Occupation";
      dimension["description"] = data.coreCustomer["occupation"];
      noOfCharacters = pushToCanvasCoreCustomerData(dimension, noOfCharacters);
    }
    if (data.coreCustomer["familyStatus"] && noOfCharacters < limit) {
      let dimension = {};
      dimension["headline"] = "Family Status";
      dimension["description"] = data.coreCustomer["familyStatus"];
      noOfCharacters = pushToCanvasCoreCustomerData(dimension, noOfCharacters);
    }
    if (data.coreCustomer["location"] && noOfCharacters < limit) {
      let dimension = {};
      dimension["headline"] = "Lives";
      dimension["description"] = data.coreCustomer["location"];
      noOfCharacters = pushToCanvasCoreCustomerData(dimension, noOfCharacters);
    }
    if (data.coreCustomer["interests"] && noOfCharacters < limit) {
      let dimension = {};
      dimension["headline"] = "Interests/Passions";
      dimension["description"] = data.coreCustomer["interests"];
      noOfCharacters = pushToCanvasCoreCustomerData(dimension, noOfCharacters);
    }
    if (data.coreCustomer["personalityAttributes"] && noOfCharacters < limit) {
      let dimension = {};
      dimension["headline"] = "Personality Attributes";
      dimension["description"] = data.coreCustomer["personalityAttributes"];
      noOfCharacters = pushToCanvasCoreCustomerData(dimension, noOfCharacters);
    }
    if (data.coreCustomer["mediaUse"] && noOfCharacters < limit) {
      let dimension = {};
      dimension["headline"] = "Media, Social Media & Tech use";
      dimension["description"] = data.coreCustomer["mediaUse"];
      noOfCharacters = pushToCanvasCoreCustomerData(dimension, noOfCharacters);
    }
    if (data.coreCustomer["defines"] && noOfCharacters < limit) {
      let dimension = {};
      dimension["headline"] = "What defines them relative to this category?";
      dimension["description"] = data.coreCustomer["defines"];
      noOfCharacters = pushToCanvasCoreCustomerData(dimension, noOfCharacters);
    }
    if (data.coreCustomer["frustrations"] && noOfCharacters < limit) {
      let dimension = {};
      dimension["headline"] = "What are their most significant frustrations?";
      dimension["description"] = data.coreCustomer["frustrations"];
      noOfCharacters = pushToCanvasCoreCustomerData(dimension, noOfCharacters);
    }
    if (data.coreCustomer["motivations"] && noOfCharacters < limit) {
      let dimension = {};
      dimension["headline"] = "What are their key motivations?";
      dimension["description"] = data.coreCustomer["motivations"];
      noOfCharacters = pushToCanvasCoreCustomerData(dimension, noOfCharacters);
    }
    if (data.coreCustomer["improve"] && noOfCharacters < limit) {
      let dimension = {};
      dimension["headline"] =
        "What do they wish to achieve/improve from their interaction with this category?";
      dimension["description"] = data.coreCustomer["improve"];
      noOfCharacters = pushToCanvasCoreCustomerData(dimension, noOfCharacters);
    }
  }

  let brandCanvasContent = "";
  brandCanvasContent =
    brandCanvasContent +
    `<div id="page9" class="page" size="A4" layout="landscape">
      <h1>${client?.projectId?.name} Brand Canvas</h1>
      <div class="main_box">
        <div class="bar_left">
          <div class="inner_bar" style="padding-top: 8px">
            <div>
              <h6 style="margin-bottom: 12px">CORE CUSTOMER / CLIENT</h6>`;

  canvasCoreCustomerData.forEach((canvasCoreCustomer) => {
    brandCanvasContent =
      brandCanvasContent +
      `<p>
            <span>${canvasCoreCustomer["headline"]}</span>
            ${canvasCoreCustomer["description"]}
        </p>`;
  });

  brandCanvasContent =
    brandCanvasContent +
    `</div>
          </div>
        </div>
        <div class="center_box">
          <div
            class="center_inner"
            style="padding-left: 15px; padding-right: 15px; padding-top: 8px; width: 435px;"
          >
            <div>
              <h6 style="margin-bottom: 12px">BRAND BELIEFS.<br />WE BELIEVE...</h6>
              <div
                class="center_content"
                style="display: block; width: 100%; float: left"
              >
                <div
                  class="center_text"
                  style="
                    margin-top: 0;
                    margin-left: 0;
                    width: 210px;
                    margin-right: 0;
                    float: left;
                  "
                >`;

  if (data?.brandProofPoints?.brandProofPoints?.length > 0) {
    data.brandProofPoints.brandProofPoints.forEach((brandProofPoint, ind) => {
      if (ind < 4) {
        brandCanvasContent =
          brandCanvasContent + `<p>${brandProofPoint?.name}</p>`;
      }
    });
  }

  brandCanvasContent =
    brandCanvasContent +
    `</div>
        <div
            class="center_text"
            style="
            margin-top: 0;
            margin-left: 15px;
            width: 210px;
            margin-right: 0;
            float: left;
            "
        >`;
  if (data?.brandProofPoints?.brandProofPoints?.length > 0) {
    data.brandProofPoints.brandProofPoints.forEach((brandProofPoint, ind) => {
      if (ind > 3) {
        brandCanvasContent =
          brandCanvasContent + `<p>${brandProofPoint?.name}</p>`;
      }
    });
  }
  brandCanvasContent =
    brandCanvasContent +
    `</div>
              </div>
            </div>
          </div>
          <div class="main_box">
            <div class="bar">
              <div
                class="inner_bar"
                style="
                  height: 222px;
                  padding: 8px;
                  padding-left: 15px;
                  padding-right: 6px;
                "
              >
                <h6 style="margin-bottom: 12px">BRAND STRENGTHS</h6>`;

  if (data?.brandStrengths?.length > 0) {
    data.brandStrengths.forEach((item, ind) => {
      if (item?.loaded) {
        brandCanvasContent = brandCanvasContent + `<p>${item?.name}</p>`;
      }
    });
  }

  brandCanvasContent =
    brandCanvasContent +
    `</div>
            </div>
            <div class="center_box2">
              <div
                class="round"
                style="padding-left: 10px; padding-right: 10px"
              >
                <div>
                  <h6>ESSENCE</h6>`;
  let bppBrandEssenceSummary =
    (data?.brandProofPoints && data?.brandProofPoints["brandEssence"]) ||
    (data?.brandEssenceRefinement &&
      data?.brandEssenceRefinement["final"] &&
      data?.brandEssenceRefinement["final"][0]?.["name"]) ||
    "";
  if (bppBrandEssenceSummary?.length > 50) {
    brandCanvasContent =
      brandCanvasContent +
      `<p>${bppBrandEssenceSummary.substring(0, 48)}...</p>`;
  } else {
    brandCanvasContent =
      brandCanvasContent + `<p>${bppBrandEssenceSummary}</p>`;
  }

  brandCanvasContent =
    brandCanvasContent +
    `</div>
              </div>
              <div
                class="center_inner yellow_box"
                style="
                  position: relative;
                  padding-top: 15px;
                "
              >
                <div class="center_content">
                  <div class="center_text" style="width: 95px; margin: 0">
                    <ul>
                      <li
                        style="margin-bottom: 9px"
                      >
                        <span>${
                          data?.brandValuesProof
                            ? data.brandValuesProof?.brandValueProof1
                            : ""
                        }</span>
                      </li>
                      <li>
                        <span>${
                          data?.brandValuesProof
                            ? data.brandValuesProof?.brandValueProof2
                            : ""
                        }</span>
                      </li>
                    </ul>
                  </div>
                  <div
                    class="center_text"
                    style="
                      margin: 0;
                      text-align: right;
                      width: 95px;
                      float: right;
                    "
                  >
                    <ul>
                      <li
                        style="margin-bottom: 9px"
                      >
                        <span>${
                          data?.brandValuesProof
                            ? data.brandValuesProof?.brandValueProof3
                            : ""
                        }</span>
                      </li>
                      <li>
                        <span>${
                          data?.brandValuesProof
                            ? data.brandValuesProof?.brandValueProof4
                            : ""
                        }</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <h6
                  style="position: absolute; bottom: 13px; left: 8px"
                  class="wrap"
                >
                  VALUES
                </h6>
              </div>
              <div
                class="center_inner yellow_box"
                style="padding-top: 7px; margin-bottom: 0; height: 108px"
              >
                <h6 class="wrap">PERSONALITY</h6>
                <div class="center_content" style="width: 100%; float: left">
                  <div
                    class="center_text"
                    style="width: 80px; margin: 0"
                  >
                    <ul style="margin-top: 23px; margin-left: 4px">
                    ${firstPersonalitySummaries}
                    </ul>
                  </div>
                  <div
                    class="center_text"
                    style="
                      margin: 0;
                      float: right;
                      text-align: right;
                      width: 80px;
                    "
                  >
                  </div>
                </div>
              </div>
            </div>
            <div class="bar">
              <div
                class="inner_bar"
                style="
                  height: 222px;
                  padding: 8px;
                  padding-left: 15px;
                "
              >
                <h6 style="margin-bottom: 12px">BRAND DIFFERENTIATORS</h6>`;
  if (data?.brandDifferentiation?.selected?.length > 0) {
    data?.brandDifferentiation.selected.forEach((item, ind) => {
      brandCanvasContent = brandCanvasContent + `<p>${item?.name}</p>`;
    });
  }

  brandCanvasContent =
    brandCanvasContent +
    `</div>
            </div>
          </div>
          <div
            class="center_inner bottom top_15"
            style="
              height: 116px;
              margin-top: 5px;
              display: block;
              width: 450px;
              position: absolute;
              bottom: 25px;
            "
          >
            <h6 style="margin-bottom: 12px; width: 100%">
              BRAND PROOF POINTS
            </h6>
            <div class="center_content">`;

            if (data?.brandProofPoints?.brandProofPoints?.length > 0) {
              data.brandProofPoints.brandProofPoints.forEach((brandProofPoint, ind) => {
                  brandCanvasContent =
                    brandCanvasContent + `<p>${brandProofPoint?.name}</p>`;
              });
            }

    brandCanvasContent += `        
            </div>
          </div>
        </div>
        <div class="bar_right">
          <div
            class="inner_bar"
            style="padding-top: 8px; height: 230px"
          >
            <h6 style="margin-bottom: 12px">
              DIFFERENTIATED BRAND<br />
              VALUE PROPOSITION
            </h6>
            <p>
              ${
                data?.differentiatedBrandValueProposition ||
                ""
              }
            </p>
          </div>
          <div
            class="inner_bar"
            style="margin-top: 3px; padding-top: 7px; height: 230px"
          >
            <h6 style="margin-bottom: 12px">
              ELEVATOR PITCH
            </h6>
            <div>
              <p>
                <span>We are the only </span>${
                  data?.elevatorPitch?.["category"]
                }
                <span>brand in </span> ${data?.elevatorPitch?.["region"]}
                <span>that provides </span>${
                  data?.elevatorPitch?.["product"]
                }<span>to </span> ${data?.elevatorPitch?.["customer"]}
              </p>
            </div>
          </div>
        </div>
        <div style="clear: both"></div>
      </div>
    </div>`;

    // TODO after business metrics
    // ${marketData(client, finalData, logoImg)}

  let HTML = `

    ${intro(client, logoImg)}
    ${brandReport(client)}
    ${businessInfo(client, bdata, logoImg)}
    ${businessMetrics(client, bdata, logoImg)}

    ${audience(client, bdata, ManCompImg, logoImg)}
    ${competitors(client, bdata, logoImg)}
    ${brandPositioning(client, data, logoImg)}
    ${competitorMapping(client, data, logoImg)}
     
    ${customExercisesContent}
    ${brandCanvasContent}

    ${outro(client, bdata, logoImg)}
  `;

  return HTML;
};
