import { Box, Divider, Stack } from "@mui/material";
import { useSelector } from "react-redux";
import { config } from "../../../../../../config";
import CustomButton from "../../../../../ui/button/CustomButton";

const BrandSummary = (props) => {
  const projectName = useSelector((state) => state.client?.projectId?.name);
  const clientName = useSelector((state) => state.client?.clientId?.name);
  const pd = useSelector((state) => state.project.project);

  const data = props?.td?.data || false;

  const brandValues = () => {
    if (data) {
      const d1 = (data.brandValues && data.brandValues.filter((a) => a.selected)) || "";
      const d2 = data.brandValuesProof || "";
      if (d1 && d1.length) {
        
        return (
          <Box>
            <Box mb={2}>Brand Values</Box>
            <Stack gap="10px" direction="row" flexWrap="wrap" mb={2}>
              <Box flex="0.5">
                <Box fontSize="20px" mb="5px">
                  {d1[0]?.name}
                </Box>
                <Box fontSize="18px">{d2?.brandValueProof1}</Box>
              </Box>
              <Box flex="0.5">
                <Box fontSize="20px" mb="5px">
                  {d1[1]?.name}
                </Box>
                <Box fontSize="18px">{d2?.brandValueProof2}</Box>
              </Box>
              <Box flex="0.5">
                <Box fontSize="20px" mb="5px">
                  {d1[2]?.name}
                </Box>
                <Box fontSize="18px">{d2?.brandValueProof3}</Box>
              </Box>
              <Box flex="0.5">
                <Box fontSize="20px" mb="5px">
                  {d1[3]?.name}
                </Box>
                <Box fontSize="18px">{d2?.brandValueProof4}</Box>
              </Box>
            </Stack>
            <Divider />
          </Box>
        );
      }
      return <></>;
    }
    return <></>;
  };
  const meetingCustomerNeeds = () => {
    if (data) {
      const d1 = (data.customerNeeds && data.customerNeeds.filter((a) => a.loaded)) || "";
      if (d1 && d1.length) {
        
        return (
          <Box>
            <Box mb={2}>Customer needs</Box>
            {d1.map((a, idx) => (
              <li
                style={{
                  fontSize: "20px",
                }}
                key={idx}
              >
                {a.name}
              </li>
            ))}
            <Divider style={{ marginTop: "20px" }} />
          </Box>
        );
      }
      return <></>;
    }
    return <></>;
  };
  const brandProofPoints = () => {
    if (data) {
      const d1 = (data.brandProofPoints && data.brandProofPoints.brandProofPoints.filter((a) => a.name != "")) || "";
      if (d1 && d1.length) {
        
        return (
          <Box>
            <Box mb={2}>Brand proof points</Box>
            {d1.map((a, idx) => (
              <li
                style={{
                  fontSize: "20px",
                }}
                key={idx}
              >
                {a.name}
              </li>
            ))}
            <Divider style={{ marginTop: "20px" }} />
          </Box>
        );
      }
      return <></>;
    }
    return <></>;
  };
  const brandPersonality = () => {
    if (data) {
      const d1 =
        data.brandPersonality &&
        data.brandPersonality.personalitySets &&
        data.brandPersonality.personalitySets.filter(
          (a) => a.score
        );
      if (d1) {
        return (
          <Box>
            <Box mb={2}>Brand Personality</Box>
            <Stack gap="20px" direction="column" flexWrap="wrap" mb={2}>
              {d1.map((a) => (
                <Box flex="0.5">
                  <Box fontSize="20px">{a.summary || "-"}</Box>
                  <Box mb="5px" fontSize="18px">
                    {a?.personalities.join(", ")}
                  </Box>
                </Box>
              ))}
            </Stack>
            <Divider />
          </Box>
        );
      } else {
        return <></>;
      }
    }
    return <></>;
  };
  const brandStrengths = () => {
    if (data) {
      const d1 =
        data.brandStrengths && data.brandStrengths.filter((a) => a.loaded);
      const d2 =
        data.competitorStrengths && data.competitorStrengths.filter((a) => a.loaded);
      if (d1 && d2) {
        return (
          <Box>
            <Box mb={2}>Brand Strengths</Box>
            {d1.map((a, idx) => (
              <li
                style={{
                  fontSize: "20px",
                }}
                key={idx}
              >
                {a.name}
              </li>
            ))}
            <Divider style={{ marginBottom: "20px", marginTop: "20px" }} />
            <Box mb={2}>Competitor Strengths</Box>
            {d2.map((a, idx) => (
              <li
                style={{
                  fontSize: "20px",
                }}
                key={idx}
              >
                {a.name}
              </li>
            ))}
            <Divider style={{ marginTop: "20px" }} />
          </Box>
        );
      } else {
        return <></>;
      }
    }
    return <></>;
  };
  const brandDifferentiations = () => {
    if (data) {
      const d1 =
        (data.brandDifferentiation && data.brandDifferentiation.selected) || [];
      if (d1 && d1.length) {
        return (
          <Box>
            <Box mb={2}>Brand Differentiators</Box>
            {d1.filter(x => x.name != '').map((a, idx) => (
              <li
                style={{
                  fontSize: "20px",
                }}
                key={idx}
              >
                {a.name}
              </li>
            ))}
            <Divider style={{ marginTop: "20px" }} />
          </Box>
        );
      } else {
        return <></>;
      }
    }
    return <></>;
  };
  const differentiatedBrandValueProposition = () => {
    if (data) {
      const d1 =
        data.differentiatedBrandValueProposition || "";
      if (d1) {
        return (
          <Box>
            <Box mb={2}>Differentiated brand value proposition</Box>
            <Box fontSize="20px" mb={2}>{d1}</Box>
            <Divider style={{ marginTop: "20px" }} />
          </Box>
        );
      } else {
        return <></>;
      }
    }
    return <></>;
  };
  const categoryTableStakes = () => {
    if (data) {
      const d1 =
        (data.categoryTableStakes && data.categoryTableStakes.filter(x => x.name != "")) || [];
      if (d1 && d1.length) {
        return (
          <Box>
            <Box mb={2}>Category table stakes</Box>
            {d1.map((a, idx) => (
              <li
                style={{
                  fontSize: "20px",
                }}
                key={idx}
              >
                {a.name}
              </li>
            ))}
            <Divider style={{ marginTop: "20px" }} />
          </Box>
        );
      } else {
        return <></>;
      }
    }
    return <></>;
  };
  const brandVulnerabilities = () => {
    if (data) {
      const d1 =
        (data.brandVulnerabilities && data.brandVulnerabilities.filter(x => x.name != "")) || [];
      if (d1 && d1.length) {
        return (
          <Box>
            <Box mb={2}>Brand Vulnerabilities</Box>
            {d1.map((a, idx) => (
              <li
                style={{
                  fontSize: "20px",
                }}
                key={idx}
              >
                {a.name}
              </li>
            ))}
            <Divider style={{ marginTop: "20px" }} />
          </Box>
        );
      } else {
        return <></>;
      }
    }
    return <></>;
  };
  const noWinZone = () => {
    if (data) {
      const d1 =
        (data.noWinZone && data.noWinZone.filter(x => x.name != "")) || [];
      if (d1 && d1.length) {
          return (
          <Box>
            <Box mb={2}>No win zone</Box>
            {d1.map((a, idx) => (
              <li
                style={{
                  fontSize: "20px",
                }}
                key={idx}
              >
                {a.name}
              </li>
            ))}
            <Divider style={{ marginTop: "20px" }} />
          </Box>
        );
      } else {
        return <></>;
      }
    }
    return <></>;
  };
  const brandArchetype = () => {
    if (data) {
      const d1 =
        (data.brandArchetypes && data.brandArchetypes.selected && data.brandArchetypes.brandArchetypes.filter(x => x.key === data.brandArchetypes.selected)) || [];
      if (d1) {
        return (
          <Box>
            <Box mb={2}>Brand Archetype</Box>
            {d1.map((a, idx) => (
              <div
                key={idx}
              >
                <Box fontSize="20px">{a.name || "-"}</Box>
                <Box mt="5px" fontSize="18px">Motto: {a.motto}</Box>
                <Box mb="5px" fontSize="18px">Broad approach: {a.broadApproach}</Box>
              </div>
            ))}
            <Divider style={{ marginTop: "20px" }} />
          </Box>
        );
      } else {
        return <></>;
      }
    }
    return <></>;
  };
  const brandEssence = () => {
    if (data) {
      const d1 =
        (data.brandEssenceRefinement && data.brandEssenceRefinement.final) ||
        [];
      if (d1) {
        return (
          <Box>
            <Box mb={2}>Brand Essence</Box>
            {d1.map((a, idx) => (
              <li
                style={{
                  fontSize: "20px",
                  listStyleType: "none"
                }}
                key={idx}
              >
                {a.name}
              </li>
            ))}
            <Divider style={{ marginTop: "20px" }} />
          </Box>
        );
      } else {
        return <></>;
      }
    }
    return <></>;
  };
  const elevatorPitch = () => {
    if (data.elevatorPitch) {
      return (
        <Box>
          <Box mb={2}>Elevator Pitch</Box>
          <Box
            style={{
              fontSize: "18px",
            }}
          >
            We are the only {data.elevatorPitch?.category} brand in{" "}
            {data.elevatorPitch?.region} that provides{" "}
            {data.elevatorPitch?.product} to {data.elevatorPitch?.customer}
          </Box>
          <Divider style={{ marginTop: "20px" }} />
        </Box>
      );
    }
    return <></>;
  };
  const competitors = () => {

    if (pd?.competitors) {
      const d1 = pd.competitors.filter(x => x.loaded) || [];
      if (d1) {
        return (
          <Box>
            <Box mb={2}>Competitors</Box>
            <Box display="flex">
            {d1.map((a, idx) => (
              <Box fontSize="18px"flex="0.5" mb="15px"
                key={idx}
              >
                {a.name}
                {a.logo != "" && <img 
                style={{
                  marginTop: "1em",
                  maxWidth: "8em",
                  display: "block"
                }}
                src={`${config.dwdPath}${a.logo}`} />}
              </Box>
            ))}
            </Box>
            <Divider />
          </Box>
        );
      } else {
        return <></>;
      }
    } else {
      return <></>;
    }
  };
  const coreCustomer = () => {

    if (pd.coreCustomer) {
      const d1 = pd.coreCustomer;
      if (d1) {
        return (
          <Box>
            <Box mb={2}>Core customers</Box>
            <Box display="flex" gap="10px" direction="row" mb={2}>
              <Box flex="0.5">
                <Box fontSize="20px" mb="5px">Primary Audience</Box>
                <Box fontSize="18px" mb="15px">
                  Age: {d1.age}
                  <br />
                  Gender: {d1.gender}
                  <br />
                  Occupation: {d1.occupation}
                  <br />
                  Family Status: {d1.familyStatus}
                  <br />
                  Interests / Passion: {d1.interests}
                  <br />
                  Lives: {d1.location}
                  <br />
                  Personality Attributes: {d1.personalityAttributes}
                  <br />
                  Media & Tech use: {d1.mediaUse}
                </Box>
                <Box fontSize="18px" mt="10px" mb="5px">
                  What defines them relative to this category?
                  <br />
                  {d1.defines}
                  <br /><br />
                  What are their most significant frustrations?
                  <br />
                  {d1.frustrations}
                  <br /><br />
                  What are their key motivations?
                  <br />
                  {d1.motivations}
                </Box>
              </Box>

              {d1.includeSecondary && <Box flex="0.5">
                <Box fontSize="20px" mb="5px">Secondary Audience</Box>
                <Box fontSize="18px" mb="15px">
                  Age: {d1.secondaryAge}
                  <br />
                  Gender: {d1.secondaryGender}
                  <br />
                  Occupation: {d1.secondaryOccupation}
                  <br />
                  Family Status: {d1.secondaryfamilyStatus}
                  <br />
                  Interests / Passion: {d1.secondaryInterests}
                  <br />
                  Lives: {d1.secondaryLocation}
                  <br />
                  Personality Attributes: {d1.secondaryPersonalityAttributes}
                  <br />
                  Media & Tech use: {d1.secondarymediaUse}
                </Box>
                <Box fontSize="18px" mt="10px" mb="5px">
                  What defines them relative to this category?
                  <br />
                  {d1.secondaryDefines}
                  <br /><br />
                  What are their most significant frustrations?
                  <br />
                  {d1.secondaryFrustrations}
                  <br /><br />
                  What are their key motivations?
                  <br />
                  {d1.secondaryMotivations}
                </Box>
              </Box>}
             
            </Box>
            <Divider />
          </Box>
        );
      } else {
        return <></>;
      }
    } else {
      return <></>;
    }
  };
  const businessInfo = () => {

    if (pd?.business) {
      const d1 = pd.business;
      const d2 = pd.businessMetrics;

      if (d1) {
        return (
          <Box>
            <Box mb={2}>Your business</Box>
            <Box display="flex" gap="10px" direction="row" mb={2}>
              <Box flex="0.5">
                <Box fontSize="22px" mb="5px">
                  {d1.marketType || "-"}
                </Box>
                <Box fontSize="18px">Market Type</Box>              
              </Box>
              <Box flex="0.5">
                <Box mb="5px" fontSize="22px">
                  {d1.category?.name || "-"}
                </Box>
                <Box fontSize="18px">
                  Category                  
                </Box>
              </Box>{" "}
              {d1.customCategory && <Box flex="0.5">
                <Box mb="5px" fontSize="22px">
                  {d1.customCategory || "-"}
                </Box>
                <Box fontSize="18px">Custom Category</Box>
              </Box>}{" "}
              <Box flex="0.5">
                <Box mb="5px" fontSize="22px">
                  {d1.primaryService || "-"}
                </Box>
                <Box fontSize="18px">
                  What is the primary product, service, or experience provided?
                </Box>
              </Box>{" "}
            </Box>      
            <Divider />
            {d2 && d2.businessMetricsTemplate === "subscription" && <Box mt="15px">
              <Box mb={2}>Business metrics</Box>
              <Box display="flex" gap="10px" direction="row" mb={2}>
              <Box flex="0.5">
                  <Box fontSize="22px">
                    Subscription
                  </Box>
                </Box>{" "}
                <Box flex="0.5">
                  <Box fontSize="22px" mb="5px">
                    {d2.revenueAtMaturity || "-"}
                  </Box>
                  <Box fontSize="18px">Revenue at maturity</Box>              
                </Box>
                <Box flex="0.5">
                  <Box mb="5px" fontSize="22px">
                    {d2.subscribers || "-"}
                  </Box>
                  <Box fontSize="18px">
                    Subscribers
                  </Box>
                </Box>{" "}
                <Box flex="0.5">
                  <Box mb="5px" fontSize="22px">
                    ${d2.averagePerMonth || "-"}
                  </Box>
                  <Box fontSize="18px">Average per month</Box>
                </Box>{" "}
                <Box flex="0.5">
                  <Box mb="5px" fontSize="22px">
                    ${d2.subscribers * d2.averagePerMonth}
                  </Box>
                  <Box fontSize="18px">
                    ARR
                  </Box>
                </Box>{" "}
              </Box>      
              <Divider />
            </Box>}
            {d2 && d2.businessMetricsTemplate === "b2cRetailChannel" && <Box mt="15px">
              <Box mb={2}>Business metrics</Box>
              <Box display="flex" gap="10px" direction="row" mb={2}>
              <Box flex="0.5">
                  <Box fontSize="22px">
                    B2C
                  </Box>
                </Box>{" "}
                <Box flex="0.5">
                  <Box fontSize="22px" mb="5px">
                    {d2.revenueAtMaturity || "-"}
                  </Box>
                  <Box fontSize="18px">Revenue at maturity</Box>              
                </Box>
                <Box flex="0.5">
                  <Box mb="5px" fontSize="22px">
                    {d2.salesInUnits || "-"}
                  </Box>
                  <Box fontSize="18px">
                    Sales
                  </Box>
                </Box>{" "}
                <Box flex="0.5">
                  <Box mb="5px" fontSize="22px">
                    ${d2.averageUnitPrice || "-"}
                  </Box>
                  <Box fontSize="18px">
                    Average unit price
                  </Box>
                </Box>{" "}
                <Box flex="0.5">
                  <Box mb="5px" fontSize="22px">
                    ${parseInt(d2.salesInUnits) * parseInt(d2.averageUnitPrice) || "-"}
                  </Box>
                  <Box fontSize="18px">Wholesale</Box>
                </Box>{" "}
              </Box>      
              <Divider />
            </Box>}
             {d2 && d2.businessMetricsTemplate === "b2cRetailDirect" && <Box mt="15px">
              <Box mb={2}>Business metrics</Box>
              <Box display="flex" gap="10px" direction="row" mb={2}>
              <Box flex="0.5">
                  <Box fontSize="22px">
                    B2C
                  </Box>
                </Box>{" "}
                <Box flex="0.5">
                  <Box fontSize="22px" mb="5px">
                    {d2.revenueAtMaturity || "-"}
                  </Box>
                  <Box fontSize="18px">Revenue at maturity</Box>              
                </Box>
                <Box flex="0.5">
                  <Box mb="5px" fontSize="22px">
                    {d2.salesInUnits || "-"}
                  </Box>
                  <Box fontSize="18px">
                    Sales
                  </Box>
                </Box>{" "}
                <Box flex="0.5">
                  <Box mb="5px" fontSize="22px">
                    ${d2.averageUnitPrice || "-"}
                  </Box>
                  <Box fontSize="18px">
                    Average unit price
                  </Box>
                </Box>{" "}
                <Box flex="0.5">
                  <Box mb="5px" fontSize="22px">
                    {d2.retailMarkup || "-"}%
                  </Box>
                  <Box fontSize="18px">Retail markup</Box>
                </Box>{" "}
                <Box flex="0.5">
                  <Box mb="5px" fontSize="22px">
                    ${parseInt(d2.salesInUnits) * parseInt(d2.averageUnitPrice) || "-"}
                  </Box>
                  <Box fontSize="18px">Wholesale</Box>
                </Box>{" "}
                <Box flex="0.5">
                  <Box mb="5px" fontSize="22px">
                    ${d2.retailMarkup > 0 && (parseInt(d2.salesInUnits) * parseInt(d2.averageUnitPrice) + ((parseInt(d2.salesInUnits) * parseInt(d2.averageUnitPrice)) * parseInt(d2.retailMarkup) / 100))}
                    {d2.retailMarkup == 0 && parseInt(d2.salesInUnits) * parseInt(d2.averageUnitPrice)}
                  </Box>
                  <Box fontSize="18px">Retail</Box>
                </Box>{" "}
              </Box>      
              <Divider />
            </Box>}
          </Box>
        );
      } else {
        return <></>;
      }
      
    } else {
      return <></>;
    }
  };
  const brandCustom = () => {
    if (data) {
      const d1 = data.custom;
      const d2 =
        (data.exercises && data.exercises.filter((a) => a.custom)) || [];

      if (d1 && d2) {
        return d2.map((a) => (
          <Box>
            <Box mb={2}>Custom {a.name}</Box>
            <Stack gap="20px" direction="column" flexWrap="wrap" mb={2}>
              {a.questions && a.questions.length
                ? a.questions.map((b) =>
                    d1[b.uuid] ? (
                      <Box flex="0.5">
                        <Box mb="5px" fontSize="18px">
                          {b.name || ""}
                        </Box>
                        {customSelect(
                          b.title,
                          d1[b.uuid],
                          b["Question Multi Choice"]
                        )}
                      </Box>
                    ) : null
                  )
                : null}
            </Stack>
            <Divider />
          </Box>
        ));
      } else return <></>;
    } else {
      return <></>;
    }
  };
  const customSelect = (t, d, h) => {
    switch (t) {
      case "Text Only":
        return <Box fontSize="22px">{d || ""}</Box>;
      case "Question Yes/No":
        return <Box fontSize="22px">{d || ""}</Box>;
      case "Question - Select one":
        return <Box fontSize="22px">{d || ""}</Box>;
      case "Question Multi Choice":
        {
          const options = d && h && Object.keys(h).filter((a) => d[a]);
          if (options) {
            return (
              <Box fontSize="22px">
                {options.map((a) => (
                  <li>{a}</li>
                ))}
              </Box>
            );
          }
        }
        break;
      case "Full Width Image":
        return <img src={d} alt={d} />;
      default:
        return <></>;
    }
  };
  return (
    <>
      <Stack gap="20px" padding="10px" fontSize="22px">
        <Box>Client: {clientName}</Box>
        <Box>Project: {projectName}</Box>
        <Divider />
        {businessInfo()}
        {competitors()}
        {coreCustomer()}
        {meetingCustomerNeeds()}
        {brandStrengths()}
        {brandArchetype()}
        {categoryTableStakes()}
        {brandVulnerabilities()}
        {noWinZone()}
        {brandVulnerabilities()}
        {brandDifferentiations()}
        {differentiatedBrandValueProposition()}
        {brandPersonality()}
        {brandValues()}
        {brandEssence()} 
        {brandProofPoints()}
        {elevatorPitch()}
        {brandCustom()}
      </Stack>

      <Box display="flex" justifyContent="space-between" mt="auto" mb="0">
        <CustomButton name="<   Prev" onclick={() => props.prvStep()} />
        <CustomButton name="Next   >" onclick={() => props.nextStep()} />
      </Box>
    </>
  );
};

export default BrandSummary;
