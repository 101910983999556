import { setTitle } from "../redux/actions/theme/themeActions";
import { store } from "../redux/store";

export const setHTitle = (
  title = "",
  flag = "",
  subTitle = "",
  subTitlePosition = "top"
) => {
  store.dispatch(
    setTitle({
      title: title,
      isTitle: flag,
      subTitle: subTitle,
      subTitlePosition: subTitlePosition,
    })
  );
};
