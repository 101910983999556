import { Box, Grid, Slider } from "@mui/material";

const Step5 = ({
  competitorPositioningMatrixB,
  setCompetitorPositioningMatrixB,
  competitorPositioningAxes,
}) => {
  return (
    <>
      <Box mb={2}>
        For your brand and your competitors, score them for each of the
        differentiators where 1 is low and 10 is high
      </Box>
      <Grid container spacing={2}>
        <Grid item lg={4} md={4} sm={4} xs={4}></Grid>
        <Grid item lg={4} md={4} sm={4} xs={4} fontSize="18px">
          {competitorPositioningAxes[2].name || ""}
        </Grid>
        <Grid item lg={4} md={4} sm={4} xs={4} fontSize="18px">
          {competitorPositioningAxes[3].name || ""}
        </Grid>
        {competitorPositioningMatrixB && competitorPositioningMatrixB.length
          ? competitorPositioningMatrixB.map((a, idx) => (
              <>
                <Grid item lg={4} md={4} sm={4} xs={4}>
                  <Box
                    style={{
                      background: "white",
                      padding: "10px",
                      width: "80%",
                    }}
                  >
                    {idx + 1} {a.name || ""}
                  </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={4} xs={4} fontSize="18px" pr={1}>
                  <Slider
                    color="secondary"
                    value={a.differentiator3 || 0}
                    min={0}
                    max={10}
                    valueLabelDisplay="auto"
                    step={1}
                    onChange={(_, e) => {
                      const data = [...competitorPositioningMatrixB];
                      data[idx].differentiator3 = e;
                      setCompetitorPositioningMatrixB(data);
                    }}
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={4} xs={4} fontSize="18px" pr={1}>
                  <Slider
                    color="secondary"
                    value={a.differentiator4 || 0}
                    min={0}
                    max={10}
                    valueLabelDisplay="auto"
                    step={1}
                    onChange={(_, e) => {
                      const data = [...competitorPositioningMatrixB];
                      data[idx].differentiator4 = e;
                      setCompetitorPositioningMatrixB(data);
                    }}
                  />
                </Grid>
              </>
            ))
          : null}
      </Grid>
    </>
  );
};

export default Step5;
