import { ButtonBase, DialogContent, Grid, Stack } from "@mui/material";
import { ArrowForwardIos } from "@mui/icons-material";
import CatImg from "../../../assets/images/catimg.png";
import FormGroupTextField from "../formgroup/FormGroupTextField";
import CustomButton from "../button/CustomButton";
import ModalContainer from "./ModalContainer";
import { makeStyles } from "@mui/styles";
import { api } from "../../../redux/api/api";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cloneDeep } from "lodash";
import { GET_CAT_DATA } from "../../../redux/constants";
const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "20px",
    color: theme.palette.secondary.main,
    marginBottom: "20px",
    fontWeight: 600,
  },
  endIcon: {
    marginLeft: "auto !important",
  },
  btnContainer: { width: "60%" },
  cancelBtn: {
    margin: "auto 0",
    textDecoration: "underline !important",
    fontFamily: theme.typography.fontFamily,
    fontSize: "16px",
  },
  dialogContent: {
    height: "500px",
  },
}));

const CategoryModal = ({ open, onClose, edit, name, id }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [catName, setCatName] = useState("");
  const { agencies } = useSelector((state) => state.auth.user);
  const cats = useSelector((state) => state.profile.cats);
  const dispatch = useDispatch();

  useEffect(() => {
    if (edit && id) {
      setCatName(() => name);
    } else {
      setCatName("");
    }
  }, [open]);

  const handleClick = async () => {
    setLoading(true);

    const aid = agencies && agencies.uuid;
    if (aid) {
      if (edit && id) {
        const res = await api(
          "agencies/category",
          { categoryId: id, name: catName },
          "put",
          "",
          "",
          "Category Name Updated!"
        );
        if (res && res.success) {
          const cd = cloneDeep(cats);
          for (let i = 0; i < cd.length; i++) {
            if (cd[i].uuid === id) {
              cd[i].name = catName;
              break;
            }
          }
          dispatch({ type: GET_CAT_DATA, payload: { data: cd } });
        }
      } else {
        const res = await api(
          "agencies/category",
          { agencyId: aid, name: catName },
          "post",
          "",
          "",
          "Category Created!"
        );
        if (res && res.success) {
          let cd = [];
          if (cats) {
            cd = cloneDeep(cats);
          }
          cd.push(res)
          dispatch({ type: GET_CAT_DATA, payload: { data: cd } });
        }
      }
    }
    setLoading(false);
    onClose();
  };
  return (
    <ModalContainer
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth={true}
    >
      <DialogContent className={classes.dialogContent}>
        <Grid container mt={2}>
          <Grid item lg={7} md={7} margin="auto" p={4} mt={11}>
            <div className={classes.title}>
              {edit ? "Edit" : "Add"} Category
            </div>
            <FormGroupTextField
              label="Category Name"
              value={catName}
              onChange={(e) => setCatName(e)}
            />
            <Stack direction="row" mt={3} gap="20px">
              <div className={classes.btnContainer}>
                <CustomButton
                  variant="contained"
                  size="large"
                  name={edit ? "Update" : "Add"}
                  endIcon={<ArrowForwardIos />}
                  fullWidth={true}
                  iconClass={classes.endIcon}
                  loading={loading}
                  onclick={() => (catName ? handleClick() : {})}
                />
              </div>
              <ButtonBase onClick={onClose} className={classes.cancelBtn}>
                Cancel
              </ButtonBase>
            </Stack>
          </Grid>
          <Grid item lg={5} md={5}>
            <img src={CatImg} alt={CatImg} height="383px" />
          </Grid>
        </Grid>
      </DialogContent>
    </ModalContainer>
  );
};

export default CategoryModal;
