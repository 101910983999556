import { Divider, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  divider: {
    backgroundColor: "#FFED71",
  },
}));
const PTHeader = ({ generalClass }) => {
  const classes = useStyles();

  return (
    <>
      <Grid item lg={4} md={3} sm={3} xs={3} className={generalClass.title}>
        Tool & Name
      </Grid>
      <Grid
        item
        lg={2}
        md={1.5}
        sm={1.5}
        xs={1.5}
        className={generalClass.title}
      >
        Template Used
      </Grid>
      <Grid
        item
        lg={2}
        md={2.5}
        sm={2.5}
        xs={2.5}
        className={generalClass.title}
      >
        Due
      </Grid>
      <Grid item lg={1} md={1} sm={1} xs={1} className={generalClass.title}>
        Lead
      </Grid>
      <Grid item lg={2} md={2} sm={2} xs={2} className={generalClass.title}>
        Status
      </Grid>
      <Grid item lg={1} md={2} sm={2} xs={2} className={generalClass.title}>
        Actions
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} mb={2} mt={2}>
        <Divider variant="fullWidth" className={classes.divider} />
      </Grid>
    </>
  );
};

export default PTHeader;
