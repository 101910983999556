import { Divider, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import { ReactComponent as MyLogo } from "../../../../assets/images/downloadIcon.svg";
import SelectButton from "../../../ui/button/SelectButton";
import { ToolType } from "../../../../utils/AllOptions";
const useStyles = makeStyles((theme) => ({
  divider: {
    backgroundColor: "#F19C7E",
  },
  icon: {
    fill: theme.palette.secondary.main,
    height: "24px",
    width: "24px",
  },
}));

const PDList = (props) => {
  const classes = useStyles();
  return (
    <>
      <Grid item lg={9} md={7} sm={7} xs={7} margin="auto 0">
        <div
          className={props.generalClass.res}
          style={{ cursor: "pointer" }}
          onClick={() => props?.downloadNow(props.file, props.fileType)}
        >
          {props.type === "tool"
            ? (props.toolType && ToolType[props.toolType]) || "-"
            : "Australia Post"}
        </div>
        <div className={props.generalClass.nameDate}>
          {props.type === "tool" ? props.toolName || "-" : props.rname || "-"}
        </div>
      </Grid>
      <Grid item lg={2} md={3} sm={3} xs={3} margin="auto 0">
        <div className={props.generalClass.provider}>
          {moment(props.dateCreated || "").format("D MMM YY")}
        </div>
        <div className={props.generalClass.nameDate}>
          {props.fileType || "-"} file
        </div>
      </Grid>
      <Grid item lg={1} md={2} sm={2} xs={2} margin="auto 0">
        <SelectButton
          title={<MyLogo className={classes.icon} />}
          dropClick={(e) => props.handleClick(e, props)}
          color="sufron"
          titleClick={() => props?.downloadNow(props.file, props.fileType)}
        />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} mb={2} mt={2}>
        <Divider variant="fullWidth" className={classes.divider} />
      </Grid>
    </>
  );
};

export default PDList;
