import { Box, Grid, Stack } from "@mui/material";
import WorkshopCategoryTableStakesImg from "../../../../../../../assets/images/workshop-category-table-stakes.png";
import { makeStyles } from "@mui/styles";
import FormGroupTextField from "../../../../../../ui/formgroup/FormGroupTextField";

const useStyle = makeStyles((theme) => ({
  imgc: {
    display: "block",
    margin: "auto",
    maxWidth: "50%",
  },
}));

const Step3 = (props) => {
  const classes = useStyle();

  return (
    <Stack>
      <Box>
        <Box color="secondary.main" fontSize="20px" fontWeight="800px">
          Your Category Table Stakes
        </Box>
        <Grid container pt={4}>
          <Grid item lg={7} md={8} sm={8} xs={8}>
            <Box marginTop={"30px !important"}>
              {" "}
              The overlap between your brand strengths, your customers' most important needs and your competitors' greatest strengths.{" "}
            </Box>
            <br />
            <Box>
              {" "}
              Are there any others that you think should be added to this list?{" "}
            </Box>
            <br />
            <br />
            <Stack maxHeight={"400px"} overflow="auto" gap={2} pr={2}>
              {props.categoryTableStakeData &&
                props.categoryTableStakeData.map((item, index) => (
                  <FormGroupTextField
                    key={item.uuid}
                    placeholder="Table stake"
                    type="text"
                    value={item.name}
                    onChange={(e) =>
                      props.handletableStakeData(index, "name", e)
                    }
                  />
                ))}
            </Stack>
            <br />
            <br />
            <Box>
              {" "}
              Category table stakes are the things that everyone is competing on. Even though they may be your strengths, these are the things your customers will expect you to deliver, but they will not provide a competitve advantage to your brand.{" "}
            </Box>
            <br />
          </Grid>
          <Grid item lg={5} md={4} sm={4} xs={4}>
            <img
              className={classes.imgc}
              src={WorkshopCategoryTableStakesImg}
              alt=""
            />
          </Grid>
        </Grid>
      </Box>
    </Stack>
  );
};

export default Step3;
