import { Avatar, ButtonBase, MenuItem } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { memo, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { logout } from "../../../redux/actions/auth/authActions";
import { PERMISSION_ERROR } from "../../../redux/constants";
import { profileRoutes } from "../../../utils/NavBarConstants";
import HeaderMenu from "../menus/HeaderMenu";

const sxObj = {
  mt: 1,
  width: "220px",
  borderRadius: "18px",
};

const useStyles = makeStyles((theme) => ({
  mainDiv: {
    margin: "auto",
    marginRight: "6px",
  },
  menuName: {
    fontSize: "12px",
    color: theme.palette.secondary.main,
    margin: "10px",
    fontFamily: theme.typography.fontFamily,
  },
  menuItem: {
    fontFamily: theme.typography.fontFamily,
    backgroundColor: "#F5F5F5 !important",
    margin: "6px !important",
    borderRadius: "10px !important",
    color: `${theme.palette.secondary.main} !important`,
    fontWeight: "00 !important",
  },

  avatar: {
    fontFamily: theme.typography.fontFamily,
    background: `${theme.palette.secondary.main} !important`,
    color: "white !important",
    fontSize: "16px !important",
  },
}));

const ProfileComponent = () => {
  const classes = useStyles();
  const history = useNavigate();
  const dispatch = useDispatch();

  const name = useSelector((state) => state?.auth?.user?.name, shallowEqual);
  const role = useSelector((state) => state?.auth?.user?.role, shallowEqual);
  const isadmin = useSelector(
    (state) => state.auth.user?.agencies?.admin,
    shallowEqual
  );

  const [nameToDisplay, setNameToDisplay] = useState("");
  const handleRouteChange = (path) => {
    if (
      path === "/profile/user-management"
        ? isadmin || (role && role.includes("USERS_MANAGE"))
        : true
    ) {
      history(path, { replace: false });
    } else toast.error(PERMISSION_ERROR);
    handleClose();
  };
  useEffect(() => {
    let twoLetter = "";
    if (name) {
      let [f, n] = name.split(" ");
      if (f) twoLetter += f[0];
      if (n) twoLetter += n[0];
    }
    setNameToDisplay(() => twoLetter);
  }, []);

  const [enchorEl, setEnchorEl] = useState(null);
  const [openP, setOpenP] = useState(false);

  const handleClick = (event) => {
    setEnchorEl(event.currentTarget);
    setOpenP(true);
  };

  const handleClose = () => {
    setEnchorEl(null);
    setOpenP(false);
  };

  return (
    <div className={classes.mainDiv}>
      <ButtonBase onClick={handleClick}>
        <Avatar classes={{ root: classes.avatar }}>
          {nameToDisplay.toUpperCase()}{" "}
        </Avatar>
      </ButtonBase>
      <HeaderMenu
        openP={openP}
        enchorEl={enchorEl}
        handleClose={handleClose}
        sxObj={sxObj}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <div className={classes.menuName}>{name}</div>
        {profileRoutes.map((e) => (
          <MenuItem
            key={e.name}
            className={classes.menuItem}
            onClick={() =>
              !e.btn ? handleRouteChange(e.path) : dispatch(logout())
            }
          >
            {e.name}
          </MenuItem>
        ))}
      </HeaderMenu>
    </div>
  );
};

export default memo(ProfileComponent);
