import { TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import FormGroupContainer from "./FormGroupContainer";

const useStyles = makeStyles((theme) => ({
  textField: {
    backgroundColor: "white !important",
    borderRadius: "10px !important",
    borderColor: "white !important",
    "& .MuiOutlinedInput-root": {
      borderRadius: "10px !important",
      borderColor: "white !important",
    },
    "& fieldset": {
      borderColor: "white",
      borderRadius: "10px !important",
    },
  },
  tf2: {
    backgroundColor: "#F5F5F5 !important",
    borderRadius: "10px !important",
    borderColor: "#F5F5F5 !important",
    "& .MuiOutlinedInput-root": {
      borderRadius: "10px !important",
      borderColor: "#F5F5F5 !important",
    },
    "& fieldset": {
      borderColor: "#F5F5F5",
      borderRadius: "10px !important",
    },
  },
}));

const FormGroupTextField = ({
  type,
  label,
  onChange,
  value,
  placeholder = "",
  tf = false,
  size = "medium",
  fullWidth = true,
  onBlur = () => {},
  validator = null,
  required = false,
  disabled = false,
  other = {},
  multiline,
  rows,
  mTop,
}) => {
  const classes = useStyles();

  return label ? (
    <FormGroupContainer label={label} required={required}>
      {" "}
      <TextField
        multiline={multiline}
        rows={rows}
        type={type}
        size={size}
        classes={{ root: tf ? classes.tf2 : classes.textField }}
        fullWidth={fullWidth}
        disabled={disabled}
        value={value}
        variant="outlined"
        placeholder={placeholder || label}
        onBlur={onBlur}
        color="secondary"
        onChange={(e) => onChange(e.target.value)}
        {...other}
      />
      {validator}
    </FormGroupContainer>
  ) : (
    <TextField
      style={{ marginTop: mTop }}
      multiline={multiline}
      rows={rows}
      type={type}
      size={size}
      classes={{ root: tf ? classes.tf2 : classes.textField }}
      fullWidth={fullWidth}
      disabled={disabled}
      value={value}
      variant="outlined"
      placeholder={placeholder || label}
      onBlur={onBlur}
      color="secondary"
      onChange={(e) => onChange(e.target.value)}
      {...other}
    />
  );
};

export default FormGroupTextField;
