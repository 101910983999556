import { Box, Divider, Grid, useTheme } from "@mui/material";
import CustomButton from "../../ui/button/CustomButton";
import { AddCircleOutlineRounded as AddCircleOutlineRoundedIcon } from "@mui/icons-material";

const TRTitle = ({ title, onclick, nmt }) => {
  const scolor = useTheme().palette.primary.main;

  return (
    <>
      <Grid item lg={12} md={12} sm={12} xs={12} mt={nmt ? 0 : 6} mb={4}>
        {" "}
        <Box
          fontWeight="400"
          display="flex"
          fontSize="28px"
          color="secondary.main"
          gap="14px"
        >
          <Box m="auto 0">{title}</Box>
          <Box>
            <CustomButton
              onclick={onclick}
              name="Add"
              size="medium"
              classNameI="pink"
              endIcon={<AddCircleOutlineRoundedIcon />}
            />
          </Box>
        </Box>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} mb={2}>
        <Divider
          variant="fullWidth"
          sx={{
            backgroundColor: scolor || "",
          }}
        />
      </Grid>
    </>
  );
};

export default TRTitle;
