import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import Loader from "../../../components/ui/loader/Loader";
import PaymentModal from "../../../components/ui/modals/PaymentModal";
import useBindActionCreator from "../../../hooks/useBindActionCreator";
import {
  getProjectListData,
  getSubsDetails,
} from "../../../redux/actions/client/ClientAction";
import { api } from "../../../redux/api/api";
import { setHTitle } from "../../../utils/SetHeaderFunction";
const PaymentPage = ({ rejectPage = false }) => {
  const nav = useNavigate();
  const agencyId = useSelector((state) => state.auth.user?.agencies?.uuid);

  const checkCid = useSelector((state) => state.client.clientId?.id);
  const actions = useBindActionCreator({ getProjectListData, getSubsDetails });

  const [modalData, setModalData] = useState("");
  const [resModal, setResModal] = useState(false);
  const [payModal, setPayModal] = useState(false);

  const getSubDetails = () => {
    if (agencyId) actions.getSubsDetails(agencyId);
  };
  const forCancel = (research) => {
    nav(research ? "/research/australia-post" : "/", { replace: true });
    clearStorage();
  };
  const checkForRes = async () => {
    try {
      const d = localStorage.getItem("resData");
      const data = d ? JSON.parse(d) : "";
      if (!data) return forCancel(true);
      const res1 = await api(
        `payments/research-sessions/${localStorage.getItem(
          "sessionId"
        )}/validate`,
        {
          researchId: data?.rid || "",
          projectId: data?.pid || "",
          type: data?.researchType || "",
        },
        "post",
        "",
        "",
        "Payment Success!"
      );
      if (!res1 || !res1.success) return forCancel();
      if (checkCid && data?.clientId && checkCid === data?.clientId) {
        await actions.getProjectListData(checkCid);
      }
      setModalData(() => data);
      setPayModal(() => false);
      setResModal(() => true);
      clearStorage();
      nav(`/research/view-research/${data?.rid}`, { replace: true });
    } catch (error) {
      forCancel();
    }
  };
  const checkForPay = async () => {
    try {
      const res = await api(
        `payments/subscription-sessions/${localStorage.getItem(
          "sessionId"
        )}/validate`,
        { agencyId: agencyId },
        "post",
        "",
        "",
        "Payment Success!"
      );
      if (res && res.success) {
        setPayModal(() => true);
        setResModal(() => true);
        getSubDetails();
        clearStorage();
        nav("/", { replace: true });
      } else {
        forCancel();
      }
    } catch (error) {
      clearStorage();
    }
  };
  const clearStorage = () => {
    localStorage.removeItem("res");
    localStorage.removeItem("sessionId");
    localStorage.removeItem("resData");
    localStorage.removeItem("pay");
  };
  useEffect(() => {
    setHTitle("", false);
    if (rejectPage) {
      toast.error("Payment failed");
      forCancel();
    } else {
      if (localStorage.getItem("sessionId")) {
        if (
          localStorage.getItem("res") &&
          localStorage.getItem("res") === "true"
        ) {
          checkForRes();
        } else if (
          localStorage.getItem("pay") &&
          localStorage.getItem("pay") === "true"
        ) {
          checkForPay();
        } else {
          forCancel();
        }
      } else {
        forCancel();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Loader />
      <PaymentModal
        open={modalData && resModal}
        onClose={() => {
          setResModal(false);
          nav("/", { replace: true });
        }}
        modalData={modalData}
        pay={payModal}
      />
    </>
  );
};

export default PaymentPage;
