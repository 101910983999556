import { Box, Stack } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import CustomButton from "../../../../../ui/button/CustomButton";
import FormGroupTextField from "../../../../../ui/formgroup/FormGroupTextField";
import TextAutoResize from "../../../../../ui/formgroup/TextAutoResize";

const Considerations = (props) => {
  const [considerations, setConsiderations] = useState("");
  const data = props?.td?.data;
  useEffect(() => {
    if (
      data &&
      data.considerations &&
      typeof data.considerations === "string"
    ) {
      setConsiderations(data.considerations);
    }
  }, [props]);
  return (
    <>
      <Stack direction="row" gap="30px">
        <Box flex="0.2" display="flex" gap="10px" flexDirection="column">
          <Box color="secondary.main" fontSize="20px" fontWeight="800px">
            What Are The Considerations?
          </Box>
          <Box mb={2} fontSize="14px">
            What are the mandatory elements for creative?
            <br />
            <br />
            Which communication channels are likely to be most effective in
            addressing this brief?
            <br />
            <br />
            (Based upon the problem / opportunity and insights about the target
            audience what channels of communication will be most effective in
            influencing the target audience)
          </Box>
        </Box>
        <Box flex="0.8">
          <TextAutoResize
            other={{ minRows: 6, maxRows: 6 }}
            value={considerations}
            onChange={(e) => setConsiderations(e)}
          />
        </Box>
      </Stack>
      <Box display="flex" justifyContent="space-between" mt="auto" mb="0">
        <CustomButton
          name="<   Prev"
          onclick={() => props.callApi({ considerations }, true, false)}
          loading={props.btnLoading}
        />
        <CustomButton
          name="Next   >"
          disabled={!considerations}
          onclick={() => props.callApi({ considerations }, true, true)}
          loading={props.btnLoading}
        />
      </Box>
    </>
  );
};

export default Considerations;
