import { makeStyles } from "@mui/styles";
import { Delete } from "@mui/icons-material";
import { Box } from "@mui/material";

const useStyles = makeStyles((theme) => {
  return {
    imgDel: {
      backgroundColor: "#D40841",
      width: "24px",
      height: "24px",
      padding: "8px",
      borderRadius: "10px",
      color: "white",
      cursor: "pointer",
    },
  };
});

const DeleteButton = ({ className, onclick = () => {}, container = true }) => {
  const classes = useStyles();

  return (
    <Box
      className={container ? className || classes.imgDel : ""}
      onClick={onclick}
      cursor="pointer"
    >
      <Delete
        color={!container ? "secondary" : ""}
        fontSize={container ? "medium" : "large"}
      />
    </Box>
  );
};

export default DeleteButton;
