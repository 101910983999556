import { Box, Stack } from "@mui/material";
import { orderBy } from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CustomButton from "../../../../../../ui/button/CustomButton";
import { v4 as uuidv4 } from "uuid";
import OpeningScreen from "../../OpeningScreen";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";
import Step6 from "./Step6";
import Step7 from "./Step7";
import Step8 from "./Step8";
import Step9 from "./Step9";
const headerText = [
  "",
  "What Are Your Brand Strengths?",
  "What Are Your Competitors Really Good At?",
  "Selecting Competitor Mapping Criteria",
  "Competitor Positioning Matrix A",
  "Competitor Positioning Matrix B",
  "Competitor Positioning Matrix C",
  "Competitor Positioning Matrix D",
  <span>
    Competitor Positioning Matrix <br />A & B
  </span>,
  <span>
    Competitor Positioning Matrix <br />C & D
  </span>,
];
const CompMaping = (props) => {
  const projectData = useSelector(
    (state) => state.project?.project?.competitors
  );
  const clientListing = useSelector((state) => state.project.clients);
  const pData = useSelector((state) => state.project?.project);

  const [brandStrengths, setBrandStrengths] = useState([]);
  const [competitorStrengths, setCompetitorStrengths] = useState([]);
  const [competitorPositioningAxes, setCompetitorPositioningAxes] = useState(
    []
  );
  const [competitorPositioningMatrixA, setCompetitorPositioningMatrixA] =
    useState([]);
  const [competitorPositioningMatrixB, setCompetitorPositioningMatrixB] =
    useState([]);
  const [competitorPositioningMatrixC, setCompetitorPositioningMatrixC] =
    useState([]);
  const [competitorPositioningMatrixD, setCompetitorPositioningMatrixD] =
    useState([]);

  const [includeProjectBrandInExercises, setIncludeProjectBrandInExercises] =
    useState(false);
  const [clientData, setClientData] = useState({
    logo: "",
    name: "",
    uuid: "",
  });
  const [step, setStep] = useState(0);

  const updateCompetior = async (n) => {
    const f =
      (competitorPositioningAxes &&
        competitorPositioningAxes.length &&
        competitorPositioningAxes.filter((a) => a.checked).length) ||
      0;

    switch (step) {
      case 0: {
        if (n) {
          setStep(1);
        } else {
          props.prvStep();
        }
        break;
      }
      case 1: {
        const res = await props.callApi({ brandStrengths: brandStrengths });
        if (res) {
          if (n) setStep(2);
          else setStep(0);
        }
        break;
      }
      case 2: {
        const res = await props.callApi({
          competitorStrengths: competitorStrengths,
        });
        if (res) {
          if (n) {
            const allComp = [
              ...competitorStrengths.filter((a) => a.loaded),
              ...brandStrengths.filter((a) => a.loaded),
            ];

            const newD = [];
            const propsData =
              props?.td?.data?.competitorPositioningAxes &&
              props.td.data.competitorPositioningAxes.length
                ? props.td.data.competitorPositioningAxes
                : [];
            if (propsData && propsData.length) {
              for (let i = 0; i < propsData.length; i++) {
                const findPropIdx = allComp.findIndex(
                  (a) => a.uuid === propsData[i].uuid
                );
                if (
                  findPropIdx !== -1 &&
                  allComp[findPropIdx].name &&
                  typeof allComp[findPropIdx].name === "string"
                )
                  newD.push({
                    ...propsData[i],
                    name: allComp[findPropIdx].name,
                  });
              }
              for (let i = 0; i < allComp.length; i++) {
                const compIdx = propsData.findIndex(
                  (a) => a.uuid === allComp[i].uuid
                );
                if (
                  compIdx === -1 &&
                  allComp[i].name &&
                  typeof allComp[i].name === "string"
                )
                  newD.push(allComp[i]);
              }
            } else {
              newD.push(...allComp);
            }

            setCompetitorPositioningAxes(
              newD.map((a) => (a.score ? a : { ...a, score: 0 }))
            );
            setStep(3);
          } else {
            setStep(1);
          }
        }
        break;
      }
      case 3: {
        const ordBy = orderBy(
          competitorPositioningAxes || [],
          (o) => (o.score ? o.score : 0),
          "desc"
        );
        const res = await props.callApi({
          competitorPositioningAxes: ordBy,
        });
        if (res) {
          setCompetitorPositioningAxes(ordBy);
          if (n) {
            const data = props.td?.data?.competitorPositioningMatrixA || [];
            const data2 = arrangeContainer(data);
            setCompetitorPositioningMatrixA(data2 || []);
            setStep(4);
          } else {
            setStep(2);
          }
        }
        break;
      }
      case 4: {
        const res = await props.callApi({
          competitorPositioningMatrixA: competitorPositioningMatrixA,
        });
        if (res) {
          if (n) {
            const data = props.td?.data?.competitorPositioningMatrixB || [];
            const data2 = arrangeContainer(data);
            setCompetitorPositioningMatrixB(data2 || []);
            setStep(5);
          } else {
            setStep(3);
          }
        }
        break;
      }
      case 5: {
        const res = await props.callApi({
          competitorPositioningMatrixB: competitorPositioningMatrixB,
        });
        if (res) {
          if (n) {
            if (f > 4) {
              const data = props.td?.data?.competitorPositioningMatrixC || [];
              const data2 = arrangeContainer(data);
              setCompetitorPositioningMatrixC(data2 || []);
              setStep(6);
            } else setStep(8);
          } else {
            setStep(4);
          }
        }
        break;
      }
      case 6: {
        const res = await props.callApi({
          competitorPositioningMatrixC: competitorPositioningMatrixC,
        });
        if (res) {
          if (n) {
            if (f > 6) {
              const data = props.td?.data?.competitorPositioningMatrixD || [];
              const data2 = arrangeContainer(data);
              setCompetitorPositioningMatrixD(data2 || []);
              setStep(7);
            } else setStep(8);
          } else {
            setStep(5);
          }
        }
        break;
      }
      case 7: {
        const res = await props.callApi({
          competitorPositioningMatrixD: competitorPositioningMatrixD,
        });
        if (res) {
          if (n) {
            setStep(8);
          } else {
            setStep(6);
          }
        }
        break;
      }
      case 8: {
        if (n) {
          if (f > 4) {
            setStep(9);
          } else {
            props.nextStep();
          }
        } else {
          if (f > 6) {
            setStep(7);
          } else if (f > 4) {
            setStep(6);
          } else {
            setStep(5);
          }
        }
        break;
      }
      case 9: {
        if (n) {
          props.nextStep();
        } else {
          setStep(8);
        }
        break;
      }
      default:
        break;
    }
  };

  useEffect(() => {
    if (clientListing && clientListing.length && pData && pData.clientId) {
      const findClient = clientListing.find((el) => el.uuid === pData.clientId);
      if (findClient) {
        setClientData({
          logo: findClient?.settings?.branding?.img,
          name: findClient?.name,
          uuid: findClient?.uuid,
        });
      }
    }
  }, [clientListing, pData]);

  useEffect(() => {
    if (props.td && props.td.data) {
      let n3 = 5;
      let brandStrengthsArray = [];
      let competitorStrengthsArray = [];

      if (props.td && props.td.data && props.td.data.brandStrengths === null) {
        for (let i = 1; i <= n3; i++) {
          brandStrengthsArray.push({
            name: "",
            logo: "",
            uuid: uuidv4(),
            loaded: false,
          });
        }
      } else {
        let tData = props.td && props.td.data && props.td.data.brandStrengths;
        brandStrengthsArray = tData;
        let len = tData && tData.length;
        for (let i = 1; i <= n3 - len; i++) {
          brandStrengthsArray.push({
            name: "",
            logo: "",
            uuid: uuidv4(),
            loaded: false,
          });
        }
      }
      setBrandStrengths(brandStrengthsArray);

      if (
        props.td &&
        props.td.data &&
        props.td.data.competitorStrengths === null
      ) {
        for (let i = 1; i <= n3; i++) {
          competitorStrengthsArray.push({
            name: "",
            logo: "",
            uuid: uuidv4(),
            loaded: false,
          });
        }
      } else {
        let tData =
          props.td && props.td.data && props.td.data.competitorStrengths;
        competitorStrengthsArray = tData;
        let len = tData && tData.length;
        for (let i = 1; i <= n3 - len; i++) {
          competitorStrengthsArray.push({
            name: "",
            logo: "",
            uuid: uuidv4(),
            loaded: false,
          });
        }
      }
      setCompetitorStrengths(competitorStrengthsArray);

      setIncludeProjectBrandInExercises(
        props?.td?.data?.includeProjectBrandInExercises || false
      );
    }
  }, [props.td]);
  const getAllCompetitor = () => {
    const a = [];
    if (includeProjectBrandInExercises) {
      a.push({
        client: true,
        differentiator1: 1,
        differentiator2: 1,
        logo: clientData.logo,
        name: clientData.name,
        uuid: clientData.uuid,
      });
    }
    if (projectData && projectData.length) {
      const b = projectData.filter((e) => e.loaded) || [];
      a.push(...b);
    }
    return a;
  };
  const arrangeContainer = (matrix = []) => {
    const compData = getAllCompetitor();
    const newD = [];
    if (matrix && matrix.length) {
      for (let i = 0; i < matrix.length; i++) {
        const findPropIdx = compData.findIndex(
          (a) => a.uuid === matrix[i].uuid
        );
        if (
          findPropIdx !== -1 &&
          compData[findPropIdx].name &&
          typeof compData[findPropIdx].name === "string"
        )
          newD.push({ ...matrix[i], name: compData[findPropIdx].name });
      }
      for (let i = 0; i < compData.length; i++) {
        const compIdx = matrix.findIndex((a) => a.uuid === compData[i].uuid);
        if (
          compIdx === -1 &&
          compData[i].name &&
          typeof compData[i].name === "string"
        )
          newD.push(compData[i]);
      }
    } else {
      newD.push(...compData);
    }
    return newD;
  };
  const checkDisable = () => {
    let disable = false;
    if (step === 3) {
      const f = competitorPositioningAxes.filter((a) => a.checked).length;
      if (
        competitorPositioningAxes &&
        competitorPositioningAxes.length &&
        f >= 4 &&
        f <= 8 &&
        f % 2 === 0
      ) {
        disable = false;
      } else {
        disable = true;
      }
    } else if (step === 2) {
      if (
        competitorStrengths &&
        competitorStrengths.length &&
        competitorStrengths.filter((a) => a.loaded).length >= 3
      ) {
        disable = false;
      } else {
        disable = true;
      }
    } else if (step === 1) {
      if (
        brandStrengths &&
        brandStrengths.length &&
        brandStrengths.filter((a) => a.loaded).length >= 3
      ) {
        disable = false;
      } else {
        disable = true;
      }
    }
    return disable;
  };
  const handleCompSelect = () => {
    switch (step) {
      case 0:
        return (
          <OpeningScreen
            img=""
            title=""
            description=""
            bestPratice=""
            bestEx=""
          />
        );
      case 1:
        return (
          <Step1
            brandStrengths={brandStrengths}
            setBrandStrengths={(data) => setBrandStrengths(data)}
          />
        );
      case 2:
        return (
          <Step2
            competitorStrengths={competitorStrengths}
            setCompetitorStrengths={(data) => setCompetitorStrengths(data)}
          />
        );
      case 3:
        return (
          <Step3
            competitorPositioningAxes={competitorPositioningAxes}
            setCompetitorPositioningAxes={(data) =>
              setCompetitorPositioningAxes(data)
            }
          />
        );
      case 4:
        return (
          <Step4
            competitorPositioningMatrixA={competitorPositioningMatrixA}
            setCompetitorPositioningMatrixA={(data) =>
              setCompetitorPositioningMatrixA(data)
            }
            includeProjectBrandInExercises={includeProjectBrandInExercises}
            setIncludeProjectBrandInExercises={(d) => {
              setIncludeProjectBrandInExercises(d);
              props.callApi({
                includeProjectBrandInExercises: d,
              });
            }}
            competitorPositioningAxes={competitorPositioningAxes}
            clientData={clientData}
          />
        );
      case 5:
        return (
          <Step5
            competitorPositioningMatrixB={competitorPositioningMatrixB}
            setCompetitorPositioningMatrixB={(data) =>
              setCompetitorPositioningMatrixB(data)
            }
            competitorPositioningAxes={competitorPositioningAxes}
          />
        );
      case 6:
        return (
          <Step6
            competitorPositioningMatrixC={competitorPositioningMatrixC}
            setCompetitorPositioningMatrixC={(data) =>
              setCompetitorPositioningMatrixC(data)
            }
            competitorPositioningAxes={competitorPositioningAxes}
          />
        );
      case 7:
        return (
          <Step7
            competitorPositioningMatrixD={competitorPositioningMatrixD}
            setCompetitorPositioningMatrixD={(data) =>
              setCompetitorPositioningMatrixD(data)
            }
            competitorPositioningAxes={competitorPositioningAxes}
          />
        );
      case 8:
        return (
          <Step8
            competitorPositioningMatrixA={competitorPositioningMatrixA}
            competitorPositioningMatrixB={competitorPositioningMatrixB}
            competitorPositioningAxes={competitorPositioningAxes}
          />
        );
      case 9: {
        const f =
          (competitorPositioningAxes &&
            competitorPositioningAxes.length &&
            competitorPositioningAxes.filter((a) => a.checked).length) ||
          0;
        return (
          <Step9
            competitorPositioningMatrixC={competitorPositioningMatrixC}
            competitorPositioningMatrixD={competitorPositioningMatrixD}
            competitorPositioningAxes={competitorPositioningAxes}
            displayGraphLast={f === 8}
          />
        );
      }
      default:
        return <></>;
    }
  };
  return (
    <>
      {step === 0 ? (
        <OpeningScreen
          img={props?.sas?.img || ""}
          title="Competitor Mapping"
          description="Competitor maps show how brands are different from each other and allow you to identify a unique space for your brand. It will also show areas of risk and vulnerability."
          bestPratice=""
          bestEx=""
        />
      ) : (
        <Stack direction="row" gap="30px">
          <Box flex="0.2" display="flex" gap="10px" flexDirection="column">
            <Box color="secondary.main" fontSize="20px" fontWeight="800px">
              {headerText[step]}
            </Box>
            <Box mb={2} fontSize="14px">
              Competitor maps show how brands are different from each other and
              allow you to identify a unique space for your brand. It will also
              show areas of risk and vulnerability.
            </Box>
          </Box>
          <Box flex="0.8">{handleCompSelect()}</Box>
        </Stack>
      )}
      <Box display="flex" justifyContent="space-between" mt="auto" mb="0">
        <CustomButton
          name="<   Prev"
          onclick={() => updateCompetior(false)}
          loading={props.btnLoading}
        />
        <CustomButton
          name="Next   >"
          disabled={checkDisable()}
          onclick={() => updateCompetior(true)}
          loading={props.btnLoading}
        />
      </Box>
    </>
  );
};

export default CompMaping;
