import { Grid, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/styles";

import RoyMorgan from "../../../assets/images/RoyMorgan.svg";
import AustrailaPost from "../../../assets/images/AustrailaPost.svg";
import Smrtr from "../../../assets/images/Smrtr.svg";
import DIY from "../../../assets/images/DIY.png";
import ABS from "../../../assets/images/ABS.png";
import Shopfully from "../../../assets/images/Shopfully.png";
import Pure from "../../../assets/images/Purepng.png";
import Fonto from "../../../assets/images/fonto.svg";
import Nels from "../../../assets/images/nielsen-vector-logo.svg";
import RCard from "./RCard";
import RTitle from "./RTitle";
import ExternalRes from "./externalRes/ExternalRes";
import ExstingRes from "./exstingRes/ExstingRes";
import CommonTitle from "../../common/CommonTitle";
import { useLocation } from "react-router";
import NielsenEnquiryModel from "../../ui/modals/NielsenEnquiryModel";
import { useState } from "react";
import PureSpectrumEnquiryModel from "../../ui/modals/PureSpectrumEnquiryModel";

const researchData = [
  {
    title: "Smrtr",
    subTitle: "Coming soon",
    desc: "Simplicity, automation, and the industry's first and only respondent-leve scoring system, PureScore™.",
    img: Smrtr,
    commingSoon: true,
    btn: "View",
    viewPath: "view-smrtr",
  },
  {
    title: "Australia Post",
    subTitle: "View",
    desc: "Access free Australia Post insights and reports. These reports unpack huge volumes of data to understand how Australians shop online. They look at consumer shopper habits and give you practical tips.",
    img: AustrailaPost,
    btn: "View",
    commingSoon: false,
    viewPath: "australia-post",
  },
  {
    title: "Fonto",
    subTitle: "Free Interactive Dashboards",
    desc: "A unique consumer-driven data ecosystem that brings accuracy, speed and insight to business decision making.",
    img: Fonto,
    commingSoon: false,
    btn: "View",
    viewPath: "fonto",
  },
  {
    title: "Pure Spectrum",
    subTitle: "Enquire Now",
    desc: "Simplicity, automation, and the industry's first and only respondent-leve scoring system, PureScore™.",
    img: Pure,
    btn: "Send enquiry",
    commingSoon: false,
    viewPath: "",
  },
  {
    title: "My Own Research",
    subTitle: "Free to upload",
    desc: "Pre-fill market data yourself based on your own research OR upload your own slides, including other data providers.",
    img: DIY,
    btn: "Add",
    commingSoon: false,
    viewPath: "add-dyi",
  },
  {
    title: "ABS",
    subTitle: "FREE",
    desc: "Search and browse datasets, filter and view data and automatically generate APl calls based on your selection.",
    img: ABS,
    btn: "View",
  },
  {
    title: "Shopfully",
    subTitle: "FREE",
    desc: "ShopFully is the leading tech company in developing digital solutions that generate traffic in physical stores.",
    img: Shopfully,
    btn: "View",
    viewPath: "view-shopfully",
  },
  {
    title: "Nielsen",
    subTitle: "Enquire Now",
    desc: "Nielsen fuels the media industry with th most accurate understanding of what people listen to and watch.",
    img: Nels,
    btn: "Send enquiry",
    commingSoon: false,
    viewPath: "",
  }
];

const RRight = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("lg"));
  const loc = useLocation().pathname;
  const projectResearchPathName = "/projects/research";
  const [showNielsenModal, setShowNielsenModal] = useState(false);
  const [showSpectrumModal, setShowSpectrumModal] = useState(false);
  return (
    <>
      <Grid
        pt={loc === projectResearchPathName ? "4rem" : "0px"}
        mt={loc === projectResearchPathName ? "0px" : "8px"}
        pl={matches ? 4 : 6}
        container
        item
        lg={loc === projectResearchPathName ? 10 : 10.8}
        md={10}
        sm={10}
        xs={10}
        textAlign="left"
      >
        {loc === projectResearchPathName && <CommonTitle />}
        <RTitle />
        {researchData.map((a) => (
          <RCard
            {...a}
            key={a.title}
            onBtnClick={() =>
              a.title === "Pure Spectrum"
                ? setShowSpectrumModal(true)
                : a.title === "Nielsen"
                ? setShowNielsenModal(true)
                : a.title === "ABS"
                ? window.open("https://explore.data.abs.gov.au/", "_blank")
                : ""
            }
          />
        ))}
        <ExternalRes />
        <ExstingRes />
      </Grid>
      <NielsenEnquiryModel
        open={showNielsenModal}
        onClose={() => setShowNielsenModal(false)}
      />
      <PureSpectrumEnquiryModel
        open={showSpectrumModal}
        onClose={() => setShowSpectrumModal(false)}
      />
    </>
  );
};

export default RRight;
