import { Grid, Stack, useMediaQuery, useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import { Outlet } from "react-router";
import CommonTitle from "../common/CommonTitle";
import PrivateSidebar from "../layout/PrivateSidebar";
import RComment from "../research/RComment";
import Loader from "../ui/loader/Loader";

const ProjectSettingsComponent = () => {
  const loader = useSelector((state) => state.project.loading);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <>
      <Grid container>
        <PrivateSidebar />
        <Grid
          pt={"4rem !important"}
          pl={matches ? 4 : 6}
          item
          lg={10}
          md={10}
          sm={10}
          xs={10}
          textAlign="left"
        >
          <Stack>
            <CommonTitle />
            {loader ? <Loader /> : <Outlet />}
          </Stack>
        </Grid>
        <RComment />
      </Grid>
    </>
  );
};

export default ProjectSettingsComponent;
