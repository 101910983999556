import { Box, DialogContent, Divider, Grid, Stack } from "@mui/material";
import CustomButton from "../button/CustomButton";
import ModalContainer from "./ModalContainer";
import { ArrowForwardIos } from "@mui/icons-material";
import { plans } from "../../../utils/AllOptions";
import { useState } from "react";
import CustomCheckBox from "../checkbox/CustomCheckBox";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { api } from "../../../redux/api/api";
import { config } from "../../../config";
import { logout, setToken } from "../../../redux/actions/auth/authActions";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(config.stripePublishableKey);

const SubscriptionModal = ({
  open,
  getSubs = () => {},
  onClose = () => {},
  closable = false,
}) => {
  const dispatch = useDispatch();

  const rtoken = useSelector(
    (state) => state.auth?.token?.refreshToken,
    shallowEqual
  );
  const agencyId = useSelector(
    (state) => state?.auth?.user?.agencies?.uuid,
    shallowEqual
  );
  const package_details = useSelector(
    (state) => state?.client?.package_details,
    shallowEqual
  );

  const [month, setMonth] = useState(false);
  const [selPlan, setSelPlan] = useState("");
  const [loading, setLoading] = useState(false);

  const handeSub = async () => {
    try {
      setLoading(true);
      if (selPlan !== "BASIC") {
        const data = {
          monthlyBilling: month,
          plan: selPlan,
          source: "agency",
          uuid: agencyId,
        };
        const res = await api(`payments/subscription-sessions`, data, "post");
        if (res && res.success && res.id) {
          setLoading(false);
          localStorage.setItem("sessionId", res.id);
          localStorage.setItem("pay", true);
          localStorage.removeItem("res");
          const stripe = await stripePromise;
          await stripe.redirectToCheckout({ sessionId: res.id });
        }
      } else {
        const res = await api(
          `first-agency-actions/${rtoken}`,
          { actions: [] },
          "put"
        );
        if (res && res.success && res.token && res.refreshToken) {
          dispatch(
            setToken({ token: res.token, refreshToken: res.refreshToken })
          );
        }
        await getSubs();
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const cancelSub = async () => {
    setLoading(true);
    const res = await api(
      `payments/subscriptions/${agencyId}`,
      {},
      "delete",
      "",
      "",
      "Subscription cancelled successfully"
    );
    if (res && res.success) {
      dispatch({
        type: "GET_SUBS_DETAILS",
        payload: { data: res.subscription },
      });
      onClose();
    }
    setLoading(false);
  };

  return (
    <ModalContainer
      open={open}
      onClose={closable ? onClose : () => {}}
      maxWidth="xl"
      title="Plans for marketers & agencies of every size"
      fullWidth={true}
      classPrimary={true}
      titleFontSize="26px"
    >
      <DialogContent
        style={{
          height: "782px",
        }}
      >
        <Grid container mt={2}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box display="flex">
              <Box ml="auto" mr="0">
                <Stack direction="row" gap={2} alignItems="center">
                  {!package_details?.freeTrial &&
                    package_details?.willRenew &&
                    package_details?.cancelDate === null && (
                      <CustomButton
                        variant="contained"
                        size="small"
                        name="Cancel subscription"
                        fullWidth={false}
                        loading={loading}
                        onclick={() => cancelSub()}
                      />
                    )}
                  {!package_details?.freeTrial &&
                    !package_details?.willRenew &&
                    package_details?.cancelDate && (
                      <Box>Your plan is cancelled</Box>
                    )}
                  <CustomCheckBox
                    label="Monthly billing"
                    checked={month}
                    onchange={(e) => setMonth(e)}
                  />
                </Stack>
              </Box>
            </Box>
            <Stack
              direction="row"
              mb={2}
              gap="16px"
              flexWrap="wrap"
              justifyContent="center"
            >
              {plans.map((a) => (
                <Box
                  style={{
                    background:
                      selPlan === a.id
                        ? "#1D2D91"
                        : package_details?.plan === a.id
                        ? "#1D2D91"
                        : "",
                  }}
                  borderRadius="10px"
                  border={
                    selPlan === a.id
                      ? "3px solid #1D2D91"
                      : package_details?.plan === a.id
                      ? "3px solid #1D2D91"
                      : ""
                  }
                  key={a.id}
                >
                  <Box color="white" p={1} textAlign="center">
                    {selPlan === a.id
                      ? "SELECTED PLAN"
                      : package_details?.plan === a.id
                      ? "YOUR CURRENT PLAN"
                      : ""}
                  </Box>
                  <Box
                    width="250px"
                    height="512px"
                    style={{ background: "white" }}
                    borderRadius="10px"
                    textAlign="center"
                    padding="10px"
                    pt={2}
                    key={a.name}
                    onClick={() =>
                      !a.connect && !a.free
                        ? // ||  (a.free && !package_details?.plan)
                          setSelPlan(a.id)
                        : null
                    }
                  >
                    <Box
                      mb={1}
                      fontSize="18px"
                      fontWeight="600"
                      color="secondary.main"
                      height="24px"
                    >
                      {a.name}
                    </Box>
                    <Box fontSize="14px" mb={1} height="50px">
                      {a.desc}
                    </Box>
                    <Divider />
                    <Box
                      color="secondary.main"
                      fontWeight="600"
                      mt={1}
                      height="25px"
                    >
                      {a.connect ? "" : "AU$"}
                      <span
                        style={{
                          fontSize: "24px",
                        }}
                      >
                        {month ? a.price2 : a.price}
                      </span>
                    </Box>
                    <Box
                      fontSize="14px"
                      color="secondary.main"
                      mt={1}
                      mb={1}
                      height="50px"
                      fontWeight="600"
                    >
                      {a.free || a.connect ? (
                        <>
                          All the bells and
                          <br />
                          whistles PLUS
                        </>
                      ) : month ? (
                        `Billed monthly, or
                      AU${a.price} per month
                      billed annually`
                      ) : (
                        `Per month billed
                        annually, or AU$${a.price2}
                        billed monthly`
                      )}
                    </Box>
                    {a.btnText && (
                      <Box mb={2}>
                        <CustomButton
                          primary={true}
                          name={a.btnText}
                          size="large"
                          onclick={() => window.open(a.btnUrl)}
                        />
                      </Box>
                    )}
                    {a.features.map((f) => (
                      <Box mb={1} fontSize="14px">
                        {f}
                      </Box>
                    ))}
                  </Box>
                </Box>
              ))}
            </Stack>
            <Divider />
            <Stack justifyContent="space-between" direction="row" mt={2}>
              <Box>
                {/* <Box fontSize="18px" fontWeight="600" color="secondary.main">
                  Featuring Roy Morgan and Australia Post e-commerce <br />
                  insights data and Roy Morgan Helix personas*
                </Box> */}
                <Box fontSize="14px" color="secondary.main" mt={2}>
                  *Where market data is available.
                  <br />
                  Costs may incur depending on research provider.
                  <br />
                  Research is based on the Australian market only.
                  <br />
                  Price per project.
                </Box>
              </Box>
              <Box display="flex">
                <Box>
                  {closable ? (
                    <CustomButton
                      variant="text"
                      size="large"
                      name="Close"
                      onclick={() => onClose()}
                    />
                  ) : (
                    <CustomButton
                      variant="text"
                      size="large"
                      name="Logout"
                      onclick={() => dispatch(logout())}
                    />
                  )}
                </Box>
                <Box>
                  <CustomButton
                    variant="contained"
                    size="large"
                    name="Subscribe"
                    disabled={!selPlan}
                    endIcon={<ArrowForwardIos />}
                    fullWidth={true}
                    iconClass={"mla"}
                    loading={loading}
                    onclick={() => handeSub()}
                  />
                </Box>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </DialogContent>
    </ModalContainer>
  );
};

export default SubscriptionModal;
