import { Box, Dialog, DialogTitle, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Close as CloseIcon } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  modal: {
    fontFamily: theme.typography.fontFamily,
    backgroundColor: "rgba(255,255,255,0.4) !important",
  },
  paperClass: {
    backgroundColor: `${theme.palette.primary.main} !important`,
  },
  paperClass2: {
    backgroundColor: `#F5F5F5 !important`,
  },
  paperClass3: {
    backgroundColor: "#FAF7E4 !important",
    border: "2px solid #FFED71 !important",
  },
  approvalModal: {
    backgroundColor: "#B5E0E0 !important",
  }
}));

const ModalContainer = (props) => {
  const classes = useStyles();
  return (
    <Dialog
      className={classes.modal}
      classes={{
        paper: props.yellowBack
          ? classes.paperClass3
          : props.classPrimary
          ? classes.paperClass
          : classes.paperClass2,
      }}
      open={props.open}
      fullWidth={props.fullWidth}
      onClose={props.onClose}
      maxWidth={props.maxWidth}
    >
      <DialogTitle display="flex">
        <Box
          mr="auto"
          ml="0"
          color="secondary.main"
          fontWeight="normal"
          fontSize={props?.titleFontSize || "18px"}
        >
          {props.title}
        </Box>
        {props.onClose ? (
          <IconButton color="secondary" onClick={props.onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>

      {props.children}
    </Dialog>
  );
};

export default ModalContainer;
