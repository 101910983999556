import { v4 as uuidv4 } from "uuid";

export const personalitySets = [
  {
    uuid: uuidv4(),
    personalities: [
      "down to earth",
      "family oriented",
      "conventional",
      "blue collar",
      "typical Aussie",
    ],
    brandValues: [
      "Family-oriented",
      "Caring",
      "Hard working",
      "Empathetic",
      "Dedicated",
      "Approachable",
      "Friendly",
      "Genuine",
      "Harmonious",
      "Proud",
      "Resourceful",
      "Safe",
      "Stable",
      "Trusted",
      "Welcoming",
    ],
  },
  {
    uuid: uuidv4(),
    personalities: [
      "honest",
      "sincere",
      "real",
      "ethical",
      "thoughtful",
      "caring",
    ],
    brandValues: [
      "Honest",
      "Sincere",
      "Real",
      "Ethical",
      "Thoughtful",
      "Caring",
      "Dependable",
      "Connected",
      "Empathetic",
      "Credible",
      "Trusted",
      "Safe",
      "Secure",
      "Proud",
      "Perceptive",
      "Loyal",
      "Genuine",
      "Engaging",
    ],
  },
  {
    uuid: uuidv4(),
    personalities: [
      "wholesome",
      "original",
      "genuine",
      "ageless",
      "classic",
      "old-fashioned",
    ],
    brandValues: [
      "Traditional",
      "Genuine",
      "Approachable",
      "Crafted",
      "Dependable",
      "Dignified",
      "Family-oriented",
      "Friendly",
      "Hard working",
      "Loyal",
      "Proud",
      "Pure",
      "Resiliant",
      "Safe",
      "Stable",
      "Welcoming",
    ],
  },
  {
    uuid: uuidv4(),
    personalities: ["cheerful", "sentimental", "friendly", "warm", "happy"],
    brandValues: [
      "Cheerful",
      "Friendly",
      "Warm",
      "Happy",
      "Approachable",
      "Confident",
      "Customer-centric",
      "Eager",
      "Engaging",
      "Enthusiastic",
      "Fresh",
      "Optimistic",
      "Playful",
      "Positive",
      "Welcoming",
    ],
  },
  {
    uuid: uuidv4(),
    personalities: [
      "daring",
      "trendy",
      "exciting",
      "off-beat",
      "provocative",
      "bold",
    ],
    brandValues: [
      "Exciting",
      "Bold",
      "Off-beat",
      "Daring",
      "Ambitious",
      "Confident",
      "Direct",
      "Driven",
      "Eager",
      "Energetic",
      "Enthusiastic",
      "Entrepreneurial",
      "Exciting",
      "Exhilarating",
      "Individual",
      "Original",
    ],
  },
  {
    uuid: uuidv4(),
    personalities: [
      "spirited",
      "cool",
      "young",
      "lively",
      "outgoing",
      "adventurous",
    ],
    brandValues: [
      "Spirited",
      "Lively",
      "Adventurous",
      "Outgoing",
      "Unique",
      "Spontaneous",
      "Positive",
      "Playful",
      "Fresh",
      "Free",
      "Fearless",
      "Exhilarating",
      "Entrepreneurial",
      "Energetic",
      "Ambitious",
      "Active",
    ],
  },
  {
    uuid: uuidv4(),
    personalities: [
      "imaginative",
      "unique",
      "humorous",
      "surprising",
      "artistic",
      "fun",
    ],
    brandValues: [
      "Imaginative",
      "Unique",
      "Humorous",
      "Surprising",
      "Artistic",
      "Fun",
      "Approachable",
      "Dreamer",
      "Engaging",
      "Intuitive",
      "Optimistic",
      "Playful",
      "Popular",
      "Positive",
      "Spontaneous",
      "Talented",
    ],
  },
  {
    uuid: uuidv4(),
    personalities: [
      "up-to-date",
      "independent",
      "contemporary",
      "innovative",
      "aggressive",
    ],
    brandValues: [
      "Independent",
      "Contemporary",
      "Innovative",
      "Resourceful",
      "Fresh",
      "Entrepreneurial",
      "Driven",
      "Adaptive",
      "Disruptive",
      "Ambitious",
      "Competitive",
      "Inspiring",
    ],
  },
  {
    uuid: uuidv4(),
    personalities: [
      "reliable",
      "hardworking",
      "secure",
      "efficient",
      "trustworthy",
      "careful",
    ],
    brandValues: [
      "Reliable",
      "Hard working",
      "Secure",
      "Trusted",
      "Careful",
      "Down-to-earth",
      "Capable",
      "Dedicated",
      "Dependable",
      "Diligent",
      "Honest",
      "Loyal",
      "Proud",
      "Resiliant",
      "Resourceful",
      "Stable",
      "Trusted",
    ],
  },
  {
    uuid: uuidv4(),
    personalities: ["intelligent", "technical", "corporate", "serious"],
    brandValues: [
      "Intelligent",
      "Serious",
      "Capable",
      "Dedicated",
      "Diligent",
      "Disciplined",
      "Expert",
      "Insightful",
      "Knowledgeable",
      "Logical",
      "Skillfull",
    ],
  },
  {
    uuid: uuidv4(),
    personalities: ["successful", "leader", "confident", "influential"],
    brandValues: [
      "Successful",
      "Confident",
      "Influential",
      "Credible",
      "Dignified",
      "Direct",
      "Driven",
      "Effective",
      "Insightful",
      "Masterful",
      "Perceptive",
      "Poised",
      "Proud",
      "Resourceful",
      "Trusted",
    ],
  },
  {
    uuid: uuidv4(),
    personalities: [
      "upper class",
      "glamorous",
      "good-looking",
      "pretentious",
      "sophisticated",
    ],
    brandValues: [
      "Glamorous",
      "Sophisticated",
      "Confident",
      "Elegant",
      "Dignified",
      "Poised",
      "Tactile",
      "Classical",
      "Considered",
    ],
  },
  {
    uuid: uuidv4(),
    personalities: ["charming", "feminine", "smooth", "sexy", "gentle"],
    brandValues: [
      "Charming",
      "Smooth",
      "Sexy",
      "Gentle",
      "Confident",
      "Engaging",
      "Fresh",
      "Harmonious",
      "Intuitive",
      "Mindful",
      "Playful",
      "Popular",
      "Pure",
      "Spontaneous",
      "Talented",
    ],
  },
  {
    uuid: uuidv4(),
    personalities: ["outdoorsy", "masculine", "earthy", "active", "athletic"],
    brandValues: [
      "Outdoorsy",
      "Masculine",
      "Earthy",
      "Active",
      "Athletic",
      "Adaptive",
      "Approachable",
      "Capable",
      "Confident",
      "Dependable",
      "Engaging",
      "Free",
      "Friendly",
      "Honest",
      "Hard working",
      "Optimistic",
    ],
  },
  {
    uuid: uuidv4(),
    personalities: ["tough", "rugged", "strong", "no-nonsense"],
    brandValues: [
      "Tough",
      "Rugged",
      "Strong",
      "No-nonsense",
      "Dependable",
      "Determined",
      "Diligent",
      "Hard working",
      "Safe",
      "Stable",
      "Trusted",
    ],
  },
];

export const brandValues = [
  {
    uuid: uuidv4(),
    name: "Adapable",
    default: true,
  },

  {
    uuid: uuidv4(),
    name: "Agile",
    default: true,
  },
  {
    uuid: uuidv4(),
    name: "Ambitious",
    default: true,
  },
  {
    uuid: uuidv4(),
    name: "Believers",
    default: true,
  },
  {
    uuid: uuidv4(),
    name: "Better than yesterday",
    default: true,
  },
  {
    uuid: uuidv4(),
    name: "Bold",
    default: true,
  },
  {
    uuid: uuidv4(),
    name: "Brave",
    default: true,
  },
  {
    uuid: uuidv4(),
    name: "Business Minded",
    default: true,
  },
  {
    uuid: uuidv4(),
    name: "Caring",
    default: true,
  },
  {
    uuid: uuidv4(),
    name: "Celebrate others",
    default: true,
  },
  {
    uuid: uuidv4(),
    name: "Challengers",
    default: true,
  },
  {
    uuid: uuidv4(),
    name: "Clever",
    default: true,
  },
  {
    uuid: uuidv4(),
    name: "Collaborative",
    default: true,
  },
  {
    uuid: uuidv4(),
    name: "Collegiate",
    default: true,
  },
  {
    uuid: uuidv4(),
    name: "Confidentes",
    default: true,
  },
  {
    uuid: uuidv4(),
    name: "Connected",
    default: true,
  },
  {
    uuid: uuidv4(),
    name: "Courageous",
    default: true,
  },
  {
    uuid: uuidv4(),
    name: "Creative",
    default: true,
  },
  {
    uuid: uuidv4(),
    name: "Curious",
    default: true,
  },
  {
    uuid: uuidv4(),
    name: "Customer obsessed",
    default: true,
  },
  {
    uuid: uuidv4(),
    name: "Divergent thinkers",
    default: true,
  },
  {
    uuid: uuidv4(),
    name: "Diverse thinkers",
    default: true,
  },
  {
    uuid: uuidv4(),
    name: "Do the best for others",
    default: true,
  },
  {
    uuid: uuidv4(),
    name: "Do what they say",
    default: true,
  },
  {
    uuid: uuidv4(),
    name: "Doers",
    default: true,
  },
  {
    uuid: uuidv4(),
    name: "Eager to learn",
    default: true,
  },
  {
    uuid: uuidv4(),
    name: "Empathetic",
    default: true,
  },
  {
    uuid: uuidv4(),
    name: "Engaging",
    default: true,
  },
  {
    uuid: uuidv4(),
    name: "Enthusiastic",
    default: true,
  },
  {
    uuid: uuidv4(),
    name: "Entrepreneurial",
    default: true,
  },
  {
    uuid: uuidv4(),
    name: "Fierce",
    default: true,
  },
  {
    uuid: uuidv4(),
    name: "Fun",
    default: true,
  },
  {
    uuid: uuidv4(),
    name: "Future focussed",
    default: true,
  },
  {
    uuid: uuidv4(),
    name: "Hard Working",
    default: true,
  },
  {
    uuid: uuidv4(),
    name: "Have heart",
    default: true,
  },
  {
    uuid: uuidv4(),
    name: "Have integrity",
    default: true,
  },
  {
    uuid: uuidv4(),
    name: "Have smarts",
    default: true,
  },
  {
    uuid: uuidv4(),
    name: "Have spark",
    default: true,
  },
  {
    uuid: uuidv4(),
    name: "Honest",
    default: true,
  },
  {
    uuid: uuidv4(),
    name: "Human",
    default: true,
  },
  {
    uuid: uuidv4(),
    name: "Humble",
    default: true,
  },
  {
    uuid: uuidv4(),
    name: "Innovative",
    default: true,
  },
  {
    uuid: uuidv4(),
    name: "Inspiring",
    default: true,
  },
  {
    uuid: uuidv4(),
    name: "Integrity",
    default: true,
  },
  {
    uuid: uuidv4(),
    name: "Leaders",
    default: true,
  },
  {
    uuid: uuidv4(),
    name: "Levergae openness",
    default: true,
  },
  {
    uuid: uuidv4(),
    name: "Love their craft",
    default: true,
  },
  {
    uuid: uuidv4(),
    name: "Move fast",
    default: true,
  },
  {
    uuid: uuidv4(),
    name: "Never stand still",
    default: true,
  },
  {
    uuid: uuidv4(),
    name: "Noble",
    default: true,
  },
  {
    uuid: uuidv4(),
    name: "Nurturers",
    default: true,
  },
  {
    uuid: uuidv4(),
    name: "Obsessed",
    default: true,
  },
  {
    uuid: uuidv4(),
    name: "Original thinkers",
    default: true,
  },
  {
    uuid: uuidv4(),
    name: "Passionate",
    default: true,
  },
  {
    uuid: uuidv4(),
    name: "Persistent",
    default: true,
  },
  {
    uuid: uuidv4(),
    name: "Play together",
    default: true,
  },
  {
    uuid: uuidv4(),
    name: "Profit leaders",
    default: true,
  },
  {
    uuid: uuidv4(),
    name: "Progressive",
    default: true,
  },
  {
    uuid: uuidv4(),
    name: "Pure",
    default: true,
  },
  {
    uuid: uuidv4(),
    name: "Purposeful",
    default: true,
  },
  {
    uuid: uuidv4(),
    name: "Quality",
    default: true,
  },
  {
    uuid: uuidv4(),
    name: "Real",
    default: true,
  },
  {
    uuid: uuidv4(),
    name: "Resilient",
    default: true,
  },
  {
    uuid: uuidv4(),
    name: "Resourceful",
    default: true,
  },
  {
    uuid: uuidv4(),
    name: "Results driven",
    default: true,
  },
  {
    uuid: uuidv4(),
    name: "Simplify",
    default: true,
  },
  {
    uuid: uuidv4(),
    name: "Smart",
    default: true,
  },
  {
    uuid: uuidv4(),
    name: "Strive for excellence",
    default: true,
  },
  {
    uuid: uuidv4(),
    name: "Supportive",
    default: true,
  },
  {
    uuid: uuidv4(),
    name: "Team players",
    default: true,
  },
  {
    uuid: uuidv4(),
    name: "Think simply",
    default: true,
  },
  {
    uuid: uuidv4(),
    name: "Traditional",
    default: true,
  },
  {
    uuid: uuidv4(),
    name: "Trustworthy",
    default: true,
  },
  {
    uuid: uuidv4(),
    name: "Unconventional",
    default: true,
  },
  {
    uuid: uuidv4(),
    name: "Visionary thinkers",
    default: true,
  },
  {
    uuid: uuidv4(),
    name: "Who Deliver",
    default: true,
  },
  {
    uuid: uuidv4(),
    name: "Wise",
    default: true,
  },
  {
    uuid: uuidv4(),
    name: "World Class",
    default: true,
  },
];
