import {createTheme} from "@mui/material";
import {store} from "../../redux/store";
import WebFont from "webfontloader";

const loadWithFont = (font) => {
    if (font) {
        WebFont.load({
            google: {
                families: [`${font}:200,300,400,500,600,700,800`],
            },
        });
    }
};
export const theme = () => {
    const theme = store.getState().theme;

    loadWithFont(theme.googleFont);

    return createTheme({
        typography: {
            fontFamily: theme.googleFont || "",
            tab: {
                textTransform: "none !important",
                fontWeight: "600 !important",
                fontSize: "16px !important",
            },
            button: {
                textTransform: "none",


            },
        },
        palette: {
            primary: {
                main: theme.primaryColorHexCode || "#CAE2E6",
            },
            secondary: {
                main: theme.secondaryColorHexCode || "#1D2D91",
                sub: "#a8a8a8",
            },
            action: {
                disabledBackground: '#8891c3',
                disabled: "#fff"
            }
        },
        components: {
            MuiButton: {
                variants: [
                    {
                        props: { variant: "approved" },
                        style: {
                            background: `#1D9130`,
                            color: "#fff",
                            "&:hover": {
                                backgroundColor: "#016502",  // Darker blue on hover
                                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)", // Optional shadow on hover
                            },
                            "&:disabled": {
                                backgroundColor: "grey"
                            }
                        },

                    }
                ]
            }
        }
    });
};
