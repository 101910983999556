import { Box, Stack } from "@mui/material";
import { useRef } from "react";

const Step2 = ({ brandValues, setBrandValue }) => {
  const dragItem = useRef();
  const dragOverItem = useRef();

  const dragStart = (e, position) => {
    dragItem.current = position;
  };
  const dragEnter = (e, position) => {
    dragOverItem.current = position;
  };
  const drop = (left) => {
    const copyListItems = [...brandValues];
    const dragItemContent = copyListItems[dragItem.current];

    if (
      left &&
      dragItemContent.loaded === false &&
      copyListItems.filter((a) => a.loaded).length < 4
    ) {
      dragItemContent.loaded = true;
      copyListItems.splice(dragItem.current, 1);
      copyListItems.splice(dragOverItem.current, 0, dragItemContent);
      dragItem.current = null;
      dragOverItem.current = null;
      setBrandValue(copyListItems);
    } else if (!left && dragItemContent.loaded) {
      dragItemContent.loaded = false;
      copyListItems.splice(dragItem.current, 1);
      copyListItems.splice(dragOverItem.current, 0, dragItemContent);
      dragItem.current = null;
      dragOverItem.current = null;
      setBrandValue(copyListItems);
    }
  };
  return (
    <>
      <Stack direction="row" gap="40px">
        <Stack flex="0.3" gap="10px">
          <Box
            width="100%"
            minHeight="400px"
            padding="10px"
            border="1px solid black"
            display="flex"
            flexDirection="column"
            gap="10px"
            onDragEnd={() => drop(true)}
            onDragOver={(e) => e.preventDefault()}
          >
            {brandValues && brandValues.length
              ? brandValues.map((a, index) =>
                  a.selected ? (
                    <Box
                      padding="6px"
                      style={
                        a.default
                          ? { background: "grey" }
                          : { background: "white" }
                      }
                      color={a.default ? "white" : "black"}
                      border={a.default ? "" : "1px solid black"}
                      onDragStart={(e) => dragStart(e, index)}
                      onDragEnter={(e) => dragEnter(e, index)}
                      onDragOver={(e) => e.preventDefault()}
                      key={index}
                      draggable
                    >
                      {a.name}
                    </Box>
                  ) : null
                )
              : null}
          </Box>
        </Stack>

        <Box
          flex="0.3"
          minHeight="100px"
          padding="10px"
          border="1px solid black"
          display="flex"
          flexDirection="column"
          gap="10px"
          onDragEnd={() => drop(false)}
          onDragOver={(e) => e.preventDefault()}
        >
          {brandValues && brandValues.length
            ? brandValues.map((a, index) =>
                a.selected && a.loaded ? (
                  <Box
                    padding="6px"
                    style={
                      a.default
                        ? { background: "grey" }
                        : { background: "white" }
                    }
                    color={a.default ? "white" : "black"}
                    border={a.default ? "" : "1px solid black"}
                    onDragStart={(e) => dragStart(e, index)}
                    onDragEnter={(e) => dragEnter(e, index)}
                    onDragOver={(e) => e.preventDefault()}
                    key={index}
                    draggable
                  >
                    {a.name}
                  </Box>
                ) : null
              )
            : null}
        </Box>
      </Stack>
    </>
  );
};

export default Step2;
