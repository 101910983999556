import { Box, Stack } from "@mui/material";
import { useState, useEffect } from "react";
import CustomButton from "../../../../../ui/button/CustomButton";
import FormGroupTextField from "../../../../../ui/formgroup/FormGroupTextField";
import OpeningScreen from "../OpeningScreen";

const ElivataroPitch = (props) => {
  const [step, setStep] = useState(0);
  const [data, setData] = useState({
    category: "",
    region: "",
    product: "",
    customer: "",
  });
  useEffect(() => {
    if (props.td && props.td.data && props.td.data.elevatorPitch) {
      setData(props.td.data.elevatorPitch);
    }
  }, [props]);
  return (
    <>
      {step === 0 ? (
        <OpeningScreen
          title="Your Elevator Pitch"
          description={
            "One of the most useful expressions of your brand is the 'Elevator Pitch'. Quite simply the elevator pitch is the succinct summary you and your staff can use to describe your brand."
          }
          img={props?.sas?.img || ""}
          bestPratice={props?.sas?.workshop || false}
          bestEx={props?.sas?.workshopImg || []}
          name={props?.sas?.name || ""}
        />
      ) : (
        <Stack direction="row" gap="30px">
          <Box flex="0.2" display="flex" gap="10px" flexDirection="column">
            <Box color="secondary.main" fontSize="20px" fontWeight="800px">
              Your Elevator Pitch
            </Box>
            <Box mb={2} fontSize="14px">
              Jerry Garcia, lead singer of The Grateful Dead once said:
              <br />
              "You don't want to be the best at what you do, you want to be the
              only one"
              <br />
              This principle is compelling when applied to the way we describe
              our brand's point of difference. Using the format below:
            </Box>
          </Box>
          <Box flex="0.8" mb={2}>
            <Stack
              gap="10px"
              padding="20px"
              border="1px solid black"
              borderRadius="10px"
            >
              <Box display="flex" gap="5px">
                <Box fontSize="22px" fontWeight="600" width="20%" m="auto">
                  We are the only
                </Box>
                <FormGroupTextField
                  value={data.category}
                  onChange={(e) => setData((old) => ({ ...old, category: e }))}
                />
              </Box>
              <Box display="flex" gap="5px">
                <Box fontSize="22px" fontWeight="600" width="20%" m="auto">
                  brand in
                </Box>
                <FormGroupTextField
                  value={data.region}
                  onChange={(e) => setData((old) => ({ ...old, region: e }))}
                />
              </Box>

              <Box display="flex" gap="5px">
                <Box fontSize="22px" fontWeight="600" width="20%" m="auto">
                  that provides
                </Box>
                <FormGroupTextField
                  value={data.product}
                  onChange={(e) => setData((old) => ({ ...old, product: e }))}
                />
              </Box>
              <Box display="flex" gap="5px">
                <Box fontSize="22px" fontWeight="600" width="20%" m="auto">
                  to
                </Box>
                <FormGroupTextField
                  value={data.customer}
                  onChange={(e) => setData((old) => ({ ...old, customer: e }))}
                />
              </Box>
            </Stack>
          </Box>
        </Stack>
      )}
      <Box display="flex" justifyContent="space-between" mt="auto" mb="0">
        <CustomButton
          name="<   Prev"
          loading={props.btnLoading}
          onclick={async () => {
            if (step === 0) {
              props.prvStep();
            } else {
              await props.callApi({ elevatorPitch: data });
              setStep(0);
            }
          }}
        />
        <CustomButton
          name="Next   >"
          loading={props.btnLoading}
          onclick={async () => {
            if (step === 0) {
              setStep(1);
            } else {
              await props.callApi({ elevatorPitch: data }, false, true);
              props.nextStep();
            }
          }}
        />
      </Box>
    </>
  );
};

export default ElivataroPitch;
