import { Box, Divider, Grid, useTheme } from "@mui/material";
import moment from "moment";
import { useNavigate } from "react-router";
import SelectButton from "../../ui/button/SelectButton";

const TRList = (props) => {
  const scolor = useTheme().palette.primary.main;
  const loc = useNavigate();
  return (
    <Grid item container lg={12} md={12} sm={12} xs={12}>
      <Grid item lg={10.5} md={10} sm={10} xs={10}>
        <Box color="secondary.main" fontSize="22px" mb={"4px"}>
          {props.name || "-"}
        </Box>
        <Box color="#919191">
          Last edited{" "}
          {props.dateModified
            ? moment(props.dateModified).format("DD.MM.YYYY")
            : "-"}{" "}
          by {props.lastModifiedBy || "-"}
        </Box>
      </Grid>
      <Grid item lg={1.5} md={2} sm={2} xs={2}>
        <SelectButton
          color="pink"
          title="Edit"
          titleClick={() =>
            props.type === "CUSTOM"
              ? loc(`/templates/custom-template/${props.uuid}`)
              : loc(`/templates/${props.uuid}`)
          }
          dropClick={(e) => props.handleClick(e)}
        />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} mt={2} mb={2}>
        <Divider
          variant="fullWidth"
          sx={{
            backgroundColor: scolor || "",
          }}
        />
      </Grid>
    </Grid>
  );
};

export default TRList;
