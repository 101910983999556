import { ButtonBase, DialogContent, Grid, Stack } from "@mui/material";
import { ArrowForwardIos } from "@mui/icons-material";
import DelImg from "../../../assets/images/DelImg.png";
import CustomButton from "../button/CustomButton";
import ModalContainer from "./ModalContainer";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "20px",
    color: theme.palette.secondary.main,
    marginBottom: "20px",
    fontWeight: 600,
  },
  endIcon: {
    marginLeft: "auto !important",
  },
  btnContainer: { width: "40%" },
  cancelBtn: {
    margin: "auto 0",
    textDecoration: "underline !important",
    fontFamily: theme.typography.fontFamily,
    fontSize: "16px",
  },
  dialogContent: {
    height: "500px",
  },
}));

const DeleteModal = ({ open, onClose, title, onDelete = () => {} }) => {
  const classes = useStyles();

  return (
    <ModalContainer
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth={true}
    >
      <DialogContent className={classes.dialogContent}>
        <Grid container mt={2}>
          <Grid item lg={7} md={7} sm={7} xs={7} margin="auto" p={4}>
            <div className={classes.title}>{title}</div>
            <Stack direction="row" mt={3} gap="20px">
              <div className={classes.btnContainer}>
                <CustomButton
                  variant="contained"
                  size="large"
                  name={"Confirm"}
                  endIcon={<ArrowForwardIos />}
                  fullWidth={true}
                  iconClass={classes.endIcon}
                  onclick={onDelete}
                />
              </div>
              <ButtonBase onClick={onClose} className={classes.cancelBtn}>
                Cancel
              </ButtonBase>
            </Stack>
          </Grid>
          <Grid item lg={5} md={5} sm={5} xs={5}>
            <img src={DelImg} alt={DelImg} height="383px" />
          </Grid>
        </Grid>
      </DialogContent>
    </ModalContainer>
  );
};

export default DeleteModal;
