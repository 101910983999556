import { config } from "../config";
import { api } from "../redux/api/api";

export const awsUpload = async (
  file,
  isFileName = false,
  isBoth = false,
  token = "",
  publicPg = false,
  aid = ""
) => {
  try {
    let res = {};
    if (publicPg) {
      res = await api(
        `artwork/ext/${aid}/media`,
        file,
        "postMultipart",
        "",
        token
      );
    } else {
      res = await api("media", file, "postMultipart", "", token);
    }

    if (isBoth) {
      return (
        {
          filename: res.filename,
          fileUrl: `${config.dwdPath}${res.filename}`,
        } || ""
      );
    }
    if (isFileName) {
      return res.filename || "";
    }
    if (res.filename) {
      return `${config.dwdPath}${res.filename}`;
    }
    return res.fileUrl || "";
  } catch (error) {
    return "";
  }
};
