import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Divider, Grid, Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { isEmpty } from "lodash";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import Loader from "react-spinners/ClipLoader";
import useBindActionCreator from "../../../hooks/useBindActionCreator";
import {
  setCID,
  setPID,
  getClientListData,
  setFromIds,
  getProjectListData,
} from "../../../redux/actions/client/ClientAction";
import { getUsers } from "../../../redux/actions/profile/ProfileActions";
import { SET_PROJECT_DATA, SET_PROJECT_LOADER } from "../../../redux/constants";
import HeaderMenu from "../menus/HeaderMenu";
import AddClientWithProject from "../modals/AddClientWithProject";
import AddProjectModal from "../modals/AddProjectModal";
const sxObj = {
  width: "558px",
  borderRadius: "18px",
  mt: 1,
};

const useStyles = makeStyles((theme) => ({
  container: {
    fontFamily: theme.typography.fontFamily,
    color: `${theme.palette.secondary.main} !important`,
    marginLeft: "20px",
    display: "flex",
    gap: "2px",
    flexDirection: "column",
  },
  innerContainer: {
    display: "flex",
    gap: "8px",
    fontSize: "14px",
  },
  marginDiv: {
    margin: "auto 0",
  },
  marginWithWidth: {
    margin: "auto 0",
    fontWeight: "600",
  },
  innerContainer2: {
    display: "flex",
    gap: "8px",
    fontWeight: "550",
    fontSize: "20px",
    cursor: "pointer",
  },
  gridFont: {
    fontFamily: `${theme.typography.fontFamily} !important`,
  },
  selectMenu: {
    backgroundColor: theme.palette.primary.main,
    fontWeight: 600,
    fontSize: "14px",
  },
  menuItem: {
    padding: "10px 20px",
    color: theme.palette.secondary.main,
    margin: "2px 0px",
    fontSize: "14px",
    cursor: "pointer",
  },
  divider: {
    marginRight: "-1px !important",
    backgroundColor: `${theme.palette.primary.main} !important`,
  },
  pdiv: {
    color: theme.palette.secondary.main,
    fontSize: "12px",
  },
  psdiv: {
    color: theme.palette.secondary.main,
    fontSize: "12px",
    fontWeight: "500",
    cursor: "pointer",
  },
}));

const ProjectClientListing = () => {
  const loc = useLocation();

  const dispatch = useDispatch();
  const { clientId, projectId } = useSelector((state) => state.client);

  const actions = useBindActionCreator({
    getUsers,
    getClientListData,
    setFromIds,
    getProjectListData,
  });

  const agenciesUUID = useSelector((state) => state.auth.user?.agencies?.uuid);
  const clientListing = useSelector((state) => state.project.clients);
  const projectD = useSelector((state) => state.project.projects);
  const fromid = useSelector((state) => state.project.fromid);

  const classes = useStyles();
  const [enchorEl, setEnchorEl] = useState(null);
  const [openP, setOpenP] = useState(false);

  const [selClient, setSelClient] = useState("");
  const [selProject, setSelProject] = useState("");

  const [selClientId, setSelClientId] = useState("");
  const [selProjectId, setSelProjectId] = useState("");

  const [loading, setLoading] = useState(true);

  const [openClient, setOpenClient] = useState(false);
  const [openProject, setOpenProject] = useState(false);

  useEffect(() => {
    if (isEmpty(clientListing)) getData(true);
  }, []);

  const handleClick = (event) => {
    setEnchorEl(event.currentTarget);
    setOpenP(true);
  };
  const [first, setFirst] = useState(false);
  const [pid, setPid] = useState("");

  useEffect(() => {
    if (projectD && projectD.length) {
      if (first && ((projectId.id && projectId.name) || pid))
        handleProjectSelect(pid.id || projectId.id, pid.name || projectId.name);
      else handleProjectSelect(projectD[0].uuid, projectD[0].name);
    }
  }, [projectD]);

  const loadProjectWithId = async (id, first, pid = "") => {
    setLoading(true);
    dispatch({ type: SET_PROJECT_LOADER, payload: { data: true } });
    setFirst(() => first);
    setPid(() => pid);
    await actions.getProjectListData(id);
    dispatch({ type: SET_PROJECT_LOADER, payload: { data: false } });
    setLoading(false);
  };

  const handleClientSelect = async (id, name) => {
    setSelClient(name);
    setSelClientId(id);
    dispatch(setCID({ id, name }));
    await loadProjectWithId(id, false, "");
  };

  const getData = async (luser) => {
    dispatch({ type: SET_PROJECT_LOADER, payload: { data: true } });
    setLoading(true);
    await actions.getClientListData();
    setLoading(false);
    dispatch({ type: SET_PROJECT_LOADER, payload: { data: false } });
    if (luser && agenciesUUID) {
      loadUsers();
    }
  };
  const loadUsers = () => {
    actions.getUsers(agenciesUUID);
  };

  const loadData = async (cdata = "", pdata = "") => {
    if (clientId || cdata) {
      setSelClientId(() => cdata.id || clientId.id);
      setSelClient(() => cdata.name || clientId.name);
      await loadProjectWithId(
        cdata.id || clientId.id,
        true,
        pdata || projectId || ""
      );
    } else {
      setSelClient(() => clientListing[0].name);
      setSelClientId(() => clientListing[0].uuid);
      loadProjectWithId(clientListing[0].uuid, true, "");
      dispatch(
        setCID({ id: clientListing[0].uuid, name: clientListing[0].name })
      );
    }
  };

  useEffect(() => {
    if (clientListing && clientListing.length) {
      loadData();
    }
  }, [clientListing]);
  useEffect(() => {
    if (fromid && fromid.cdata && fromid.pdata) {
      dispatch(setCID(fromid.cdata));
      dispatch(setPID(fromid.pdata));
      loadData(fromid.cdata, fromid.pdata);
      actions.setFromIds("");
    }
  }, [fromid]);
  const handleClose = () => {
    setEnchorEl(null);
    setOpenP(false);
  };
  const handleProjectSelect = async (id, name) => {
    if (id) {
      dispatch({ type: SET_PROJECT_LOADER, payload: { data: true } });
      let pd = "";
      if (projectD && projectD.length) {
        pd = projectD.findIndex((a) => a.uuid === id);
      }
      if (pd !== -1) {
        dispatch({ type: SET_PROJECT_DATA, payload: { data: projectD[pd] } });
        setSelProjectId(() => id);
        setSelProject(() => name);
        dispatch(setPID({ id, name }));
      } else {
        dispatch({ type: SET_PROJECT_DATA, payload: { data: "" } });
        setSelProjectId(() => "");
        setSelProject(() => "");
        dispatch(setPID(""));
      }
      dispatch({ type: SET_PROJECT_LOADER, payload: { data: false } });
    }
  };
  return loc.pathname.includes("/projects") ? (
    <div className={classes.container}>
      <div className={classes.innerContainer}>
        <div className={classes.marginDiv}>All Projects</div>
        <ChevronRightIcon />
        <div className={classes.marginWithWidth}>{selProject || "-"}</div>
      </div>
      <div className={classes.innerContainer2} onClick={handleClick}>
        <div className={classes.marginDiv}>{selClient || "-"}</div>
        <KeyboardArrowDownIcon />{" "}
      </div>
      <HeaderMenu
        openP={openP}
        enchorEl={enchorEl}
        handleClose={handleClose}
        sxObj={sxObj}
        transformOrigin={{ horizontal: "left", vertical: "top" }}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
      >
        <Grid container className={classes.gridFont}>
          <Grid container item lg={5} md={5} sm={5} xs={5}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Stack direction="row" justifyContent="space-between" p={2}>
                <div className={classes.pdiv}>Clients</div>
                <div
                  className={classes.psdiv}
                  onClick={() => {
                    handleClose();
                    setOpenClient(true);
                  }}
                >
                  Add Client
                </div>
              </Stack>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Stack
                maxHeight="350px"
                minHeight={"300px"}
                overflow="auto"
                pr={0.2}
              >
                {clientListing && clientListing.length ? (
                  clientListing.map((e) => (
                    <div
                      key={e.uuid}
                      className={e.uuid === selClientId && classes.selectMenu}
                      onClick={() => handleClientSelect(e.uuid, e.name)}
                    >
                      <div className={classes.menuItem}>{e.name}</div>
                    </div>
                  ))
                ) : (
                  <div className={classes.menuItem}>No Client's Found</div>
                )}
              </Stack>
            </Grid>
          </Grid>
          <Divider
            orientation="vertical"
            flexItem
            className={classes.divider}
          />
          <Grid item container lg={7} md={7} sm={7} xs={7}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Stack direction="row" justifyContent="space-between" p={2}>
                <div className={classes.pdiv}>Projects</div>
                <div
                  className={classes.psdiv}
                  onClick={() => {
                    handleClose();
                    setOpenProject(true);
                  }}
                >
                  Add Project
                </div>
              </Stack>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Stack
                maxHeight="350px"
                minHeight={"300px"}
                overflow="auto"
                pr={0.2}
                pl={0.8}
              >
                {loading ? (
                  <Loader />
                ) : projectD && projectD.length ? (
                  projectD.map((e) => (
                    <div
                      key={e.uuid}
                      className={e.uuid === selProjectId && classes.selectMenu}
                      onClick={() => {
                        handleProjectSelect(e.uuid, e.name);
                        handleClose();
                      }}
                    >
                      <div className={classes.menuItem}>{e.name}</div>
                    </div>
                  ))
                ) : (
                  <div className={classes.menuItem}>
                    {selClient
                      ? "No Project Found For This Client"
                      : "Please Select Client First"}{" "}
                  </div>
                )}
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </HeaderMenu>
      <AddProjectModal
        open={selClient && openProject}
        onClose={() => setOpenProject(false)}
        name={selClient}
        cid={selClientId}
      />
      <AddClientWithProject
        open={openClient}
        onClose={() => setOpenClient(false)}
      />
    </div>
  ) : null;
};

export default ProjectClientListing;
