import { Box, Stack } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import CustomButton from "../../../../../ui/button/CustomButton";
import FormGroupTextField from "../../../../../ui/formgroup/FormGroupTextField";
import TextAutoResize from "../../../../../ui/formgroup/TextAutoResize";

const Requirements = (props) => {
  const [requirements, setRequirements] = useState("");
  const data = props?.td?.data;
  useEffect(() => {
    if (data && data.requirements && typeof data.requirements === "string") {
      setRequirements(data.requirements);
    }
  }, [props]);
  return (
    <>
      <Stack direction="row" gap="30px">
        <Box flex="0.2" display="flex" gap="10px" flexDirection="column">
          <Box color="secondary.main" fontSize="20px" fontWeight="800px">
            What Are The Main Requirements For The Creative?
          </Box>
          <Box mb={2} fontSize="14px"></Box>
        </Box>
        <Box flex="0.8">
          <TextAutoResize
            other={{ minRows: 6, maxRows: 6 }}
            value={requirements}
            onChange={(e) => setRequirements(e)}
          />
        </Box>
      </Stack>
      <Box display="flex" justifyContent="space-between" mt="auto" mb="0">
        <CustomButton
          name="<   Prev"
          onclick={() => props.callApi({ requirements }, true, false)}
          loading={props.btnLoading}
        />
        <CustomButton
          name="Next   >"
          disabled={!requirements}
          onclick={() => props.callApi({ requirements }, true, true)}
          loading={props.btnLoading}
        />
      </Box>
    </>
  );
};

export default Requirements;
