import { Box } from "@mui/material";
import { ReactComponent as UpArrowIcon } from "../../../../../../../../assets/images/Up.svg";
import { ReactComponent as DownArrowIcon } from "../../../../../../../../assets/images/Down.svg";
import { useRef } from "react";
import FormGroupTextField from "../../../../../../../ui/formgroup/FormGroupTextField";

const ArrangeContainer = (props) => {
  const dragItem = useRef();
  const dragOverItem = useRef();

  const dragStart = (e, position) => {
    props.setIndentifyStart(true);
    props.selectBox(position);
    dragItem.current = position;
  };

  const dragEnter = (e, position) => {
    if (!props.indentifyStart) props.selectBox(position);
    props.setThisSide(props.left);
    dragOverItem.current = position;
  };

  const drop = (e) => {
    props.setIndentifyStart(false);
    if (props.thisSide === props.left) {
      const copyListItems = [...props.slides];
      const dragItemContent = copyListItems[dragItem.current];
      copyListItems.splice(dragItem.current, 1);
      copyListItems.splice(dragOverItem.current, 0, dragItemContent);
      props.selectBox(dragOverItem.current);
      dragItem.current = null;
      dragOverItem.current = null;
      props.setItemOnDrag(copyListItems);
    } else {
      props.switchBox();
    }
  };

  return (
    <Box
      height="356px"
      overflow="auto"
      padding="10px"
      borderRadius="8px"
      style={{
        background: "white",
      }}
      onDragOver={(e) => e.preventDefault()}
      onDragEnd={() => drop()}
      onDragEnter={() => {
        props.setThisSide(props.left);
      }}
    >
      {props.slides && props.slides.length
        ? props.slides.map((a, index) => (
            <Box
              className={`arrange-container ${
                props.selIdx === index && "select"
              }`}
              onClick={() => props.selectBox(index)}
              onDragStart={(e) => dragStart(e, index)}
              onDragEnter={(e) => dragEnter(e, index)}
              key={index}
              draggable
            >
              <Box margin="auto 0" width={"90%"}>
                <FormGroupTextField
                  placeholder={props.placeholder || ""}
                  type="text"
                  value={a.name}
                  onChange={(e) =>
                    props.handleBrandDifferentiation(index, "name", e)
                  }
                />
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="flex-start"
                margin="auto"
                mr="0"
              >
                <DownArrowIcon
                  className={"blac-iconarrow"}
                  onClick={() => props.onMoveUp(index)}
                />
                <UpArrowIcon
                  className={"blac-iconarrow"}
                  onClick={() => props.onMoveDown(index)}
                />
              </Box>
            </Box>
          ))
        : null}
    </Box>
  );
};

export default ArrangeContainer;
