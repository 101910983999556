import { Grid } from "@mui/material";
import FormGroupTextField from "../../../../../../ui/formgroup/FormGroupTextField";
import TextAutoResize from "../../../../../../ui/formgroup/TextAutoResize";

const Step3 = ({ brandValuesProof, setBrandValuesProof, brandValues }) => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item lg={3} sm={6} md={3} xs={6}>
          <TextAutoResize
            label={brandValues[0]?.name || ""}
            other={{ minRows: 6, maxRows: 6 }}
            value={brandValuesProof.brandValueProof1}
            onChange={(e) =>
              setBrandValuesProof((old) => ({ ...old, brandValueProof1: e }))
            }
          />
        </Grid>
        <Grid item lg={3} sm={6} md={3} xs={6}>
          <TextAutoResize
            label={brandValues[1]?.name || ""}
            other={{ minRows: 6, maxRows: 6 }}
            value={brandValuesProof.brandValueProof2}
            onChange={(e) =>
              setBrandValuesProof((old) => ({ ...old, brandValueProof2: e }))
            }
          />
        </Grid>
        <Grid item lg={3} sm={6} md={3} xs={6}>
          <TextAutoResize
            label={brandValues[2]?.name || ""}
            other={{ minRows: 6, maxRows: 6 }}
            value={brandValuesProof.brandValueProof3}
            onChange={(e) =>
              setBrandValuesProof((old) => ({ ...old, brandValueProof3: e }))
            }
          />
        </Grid>
        <Grid item lg={3} sm={6} md={3} xs={6}>
          <TextAutoResize
            label={brandValues[3]?.name || ""}
            other={{ minRows: 6, maxRows: 6 }}
            value={brandValuesProof.brandValueProof4}
            onChange={(e) =>
              setBrandValuesProof((old) => ({ ...old, brandValueProof4: e }))
            }
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Step3;
