import { Box, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import DeleteButton from "../../ui/button/DeleteButton";
import FormGroupTextField from "../../ui/formgroup/FormGroupTextField";
import CustomDropzone from "../../ui/dropzone/CustomDropzone";
import { ReactComponent as Upload } from "../../../assets/images/upload.svg";
import { awsUpload } from "../../../utils/s3upload";
import { useState } from "react";
import { ClipLoader } from "react-spinners";
import { config } from "../../../config";

const useStyle = makeStyles((theme) => ({
  profileContainer: {
    background: "#F5F5F5",
    padding: "20px",
    minHeight: "190px",
    opacity: 1,
    borderRadius: "10px",
  },
  icon: {
    fill: theme.palette.secondary.main,
    height: "84px",
    width: "84px",
    margin: "auto",
  },
  imgContainer: {
    display: "flex",
    width: "100%",
    height: "190px",
    background: "#F2D9E7",
    borderRadius: "10px",
  },
  img: {
    maxWidth: "100%",
    maxHeight: "190px",
    margin: "auto",
  },
  imgc: {
    display: "flex",
    maxWidth: "100%",
    [theme.breakpoints.down("lg")]: {
      maxWidth: "260px",
    },
  },
}));
const CopetitorsCard = ({ name, logo, onDel, changeData, onUpdate }) => {
  const classes = useStyle();
  const [loading, setLoading] = useState(false);
  const handleDrop = async (file) => {
    setLoading(true);
    const fileData = new FormData();
    fileData.append("file", file);
    const fd = await awsUpload(fileData, true);
    if (fd) {
      changeData("logo", fd);
    }
    setLoading(false);
  };
  return (
    <Grid
      item
      className={classes.profileContainer}
      container
      lg={12}
      md={12}
      xs={12}
      sm={12}
      mb={3}
    >
      <Grid item lg={2.5} md={4} xs={4} sm={4}>
        {loading ? (
          <ClipLoader />
        ) : (
          <CustomDropzone
            isMulti={false}
            maxSize={1000000}
            handleDrop={(e) => {
              if (e && e[0]) handleDrop(e[0]);
            }}
            accept={{
              "image/png": [".png", ".jpg"],
            }}
            component={
              logo ? (
                <img
                  alt={config.generatePathDownloadPath(logo)}
                  src={config.generatePathDownloadPath(logo)}
                  className={classes.imgc}
                />
              ) : (
                <div className={classes.imgContainer}>
                  <Upload className={classes.icon} />
                </div>
              )
            }
          />
        )}
      </Grid>
      <Grid
        item
        lg={6}
        md={5}
        xs={5}
        sm={5}
        pl={3}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <FormGroupTextField
          label="Competitor Name"
          value={name}
          onChange={(e) => changeData("name", e)}
        />
        {/* <Box mb={3}>
          <CustomButton
            name="Update"
            size="medium"
            onclick={() => onUpdate()}
          />
        </Box> */}
      </Grid>
      <Grid item lg={3.5} md={3} xs={3} sm={3}>
        <Box display="flex">
          <Box ml="auto" mr="0">
            <DeleteButton onclick={onDel} />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default CopetitorsCard;
