import { Box, Stack } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import CustomButton from "../../../../../ui/button/CustomButton";
import TextAutoResize from "../../../../../ui/formgroup/TextAutoResize";

const MarketCovrage = (props) => {
  const [marketCoveragePriorities, setMarketCoveragePriorities] = useState("");
  const data = props?.td?.data;
  useEffect(() => {
    if (
      data &&
      data.marketCoveragePriorities &&
      typeof data.marketCoveragePriorities === "string"
    ) {
      setMarketCoveragePriorities(data.marketCoveragePriorities);
    }
  }, [props]);

  return (
    <>
      <Stack direction="row" gap="30px">
        <Box flex="0.2" display="flex" gap="10px" flexDirection="column">
          <Box color="secondary.main" fontSize="20px" fontWeight="800px">
            Market Coverage & Priorities (Metro / Regional)
          </Box>
          <Box mb={2} fontSize="14px"></Box>
        </Box>
        <Box flex="0.8">
          <TextAutoResize
            other={{ minRows: 6, maxRows: 6 }}
            value={marketCoveragePriorities}
            onChange={(e) => setMarketCoveragePriorities(e)}
          />
        </Box>
      </Stack>
      <Box display="flex" justifyContent="space-between" mt="auto" mb="0">
        <CustomButton
          name="<   Prev"
          onclick={() =>
            props.callApi({ marketCoveragePriorities }, true, false)
          }
          loading={props.btnLoading}
        />
        <CustomButton
          name="Next   >"
          disabled={!marketCoveragePriorities}
          onclick={() =>
            props.callApi({ marketCoveragePriorities }, true, true)
          }
          loading={props.btnLoading}
        />
      </Box>
    </>
  );
};

export default MarketCovrage;
