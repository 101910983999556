import { Box, Stack } from "@mui/material";
import { useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import CustomButton from "../../../../../ui/button/CustomButton";
import { config } from "../../../../../../config";

const useStyles = makeStyles((theme) => ({
  cardOuter: {
    backgroundColor: "white",
    border: "1px solid",
    borderRadius: "5px",
    padding: "10px",
    marginBottom: "10px",
    display: "flex",
    alignItems: "center",
    width: "60%",
  },
}));

const CreativeBriefCompetitor = (props) => {
  const classes = useStyles();
  const pd = useSelector((state) => state.project?.project);

  return (
    <>
      <Stack gap="30px">
        <Box
          color="secondary.main"
          fontSize="20px"
          fontWeight="800px"
          display="flex"
        >
          Who Are The Direct Competitors That Need To Be Considered For The
          Creative ?
        </Box>
        <Box>
          {pd?.competitors &&
            pd.competitors.length > 0 &&
            pd.competitors.map((comp) => (
              <Box className={classes.cardOuter}>
                {comp?.logo && (
                  <img
                    alt="Competitor Logo"
                    src={`${config.AWS_S3_MEDIA_BUCKET}/${comp.logo}`}
                    width={100}
                    height={50}
                    style={{ marginRight: "10px" }}
                  />
                )}
                <span>{comp.name}</span>
              </Box>
            ))}
        </Box>
      </Stack>
      <Box display="flex" justifyContent="space-between" mt="auto" mb="0">
        <CustomButton name="<   Prev" onclick={() => props.prvStep()} />
        <CustomButton name="Next   >" onclick={() => props.nextStep()} />
      </Box>
    </>
  );
};

export default CreativeBriefCompetitor;
