import { Divider, Grid } from "@mui/material";
import SelectButton from "../../../ui/button/SelectButton";
import moment from "moment";
import { researchMenuOption } from "../../../../utils/AllOptions";
import { useNavigate } from "react-router";

const PRList = (props) => {
  const loc = useNavigate();
  const handleOpen = () =>
    props?.data?.researchType === "CUSTOM"
      ? loc(`/research/view-Diy/${props.uuid}`)
      : loc(`/research/view-research/${props.uuid}`);

  return (
    <>
      <Grid item lg={9} md={7} sm={7} xs={7} margin="auto 0">
        <div className={props.generalClass.res} onClick={handleOpen}>
          {props.name}
        </div>
        <div className={props.generalClass.nameDate}>
          {(props.data &&
            props.data.researchType &&
            researchMenuOption[props.data.researchType]) ||
            "-"}
        </div>
      </Grid>
      <Grid item lg={2} md={3} sm={3} xs={3} margin="auto 0">
        <div className={props.generalClass.provider}>
          {(props.data &&
            props.data.researchType &&
            researchMenuOption[props.data.researchType]) ||
            "-"}
        </div>
        <div className={props.generalClass.nameDate}>
          {" "}
          {props.dateCreated
            ? moment(props.dateCreated).format("D MMM YY")
            : "-"}
        </div>
      </Grid>
      <Grid item lg={1} md={2} sm={2} xs={2} margin="auto 0">
        <SelectButton
          title="Open"
          color="red"
          titleClick={handleOpen}
          dropClick={(e) =>
            props.handleClick(e, {
              id: props.uuid,
              name: props.name,
              type:
                (props.data &&
                  props.data.researchType &&
                  researchMenuOption[props.data.researchType]) ||
                "-",
            })
          }
        />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} mb={2} mt={2}>
        <Divider
          variant="fullWidth"
          sx={{
            backgroundColor: "#D40841",
          }}
        />
      </Grid>
    </>
  );
};

export default PRList;
