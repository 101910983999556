import BrandvasIcon from "../../../assets/images/Brandvas.png";
import { SET_THEME, SET_DEFAULT_THEME, SET_THEME_API } from "../../constants";

const initState = {
  primaryColorHexCode: "#CAE2E6",
  secondaryColorHexCode: "#1D2D91",
  googleFont: "Rubik",
  logo: BrandvasIcon,
  whitelabelMode: false,
};

const themeReducer = (state = initState, action) => {
  switch (action.type) {
    case SET_THEME:
      return { ...state, ...action.payload.data };
    case SET_THEME_API:
      return { ...state, ...action.payload.data };
    case SET_DEFAULT_THEME:
      return {
        primaryColorHexCode: "#CAE2E6",
        secondaryColorHexCode: "#1D2D91",
        googleFont: "Rubik",
        logo: BrandvasIcon,
        whitelabelMode: false,
      };
    default:
      return state;
  }
};

export default themeReducer;
