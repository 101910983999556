import { Box, DialogContent, Grid, Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ModalContainer from "./ModalContainer";
import { ArrowForwardIos } from "@mui/icons-material";
import CustomButton from "../button/CustomButton";
import { useEffect, useRef, useState } from "react";
import SimpleReactValidator from "simple-react-validator";
import { api } from "../../../redux/api/api";
import FormGroupTextField from "../formgroup/FormGroupTextField";
import CustomPhone from "../formgroup/CustomPhone";
import TextAutoResize from "../formgroup/TextAutoResize";
import CustomCheckBox from "../checkbox/CustomCheckBox";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "20px",
    color: theme.palette.secondary.main,
    marginBottom: "10px",
    fontWeight: 600,
  },
  endIcon: {
    marginLeft: "auto !important",
  },
  btnContainer: { width: "40%", marginTop: "10px" },
  dialogContent: {
    height: "500px",
  },
  fullWidth: {
    width: "100%",
  },
  noteDiv: { fontSize: "12px" },
}));
const intialData = {
  name: "",
  agencyName: "",
  emailAddress: "",
  phone: "",
  phoneNumber: {
    number: "",
    internationalNumber: "",
    nationalNumber: "",
    e164Number: "",
    countryCode: "",
    dialCode: "",
  },
  message: "",
  category: "",
  region: "National",
  advertisers: "",
  budget: "Spend",
  dateRange: "",
  typeOfMedia: "",
  provider: "NIELSEN",
};
const NielsenEnquiryModel = ({ open, onClose }) => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    simpleValidator.current.hideMessages();
  }, [open]);

  const simpleValidator = useRef(new SimpleReactValidator());
  const [, setC] = useState();

  const [nielsenData, setNielsenData] = useState(intialData);

  const setData = (field, value) => {
    setNielsenData((old) => ({ ...old, [field]: value }));
  };

  const phoneNumGet = (val, info) => {
    setData("phone", val);
    const phoneNumber = {
      number: info.nationalNumber,
      internationalNumber: info.numberValue,
      nationalNumber: info.nationalNumber,
      e164Number: info.numberValue,
      countryCode: info.countryCode,
      dialCode: "+" + info.countryCallingCode,
    };
    setData("phoneNumber", phoneNumber);
  };

  const addClick = async () => {
    setC((old) => !old);
    if (simpleValidator.current.allValid()) {
      setLoading(true);
      const res = await api(
        "insights/enquiries",
        nielsenData,
        "post",
        "",
        "",
        "Your enquiry has been sent!"
      );
      if (res && res.success) {
        setNielsenData(intialData);
        onClose();
      }
      setLoading(false);
    } else {
      simpleValidator.current.showMessages();
    }
    setC((old) => !old);
  };

  return (
    <ModalContainer
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth={true}
    >
      <DialogContent className={classes.dialogContent}>
        <Grid container mt={0}>
          <Grid item lg={12} md={12} margin="auto" px="10px">
            <Stack mb="30px">
              <div className={classes.title}>Send Nielsen Enquiry</div>
            </Stack>
          </Grid>
          <Grid item lg={6} md={6} mb="15px" px="10px">
            <FormGroupTextField
              label="Name"
              value={nielsenData.name}
              onChange={(e) => setData("name", e)}
              required={true}
              validator={simpleValidator.current.message(
                "Name",
                nielsenData.name,
                "required",
                {
                  className: "errorClass",
                }
              )}
            />
          </Grid>
          <Grid item lg={6} md={6} mb="15px" px="10px">
            <FormGroupTextField
              label="Category"
              value={nielsenData.category}
              onChange={(e) => setData("category", e)}
              required={false}
            />
          </Grid>
          <Grid item lg={6} md={6} mb="15px" px="10px">
            <FormGroupTextField
              label="Agency"
              value={nielsenData.agencyName}
              onChange={(e) => setData("agencyName", e)}
              required={true}
              validator={simpleValidator.current.message(
                "Agency",
                nielsenData.agencyName,
                "required",
                {
                  className: "errorClass",
                }
              )}
            />
          </Grid>
          <Grid item lg={6} md={6} mb="15px" px="10px">
            <Box mb={"12px"} fontSize="14px">
              Region
            </Box>
            <Stack direction="row" gap="10px">
              <CustomCheckBox
                label="National"
                checked={nielsenData.region === "National"}
                onchange={(e) => setData("region", "National")}
              />
              <CustomCheckBox
                label="State"
                checked={nielsenData.region === "State"}
                onchange={(e) => setData("region", "State")}
              />
            </Stack>
          </Grid>
          <Grid item lg={6} md={6} mb="15px" px="10px">
            <FormGroupTextField
              label="Email Address"
              value={nielsenData.emailAddress}
              onChange={(e) => setData("emailAddress", e)}
              required={true}
              validator={simpleValidator.current.message(
                "Email Address",
                nielsenData.emailAddress,
                "required|email",
                {
                  className: "errorClass",
                }
              )}
            />
          </Grid>
          <Grid item lg={6} md={6} mb="15px" px="10px">
            <FormGroupTextField
              label="Advertisers"
              value={nielsenData.advertisers}
              onChange={(e) => setData("advertisers", e)}
              required={false}
            />
          </Grid>
          <Grid item lg={6} md={6} mb="15px" px="10px">
            <CustomPhone
              label="Phone Number"
              value={nielsenData.phone}
              onChange={(e, info) => phoneNumGet(e, info)}
              required={true}
              validator={simpleValidator.current.message(
                "Phone Number",
                nielsenData.phone,
                "required",
                {
                  className: "errorClass",
                }
              )}
            />
          </Grid>
          <Grid item lg={6} md={6} mb="15px" px="10px">
            <Box mb={"12px"} fontSize="14px">
              Budget
            </Box>
            <Stack direction="row" gap="10px">
              <CustomCheckBox
                label="Spend"
                checked={nielsenData.budget === "Spend"}
                onchange={(e) => setData("budget", "Spend")}
              />
              <CustomCheckBox
                label="Creative"
                checked={nielsenData.budget === "Creative"}
                onchange={(e) => setData("budget", "Creative")}
              />
            </Stack>
          </Grid>
          <Grid item lg={6} md={6} mb="15px" px="10px">
            <TextAutoResize
              label="Message"
              required={true}
              other={{ minRows: 10, maxRows: 10 }}
              value={nielsenData.message}
              onChange={(e) => setData("message", e)}
              validator={simpleValidator.current.message(
                "Message",
                nielsenData.message,
                "required",
                {
                  className: "errorClass",
                }
              )}
            />
          </Grid>
          <Grid item lg={6} md={6} mb="15px" px="10px">
            <Box mb="15px">
              <FormGroupTextField
                label="Date Range"
                value={nielsenData.dateRange}
                onChange={(e) => setData("dateRange", e)}
                required={false}
              />
            </Box>
            <FormGroupTextField
              label="Type of Media"
              value={nielsenData.typeOfMedia}
              onChange={(e) => setData("typeOfMedia", e)}
              required={false}
            />
          </Grid>
          <Grid item lg={12} md={12} mb="15px" px="10px">
            <div className={classes.btnContainer}>
              <CustomButton
                variant="contained"
                size="large"
                name={"Send Enquiry"}
                endIcon={<ArrowForwardIos />}
                fullWidth={true}
                iconClass={classes.endIcon}
                onclick={addClick}
                loading={loading}
              />
            </div>
          </Grid>
        </Grid>
      </DialogContent>
    </ModalContainer>
  );
};

export default NielsenEnquiryModel;
