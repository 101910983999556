import { Box, Divider, Stack } from "@mui/material";
import { cloneDeep } from "lodash";
import { useSelector } from "react-redux";
import CustomButton from "../../../../../ui/button/CustomButton";

const MediaBriefSummary = (props) => {
  const projectName = useSelector((state) => state.client?.projectId?.name);
  const clientName = useSelector((state) => state.client?.clientId?.name);

  const data = props?.td?.data || false;

  const brandCustom = () => {
    if (data) {
      const d1 = data.custom;
      const d2 =
        (data.exercises && data.exercises.filter((a) => a.custom)) || [];

      if (d1 && d2) {
        return d2.map((a) => (
          <Box>
            <Box mb={2}>Custom {a.name}</Box>
            <Stack gap="20px" direction="column" flexWrap="wrap" mb={2}>
              {a.questions && a.questions.length
                ? a.questions.map((b) =>
                    d1[b.uuid] ? (
                      <Box flex="0.5">
                        <Box mb="5px" fontSize="18px">
                          {b.name || ""}
                        </Box>
                        {customSelect(
                          b.title,
                          d1[b.uuid],
                          b["Question Multi Choice"]
                        )}
                      </Box>
                    ) : null
                  )
                : null}
            </Stack>
            <Divider />
          </Box>
        ));
      } else return <></>;
    } else {
      return <></>;
    }
  };
  const customSelect = (t, d, h) => {
    switch (t) {
      case "Text Only":
        return <Box fontSize="22px">{d || ""}</Box>;
      case "Question Yes/No":
        return <Box fontSize="22px">{d || ""}</Box>;
      case "Question - Select one":
        return <Box fontSize="22px">{d || ""}</Box>;
      case "Question Multi Choice": {
        const options = d && h && Object.keys(h).filter((a) => d[a]);
        if (options) {
          return (
            <Box fontSize="22px">
              {options.map((a) => (
                <li>{a}</li>
              ))}
            </Box>
          );
        }
      }
      case "Full Width Image":
        return <img src={d} alt={d} />;
      default:
        return <></>;
    }
  };

  const handleSections = () => {
    if (data && data.exercises) {
      const componets = [];
      const d1 = cloneDeep(data.exercises);
      const d2 = d1.filter(
        (a) =>
          !a.custom &&
          a.key !== "business" &&
          a.key !== "brand-personality" &&
          a.key !== "creative-brief-summary"
      );
      if (d2 && d2.length) {
        d2.forEach((a) => {
          if (a.key === "objectives" && data.objectives) {
            componets.push(
              <>
                <Box>Objectives</Box>
                <Box fontSize="20px">{data.objectives || ""}</Box>
                <Divider />
              </>
            );
          } else if (a.key === "creative" && data.creative) {
            componets.push(
              <>
                <Box>Creative</Box>
                <Box fontSize="20px">{data.creative || ""}</Box>
                <Divider />
              </>
            );
          } else if (
            a.key === "market-coverage-priorities" &&
            data.marketCoveragePriorities
          ) {
            componets.push(
              <>
                <Box>Market coverage & priorities</Box>
                <Box fontSize="20px">{data.marketCoveragePriorities || ""}</Box>
                <Divider />
              </>
            );
          } else if (a.key === "target-audience" && data.targetAudience) {
            componets.push(
              <>
                <Box mb={2}>Target audience</Box>
                <Box>Core customers</Box>
                <Box fontSize="20px">
                  {data.targetAudience.coreCustomer || ""}
                </Box>
                <Box>What do we want the target audience to think:</Box>
                <Box fontSize="20px">{data.targetAudience.think || ""}</Box>
                <Box>What do we want the target audience to feel:</Box>
                <Box fontSize="20px">{data.targetAudience.feel || ""}</Box>
                <Box>What do we want the target audience to do:</Box>
                <Box fontSize="20px">{data.targetAudience.do || ""}</Box>
                <Divider />
              </>
            );
          } else if (a.key === "advertising-timing" && data.advertisingTiming) {
            componets.push(
              <>
                <Box>Advertising timing</Box>
                <Box fontSize="20px">{data.advertisingTiming || ""}</Box>
                <Divider />
              </>
            );
          } else if (a.key === "budget" && data.budget) {
            componets.push(
              <>
                <Box>Budget</Box>
                <Box fontSize="20px">{data.budget || ""}</Box>
                <Divider />
              </>
            );
          } else if (a.key === "other-requirements" && data.otherRequirements) {
            componets.push(
              <>
                <Box>Other requirements</Box>
                <Box fontSize="20px">{data.otherRequirements || ""}</Box>
                <Divider />
              </>
            );
          } else if (a.key === "date-plan" && data.datePlan) {
            componets.push(
              <>
                <Box>Date plan</Box>
                <Box fontSize="20px">{data.datePlan || ""}</Box>
                <Divider />
              </>
            );
          }
        });
        return componets;
      }
    }
    return [];
  };
  return (
    <>
      <Stack gap="20px" padding="10px">
        <Box>Client: {clientName}</Box>
        <Box>Project: {projectName}</Box>
        <Divider />
        {handleSections()}
        {brandCustom()}
      </Stack>

      <Box display="flex" justifyContent="space-between" mt="auto" mb="0">
        <CustomButton name="<   Prev" onclick={() => props.prvStep()} />
        <CustomButton name="Next   >" onclick={() => props.nextStep()} />
      </Box>
    </>
  );
};

export default MediaBriefSummary;
