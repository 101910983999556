import { Box, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { api } from "../../../../../../redux/api/api";
import CustomButton from "../../../../../ui/button/CustomButton";
import OpeningScreen from "../OpeningScreen";
import DragDrop from "./DragDrop/DragDrop";

const Competitors = (props) => {
  const pd = useSelector((state) => state.project.project);
  const [loading, setLoading] = useState(false);
  const [competitor, setCompetitor] = useState([]);
  const [steps, setSteps] = useState(0);

  const updateCompetior = async (n) => {
    setLoading(true);
    const res = await api(
      `projects/${props.td.projectId}`,
      { competitors: competitor },
      "patch",
      "",
      "",
      "Details updated!"
    );
    if (res && res.success) {
      pd.competitors = competitor;
      if (n) {
        props.nextStep();
      } else {
        setSteps(0);
      }
    }
    setLoading(false);
  };
  useEffect(() => {
    if (pd && pd.competitors) {
      setCompetitor(pd.competitors);
    }
  }, [pd]);

  return (
    <>
      {steps === 0 ? (
        <OpeningScreen
          title="Understanding
        Your Competitors"
          description={
            <Box>
              All brands live in the context of their competitors. The way a
              customers (client) understands a brand’s positioning is relative
              to how it’s biggest competitors are positioned.
              <br />
              <br />
              For example a prestige car may be priced at $70,000 based on
              competitor set, where the equivalent size and spec’d Korean car
              may be priced at only $30,000.
              <br />
              <br />
              Price is just one dimension of brand positioning that sets the
              competitive context. Other dimensions include quality, design,
              performance, reliability, etc.
            </Box>
          }
          img={props?.sas?.img || ""}
        />
      ) : (
        <Stack direction="row" gap="30px">
          <Box flex="0.2" display="flex" gap="10px" flexDirection="column">
            <Box color="secondary.main" fontSize="20px" fontWeight="800px">
              Understanding What The Business Does
            </Box>
            <Box mb={2} fontSize="14px">
              All brands live in the context of their competitors. The way a
              customers (client) understands a brand’s positioning is relative
              to how it’s biggest competitors are positioned.
              <br />
              <br />
              For example a prestige car may be priced at $70,000 based on
              competitor set, where the equivalent size and spec’d Korean car
              may be priced at only $30,000.
              <br />
              <br />
              Price is just one dimension of brand positioning that sets the
              competitive context. Other dimensions include quality, design,
              performance, reliability, etc.
            </Box>
          </Box>
          <Box flex="0.8">
            <Box>Who do you consider your top 3 - 10 competitors to be?</Box>
            <DragDrop
              data={competitor || []}
              btnText="Add New Competitor"
              rightText="Drag and drop at least 3 competitors"
              setItemOnDrag={(data) => setCompetitor(data)}
            />
          </Box>
        </Stack>
      )}
      <Box display="flex" justifyContent="space-between" mt="auto" mb="0">
        <CustomButton
          name="<   Prev"
          onclick={() =>
            steps === 0 ? props.prvStep() : updateCompetior(false)
          }
          loading={loading}
        />
        <CustomButton
          name="Next   >"
          onclick={() => (steps === 0 ? setSteps(1) : updateCompetior(true))}
          loading={loading}
        />
      </Box>
    </>
  );
};

export default Competitors;
