import { Box, DialogContent, Divider, Grid, Stack } from "@mui/material";
import CustomButton from "../button/CustomButton";
import ModalContainer from "./ModalContainer";
import Img1 from "../../../assets/images/planeImg.png";
import { ArrowForwardIos } from "@mui/icons-material";
import { researchMenuOption } from "../../../utils/AllOptions";
import { useNavigate } from "react-router";

const PaymentModal = ({ open, onClose, modalData, pay = false }) => {
  const loc = useNavigate();
  return (
    <ModalContainer
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth={true}
    >
      <DialogContent
        style={{
          height: "450px",
        }}
      >
        <Grid container mt={2}>
          {pay ? (
            <Grid item lg={7} md={7} sm={7} xs={7} margin="auto" p={4} mt={6}>
              <Box
                fontSize="20px"
                color="secondary.main"
                marginBottom="20px"
                fontWeight={600}
                display="flex"
              >
                Payment Successful!
              </Box>
              <Divider />
              <Stack direction="row" mt={3} gap="20px">
                <Box width="40%">
                  <CustomButton
                    variant="contained"
                    size="large"
                    name="Access Now"
                    endIcon={<ArrowForwardIos />}
                    fullWidth={true}
                    iconClass={"mla"}
                    onclick={() => onClose()}
                  />
                </Box>
              </Stack>
            </Grid>
          ) : (
            <Grid item lg={7} md={7} sm={7} xs={7} margin="auto" p={4} mt={6}>
              <Box
                fontSize="20px"
                color="secondary.main"
                marginBottom="20px"
                fontWeight={600}
                display="flex"
              >
                Research Order Successful
              </Box>
              <Box
                marginBottom="7px"
                fontWeight={400}
                fontSize="14px !important"
              >
                Research Provider & Name
              </Box>
              <Box mb="16px">
                {researchMenuOption[modalData?.researchType || ""] || ""} /{" "}
                {modalData?.name || ""}
              </Box>
              <Divider />
              <Stack direction="row" mt={3} gap="20px">
                <Box width="40%">
                  <CustomButton
                    variant="contained"
                    size="large"
                    name="Access Now"
                    endIcon={<ArrowForwardIos />}
                    fullWidth={true}
                    iconClass={"mla"}
                    onclick={() =>
                      loc(`/research/view-research/${modalData?.rid}`)
                    }
                  />
                </Box>
              </Stack>
            </Grid>
          )}
          <Grid item lg={5} md={5} sm={5} xs={5}>
            <img src={Img1} alt={Img1} height="383px" />
          </Grid>
        </Grid>
      </DialogContent>
    </ModalContainer>
  );
};

export default PaymentModal;
