import { Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
// import HomeImg from "../../../assets/images/homeImg1.png";
const useStyles = makeStyles((theme) => ({
  homeTextImgDiv: {
    textAlign: "left",
    margin: "auto auto 8vh 16%",
    color: theme.palette.secondary.main,
    paddingTop: "49px",
  },
  homeMainTextImgDiv: {
    fontSize: "32px",
    fontWeight: 600,
  },
  homeInnerTextImgDiv: {
    fontSize: "32px",
  },
  homeMainImgDiv: {
    width: "100%",
    maxWidth: "850px",
    height: "auto",
    [theme.breakpoints.down("lg")]: {
      maxWidth: "600px",
    },
    [theme.breakpoints.down("md")]: {
      maxWidth: "520px",
    },
  },
  stackClass: {
    background: theme.palette.primary.main,
    minWidth: "1024px !important",
  },
}));
const PrivateHomeHeader = () => {
  const classes = useStyles();

  return (
    <Stack
      className={classes.stackClass}
      justifyContent="space-between"
      direction="row"
      flexWrap="wrap"
    >
      <div className={classes.homeTextImgDiv}>
        <div className={classes.homeMainTextImgDiv}>Welcome to Brandvas.</div>
        <div className={classes.homeInnerTextImgDiv}>Let's get started.</div>
      </div>
      {/* <img src={HomeImg} alt="home img" className={classes.homeMainImgDiv} /> */}
      <div className={classes.homeMainImgDiv}></div>
    </Stack>
  );
};

export default PrivateHomeHeader;
