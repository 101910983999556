import { api } from "../../api/api";
import {
  LOGOUT,
  msgShowFun,
  SET_DEFAULT_THEME,
  SET_LOGIN_USER,
  ERRORMSG,
  LOGINMSG,
  SET_THEME_API,
  SET_TOKENS,
  SET_SIDEBAR_LAYOUT_DATA,
  SET_ACTIVE_SIDEBAR_SECTION,
} from "../../constants";
import jwt_decode from "jwt-decode";
import BrandvasIcon from "../../../assets/images/Brandvas.png";
import { SidebargNavLayoutData } from "../../../utils/SideBarConstants";

export const setToken = (data) => {
  return async (dispatch) => {
    dispatch({
      type: SET_TOKENS,
      payload: { data: data },
    });
  };
};

export const logout = () => {
  return async (dispatch) => {
    localStorage.clear();
    dispatch({
      type: LOGOUT,
      payload: { data: "" },
    });
    dispatch({
      type: SET_DEFAULT_THEME,
    });
  };
};

export const login = (data) => {
  return async (dispatch) => {
    try {
      const res = await api(
        "login",
        data,
        "postWithoutToken",
        "",
        "",
        LOGINMSG
      );

      if (res && res.success) {
        const userJwt = jwt_decode(res.token);
        if (userJwt) {
          const userData = {
            firstName: userJwt.firstName,
            lastName: userJwt.lastName,
            actionsRequired: userJwt.actionsRequired,
            role: userJwt.role,
            agencies: userJwt.agencies[0] || "",
            email: data.emailAddress,
            name: userJwt.firstName + " " + userJwt.lastName,
            id: userJwt.identifier,
          };

          if (userData?.agencies?.uuid) {
            // const subsRes = await api(
            //   `agencies/${userData?.agencies?.uuid}/subscription`,
            //   "",
            //   "get",
            //   "",
            //   res.token,
            //   true,
            //   false,
            //   true
            // );
            // if (subsRes && subsRes.success) {
            //   dispatch({
            //     type: "GET_SUBS_DETAILS",
            //     payload: { data: subsRes },
            //   });
            // }
            const whiteLabelData = await api(
              `agencies/whitelabel/${userData.agencies.uuid}`,
              {},
              "get",
              "",
              res.token,
              "",
              true,
              false,
              true
            );
            if (whiteLabelData.success) {
              const whiteData = {
                ...whiteLabelData,
                logo: whiteLabelData.logo || BrandvasIcon,
              };

              if (whiteLabelData.whitelabelMode)
                dispatch({
                  type: SET_THEME_API,
                  payload: { data: whiteData },
                });
              else
                dispatch({
                  type: SET_THEME_API,
                  payload: { data: { uuid: whiteLabelData.uuid } },
                });
            } else {
              await api(
                `agencies/whitelabel`,
                {
                  agencyId: userData.agencies.uuid,
                  primaryColorHexCode: "#CAE2E6",
                  secondaryColorHexCode: "#1D2D91",
                  googleFont: "Rubik",
                  whitelabelMode: false,
                },
                "post",
                "",
                res.token,
                true,
                false,
                false
              );
            }
          }
          dispatch({
            type: SET_TOKENS,
            payload: { data: res },
          });
          dispatch({
            type: SET_LOGIN_USER,
            payload: { data: userData },
          });
          dispatch({
            type: SET_SIDEBAR_LAYOUT_DATA,
            payload: { data: SidebargNavLayoutData },
          });
          dispatch({
            type: SET_ACTIVE_SIDEBAR_SECTION,
            payload: { data: "Research" },
          });
        } else {
          msgShowFun("", ERRORMSG);
        }
      }
    } catch (error) {
      msgShowFun("", error);
    }
  };
};
