import { Box, Grid, Stack } from "@mui/material";
import { useEffect } from "react";
import { useMemo } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { api } from "../../../../../../redux/api/api";
import {
  b2bCategories,
  b2cCategories,
} from "../../../../../../utils/AllOptions";
import CustomButton from "../../../../../ui/button/CustomButton";
import CustomCheckBox from "../../../../../ui/checkbox/CustomCheckBox";
import CustomSelect from "../../../../../ui/formgroup/CustomSelect";
import FormGroupContainer from "../../../../../ui/formgroup/FormGroupContainer";
import FormGroupTextField from "../../../../../ui/formgroup/FormGroupTextField";
import TextAutoResize from "../../../../../ui/formgroup/TextAutoResize";

const ops = ["b2cRetailDirect", "b2cRetailChannel", "subscription"];
const business2 = [
  { uuid: "b2cRetailDirect", name: "B2C retail direct" },
  { uuid: "b2cRetailChannel", name: "B2C retail channel" },
  { uuid: "subscription", name: "Subscription (B2B or B2C)" },
];
const ops2 = [2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030];
const checkForValidation = (step, obj) => {
  if (
    step === 1 &&
    (!obj?.marketType ||
      !obj?.primaryService ||
      !obj?.clientLocation ||
      !obj?.businessGoals ||
      (!obj?.customCategoryDecision && !obj?.category) ||
      (obj?.customCategoryDecision && !obj?.customCategory) ||
      (obj?.futureCategoryDecision && !obj?.futureCategory))
  )
    return false;
  if (
    step === 2 &&
    (!obj?.projectObjectives ||
      (!obj?.businessMetricsDecision && !obj?.businessMetricsNoTemplate))
  )
    return false;
  return true;
};

const Business = (props) => {
  const pd = useSelector((state) => state.project.project);
  const [detailsData, setDetailsData] = useState({
    clientName: "",
    marketType: "b2b",
    category: b2bCategories[0],
    customCategory: "",
    primaryService: "",
    futureCategoryDecision: false,
    clientLocation: "",
    businessGoals: "",
    customCategoryDecision: false,
    futureCategory: "",
  });
  const [businessMatrix, setBusinessMatrix] = useState({
    averageCustomerLifetime: "",
    averagePerMonth: "",
    averageTransaction: "",
    averageUnitPrice: 0,
    businessMetricsDecision: true,
    businessMetricsNoTemplate: "",
    businessMetricsTemplate: "b2cRetailDirect",
    customerAcquisitionCost: "",
    projectObjectives: "",
    retailMarkup: 0,
    revenueAtMaturity: 2021,
    salesInUnits: 0,
    salesPerYear: "",
    subscribers: "",
  });
  useEffect(() => {
    if (pd) {
      if (pd.businessMetrics) {
        setBusinessMatrix(pd.businessMetrics);
      }
      if (pd.business) {
        setDetailsData(pd.business);
      }
    }
  }, [pd]);
  const updateBusiness = async (n) => {
    setLoading(true);
    let s = false;
    if (curr === 1) {
      const res = await api(
        `projects/${props.td.projectId}`,
        { business: detailsData },
        "patch",
        "",
        "",
        "Details updated!"
      );
      if (res && res.success) {
        pd.business = detailsData;
        s = true;
      }
    } else {
      const res = await api(
        `projects/${props.td.projectId}`,
        { businessMetrics: businessMatrix },
        "patch",
        "",
        "",
        "Details updated!"
      );
      if (res && res.success) {
        pd.businessMetrics = businessMatrix;
        s = true;
      }
    }
    if (s)
      if (n) {
        if (curr === 1) {
          setCurr(2);
        } else if (!props.saveBtn) {
          props.nextStep();
        }
      } else {
        if (curr === 2) {
          setCurr(1);
        } else {
          props.prvStep();
        }
      }
    setLoading(false);
  };
  const setData2 = (field, value) =>
    setBusinessMatrix((old) => ({ ...old, [field]: value }));

  const setData = (field, value) =>
    setDetailsData((old) => ({ ...old, [field]: value }));
  const [loading, setLoading] = useState(false);
  const [curr, setCurr] = useState(1);
  const Wholesale =
    businessMatrix.salesInUnits * businessMatrix.averageUnitPrice;
  const retail =
    Wholesale && businessMatrix.retailMarkup
      ? (Wholesale * businessMatrix.retailMarkup) / 100 + Wholesale
      : 0;
  const catOp = useMemo(
    () => (detailsData.marketType === "B2C" ? b2cCategories : b2bCategories),
    [detailsData.marketType]
  );
  return (
    <>
      <Stack
        direction={props.saveBtn ? "column" : "row"}
        gap={props.saveBtn ? "10px" : "30px"}
      >
        <Box
          flex={props.saveBtn ? "1" : "0.2"}
          display="flex"
          gap="10px"
          flexDirection="column"
        >
          <Box color="secondary.main" fontSize="20px" fontWeight="800px">
            Understanding What The Business Does
          </Box>
          <Box mb={2} fontSize="14px"></Box>
        </Box>
        {curr === 1 ? (
          <Box flex={props.saveBtn ? "1" : "0.8"}>
            <Grid
              item
              lg={10}
              md={9}
              sm={9}
              xs={9}
              pl={props.saveBtn ? 0 : 2}
              container
              spacing={3}
            >
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box mb={"12px"} fontSize="14px">
                  Type of market
                </Box>
                <Stack direction="row" gap="10px">
                  <CustomCheckBox
                    label="B2C"
                    checked={detailsData.marketType === "B2C"}
                    onchange={(e) => setData("marketType", "B2C")}
                  />
                  <CustomCheckBox
                    label="B2B"
                    checked={detailsData.marketType === "B2B"}
                    onchange={(e) => setData("marketType", "B2B")}
                  />
                </Stack>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <FormGroupContainer label="What industry / category Is the Business in?">
                  <Stack direction="row" gap="10px" mb={1}>
                    <CustomCheckBox
                      label="Select from the list below"
                      checked={!detailsData?.customCategoryDecision}
                      onchange={(e) => setData("customCategoryDecision", false)}
                    />
                    <CustomCheckBox
                      label="Custom category"
                      checked={detailsData?.customCategoryDecision}
                      onchange={(e) => setData("customCategoryDecision", true)}
                    />
                  </Stack>
                </FormGroupContainer>
                {detailsData?.customCategoryDecision ? (
                  <FormGroupTextField
                    border={props.saveBtn}
                    placeholder="Category is required"
                    other={{ minRows: 6, maxRows: 6 }}
                    value={detailsData.customCategory}
                    onChange={(e) => setData("customCategory", e)}
                  />
                ) : (
                  <CustomSelect
                    border={props.saveBtn}
                    options={catOp}
                    value={catOp.find(
                      (c) => c.uuid === detailsData?.category?.uuid
                    )}
                    onChange={(e) => setData("category", e)}
                  />
                )}
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextAutoResize
                  tf={props.saveBtn}
                  label="What is the primary product, service, or experience provided?"
                  other={{ minRows: 6, maxRows: 6 }}
                  value={detailsData.primaryService}
                  onChange={(e) => setData("primaryService", e)}
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextAutoResize
                  tf={props.saveBtn}
                  label="Where are your clients located?"
                  other={{ minRows: 6, maxRows: 6 }}
                  value={detailsData.clientLocation}
                  onChange={(e) => setData("clientLocation", e)}
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextAutoResize
                  tf={props.saveBtn}
                  label="Business goals"
                  other={{ minRows: 6, maxRows: 6 }}
                  value={detailsData.businessGoals}
                  onChange={(e) => setData("businessGoals", e)}
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box mb={"12px"} fontSize="14px">
                  Are there future categories planned?
                </Box>
                <Stack direction="row" gap="10px">
                  <CustomCheckBox
                    label="Yes"
                    checked={detailsData.futureCategoryDecision}
                    onchange={(e) => setData("futureCategoryDecision", true)}
                  />
                  <CustomCheckBox
                    label="No"
                    checked={detailsData.futureCategoryDecision === false}
                    onchange={(e) => setData("futureCategoryDecision", false)}
                  />
                </Stack>
                {detailsData.futureCategoryDecision ? (
                  <Box mt={3}>
                    <FormGroupTextField
                      border={props.saveBtn}
                      label="What is this new category?"
                      other={{ minRows: 6, maxRows: 6 }}
                      value={detailsData.futureCategory}
                      onChange={(e) => setData("futureCategory", e)}
                    />
                  </Box>
                ) : null}
              </Grid>
            </Grid>
          </Box>
        ) : (
          <Box flex="0.8">
            <Grid
              item
              lg={10}
              md={9}
              sm={9}
              xs={9}
              pl={props.saveBtn ? 0 : 2}
              container
              spacing={3}
            >
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box mb={"12px"} fontSize="14px">
                  Business metrics
                </Box>
                <Stack direction="row" gap="10px">
                  <CustomCheckBox
                    label="No template"
                    checked={!businessMatrix.businessMetricsDecision}
                    onchange={(e) => setData2("businessMetricsDecision", false)}
                  />
                  <CustomCheckBox
                    label="Use template"
                    checked={businessMatrix.businessMetricsDecision}
                    onchange={(e) => setData2("businessMetricsDecision", true)}
                  />
                </Stack>
              </Grid>
              {!businessMatrix.businessMetricsDecision ? (
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <TextAutoResize
                    tf={props.saveBtn}
                    label="Enter your own summary"
                    other={{ minRows: 6, maxRows: 6 }}
                    value={businessMatrix.businessMetricsNoTemplate}
                    onChange={(e) => setData2("businessMetricsNoTemplate", e)}
                  />
                </Grid>
              ) : (
                <Grid
                  container
                  spacing={3}
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                >
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <CustomSelect
                      options={business2}
                      label="Choose a template"
                      value={businessMatrix.businessMetricsTemplate}
                      id={true}
                      border={props.saveBtn}
                      onChange={(e) => setData2("businessMetricsTemplate", e)}
                    />
                  </Grid>
                  <Grid
                    item
                    lg={
                      businessMatrix.businessMetricsTemplate === ops[2] ? 4 : 6
                    }
                    md={
                      businessMatrix.businessMetricsTemplate === ops[2] ? 4 : 6
                    }
                    sm={
                      businessMatrix.businessMetricsTemplate === ops[2] ? 4 : 6
                    }
                    xs={
                      businessMatrix.businessMetricsTemplate === ops[2] ? 4 : 6
                    }
                  >
                    <CustomSelect
                      options={ops2}
                      border={props.saveBtn}
                      label="Revenue at maturity in"
                      value={businessMatrix.revenueAtMaturity}
                      onChange={(e) => setData2("revenueAtMaturity", e)}
                    />
                  </Grid>
                  {businessMatrix.businessMetricsTemplate === ops[0] ? (
                    <>
                      <Grid item lg={6} md={6} sm={6} xs={6}>
                        <FormGroupTextField
                          label="Sales (units)"
                          value={businessMatrix.salesInUnits}
                          onChange={(e) => setData2("salesInUnits", e)}
                          type="number"
                          border={props.saveBtn}
                        />
                      </Grid>
                      <Grid item lg={6} md={6} sm={6} xs={6}>
                        <FormGroupTextField
                          label="Avg unit price $"
                          value={businessMatrix.averageUnitPrice}
                          onChange={(e) => setData2("averageUnitPrice", e)}
                          type="number"
                          border={props.saveBtn}
                        />
                      </Grid>
                      <Grid item lg={6} md={6} sm={6} xs={6}>
                        <FormGroupTextField
                          label="Retail markup %"
                          border={props.saveBtn}
                          value={businessMatrix.retailMarkup}
                          onChange={(e) => setData2("retailMarkup", e)}
                          type="number"
                        />
                      </Grid>
                      {Wholesale ? (
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Stack
                            mb={"12px"}
                            fontSize="16px"
                            direction="row"
                            gap="16px"
                          >
                            <Box mt="auto" mb="auto">
                              {" "}
                              Wholesale
                            </Box>{" "}
                            <Box fontSize="18px" color="green">
                              {" "}
                              ${Wholesale}
                            </Box>
                          </Stack>
                          <Stack
                            mb={"12px"}
                            fontSize="16px"
                            direction="row"
                            gap="16px"
                          >
                            <Box mt="auto" mb="auto">
                              Retail
                            </Box>
                            <Box fontSize="18px" color="green">
                              {" "}
                              ${retail || Wholesale}
                            </Box>{" "}
                          </Stack>
                        </Grid>
                      ) : null}
                    </>
                  ) : businessMatrix.businessMetricsTemplate === ops[1] ? (
                    <>
                      <Grid item lg={6} md={6} sm={6} xs={6}>
                        <FormGroupTextField
                          label="Sales (units)"
                          value={businessMatrix.salesInUnits}
                          onChange={(e) => setData2("salesInUnits", e)}
                          type="number"
                          border={props.saveBtn}
                        />
                      </Grid>
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <FormGroupTextField
                          label="Avg unit price $"
                          value={businessMatrix.averageUnitPrice}
                          onChange={(e) => setData2("averageUnitPrice", e)}
                          type="number"
                          border={props.saveBtn}
                        />
                      </Grid>
                      {Wholesale ? (
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Stack
                            mb={"12px"}
                            fontSize="16px"
                            direction="row"
                            gap="16px"
                          >
                            <Box mt="auto" mb="auto">
                              Wholesale
                            </Box>
                            <Box fontSize="18px" color="green">
                              {" "}
                              ${Wholesale}
                            </Box>
                          </Stack>
                        </Grid>
                      ) : null}
                    </>
                  ) : (
                    <>
                      <Grid item lg={4} md={4} sm={4} xs={4}>
                        <FormGroupTextField
                          label="Subscribers"
                          value={businessMatrix.subscribers}
                          onChange={(e) => setData2("subscribers", e)}
                          type="number"
                          border={props.saveBtn}
                        />
                      </Grid>{" "}
                      <Grid item lg={4} md={4} sm={4} xs={4}>
                        <FormGroupTextField
                          label="Avg per month $"
                          value={businessMatrix.averagePerMonth}
                          onChange={(e) => setData2("averagePerMonth", e)}
                          type="number"
                          border={props.saveBtn}
                        />
                      </Grid>{" "}
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Stack
                          mb={"12px"}
                          fontSize="16px"
                          direction="row"
                          gap="16px"
                        >
                          <Box mt="auto" mb="auto">
                            Annual Recurring Revenue (ARR)
                          </Box>
                          <Box fontSize="18px" color="green">
                            {" "}
                            {businessMatrix.subscribers &&
                            businessMatrix.averagePerMonth
                              ? ` $${
                                  businessMatrix.subscribers *
                                  businessMatrix.averagePerMonth *
                                  12
                                } `
                              : ``}
                          </Box>
                        </Stack>
                      </Grid>
                      <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        mb="2"
                        fontStyle={"italic"}
                      >
                        <Box mb={"12px"} fontSize="16px">
                          Per customer:
                        </Box>
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Stack
                          mb={"12px"}
                          fontSize="16px"
                          direction="row"
                          gap="16px"
                        >
                          <Box mt="auto" mb="auto">
                            {" "}
                            Annual Recurring Revenue (ARR)
                          </Box>
                          <Box fontSize="18px" color="green">
                            {businessMatrix.subscribers &&
                            businessMatrix.averagePerMonth
                              ? ` $${businessMatrix.averagePerMonth * 12} `
                              : ``}
                          </Box>
                        </Stack>
                      </Grid>
                      <Grid item lg={6} md={6} sm={6} xs={6}>
                        <FormGroupTextField
                          label="Customer Acquisition Cost (CAC) $"
                          value={businessMatrix.customerAcquisitionCost}
                          onChange={(e) =>
                            setData2("customerAcquisitionCost", e)
                          }
                          type="number"
                          border={props.saveBtn}
                        />
                      </Grid>{" "}
                      <Grid item lg={7} md={7} sm={7} xs={7}>
                        <Stack
                          mb={"12px"}
                          fontSize="16px"
                          direction="row"
                          gap="16px"
                        >
                          <Box mt="auto" mb="auto">
                            {" "}
                            ARR-CAC val{" "}
                          </Box>

                          <Box fontSize="18px" color="green">
                            {" "}
                            {businessMatrix.subscribers &&
                            businessMatrix.averagePerMonth &&
                            businessMatrix.customerAcquisitionCost > -1
                              ? ` $${
                                  businessMatrix.averagePerMonth * 12 -
                                  businessMatrix.customerAcquisitionCost
                                } `
                              : ``}
                          </Box>
                        </Stack>
                      </Grid>
                      <Grid item lg={6} md={6} sm={6} xs={6}>
                        <FormGroupTextField
                          label={`Avg Customer Lifetime = ${
                            businessMatrix.averageCustomerLifetime > 0
                              ? (
                                  (1 / businessMatrix.averageCustomerLifetime) *
                                  100
                                ).toFixed(2)
                              : 0
                          }% churn years`}
                          value={businessMatrix.averageCustomerLifetime}
                          onChange={(e) =>
                            setData2("averageCustomerLifetime", e)
                          }
                          type="number"
                          border={props.saveBtn}
                        />
                      </Grid>{" "}
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Stack
                          mb={"12px"}
                          fontSize="16px"
                          direction="row"
                          gap="16px"
                        >
                          <Box mt="auto" mb="auto">
                            {" "}
                            Customer Lifetime Value (CLV) value
                          </Box>
                          <Box fontSize="18px" color="green">
                            {" "}
                            {businessMatrix.subscribers &&
                            businessMatrix.averagePerMonth &&
                            businessMatrix.averageCustomerLifetime > -1
                              ? ` $${
                                  businessMatrix.averagePerMonth *
                                  12 *
                                  businessMatrix.averageCustomerLifetime
                                } `
                              : ``}
                          </Box>
                        </Stack>
                      </Grid>{" "}
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Stack
                          mb={"12px"}
                          fontSize="16px"
                          direction="row"
                          gap="16px"
                        >
                          <Box mt="auto" mb="auto">
                            CLV-CAC value
                          </Box>
                          <Box fontSize="18px" color="green">
                            {" "}
                            {businessMatrix.subscribers &&
                            businessMatrix.averagePerMonth &&
                            businessMatrix.averageCustomerLifetime > -1
                              ? ` $${
                                  businessMatrix.averagePerMonth *
                                    12 *
                                    businessMatrix.averageCustomerLifetime -
                                  businessMatrix.customerAcquisitionCost
                                } `
                              : ``}
                          </Box>
                        </Stack>
                      </Grid>
                    </>
                  )}
                </Grid>
              )}
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextAutoResize
                  label="Project objectives"
                  other={{ minRows: 6, maxRows: 6 }}
                  value={businessMatrix.projectObjectives}
                  onChange={(e) => setData2("projectObjectives", e)}
                  tf={props.saveBtn}
                />
              </Grid>
            </Grid>
          </Box>
        )}
      </Stack>
      <Box
        display="flex"
        justifyContent="space-between"
        mt={props.saveBtn ? 3 : "auto"}
        mb="0"
      >
        {!props.saveBtn ? (
          <CustomButton
            name="<   Prev"
            onclick={() => updateBusiness(false)}
            loading={loading}
          />
        ) : props.saveBtn && curr === 2 ? (
          <CustomButton
            name="<   Prev"
            onclick={() => updateBusiness(false)}
            loading={loading}
          />
        ) : null}
        <CustomButton
          name={props.saveBtn && curr === 2 ? "Save" : "Next   >"}
          disabled={
            !checkForValidation(curr, curr === 1 ? detailsData : businessMatrix)
          }
          onclick={() =>
            checkForValidation(
              curr,
              curr === 1 ? detailsData : businessMatrix
            ) && updateBusiness(true)
          }
          loading={loading}
          styled={props.saveBtn ? { marginLeft: "auto", marginRight: 0 } : {}}
        />
      </Box>
    </>
  );
};

export default Business;
