import { bindActionCreators } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useDispatch } from "react-redux";

const useBindActionCreator = (actionToDispatch = {}) => {
  const dispatch = useDispatch();

  return useMemo(() => {
    return bindActionCreators({ ...actionToDispatch }, dispatch);
  }, [dispatch]);
};
export default useBindActionCreator;
