import { Menu } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  paperClass: {
    fontFamily: `${theme.typography.fontFamily} !important`,
    overflow: "visible",
    elevation: 0,
    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
    "& .MuiAvatar-root": {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1,
    },
    "&:before": {
      content: '""',
      display: "block",
      position: "absolute",
      top: 0,
      right: 14,
      width: 10,
      height: 10,
      bgcolor: "background.paper",
      zIndex: 0,
    },
    borderRadius: "18px",
  },
}));

const HeaderMenu = (props) => {
  const classes = useStyles();

  return (
    <Menu
      anchorEl={props.enchorEl}
      open={props.openP}
      onClose={props.handleClose}
      PaperProps={{
        sx: { ...props.sxObj },
      }}
      classes={{
        paper: classes.paperClass,
      }}
      transformOrigin={props.transformOrigin}
      anchorOrigin={props.anchorOrigin}
    >
      {props.children}
    </Menu>
  );
};

export default HeaderMenu;
