import {
  SET_SIDEBAR_LAYOUT_DATA,
  SET_ACTIVE_SIDEBAR_SECTION,
} from "../../constants";

const initState = {
  sidebarLayoutData: [],
  activeSidebarSection: "Research",
};

const sideBarReducer = (state = initState, action) => {
  switch (action.type) {
    case SET_SIDEBAR_LAYOUT_DATA:
      return { ...state, sidebarLayoutData: action.payload.data };
    case SET_ACTIVE_SIDEBAR_SECTION:
      return { ...state, activeSidebarSection: action.payload.data };
    default:
      return state;
  }
};

export default sideBarReducer;
