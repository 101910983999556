import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { memo } from "react";

const useStyle = makeStyles((theme) => ({
  title: {
    fontSize: "28px",
    color: `${theme.palette.secondary.main} !important`,
    marginBottom: "10px",
  },
  subTitle: {
    fontSize: "18px",
  },
}));

const RLTitle = ({ title, subTitle, mb = 4 }) => {
  const classes = useStyle();
  return (
    <Grid lg={12} md={12} sm={12} xs={12} mt={4} mb={mb}>
      <div className={classes.title}>{title}</div>
      <div className={classes.subTitle}>{subTitle}</div>
    </Grid>
  );
};

export default memo(RLTitle);
