import { Grid } from "@mui/material";
import RLeft from "./RLeft/RLeft";
import PrivateSidebar from "../layout/PrivateSidebar";
import RRight from "./RRight/RRight";
import RComment from "./RComment";
import { useLocation } from "react-router";

const ResearchComponent = () => {
  const loc = useLocation().pathname;
  return (
    <>
      <Grid container>
        {loc === "/research" ? <RLeft /> : <PrivateSidebar />}
        <RRight />
      </Grid>
      <RComment />
    </>
  );
};

export default ResearchComponent;
