import { Box, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import moment from "moment";
import CustomButton from "../ui/button/CustomButton";
import SubscriptionModal from "../ui/modals/SubscriptionModal";

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: "#f5ddeb",
    minWidth: "1024px",
    padding: "20px 0",
  },
  textContainer: {
    color: theme.palette.secondary.main,
    fontSize: "18px",
    paddingLeft: "3em",
  },
  trialCount: {
    color: theme.palette.secondary.main,
    fontSize: "18px",
    paddingLeft: "3em",
    fontWeight: "bold",
    marginBottom: "10px",
    "& span": {
      backgroundColor: theme.palette.secondary.main,
      color: "#fff",
      padding: ".4em .5em",
    },
  },
  selBtn: {
    paddingRight: "3em",
    textAlign: "right",
  },
}));
const SubscriptionBanner = ({ freeTrialEnd }) => {
  const classes = useStyles();
  const today = moment();
  const trialEnd = moment(freeTrialEnd);
  const totalDays = trialEnd.diff(today, "days");
  const [open, setOpen] = useState(false);

  return (
    <>
      <div className={classes.container}>
        <Grid container spacing={2}>
          <Grid item lg={8} md={8} sm={8} xs={8} margin="auto">
            {today > trialEnd ? (
              <Box className={classes.textContainer}>
                Your free trial has ended, sign up now to access all of
                Brandvas' great features!
              </Box>
            ) : (
              <>
                <Box className={classes.trialCount}>
                  Just <span>{totalDays} days</span> to go on your trial.
                </Box>
                <Box className={classes.textContainer}>
                  Sign up for an annual plan before your trial ends with the
                  promo code <b>"tenoff"</b> to get 10% off!
                </Box>
              </>
            )}
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={4}>
            <Box className={classes.selBtn}>
              <CustomButton
                variant="contained"
                size="large"
                name="Select Plan"
                onclick={() => setOpen(true)}
              />
            </Box>
          </Grid>
        </Grid>
      </div>
      <SubscriptionModal
        open={open}
        onClose={() => setOpen(false)}
        closable={true}
      />
    </>
  );
};

export default SubscriptionBanner;
