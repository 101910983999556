import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import CustomButton from "../../../../../ui/button/CustomButton";
import CustomCheckBox from "../../../../../ui/checkbox/CustomCheckBox";
import FormGroupTextField from "../../../../../ui/formgroup/FormGroupTextField";
import OpeningScreen from "../OpeningScreen";

const brandArchetypes = {
  selected: "",
  brandArchetypes: [
    {
      group: "Yearning for paradise",
      numberInGroup: 3,
      position: 0,
      name: "The Innocent",
      key: "innocent",
      image: "the-innocent.png",
      brandValues: ["Simple", "Elegant", "Free-spirited", "Honest", "Honest"],
      motto: "Free to be you and me",
      broadApproach: "Do things right",
      notes: "",
    },
    {
      group: "Yearning for paradise",
      numberInGroup: 3,
      position: 1,
      name: "The Explorer",
      key: "explorer",
      image: "the-explorer.png",
      brandValues: [
        "Mysterious",
        "Curious",
        "Tactile",
        "Innovative",
        "Courageous",
        "Driven",
        "Inspiring",
        "Inquisitive",
        "Open minded",
        "Adventurous",
      ],
      motto: "Don't box me in",
      broadApproach: "Journey, escape, experience",
      notes: "",
    },
    {
      group: "Yearning for paradise",
      numberInGroup: 3,
      position: 2,
      name: "The Sage",
      key: "sage",
      image: "the-sage.png",
      brandValues: [
        "Wise",
        "Considered",
        "Careful",
        "Fair",
        "Respectful",
        "Integrity",
        "Logical",
      ],
      motto: "The truth will set you free",
      broadApproach: "Reflective, self-aware",
      notes: "",
    },
    {
      group: "Leaving a mark on the world",
      numberInGroup: 3,
      position: 0,
      name: "The Hero",
      key: "hero",
      image: "the-hero.png",
      brandValues: [
        "Strong",
        "Tough",
        "Smart",
        "Bold",
        "Courageous",
        "Integrity",
        "Heroism",
        "Inspiring",
      ],
      motto: "Where there's a will, there's a way",
      broadApproach: "Strong, powerful",
      notes: "",
    },
    {
      group: "Leaving a mark on the world",
      numberInGroup: 3,
      position: 1,
      name: "The Outlaw",
      key: "outlaw",
      image: "the-outlaw.png",
      brandValues: [
        "Cunning",
        "Wiley",
        "Disruptive",
        "Risk taker",
        "Aggressive",
        "Competitive",
      ],
      motto: "Rules are meant to be broken",
      broadApproach: "Disrupt, destroy, shock",
      notes: "",
    },
    {
      group: "Leaving a mark on the world",
      numberInGroup: 3,
      position: 2,
      name: "The Magician",
      key: "magician",
      image: "the-magician.png",
      brandValues: ["Surprising", "Challenging", "Magical", "Entertaining"],
      motto: "It can happen",
      broadApproach: "Develop vision and live it",
      notes: "",
    },
    {
      group: "No one is an island",
      numberInGroup: 3,
      position: 0,
      name: "The Regular",
      key: "regular",
      image: "the-regular.png",
      brandValues: [
        "Dependable",
        "Reliable",
        "Strong",
        "Fair",
        "Honest",
        "Disciplined",
        "Responsible",
        "Collaborative",
      ],
      motto: "All of us are created equal",
      broadApproach: "Common touch, blend in",
      notes: "",
    },
    {
      group: "No one is an island",
      numberInGroup: 3,
      position: 1,
      name: "The Lover",
      key: "lover",
      image: "the-lover.png",
      brandValues: ["Fun", "Tactile", "Intimate", "Passionate", "Affectionate"],
      motto: "I only have eyes for you",
      broadApproach: "Connect and attract",
      notes: "",
    },
    {
      group: "No one is an island",
      numberInGroup: 3,
      position: 2,
      name: "The Jester",
      key: "jester",
      image: "the-jester.png",
      brandValues: [
        "Fun",
        "Funny",
        "Energetic",
        "Irreverant",
        "Cheeky",
        "Inquisitive",
        "Joyful",
      ],
      motto: "If I can't dance, I don't want to be part of your revolution",
      broadApproach: "Play, fun, entertain",
      notes: "",
    },
    {
      group: "Providing structure and support to the world",
      numberInGroup: 3,
      position: 0,
      name: "The Caregiver",
      key: "caregiver",
      image: "the-caregiver.png",
      brandValues: [
        "Sensitive",
        "Caring",
        "Considerate",
        "Respectful",
        "Responsible",
        "Comforting",
        "Compassionate",
        "Kind",
      ],
      motto: "Love your neighbour as yourself",
      broadApproach: "Do good for others",
      notes: "",
    },
    {
      group: "Providing structure and support to the world",
      numberInGroup: 3,
      position: 1,
      name: "The Creator",
      key: "creator",
      image: "the-creator.png",
      brandValues: [
        "Artistic",
        "Skillful",
        "Creative",
        "Bold",
        "Passionate",
        "Driven",
        "Inspiring",
        "Visionary",
      ],
      motto: "If it can be imagined, it can be created",
      broadApproach: "Artistic control and skill",
      notes: "",
    },
    {
      group: "Providing structure and support to the world",
      numberInGroup: 3,
      position: 2,
      name: "The Ruler",
      key: "ruler",
      image: "the-ruler.png",
      brandValues: [
        "Respected",
        "Smart",
        "Impactful",
        "Fair",
        "Courageous",
        "Integrity",
        "Driven",
        "Powerful",
        "Empowering",
        "Responsible",
      ],
      motto: "Power isn't everything, it's the only thing",
      broadApproach: "Expert leadership",
      notes: "",
    },
  ],
};

const BrandArchType = (props) => {
  const isFullScreen = useSelector((state) => state?.project?.workshop);
  const [data, setData] = useState(brandArchetypes);
  const [step, setStep] = useState(0);
  useEffect(() => {
    if (props.td && props.td.data && props.td.data.brandArchetypes) {
      setData(props.td.data.brandArchetypes);
    }
  }, []);

  const getCardInnerHtml = (item, index) => {
    return (
      <>
        {index === 0 && (
          <Box
            color="secondary.main"
            fontSize="20px"
            marginBottom="10px"
            fontWeight="800px"
          >
            {item?.group}
          </Box>
        )}
        <Card
          onClick={() => setData((old) => ({ ...old, selected: item.key }))}
          sx={{
            marginBottom: "44px",
            background: data.selected === item.key ? "#1d2d9145" : "#FFF",
          }}
        >
          <CardActionArea>
            <CardMedia
              component="img"
              minWidth="180"
              width="auto"
              image={`./../../../../../static/archetypes/${item?.image}`}
              alt="green iguana"
            />

            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                {item?.name}
              </Typography>
              <Typography variant="h6" color="text.secondary">
                Motto: {item?.motto}
              </Typography>
              <Typography variant="h6" color="text.secondary">
                Brand Approach: {item?.broadApproach}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </>
    );
  };
  return (
    <>
      {step === 0 ? (
        <OpeningScreen
          title="Brand Archetypes"
          description={
            <>
              The concept of Brand Archetypes was popularised by Margaret Mark &
              Carol Pearson in their book The Hero and the Outlaw
              <br />
              <br />
              Here we have replicated this exercise to help identity your
              brand's best archetype.
            </>
          }
          img={props?.sas?.img || ""}
          bestPratice={props?.sas?.workshop || false}
          bestEx={props?.sas?.workshopImg || []}
          name={props?.sas?.name || ""}
        />
      ) : !isFullScreen ? (
        <>
          <Stack direction="row" gap="30px">
            <Box flex="0.2" display="flex" gap="10px" flexDirection="column">
              <Box color="secondary.main" fontSize="20px" fontWeight="800px">
                Brand Archetypes
              </Box>
              <Box mb={2} fontSize="14px">
                Select the archetype that best represents the brand - where it
                is now, or where you would like it to be. Collect client group
                feedback on archetype/that most strongly represents the
                desired/brand persona.
              </Box>
            </Box>
            <Box flex="0.8">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="left" width="15%">
                      Archetype
                    </TableCell>
                    <TableCell align="left" width="30%">
                      Motto
                    </TableCell>
                    <TableCell align="left" width="30%">
                      Broad approach
                    </TableCell>
                    <TableCell align="left">Select</TableCell>
                    <TableCell align="left" width="24%">
                      Notes
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data && data.brandArchetypes
                    ? data.brandArchetypes.map((a, idx) => (
                        <TableRow key={a.key}>
                          <TableCell align="left">{a.name}</TableCell>
                          <TableCell align="left">{a.motto}</TableCell>
                          <TableCell align="left">{a.broadApproach}</TableCell>
                          <TableCell align="center">
                            <CustomCheckBox
                              checked={
                                data?.selected
                                  ? data?.selected === a.key
                                  : false
                              }
                              onchange={() =>
                                setData((old) => ({ ...old, selected: a.key }))
                              }
                            />
                          </TableCell>
                          <TableCell align="left">
                            <FormGroupTextField
                              placeholder="notes"
                              value={a.notes}
                              onChange={(e) => {
                                const dd = { ...data };
                                dd.brandArchetypes[idx].notes = e;
                                setData(dd);
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      ))
                    : null}
                </TableBody>
              </Table>
            </Box>
          </Stack>
        </>
      ) : (
        <>
          <Stack>
            <Box>
              <Box color="secondary.main" fontSize="20px" fontWeight="800px">
                Brand archetypes
              </Box>
              <Grid container pt={2}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Box>
                    Select the archetype that best represents the brand - where
                    it is now, or where you would like it to be.
                  </Box>
                  <br />
                  <br />
                </Grid>
                <Grid
                  item
                  lg={6}
                  md={6}
                  sm={6}
                  xs={6}
                  sx={{
                    paddingRight: "14px",
                    paddingBottom: "14px",
                    borderRight: "1px solid #dce0e4",
                    borderBottom: "1px solid #dce0e4",
                  }}
                >
                  {data &&
                    data.brandArchetypes &&
                    data.brandArchetypes.slice(0, 3) &&
                    data.brandArchetypes
                      .slice(0, 3)
                      .map((item, index) => (
                        <>{getCardInnerHtml(item, index)}</>
                      ))}
                </Grid>
                <Grid
                  item
                  lg={6}
                  md={6}
                  sm={6}
                  xs={6}
                  sx={{
                    paddingLeft: "14px",
                    paddingBottom: "14px",
                    borderBottom: "1px solid #dce0e4",
                  }}
                >
                  {data &&
                    data.brandArchetypes &&
                    data.brandArchetypes.slice(3, 6) &&
                    data.brandArchetypes
                      .slice(3, 6)
                      .map((item, index) => (
                        <>{getCardInnerHtml(item, index)}</>
                      ))}
                </Grid>
                <Grid
                  item
                  lg={6}
                  md={6}
                  sm={6}
                  xs={6}
                  sx={{
                    paddingRight: "14px",
                    paddingBottom: "14px",
                    paddingTop: "14px",
                    borderRight: "1px solid #dce0e4",
                  }}
                >
                  {data &&
                    data.brandArchetypes &&
                    data.brandArchetypes.slice(6, 9) &&
                    data.brandArchetypes
                      .slice(6, 9)
                      .map((item, index) => (
                        <>{getCardInnerHtml(item, index)}</>
                      ))}
                </Grid>
                <Grid
                  item
                  lg={6}
                  md={6}
                  sm={6}
                  xs={6}
                  sx={{
                    paddingLeft: "14px",
                    paddingTop: "14px",
                    paddingBottom: "14px",
                  }}
                >
                  {data &&
                    data.brandArchetypes &&
                    data.brandArchetypes.slice(9, 12) &&
                    data.brandArchetypes
                      .slice(9, 12)
                      .map((item, index) => (
                        <>{getCardInnerHtml(item, index)}</>
                      ))}
                </Grid>
              </Grid>
            </Box>
            <br />
            <br />
            <br />
            <br />
          </Stack>
        </>
      )}
      <Box display="flex" justifyContent="space-between" mt="auto" mb="0">
        <CustomButton
          name="<   Prev"
          loading={props.btnLoading}
          onclick={async () => {
            if (step === 0) {
              props.prvStep();
            } else {
              await props.callApi({ brandArchetypes: data });
              setStep(0);
            }
          }}
        />
        <CustomButton
          name="Next   >"
          onclick={async () => {
            if (step === 1) {
              props.callApi({ brandArchetypes: data }, true, true);
            } else {
              setStep(1);
            }
          }}
          loading={props.btnLoading}
        />
      </Box>
    </>
  );
};

export default BrandArchType;
