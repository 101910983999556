import { Box } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import CustomButton from "../../../../../../ui/button/CustomButton";
import OpeningScreen from "../../OpeningScreen";
import { v4 as uuidv4 } from "uuid";
import Step1 from "./Step1";
import { cloneDeep } from "lodash";
import Step2 from "./Step2";
import Step3 from "./Step3";

const BrandEssence = (props) => {
  // comparativeStrengths;
  const [steps, setSteps] = useState(0);
  const [brandDifferentiatorsData, setBrandDifferentiatorsData] = useState([]);
  const [brandValuesData, setBrandValuesData] = useState([]);
  const [draftBrandEssencesData, setDraftBrandEssencesData] = useState([]);

  const [leftSlide, setLeftSlide] = useState([]);
  const [rightSlide, setRightSlide] = useState([]);
  const [downSlide, setDownSlide] = useState([]);
  const [lidx, setLidx] = useState("");
  const [ridx, setRidx] = useState("");
  const [didx, setDidx] = useState("");

  const [thisSide, setThisSide] = useState("");
  const [indentifyStart, setIndentifyStart] = useState(false);

  const switchBox = (idx = "", left = "") => {
    const ls = cloneDeep(leftSlide);
    const rs = cloneDeep(rightSlide);
    const ds = cloneDeep(downSlide);

    if (idx !== "") {
      if (left === "left" && thisSide === "right") {
        const ele = ls[idx];
        ele.hidden = true;
        if (rs && rs.length === 2) rs.splice(0, 1);
        let obj = {};
        obj.name = ele.name;
        obj.uuid = uuidv4();
        obj.key = ele.key;
        rs.push(obj);
        setLidx(() => "");
      } else if (left === "right" && thisSide === "left") {
        const ele = rs[idx];
        const dsIndx = ds && ds.findIndex((item) => item.key === ele.key);
        if (dsIndx !== -1) {
          ds.splice(dsIndx, 1);
          setDidx(() => "");
        }
        ele.hidden = false;
        rs.splice(idx, 1);
        setRidx(() => "");
      } else if (left === "right" && thisSide === "down") {
        const ele = rs[idx];
        ele.hidden = true;
        if (ds && ds.length === 1) ds.splice(0, 1);
        let obj = {};
        obj.name = ele.name;
        obj.key = ele.key;
        obj.uuid = uuidv4();
        ds.push(obj);
        setDidx(() => "");
      } else if (left === "down" && thisSide === "right") {
        const ele = ds[idx];
        ele.hidden = false;
        ds.splice(idx, 1);
        setDidx(() => "");
      }
    }
    setLeftSlide(() => ls);
    setRightSlide(() => rs);
    setDownSlide(() => ds);
  };
  const onMoveUpG = (Fidx, dd) => {
    if (dd.length > 1) {
      if (Fidx - 1 < 0) {
        [dd[dd.length - 1], dd[Fidx]] = [dd[Fidx], dd[dd.length - 1]];
      } else {
        [dd[Fidx - 1], dd[Fidx]] = [dd[Fidx], dd[Fidx - 1]];
      }
    }
    return dd;
  };
  const onMoveDownG = (Fidx, dd) => {
    if (dd.length > 1) {
      if (Fidx + 1 > dd.length - 1) {
        [dd[0], dd[Fidx]] = [dd[Fidx], dd[0]];
      } else {
        [dd[Fidx + 1], dd[Fidx]] = [dd[Fidx], dd[Fidx + 1]];
      }
    }
    return dd;
  };
  const onMoveUp = (idx, left) => {
    if (left) {
      const dd = [...leftSlide];
      setLeftSlide(() => onMoveUpG(idx, dd));
    } else {
      const dd = [...rightSlide];
      setRightSlide(() => onMoveUpG(idx, dd));
    }
  };
  const onMoveDown = (idx, left) => {
    if (left) {
      const dd = [...leftSlide];
      setLeftSlide(() => onMoveDownG(idx, dd));
    } else {
      const dd = [...rightSlide];
      setRightSlide(() => onMoveDownG(idx, dd));
    }
  };

  useEffect(() => {
    let n = 8;
    let brandDifferentiatorsDataArray = [];
    let isBrandEssence =
      props.td && props.td.data && props.td.data.brandEssence;
    if (
      isBrandEssence === null ||
      (isBrandEssence &&
        props.td.data.brandEssence.brandDifferentiators &&
        props.td.data.brandEssence.brandDifferentiators.length === 0)
    ) {
      if (
        props?.td?.data?.brandDifferentiation?.selected &&
        props.td.data.brandDifferentiation.selected.length > 0
      ) {
        let tData = props.td.data.brandDifferentiation.selected;
        brandDifferentiatorsDataArray = tData;
        let len = tData.length;
        for (let i = 1; i <= n - len; i++) {
          brandDifferentiatorsDataArray.push({
            name: "",
            uuid: uuidv4(),
          });
        }
      } else {
        for (let i = 1; i <= n; i++) {
          brandDifferentiatorsDataArray.push({
            name: "",
            uuid: uuidv4(),
          });
        }
      }
    } else {
      let tData =
        isBrandEssence && props.td.data.brandEssence.brandDifferentiators;
      brandDifferentiatorsDataArray = tData;
      let len = tData.length;
      for (let i = 1; i <= n - len; i++) {
        brandDifferentiatorsDataArray.push({
          name: "",
          uuid: uuidv4(),
        });
      }
    }
    setBrandDifferentiatorsData(brandDifferentiatorsDataArray);
    let n1 = 4;
    let brandValuesDataArray = [];

    if (
      isBrandEssence === null ||
      (isBrandEssence &&
        props.td.data.brandEssence.brandValues &&
        props.td.data.brandEssence.brandValues.length === 0)
    ) {

      const bv = props?.td?.data?.brandValues || [];
      const bvd =
        bv
          .filter((d) => d.loaded && d.selected)
          ?.map((d, idx) => (
            { name: d.name, uuid: d.uuid, value: props?.td?.data?.brandValuesProof[`brandValueProof${idx+1}`] })) || [];

      brandValuesDataArray.push(...bvd);

      if (bvd.length < n1)
        for (let i = bvd.length; i <= n1; i++) {
          brandValuesDataArray.push({
            name: "",
            uuid: uuidv4(),
          });
        }
      
    } else {
      let tData = isBrandEssence && props.td.data.brandEssence.brandValues;
      brandValuesDataArray = tData;
      let len = tData.length;
      for (let i = 1; i <= n1 - len; i++) {
        brandValuesDataArray.push({
          name: "",
          uuid: uuidv4(),
        });
      }
    }
    setBrandValuesData(brandValuesDataArray);
    let n2 = 2;
    let draftBrandEssencesDataArray = [];
    if (
      isBrandEssence === null ||
      (isBrandEssence &&
        props.td.data.brandEssence.draftBrandEssences &&
        props.td.data.brandEssence.draftBrandEssences.length === 0)
    ) {
      for (let i = 1; i <= n2; i++) {
        draftBrandEssencesDataArray.push({
          name: "",
          uuid: uuidv4(),
        });
      }
    } else {
      let tData =
        isBrandEssence && props.td.data.brandEssence.draftBrandEssences;
      draftBrandEssencesDataArray = tData;
      let len = tData.length;
      for (let i = 1; i <= n2 - len; i++) {
        draftBrandEssencesDataArray.push({
          name: "",
          uuid: uuidv4(),
        });
      }
    }
    setDraftBrandEssencesData(draftBrandEssencesDataArray);

    let n3 = 4;
    let brandEssenceRefinementValuesArray = [];

    if (
      (props.td &&
        props.td.data &&
        props.td.data.brandEssenceRefinement === null) ||
      (props.td &&
        props.td.data &&
        props.td.data.brandEssenceRefinement &&
        props.td.data.brandEssenceRefinement.values &&
        props.td.data.brandEssenceRefinement.values.length === 0)
    ) {
      let tData =
        (props.td &&
          props.td.data &&
          props.td.data.brandEssence &&
          props.td.data.brandEssence.draftBrandEssences) ||
        [];
      brandEssenceRefinementValuesArray = tData;
      // let len = tData.length;
      // for (let i = 1; i <= n3 - len; i++) {
      //   brandEssenceRefinementValuesArray.push({
      //     name: "",
      //     uuid: uuidv4(),
      //   });
      // }
    } else {
      let tData =
        props.td &&
        props.td.data &&
        props.td.data.brandEssenceRefinement &&
        props.td.data.brandEssenceRefinement.values;
      brandEssenceRefinementValuesArray = tData;

      
      if(props.td.data.brandEssence?.draftBrandEssences !== null) {

        // Update the text if it has changed on a previous slide
        for(let i = 0; i < brandEssenceRefinementValuesArray.length; i++) {
          var match = props.td.data.brandEssence?.draftBrandEssences.filter(x => x.uuid == brandEssenceRefinementValuesArray[i].uuid);
          if(match.length > 0) {
            brandEssenceRefinementValuesArray[i].name = match[0].name;
          }
        }

        // Add new from previous slide
        for(let j = 0; j < props.td.data.brandEssence?.draftBrandEssences.length; j++) {
            var match = brandEssenceRefinementValuesArray.filter(x => x.uuid == props.td.data.brandEssence?.draftBrandEssences[j].uuid);
            if(match.length == 0) {
              brandEssenceRefinementValuesArray.push(props.td.data.brandEssence.draftBrandEssences[j])
            }
        }
      }
    }

    brandEssenceRefinementValuesArray &&
      brandEssenceRefinementValuesArray.map(
        (item, index) => (item.key = index + 1)
      );

    setLeftSlide(brandEssenceRefinementValuesArray);
    let n4 = 0;
    let brandEssenceRefinementSelectedArray = [];
    if (
      (props.td &&
        props.td.data &&
        props.td.data.brandEssenceRefinement === null) ||
      (props.td &&
        props.td.data &&
        props.td.data.brandEssenceRefinement &&
        props.td &&
        props.td.data &&
        props.td.data.brandEssenceRefinement.selected &&
        props.td &&
        props.td.data &&
        props.td.data.brandEssenceRefinement.selected.length === 0)
    ) {
      for (let i = 1; i <= n4; i++) {
        brandEssenceRefinementSelectedArray.push({
          name: "",
          uuid: uuidv4(),
        });
      }
    } else {
      let tData =
        props.td &&
        props.td.data &&
        props.td.data.brandEssenceRefinement &&
        props.td &&
        props.td.data &&
        props.td.data.brandEssenceRefinement.selected;
      brandEssenceRefinementSelectedArray = tData;
      let len = tData.length;
      for (let i = 1; i <= n4 - len; i++) {
        brandEssenceRefinementSelectedArray.push({
          name: "",
          uuid: uuidv4(),
        });
      }
    }
    brandEssenceRefinementSelectedArray &&
      brandEssenceRefinementSelectedArray.map(
        (item, index) => (item.key = index + 1)
      );

    // Update the text if it has changed on a previous slide
    if(props.td.data.brandEssence?.draftBrandEssences !== null) {

      for(let k = 0; k < brandEssenceRefinementSelectedArray.length; k++) {
        var match = props.td.data.brandEssence?.draftBrandEssences.filter(x => x.uuid == brandEssenceRefinementSelectedArray[k].uuid);
        console.log(match);
        if(match.length > 0) {
          brandEssenceRefinementSelectedArray[k].name = match[0].name;
        }
      }
    }
    setRightSlide(brandEssenceRefinementSelectedArray);
    let n5 = 0;
    let brandEssenceRefinementFinalArray = [];
    if (
      (props.td &&
        props.td.data &&
        props.td.data.brandEssenceRefinement === null) ||
      (props.td &&
        props.td.data &&
        props.td.data.brandEssenceRefinement &&
        props.td &&
        props.td.data &&
        props.td.data.brandEssenceRefinement.final &&
        props.td &&
        props.td.data &&
        props.td.data.brandEssenceRefinement.final.length === 0)
    ) {
      for (let i = 1; i <= n5; i++) {
        brandEssenceRefinementFinalArray.push({
          name: "",
          uuid: uuidv4(),
        });
      }
    } else {
      let tData =
        props.td &&
        props.td.data &&
        props.td.data.brandEssenceRefinement &&
        props.td &&
        props.td.data &&
        props.td.data.brandEssenceRefinement.final;
      brandEssenceRefinementFinalArray = tData;
      let len = tData.length;
      for (let i = 1; i <= n5 - len; i++) {
        brandEssenceRefinementFinalArray.push({
          name: "",
          uuid: uuidv4(),
        });
      }
    }
    brandEssenceRefinementFinalArray &&
      brandEssenceRefinementFinalArray.map(
        (item, index) => (item.key = index + 1)
      );
    setDownSlide(brandEssenceRefinementFinalArray);
  }, [steps]);

  const handleBrandDifferentiation = (idx, key, val) => {
    let array = [...brandDifferentiatorsData];
    array[idx][key] = val;
    setBrandDifferentiatorsData(array);
  };
  const handleBrandValuesData = (idx, key, val) => {
    let array = [...brandValuesData];
    array[idx][key] = val;
    setBrandValuesData(array);
  };
  const handleDraftBrandEssencesData = (idx, key, val) => {
    let array = [...draftBrandEssencesData];
    array[idx][key] = val;
    setDraftBrandEssencesData(array);
  };
  const addMoreBrandEssence = (idx, key, val) => {
    let array = [...draftBrandEssencesData];
    array.push({
      name: "",
      uuid: uuidv4(),
    });
    setDraftBrandEssencesData(array);
  };
  const handleLeftBrandDifferentiation = (idx, key, val) => {
    let array = [...leftSlide];
    array[idx][key] = val;
    setLeftSlide(array);
  };
  const handleRightBrandDifferentiation = (idx, key, val) => {
    let array = [...rightSlide];
    array[idx][key] = val;
    setRightSlide(array);
  };
  const handleDownBrandDifferentiation = (idx, key, val) => {
    let array = [...downSlide];
    array[idx][key] = val;
    setDownSlide(array);
  };
  const handlePage = async (n) => {
    switch (steps) {
      case 0:
        if (n) {
          setSteps((old) => old + 1);
        } else {
          props.prvStep();
        }
        break;

      case 1:
        await props.callApi({
          brandEssence: {
            brandDifferentiators:
              brandDifferentiatorsData &&
              brandDifferentiatorsData.filter((item) => item.name !== ""),
            brandValues:
              brandValuesData &&
              brandValuesData.filter((item) => item.name !== ""),
            draftBrandEssences:
              draftBrandEssencesData &&
              draftBrandEssencesData.filter((item) => item.name !== ""),
          },
        });
        if (n) {
          setSteps((old) => old + 1);
        } else {
          setSteps((old) => old - 1);
        }
        break;
      case 2:
        await props.callApi({
          brandEssenceRefinement: {
            values: leftSlide && leftSlide.filter((item) => item.name !== ""),
            selected:
              rightSlide && rightSlide.filter((item) => item.name !== ""),
            final: downSlide && downSlide.filter((item) => item.name !== ""),
          },
        });
        if (n) {
          setSteps((old) => old + 1);
        } else {
          setSteps((old) => old - 1);
        }
        break;
      case 3:
        if (n) {
          props.nextStep();
        } else {
          setSteps((old) => old - 1);
        }
        break;

      default:
        break;
    }
  };

  const handleSelect = () => {
    switch (steps) {
      case 1:
        return (
          <Step1
            brandDifferentiatorsData={brandDifferentiatorsData}
            brandValuesData={brandValuesData}
            draftBrandEssencesData={draftBrandEssencesData}
            handleBrandDifferentiation={handleBrandDifferentiation}
            handleDraftBrandEssencesData={handleDraftBrandEssencesData}
            handleBrandValuesData={handleBrandValuesData}
            addMoreBrandEssence={addMoreBrandEssence}
          />
        );
      case 2:
        return (
          <Step2
            leftSlide={leftSlide}
            setLeftSlide={setLeftSlide}
            downSlide={downSlide}
            setDownSlide={setDownSlide}
            switchBox={switchBox}
            lidx={lidx}
            indentifyStart={indentifyStart}
            setIndentifyStart={setIndentifyStart}
            setThisSide={setThisSide}
            thisSide={thisSide}
            setLidx={setLidx}
            onMoveUp={onMoveUp}
            onMoveDown={onMoveDown}
            rightSlide={rightSlide}
            setRightSlide={setRightSlide}
            ridx={ridx}
            setRidx={setRidx}
            didx={didx}
            setDidx={setDidx}
            handleLeftBrandDifferentiation={handleLeftBrandDifferentiation}
            handleRightBrandDifferentiation={handleRightBrandDifferentiation}
            handleDownBrandDifferentiation={handleDownBrandDifferentiation}
          />
        );
      case 3:
        return <Step3 td={props.td} />;

      default:
        return <></>;
    }
  };

  const handleValidate = () => {
    let disable = false;
    if (steps === 1) {
      let data =
        draftBrandEssencesData &&
        draftBrandEssencesData.filter((item) => item.name !== "");

      if (data && data.length < 2) {
        disable = true;
      } else {
        disable = false;
      }
    }
    if (steps === 2) {
      let data = downSlide && downSlide.filter((item) => item.name !== "");

      if (data && data.length <= 0) {
        disable = true;
      } else {
        disable = false;
      }
    }
    return disable;
  };
  return (
    <>
      {steps === 0 ? (
        <OpeningScreen
          title="Brand Essence"
          description={
            <>
              At the heart of every brand is a clearly stated and highly
              evocative brand essence. The brand essence is a central thought
              that guides the organisation to deliver it’s differentiated value
              proposition. <br /> <br /> Your Brand Essence is an internal
              mantra, although sometimes it may be used as a position line for
              customers and clients, it’s primary intent is to guide the
              internal thinking of the business. <br /> <br /> An effective
              brand essence provides each person in your business with clarity
              on the guiding principles for the many decisions your business
              makes every day. A well considered brand essence aligns the
              outcomes of these decisions towards achieving your differentiated
              value proposition. This ultimately builds a strongly branded
              business with a market advantage to drive sales and
              customer/client loyalty. <br />
            </>
          }
          img={props?.sas?.img || ""}
          bestPratice={props?.sas?.workshop || false}
          bestEx={props?.sas?.workshopImg || []}
          name={props?.sas?.name || ""}
        />
      ) : (
        <>{handleSelect()}</>
      )}
      <Box display="flex" justifyContent="space-between" mt="auto" mb="0">
        <CustomButton
          name="<   Prev"
          loading={props.btnLoading}
          onclick={() => handlePage(false)}
        />
        <CustomButton
          name="Next   >"
          loading={props.btnLoading}
          disabled={handleValidate()}
          onclick={() => handlePage(true)}
        />
      </Box>
    </>
  );
};

export default BrandEssence;
