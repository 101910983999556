import { Grid, useMediaQuery, useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { makeStyles } from "@mui/styles";
import { Box, Stack } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  res: {
    fontSize: "18px",
    color: theme.palette.secondary.main,
    textDecoration: "underline",
    whiteSpace: "normal",
    overflow: "auto",
    cursor: "pointer",
  },
  nameDate: {
    fontSize: "16px",
    marginTop: "4px",
    paddingRight: "4px",
  },
  provider: {
    fontSize: "18px",
  },
  title: {
    fontSize: "16px",
    color: theme.palette.secondary.main,
  },
}));

const ProjectRightComponentProjectWarning = () => {
  const classes = useStyles();
  const theme = useTheme();
  const loc = useNavigate();

  const matches = useMediaQuery(theme.breakpoints.down("lg"));
  const activeSidebarSection = useSelector(
    (state) => state.sideBar.activeSidebarSection
  );

  return (
      <Stack
      direction="row"
      justifyContent="center"
      border={`1px solid #D40841`}
      borderRadius="10px"
      height="100px"
      width="100%"
    >
      <Box m="auto">This functionality can't be accessed without creating a project first. Please <a href="#" onClick={() => loc('/clients/')}>visit the client dashboard</a> and create a project, or select a project from the main menu before proceeding</Box>
    </Stack>
  );
};

export default ProjectRightComponentProjectWarning;
