import { Divider, Grid, Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import RemoveIcon from "@mui/icons-material/Remove";
import { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import CustomExpand from "../../ui/customCollapse/CustomExpand";
import CollapseWithGrid from "../../ui/customCollapse/CollapseWithGrid";
import { useLocation } from "react-router";

const useStyle = makeStyles((theme) => ({
  title: {
    fontWeight: 600,
    marginBottom: "20px",
    marginTop: "10px",
  },
  det: {
    fontSize: "14px",
    marginBottom: "20px",
  },
  hideDetDiv: {
    fontWeight: 600,
    fontSize: "14px",
    margin: "auto",
    marginLeft: "0",
    color: theme.palette.secondary.main,
  },
}));

const RLeft = () => {
  const loc = useLocation().pathname;
  const classes = useStyle();
  const [expanded, setExpanded] = useState(true);
  const handleExpandClick = () => {
    setExpanded(() => !expanded);
  };

  return (
    <Grid item mt={"8px"} lg={1.2} md={2} sm={2} xs={2} textAlign="left">
      <Stack>
        <div className={classes.title}>
          {loc.includes("projects")
            ? "What is research?"
            : "What is the Research Marketplace?"}
        </div>
        <CollapseWithGrid expand={expanded}>
          <div className={classes.det}>
            What is research? Research is the proof required to ensure the brand
            will target the right markets.
          </div>

          <div className={classes.det}>
            We offer a range of free and paid research integrations to make life
            simple. You can also use your own research by selecting the 'Own
            Research' option.
          </div>

          <div className={classes.det}>
            Research can be used over multiple projects.{" "}
          </div>
        </CollapseWithGrid>
        <Divider variant="fullWidth" />
        <Stack direction="row">
          <div className={classes.hideDetDiv}>
            {expanded ? "HIDE" : "SHOW"} DETAILS
          </div>
          <CustomExpand
            expanded={expanded}
            handleExpandClick={handleExpandClick}
          >
            {expanded ? (
              <RemoveIcon fontSize="large" color="secondary" />
            ) : (
              <AddIcon fontSize="large" color="secondary" />
            )}
          </CustomExpand>
        </Stack>
        <Divider variant="fullWidth" />
      </Stack>
    </Grid>
  );
};

export default RLeft;
