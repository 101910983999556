import { useEffect } from "react";
import ProjectSettingsComponent from "../../../components/projectSettings/ProjectSettingsComponent";
import { setHTitle } from "../../../utils/SetHeaderFunction";

const ProjectSettings = (props) => {
  useEffect(() => {
    setHTitle(props.title, true, "");
  }, []);
  return <ProjectSettingsComponent />;
};

export default ProjectSettings;
