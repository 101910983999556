import { Avatar, Box, Grid, Stack } from "@mui/material";
import React from "react";
import SelectButton from "../../ui/button/SelectButton";
import { ReactComponent as MyLogo } from "../../../assets/images/downloadIcon.svg";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import { useState } from "react";
import { api } from "../../../redux/api/api";
import { DwdOptions, ToolType } from "../../../utils/AllOptions";
import DropDownMenu from "../../ui/menus/DropDownMenu";
import DownloadModal from "../../ui/modals/DownloadModal";
import DeleteModal from "../../ui/modals/DeleteModal";
import { useSelector } from "react-redux";
import { config } from "../../../config";

const useStyles = makeStyles((theme) => ({
  icon: {
    fill: theme.palette.secondary.main,
    height: "24px",
    width: "24px",
  },
}));

const DownloadCard = (props) => {
  const classes = useStyles();
  const resData = useSelector((state) => state.project?.project?.downloads);

  const [enchorEl, setEnchorEl] = useState(null);
  const [openP, setOpenP] = useState(false);
  const [menuVal, setMenuVal] = useState("Download");

  const [delModal, setDelModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);

  const handleDownload = (file, fileType) => {
    if (!props.file) return null;
    if (props.fileType === "PDF") return window.open(props.file);
    return window.open(config.dwdPath + props.file);
  };
  const handleSelect = (v) => {
    handleClose();
    switch (v) {
      case DwdOptions[0]:
        handleDownload();
        break;
      // case DwdOptions[1]:
      //   if (data?.notesFile) {
      //     window.open(config.dwdPath + data.notesFile);
      //   }
      //   break;
      case DwdOptions[1]:
        setDelModal(true);
        break;
      case DwdOptions[2]:
        setUpdateModal(true);
        break;
      default:
        break;
    }
  };

  const handleClick = (event) => {
    setEnchorEl(event.currentTarget);
    setOpenP(true);
  };

  const handleClose = () => {
    setEnchorEl(null);
    setOpenP(false);
  };
  const handleDelete = async () => {
    if (props?.uuid) {
      const res = await api(
        `projects/${props?.toolId}/reports/${props?.uuid}`,
        "",
        "delete",
        "",
        "",
        "Report Deleted!"
      );
      if (res && res.success) {
        const idx = resData.findIndex((a) => a.uuid == props.uuid);
        if (idx !== -1) {
          resData.splice(idx, 1);
        }
      }
      setDelModal(false);
    }
  };

  return (
    <Grid item lg={3} md={4} sm={4} xs={4}>
      <Box
        border="1px solid #D40841"
        borderRadius="10px"
        padding="10px 20px"
        margin="4px"
        color="secondary.main"
        minHeight="230px"
      >
        <Stack direction="row" justifyContent="space-between" mb={1}>
          <Box fontSize="20px" margin="auto 0">
            {(props.toolType && ToolType[props.toolType]) || "-"}
          </Box>
          <SelectButton
            title={<MyLogo className={classes.icon} />}
            dropClick={(e) => handleClick(e)}
            color="sufron"
            titleClick={() => handleDownload()}
          />
        </Stack>
        <Stack gap="4px">
          <Box>{props.toolName || "-"}</Box>
          <Box display="flex" gap="10px">
            <Box margin="auto 0">Format:</Box>
            <Box margin="auto 0">{props.fileType || "-"} file</Box>
          </Box>
          <Box display="flex" gap="10px">
            <Box margin="auto 0">Date:</Box>
            <Box margin="auto 0">
              {" "}
              {moment(props.dateCreated || "").format("D MMM YY")}
            </Box>
          </Box>
        </Stack>
        <Stack gap="6px" direction="row" mt={3} mb={3}>
          {props.createdby && (
            <Avatar
              sx={{
                background: `#1D2D91 !important`,
                color: "white !important",
                fontSize: "16px !important",
              }}
            >
              {`${props.createdby.split(" ")?.[0]?.[0] || ""}${
                props.createdby.split(" ")?.[1]?.[0] || ""
              }`}
            </Avatar>
          )}
        </Stack>
      </Box>
      <DropDownMenu
        enchorEl={enchorEl}
        openP={openP}
        handleClose={handleClose}
        options={DwdOptions}
        val={menuVal}
        handleSelect={(v) => handleSelect(v)}
      />
      <DownloadModal open={updateModal} onClose={() => setUpdateModal(false)} />
      <DeleteModal
        title="Are you sure you want to remove the
        download?"
        open={delModal}
        onClose={() => setDelModal(false)}
        onDelete={() => handleDelete()}
      />
    </Grid>
  );
};

export default DownloadCard;
