import { makeStyles } from "@mui/styles";

const useStyle = makeStyles((theme) => ({
  formGroupContainer: {
    textAlign: "left !important",
    color: "black",
    fontFamily: theme.typography.fontFamily,
  },
  labelName: {
    marginBottom: "12px",
    fontWeight: 400,
    fontSize: "14px !important",
    fontFamily: theme.typography.fontFamily,
  },
}));

const FormGroupContainer = (props) => {
  const classes = useStyle();

  return (
    <div className={classes.formGroupContainer}>
      <div className={classes.labelName}>
        {props.label} {props.required && "*"}
      </div>
      {props.children}
    </div>
  );
};

export default FormGroupContainer;
