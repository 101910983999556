import { ButtonBase, DialogContent, Grid, Stack } from "@mui/material";
import { ArrowForwardIos } from "@mui/icons-material";
import CatImg from "../../../assets/images/catimg.png";
import CustomButton from "../button/CustomButton";
import ModalContainer from "./ModalContainer";
import { makeStyles } from "@mui/styles";
import CustomSelect from "../formgroup/CustomSelect";
import { api } from "../../../redux/api/api";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import useBindActionCreator from "../../../hooks/useBindActionCreator";
import { getProjectListData } from "../../../redux/actions/client/ClientAction";
import { useLocation } from "react-router";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "20px",
    color: theme.palette.secondary.main,
    marginBottom: "20px",
    fontWeight: 600,
  },
  endIcon: {
    marginLeft: "auto !important",
  },
  btnContainer: { width: "40%" },
  cancelBtn: {
    margin: "auto 0",
    textDecoration: "underline !important",
    fontFamily: theme.typography.fontFamily,
    fontSize: "16px",
  },
  dialogContent: {
    height: "500px",
  },
  subTitle: {
    marginBottom: "7px",
    fontWeight: 400,
    fontSize: "14px !important",
  },
  name: {
    marginBottom: "16px",
  },
}));
const UseResToAnotherProject = ({
  open,
  onClose,
  name,
  id,
  type,
  projectId = "",
  handleForceUpdate = () => {},
}) => {
  const classes = useStyles();

  const data = useSelector((state) => state.project.allProjects);
  const clientId = useSelector((state) => state.client?.clientId?.id);
  const allPros = useSelector((state) => state.project.projects);

  const loc = useLocation().pathname;

  const actions = useBindActionCreator({ getProjectListData });

  const [op, setOp] = useState([]);
  const [sel, setSel] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setSel(() => "");
  }, [id]);
  const handleUpdate = async () => {
    setLoading(true);
    const res = await api(
      `research/${id}`,
      { name: name, projectId: sel.id },
      "post",
      "",
      "",
      "Research copy success!"
    );
    if (res && res.success) {
      if (sel.cid === clientId && allPros && allPros.length) {
        for (let j = 0; j < allPros.length; j++) {
          if (allPros[j].uuid === sel.id) {
            if (allPros[j].researches) {
              allPros[j].researches.push(res);
            } else {
              await actions.getProjectListData(sel.id);
            }
            break;
          }
        }
      }
      if (loc === "/clients") handleForceUpdate(sel.cid);
      onClose();
    }
    setLoading(false);
  };
  useEffect(() => {
    if (data && data.length) {
      const newData = [];
      for (let i = 0; i < data.length; i++) {
        if (data[i].uuid !== projectId) {
          let name = `${data[i].clientName || ""} / ${data[i].name || ""}`;
          newData.push({
            name: name,
            id: data[i].uuid,
            cid: data[i].clientId,
          });
        }
      }
      setOp(() => newData);
    }
  }, [data, projectId]);

  return (
    <ModalContainer
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth={true}
    >
      <DialogContent className={classes.dialogContent}>
        <Grid container mt={2}>
          <Grid item lg={7} md={7} margin="auto" p={4} mt={6}>
            <div className={classes.title}>Use Reseach on Another Project</div>
            <div className={classes.subTitle}>Research Provider & Name</div>
            <div className={classes.name}>
              {type} / {name}
            </div>
            <CustomSelect
              label="Copy to Client/ Project"
              options={op}
              value={sel}
              onChange={(e) => setSel(() => e)}
            />
            <Stack direction="row" mt={3} gap="20px">
              <div className={classes.btnContainer}>
                <CustomButton
                  variant="contained"
                  size="large"
                  name={"Copy Now"}
                  endIcon={<ArrowForwardIos />}
                  fullWidth={true}
                  iconClass={classes.endIcon}
                  disabled={sel ? false : true}
                  onclick={handleUpdate}
                  loading={loading}
                />
              </div>
              <ButtonBase onClick={onClose} className={classes.cancelBtn}>
                Cancel
              </ButtonBase>
            </Stack>
          </Grid>
          <Grid item lg={5} md={5}>
            <img src={CatImg} alt={CatImg} height="383px" />
          </Grid>
        </Grid>
      </DialogContent>
    </ModalContainer>
  );
};

export default UseResToAnotherProject;
