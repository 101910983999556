import { Box, Stack } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import CustomButton from "../../../../../ui/button/CustomButton";
import FormGroupTextField from "../../../../../ui/formgroup/FormGroupTextField";
import TextAutoResize from "../../../../../ui/formgroup/TextAutoResize";

const SingleMindedProposition = (props) => {
  const [singleMindedProposition, setSingleMindedProposition] = useState("");
  const data = props?.td?.data;
  useEffect(() => {
    if (
      data &&
      data.singleMindedProposition &&
      typeof data.singleMindedProposition === "string"
    ) {
      setSingleMindedProposition(data.singleMindedProposition);
    }
  }, [props]);
  return (
    <>
      <Stack direction="row" gap="30px">
        <Box flex="0.2" display="flex" gap="10px" flexDirection="column">
          <Box color="secondary.main" fontSize="20px" fontWeight="800px">
            What Is The Single Minded Proposition?
          </Box>
          <Box mb={2} fontSize="14px"></Box>
        </Box>
        <Box flex="0.8">
          <TextAutoResize
            other={{ minRows: 6, maxRows: 6 }}
            value={singleMindedProposition}
            onChange={(e) => setSingleMindedProposition(e)}
          />
        </Box>
      </Stack>
      <Box display="flex" justifyContent="space-between" mt="auto" mb="0">
        <CustomButton
          name="<   Prev"
          onclick={() =>
            props.callApi({ singleMindedProposition }, true, false)
          }
          loading={props.btnLoading}
        />
        <CustomButton
          name="Next   >"
          disabled={!singleMindedProposition}
          onclick={() => props.callApi({ singleMindedProposition }, true, true)}
          loading={props.btnLoading}
        />
      </Box>
    </>
  );
};

export default SingleMindedProposition;
