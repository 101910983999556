import { Badge, Fab } from "@mui/material";
import chaticon from "../../assets/images/chat.png";
import { makeStyles } from "@mui/styles";
import CommentMenu from "../ui/menus/CommentMenu";
import { useState } from "react";
import { useLocation } from "react-router";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { api } from "../../redux/api/api";

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing.unit * 2,
  },
  customBadge: {
    backgroundColor: "red",
    color: "white",
    border: "2px solid white",
    top: "4px !important",
    fontSize: "50px !important",
    right: "0px !important",
  },
  container: {
    top: "auto !important",
    right: 40,
    bottom: 40,
    left: "auto !important",
    position: "fixed !important",
  },
}));
const RComment = () => {
  const loc = useLocation().pathname;
  const user = useSelector((state) => state.auth?.user?.id);

  const classes = useStyles();
  const [enchorEl, setEnchorEl] = useState(null);
  const [openP, setOpenP] = useState(false);
  const handleClick = (event) => {
    setEnchorEl(event.currentTarget);
    setOpenP(true);
  };

  const handleClose = () => {
    setEnchorEl(null);
    setOpenP(false);
  };

  const pid = useSelector((state) => state.client?.projectId?.id);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [calling, setCalling] = useState(false);
  const [show, setShow] = useState(false);
  const [per, setPer] = useState([]);

  const loadData = async (remove) => {
    setLoading(true);
    setCalling(true);
    try {
      const res = await api(`projects/${pid}/notes`, "", "get");
      if (res && res.success) {
        setData(res.notes);
        setCalling(false);
        setLoading(false);
        const d = [...res.notUpToDateUsers];
        const idx = d.findIndex((a) => a === user);
        if (remove) {
          setShow(false);
          if (idx !== -1) d.splice(idx, 1);
          setPer(d);
          await api(
            `projects/${pid}/notes`,
            "",
            "patch",
            "",
            "",
            "",
            true,
            false,
            true,
            true
          );
        } else {
          setPer(d);
          if (idx !== -1) {
            setShow(true);
          } else {
            setShow(false);
          }
        }
      }
    } catch (error) {
      setCalling(false);
      setLoading(false);
    }
  };
  const addComment = async (d) => {
    setLoading(true);
    await api(
      `projects/${pid}/notes`,
      {
        comment: d,
      },
      "post"
    );
    await loadData();
    setLoading(false);
  };
  useEffect(() => {
    if (openP && pid && !calling) loadData(true);
  }, [openP]);

  useEffect(() => {
    if (pid && !calling) loadData();
  }, []);

  return loc.includes("projects") ? (
    <>
      <Fab
        classes={{ root: classes.container }}
        color="secondary"
        variant="extended"
        onClick={handleClick}
      >
        {" "}
        <Badge
          classes={{ badge: classes.customBadge }}
          className={classes.margin}
          variant="dot"
          invisible={!show}
        >
          <img
            src={chaticon}
            alt={chaticon}
            height="28px"
            style={{ filter: "brightness(0) invert(1)" }}
          />
        </Badge>
      </Fab>
      <CommentMenu
        enchorEl={enchorEl}
        openP={openP}
        handleClose={handleClose}
        loading={loading}
        data={data || []}
        addComment={(d) => addComment(d)}
        per={per}
      />
    </>
  ) : null;
};

export default RComment;
