import { Box, Grid, Stack } from "@mui/material";
import TextAutoResize from "../../../../../../ui/formgroup/TextAutoResize";

const Step7 = (props) => {
  return (
    <Stack>
      <Box>
        <Box color="secondary.main" fontSize="20px" fontWeight="800px">
          Your Differentiated Value Proposition
        </Box>
        <Grid container pt={4}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box marginTop={"20px !important"}>
              You've already identified the following brand differentiation
              points:
  
              <ul style={{marginTop: "30px", marginBottom: "30px"}}>
                {props.brandDifferentiation && props.brandDifferentiation?.selected && props.brandDifferentiation?.selected.map((a) => (
                  <li
                    style={{
                      fontSize: "16px",
                    }}
                    key={a.uuid}
                  >
                    {a.name}
                  </li>
                ))}
              </ul>
        
            </Box>
            <Box marginTop={"10px !important"}>
              Taking these differentiators, weave them together into a
              one-sentence value proposition. <br />It should take the form of:
            </Box>
            <br />
            <Box background="white" padding="10px" borderRadius="10px">
              {" "}
              "We help... (insert primary audience) to... (insert what it is
              that you help your audience to do, what problem you solve or value
              you add to their lives) by doing... (insert what you
              do/offer/provide and one or two of the differentiators that make
              you unique)"{" "}
            </Box>
            <br />
            <br />
            <Box>
              <TextAutoResize
                label="Differentiated value proposition"
                other={{ minRows: 6, maxRows: 6 }}
                value={props.valueProposition}
                onChange={(e) => props.setValueProposition(e)}
              />
            </Box>
            <br />
          </Grid>
        </Grid>
      </Box>
    </Stack>
  );
};

export default Step7;
