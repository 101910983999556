import { Grid, Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CustomButton from "../../../ui/button/CustomButton";
const useStyle = makeStyles((theme) => ({
  container: {
    padding: "20px",
    borderRadius: "10px",
    border: "1px solid #DDCB4F",
    minHeight: "290px",
    color: `${theme.palette.secondary.main} !important`,
    transition: "background-color 500ms ease-out 50ms",
    "&:hover": {
      backgroundColor: "#FFFDF1 !important",
    },
  },
  title: {
    margin: "auto 0",
    fontSize: "20px",
    fontWeight: 600,
  },
  desc: {
    fontSize: "18px",
  },
}));
const ATCard = (props) => {
  const classes = useStyle();
  return (
    <Grid lg={4} md={6} sm={6} xs={6} p={2} pt={0}>
      <div className={classes.container}>
        <Stack direction="row" justifyContent="space-between" mb={6}>
          <div className={classes.title}>{props.title}</div>{" "}
          {props.commingSoon ? (
            <label className="sign">coming soon</label>
          ) : props.checkExist ? (
            <label className="sign">In use</label>
          ) : (
            <CustomButton
              name="Add"
              size="large"
              classNameI={"yellow"}
              onclick={props.handleClick}
            />
          )}
        </Stack>
        <div className={classes.desc}>{props.desc}</div>
      </div>
    </Grid>
  );
};

export default ATCard;
