import { Box, Grid, Slider } from "@mui/material";
import CustomCheckBox from "../../../../../../ui/checkbox/CustomCheckBox";

const Step4 = ({
  competitorPositioningMatrixA,
  setCompetitorPositioningMatrixA,
  includeProjectBrandInExercises,
  setIncludeProjectBrandInExercises,
  competitorPositioningAxes,
  clientData,
}) => {
  return (
    <>
      <Box mb={2}>
        For your brand and your competitors, score them for each of the
        differentiators where 1 is low and 10 is high
      </Box>
      <Box>
        <CustomCheckBox
          label="Show project brand"
          checked={includeProjectBrandInExercises}
          onchange={(e) => {
            if (e) {
              const dd = [
                {
                  client: true,
                  differentiator1: 1,
                  differentiator2: 1,
                  logo: clientData.logo,
                  name: clientData.name,
                  uuid: clientData.uuid,
                },
                ...competitorPositioningMatrixA,
              ];
              setCompetitorPositioningMatrixA(dd);
            } else {
              const dd = [...competitorPositioningMatrixA];
              dd.shift();
              setCompetitorPositioningMatrixA(dd);
            }
            setIncludeProjectBrandInExercises(e);
          }}
        />
      </Box>
      <Grid container spacing={2}>
        <Grid item lg={4} md={4} sm={4} xs={4}></Grid>
        <Grid item lg={4} md={4} sm={4} xs={4} fontSize="18px">
          {competitorPositioningAxes[0].name || ""}
        </Grid>
        <Grid item lg={4} md={4} sm={4} xs={4} fontSize="18px">
          {competitorPositioningAxes[1].name || ""}
        </Grid>
        {competitorPositioningMatrixA && competitorPositioningMatrixA.length
          ? competitorPositioningMatrixA.map((a, idx) => (
              <>
                <Grid item lg={4} md={4} sm={4} xs={4}>
                  <Box
                    style={{
                      background: "white",
                      padding: "10px",
                      width: "80%",
                    }}
                  >
                    {idx + 1} {a.name || ""}
                  </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={4} xs={4} fontSize="18px" pr={1}>
                  <Slider
                    color="secondary"
                    value={a.differentiator1 || 0}
                    min={0}
                    max={10}
                    valueLabelDisplay="auto"
                    step={1}
                    onChange={(_, e) => {
                      const data = [...competitorPositioningMatrixA];
                      data[idx].differentiator1 = e;
                      setCompetitorPositioningMatrixA(data);
                    }}
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={4} xs={4} fontSize="18px" pr={1}>
                  <Slider
                    color="secondary"
                    value={a.differentiator2 || 0}
                    min={0}
                    max={10}
                    valueLabelDisplay="auto"
                    step={1}
                    onChange={(_, e) => {
                      const data = [...competitorPositioningMatrixA];
                      data[idx].differentiator2 = e;
                      setCompetitorPositioningMatrixA(data);
                    }}
                  />
                </Grid>
              </>
            ))
          : null}
      </Grid>
    </>
  );
};

export default Step4;
