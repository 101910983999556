import { useEffect } from "react";
import ClientDashboard from "../../../components/client/clientDashboard/ClientDashboard";
import { setHTitle } from "../../../utils/SetHeaderFunction";

const Clients = () => {
  useEffect(() => setHTitle("Client Dashboard", true, ""), []);
  return <ClientDashboard />;
};

export default Clients;
