import { Stack, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import CustomButton from "../ui/button/CustomButton";
import { login as loginAction } from "../../redux/actions/auth/authActions";
import { useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { isEmpty } from "lodash";
import useBindActionCreator from "../../hooks/useBindActionCreator";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "34px",
    color: theme.palette.secondary.main,
    marginBottom: "30px",
  },
  regDiv: {
    marginBottom: "30px",
  },
  regDiv2: {
    marginBottom: "90px",
  },
  right: {
    marginLeft: "auto",
    marginRight: "0",
  },
  btnm: {
    marginTop: "14px",
  },
  fg: {
    display: "flex",
    gap: "20px",
    justifyContent: "space-between",
  },
  container: {
    textAlign: "left",
    fontSize: "20px",
    margin: "auto",
    maxWidth: "570px",
    width: "100%",
    marginTop: "100px",
    gap: "14px",
    fontWeight: 300,
    [theme.breakpoints.down("lg")]: {
      width: "390px",
    },
  },
  link: {
    textDecoration: "none",
    color: theme.palette.secondary.main,
  },
  mtlabel: { margin: "auto 0", width: "150px" },
  mt: {
    margin: "auto 0",
    width: "100%",
    maxWidth: "340px",
  },
}));
const LoginComponent = () => {
  const classes = useStyles();

  const simpleValidator = useRef(new SimpleReactValidator());
  const actions = useBindActionCreator({ loginAction });

  const [loginData, setLoginData] = useState({
    emailAddress: "",
    password: "",
    clientId: "",
  });

  useEffect(() => {
    const cid = isEmpty(localStorage.getItem("client_id"))
      ? uuidv4()
      : localStorage.getItem("client_id");
    localStorage.setItem("client_id", cid);
    setData("clientId", cid);
  }, []);

  const [loading, setLoading] = useState(false);

  const setData = (field, data) =>
    setLoginData((old) => {
      return { ...old, [field]: data };
    });

  const [, setDisplayError] = useState(false);

  const blurSetup = async (field) => {
    await setDisplayError(() => false);
    simpleValidator.current.showMessageFor(field);
    await setDisplayError(() => true);
  };
  const login = async () => {
    if (simpleValidator.current.allValid()) {
      setLoading(true);
      await actions.loginAction(loginData);
    } else {
      simpleValidator.current.showMessages();
      setDisplayError((old) => !old);
    }
    setLoading(false);
  };
  return (
    <Stack className={classes.container}>
      <div className={classes.title}>Welcome to Brandvas</div>
      <div className={classes.regDiv2}>
        Don't have an account?{" "}
        <NavLink to="/register" className={classes.link}>
          Register here
        </NavLink>
      </div>
      <div className={classes.fg}>
        <div className={classes.mtlabel}>Email address</div>
        <div className={classes.mt}>
          <TextField
            type={"text"}
            size="small"
            variant="outlined"
            color="secondary"
            placeholder="Email"
            value={loginData.emailAddress}
            onChange={(e) => setData("emailAddress", e.target.value)}
            onBlur={() => blurSetup("Email Address")}
            fullWidth
          />
          {simpleValidator.current.message(
            "Email Address",
            loginData.emailAddress,
            "required|email",
            {
              className: "errorClass1",
            }
          )}
        </div>
      </div>
      <div className={classes.fg}>
        <div className={classes.mtlabel}>Password</div>
        <div className={classes.mt}>
          <TextField
            type={"password"}
            size="small"
            variant="outlined"
            color="secondary"
            placeholder="Password"
            value={loginData.password}
            onChange={(e) => setData("password", e.target.value)}
            onBlur={() => blurSetup("Password")}
            fullWidth
          />
          {simpleValidator.current.message(
            "Password",
            loginData.password,
            "required",
            {
              className: "errorClass1",
            }
          )}
        </div>
      </div>
      <div
        className={classes.right + " " + classes.regDiv + " " + classes.btnm}
      >
        <CustomButton
          name={"Login"}
          size="midum"
          onclick={login}
          loading={loading}
        />
      </div>
      <div>
        <NavLink to="/forgot-password" className={classes.link}>
          Forgot password?
        </NavLink>
      </div>
    </Stack>
  );
};

export default LoginComponent;
