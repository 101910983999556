import { GET_CAT_DATA } from "../../constants";

const initState = {
  cats: [],
};

const profileReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_CAT_DATA:
      return { ...state, cats: action.payload.data };
    default:
      return state;
  }
};

export default profileReducer;
