import { Divider, Grid } from "@mui/material";
import { ReactComponent as MyLogo } from "../../../../assets/images/downloadIcon.svg";
import { ReactComponent as FileIcon } from "../../../../assets/images/file-icon.svg";
import { makeStyles } from "@mui/styles";
import SelectButton from "../../../ui/button/SelectButton";
import moment from "moment";
import { config } from "../../../../config";

const useStyles = makeStyles((theme) => ({
  divider: {
    backgroundColor: "#D6D6D6",
  },
  icon: {
    fill: theme.palette.secondary.main,
    height: "24px",
    width: "24px",
  },
  fileicon: {
    fill: theme.palette.secondary.main,
    height: "50px",
    width: "50px",
  },
  filename: {
    display: "flex",
    alignItems: "center",
  },
  filesize: {
    marginTop: "3px",
    color: "#7a7a7a",
  },
}));

const PUList = (props) => {
  const classes = useStyles();

  return (
    <>
      <Grid item lg={7} md={7} sm={7} xs={7} margin="auto 0" pr={2}>
        <div className={classes.filename}>
          <FileIcon className={classes.fileicon} />
          <div style={{ marginLeft: "10px" }}>
            <div
              className={props.generalClass.res}
              onClick={() => props.downloadFile(config.generatePathDownloadPath(props.fileUrl))}
            >
              {props.fileName || "-"}
            </div>
            <div className={classes.filesize}>{props.fileSize || ""}</div>
          </div>
        </div>
      </Grid>
      <Grid item lg={3} md={3} sm={3} xs={3} margin="auto 0">
        <div className={props.generalClass.provider}>
          {props.uploadDate ? moment(props.uploadDate).format("D MMM YY") : "-"}
        </div>
        <div className={props.generalClass.nameDate}>
          {props.fileFormat || "-"} file
        </div>
      </Grid>
      <Grid item lg={2} md={2} sm={2} xs={2} margin="auto 0">
        <SelectButton
          title={<MyLogo className={classes.icon} />}
          titleClick={() => props.downloadFile(config.generatePathDownloadPath(props.fileUrl))}
          dropClick={(e) =>
            props.handleClick(e, {
              fileId: props.uuid || "",
              projectId: props.projectId || "",
              fileUrl: props.fileUrl || "",
              folderId: props.folderId || "",
            })
          }
          color="grey"
        />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} mb={2} mt={2}>
        <Divider variant="fullWidth" className={classes.divider} />
      </Grid>
    </>
  );
};

export default PUList;
