import { api } from "../../api/api";
import { GET_ALL_TEMPLATE, msgShowFun } from "../../constants";
import { store } from "../../store";

export const getTemplates = () => {
  return async (dispatch) => {
    try {
      const id = store?.getState()?.auth?.user?.agencies?.uuid;
      if (id) {
        const res = await api(
          `templates?agencyId=${id}&sort=dateModified&order=desc`,
          "",
          "get",
          "",
          "",
          ""
        );
        if (res && res.success && res.templates) {
          dispatch({
            type: GET_ALL_TEMPLATE,
            payload: { data: res.templates },
          });
        }
      }
    } catch (error) {
      msgShowFun("", error);
    }
  };
};
