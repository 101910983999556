import { useEffect } from "react";
import AddToolsToProjectComponent from "../../../../components/tools/addTool/AddToolsToProjectComponent";
import { setHTitle } from "../../../../utils/SetHeaderFunction";

const AddToolsToProject = (props) => {
  useEffect(() => {
    setHTitle(props.title, true);
  }, []);

  return <AddToolsToProjectComponent />;
};

export default AddToolsToProject;
