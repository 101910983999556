import { Grid, Stack } from "@mui/material";
import CustomSelect from "../../../ui/formgroup/CustomSelect";
import FormGroupTextField from "../../../ui/formgroup/FormGroupTextField";

const ToolDet = ({ classes, name = "", template = "" }) => {
  return (
    <Grid
      container
      columnGap={3}
      item
      lg={12}
      md={12}
      sm={12}
      xs={12}
      className={classes.toolDetContainer}
    >
      <Grid item lg={3} md={4} sm={4} xs={4}>
        <div className={classes.gTitle}>Tool Details</div>
        <div className={classes.gDesc}>
          Customise your brand strategy screens here. Upload new opening screens
          and best practice examples.
        </div>
      </Grid>
      <Grid item lg={6} md={6} sm={6} xs={6}>
        <Stack gap="20px">
          <FormGroupTextField label={"Tool Name"} value={name} disabled />
          <CustomSelect
            options={[template]}
            label="Brand Strategy Template"
            value={template}
            disabled
          />
        </Stack>
      </Grid>
    </Grid>
  );
};

export default ToolDet;
