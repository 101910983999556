import { shallowEqual, useSelector } from "react-redux";
import { useLocation } from "react-router";
import PrivateGeneralHeaderTitle from "../ui/nav/PrivateGeneralHeaderTitle";
import PrivateHomeHeader from "../ui/nav/PrivateHomeHeader";
import { privateSidebarNavOp } from "../../utils/NavBarConstants";

const SubHedaers = () => {
  const loc = useLocation();
  const { isTitle, title, subTitle, subTitlePosition } = useSelector(
    (state) => state.titleH,
    shallowEqual
  );
  let checkCustomSidebar = privateSidebarNavOp.find((a) => a === loc.pathname);
  const sidebarLayoutData = useSelector(
    (state) => state.sideBar.sidebarLayoutData
  );
  if (!checkCustomSidebar) {
    sidebarLayoutData?.map((item) => {
      if (item.name === "Tools") {
        checkCustomSidebar = item?.activePaths?.find(
          (a) => a === loc?.pathname?.replaceAll("%20", " ")
        );
      }
    });
  }
  return loc.pathname === "/" ? (
    <PrivateHomeHeader />
  ) : isTitle && !checkCustomSidebar ? (
    <PrivateGeneralHeaderTitle
      title={title}
      subTitle={subTitle}
      subTitlePosition={subTitlePosition}
    />
  ) : null;
};

export default SubHedaers;
