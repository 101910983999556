import { useNavigate } from "react-router-dom";
import { AppBar, Toolbar, useScrollTrigger } from "@mui/material";
import React, { memo } from "react";
import { makeStyles } from "@mui/styles";
import NavTabs from "../ui/nav/NavTabs";
import { shallowEqual, useSelector } from "react-redux";
import ProjectClientListing from "../ui/nav/ProjectClientListing";
import SubHedaers from "./SubHedaers";
import SubscriptionBanner from "./SubscriptionBanner";

function ElevationScroll(props) {
  const { children } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

const useStyles = makeStyles((theme) => ({
  logo: {
    height: "3em",
    padding: "1em",
    paddingLeft: "3em",
    cursor: "pointer",
    paddingRight: "2em",
    [theme.breakpoints.down("lg")]: {
      height: "3em",
      paddingLeft: "1em !important",
    },
  },
  appBar: {
    position: "relative",
    zIndex: 1,
    minWidth: "1024px",
  },
  navContainer: {
    maxWidth: "55%",
    marginLeft: "auto",
  },
}));

const PrivateHeader = () => {
  const classes = useStyles();
  const chistory = useNavigate();
  const icon = useSelector((state) => state.theme.logo, shallowEqual);
  const package_details = useSelector(
    (state) => state?.client?.package_details,
    shallowEqual
  );

  return (
    <>
      {package_details?.freeTrial && (
        <SubscriptionBanner {...package_details} />
      )}
      <ElevationScroll>
        <AppBar position="relative" className={classes.appBar}>
          <Toolbar disableGutters>
            <img
              src={icon}
              className={classes.logo}
              alt="logo"
              onClick={() => chistory("/")}
            />
            <ProjectClientListing />
            <div className={classes.navContainer}>
              <NavTabs />
            </div>
          </Toolbar>
        </AppBar>
      </ElevationScroll>

      <SubHedaers />
    </>
  );
};

export default memo(PrivateHeader);
