import { Avatar, Grid, Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  menuItem: {
    fontFamily: theme.typography.fontFamily,
    backgroundColor: "#F5F5F5 !important",
    margin: "6px !important",
    borderRadius: "10px !important",
    color: `${theme.palette.secondary.main} !important`,
    fontWeight: "00 !important",
    padding: "6px",
  },
  avatar: {
    background: `${theme.palette.secondary.main} !important`,
    color: "white !important",
    width: "36px !important",
    height: "36px !important",
    fontSize: "16px !important",
  },
  notificationTitle: {
    fontSize: "14px",
  },
  notificationDesc: {
    overflowWrap: "break-word",
  },
}));

const NotificationCards = (props) => {
  const classes = useStyles();
  const buildMsg = () => {
    let str = "";

    str = `${props?.sender?.firstName || ""} ${props?.sender?.lastName || ""} ${
      props?.notificationObject?.objectName || ""
    } ${props?.activityType} for ${props?.project?.projectName || ""} project.`;

    return str;
  };
  return (
    <div className={classes.menuItem}>
      <Grid container margin="6px">
        <Grid item lg={1.5} md={1.5} sm={1.5} xs={1.5}>
          {props?.sender?.avatar ? (
            <Avatar
              //classes={{ root: classes.avatar }}
              src={props.sender?.avatar || ""}
            />
          ) : (
            <Avatar
              classes={{ root: classes.avatar }}
              src={props?.sender?.avatar || ""}
            >
              {`${props?.sender?.firstName?.[0] || ""}${
                props?.sender?.lastName?.[0] || ""
              }`}
            </Avatar>
          )}
        </Grid>
        <Grid item lg={10.5} md={10.5} sm={10.5} xs={10.5}>
          <Stack gap="5px">
            <div className={classes.notificationTitle}>
              {moment(props?.dateCreated || "").fromNow()}
            </div>
            <div className={classes.notificationDesc}>{buildMsg()}</div>
          </Stack>
        </Grid>
      </Grid>
    </div>
  );
};

export default NotificationCards;
