import { Box, Stack } from "@mui/material";
import CustomButton from "../../../../../ui/button/CustomButton";
import { useDispatch, useSelector } from "react-redux";
const Welcome = (props) => {
  const full = useSelector((state) => state.project.workshop);
  const dispatch = useDispatch();

  return (
    <Stack direction="row" gap="30px">
      <Box flex="0.2" display="flex" gap="10px" flexDirection="column">
        <Box color="secondary.main" fontSize="20px" fontWeight="800px">
          Cover Page
        </Box>
        <Box mb={2} fontSize="14px"></Box>
        <CustomButton
          name="Start Workshop"
          size="medium"
          onclick={() => props.nextStep()}
        />
        <CustomButton
          name={full ? "Exit Full Screen" : "Full Screen"}
          size="medium"
          onclick={() =>
            dispatch({
              type: "TOGGLE_WORKSHOP_EXP",
              payload: { data: !full },
            })
          }
        />
      </Box>
      {props?.sas?.img ? (
        <Box flex="0.8">
          <img
            src={
              props?.sas?.img
                ? props.sas.img.includes("static/openingScreens")
                  ? `/${props.sas.img}`
                  : props.sas.img
                : ""
            }
            alt="workshop img"
            width="100%"
            height="auto"
          />
        </Box>
      ) : null}
    </Stack>
  );
};

export default Welcome;
