import { useEffect } from "react";
import TemplateComponent from "../../../components/template/TemplateComponent";
import { setHTitle } from "../../../utils/SetHeaderFunction";

const Templates = () => {
  useEffect(() => {
    setHTitle("Templates", true, "");
  }, []);
  return <TemplateComponent />;
};

export default Templates;
