import { Box } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import CustomButton from "../../../../../../ui/button/CustomButton";
import OpeningScreen from "../../OpeningScreen";
import { v4 as uuidv4 } from "uuid";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";
import Step6 from "./Step6";
import { cloneDeep } from "lodash";
import Step7 from "./Step7";

const comparativeStrengthsData = [
  {
    name: "",
    uuid: uuidv4(),
    customerNeeds: false,
    competitorStrengths: false,
    ourBrandStrengths: false,
  },
];

const StrengthAndWeakness = (props) => {
  // comparativeStrengths;
  const [data, setData] = useState(comparativeStrengthsData);
  const [steps, setSteps] = useState(0);
  const [categoryTableStakeData, setCategoryTableStakeData] = useState([]);
  const [brandVulnerabilitieData, setBrandVulnerabilitieData] = useState([]);
  const [noWinZoneData, setNoWinZoneData] = useState([]);

  const [leftSlide, setLeftSlide] = useState([]);
  const [rightSlide, setRightSlide] = useState([]);
  const [lidx, setLidx] = useState("");
  const [ridx, setRidx] = useState("");
  const [valueProposition, setValueProposition] = useState("");

  const [thisSide, setThisSide] = useState("");
  const [indentifyStart, setIndentifyStart] = useState(false);

  const switchBox = (idx = "", left = "") => {
    const ls = cloneDeep(leftSlide);
    const rs = cloneDeep(rightSlide);
    if (idx !== "") {
      if (left) {
        const ele = ls[idx];
        ele.hidden = true;
        rs.push(ele);
        setLidx(() => "");
      } else {
        const ele = rs[idx];
        ele.hidden = false;
        rs.splice(idx, 1);
        setRidx(() => "");
      }
    }
    setLeftSlide(() => ls);
    setRightSlide(() => rs);
  };
  const onMoveUpG = (Fidx, dd) => {
    if (dd.length > 1) {
      if (Fidx - 1 < 0) {
        [dd[dd.length - 1], dd[Fidx]] = [dd[Fidx], dd[dd.length - 1]];
      } else {
        [dd[Fidx - 1], dd[Fidx]] = [dd[Fidx], dd[Fidx - 1]];
      }
    }
    return dd;
  };
  const onMoveDownG = (Fidx, dd) => {
    if (dd.length > 1) {
      if (Fidx + 1 > dd.length - 1) {
        [dd[0], dd[Fidx]] = [dd[Fidx], dd[0]];
      } else {
        [dd[Fidx + 1], dd[Fidx]] = [dd[Fidx], dd[Fidx + 1]];
      }
    }
    return dd;
  };
  const onMoveUp = (idx, left) => {
    if (left) {
      const dd = [...leftSlide];
      setLeftSlide(() => onMoveUpG(idx, dd));
    } else {
      const dd = [...rightSlide];
      setRightSlide(() => onMoveUpG(idx, dd));
    }
  };
  const onMoveDown = (idx, left) => {
    if (left) {
      const dd = [...leftSlide];
      setLeftSlide(() => onMoveDownG(idx, dd));
    } else {
      const dd = [...rightSlide];
      setRightSlide(() => onMoveDownG(idx, dd));
    }
  };

  useEffect(() => {
    let n = 10;
    let finalArray = [];
    let myArr = [];
    let propsData = props.td && props.td.data;
    if (propsData && props.td.data.comparativeStrengths === null) {
      if (props.td.data?.competitorPositioningAxes?.length) {
        myArr =
          props.td.data?.competitorPositioningAxes
            ?.filter((c) => c.checked)
            .map((od) => ({
              name: od.name,
              uuid: od.uuid,
              customerNeeds: false,
              competitorStrengths: false,
              ourBrandStrengths: false,
            })) || [];
        finalArray.push(...myArr);
      }
      // for (let i = myArr.length; i <= n; i++) {
      //   finalArray.push({
      //     name: "",
      //     uuid: uuidv4(),
      //     customerNeeds: false,
      //     competitorStrengths: false,
      //     ourBrandStrengths: false,
      //   });
      // }
    } else {
      let tData = propsData && propsData.comparativeStrengths;
      finalArray = tData;
      // for (let i = 1; i <= n - tData.length; i++) {
      //   finalArray.push({
      //     name: "",
      //     uuid: uuidv4(),
      //     customerNeeds: false,
      //     competitorStrengths: false,
      //     ourBrandStrengths: false,
      //   });
      // }
    }
    setData(finalArray);

    let tableStakeArray = [];
    if (propsData && propsData.categoryTableStakes === null) {
      if(propsData && propsData.comparativeStrengths === null) {
        for (let i = 1; i <= n; i++) {
          tableStakeArray.push({
            name: "",
            uuid: uuidv4(),
          });
        }
      } else {
        var existingSize = 0;
        for(var s of propsData.comparativeStrengths) {
          if(s['customerNeeds'] && s['competitorStrengths'] && s['ourBrandStrengths']) {
            tableStakeArray.push({
              name: s['name'],
              uuid: s['uuid']
            });
            existingSize++;
          }
        }

        for (let i = 1; i <= n-existingSize; i++) {
          tableStakeArray.push({
            name: "",
            uuid: uuidv4(),
          });
        }
      }
    } else {
      let categoryTableStakes = propsData && propsData.categoryTableStakes;
      tableStakeArray = categoryTableStakes;
      for (let i = 1; i <= n - categoryTableStakes.length; i++) {
        tableStakeArray.push({
          name: "",
          uuid: uuidv4(),
        });
      }
    }
    setCategoryTableStakeData(tableStakeArray);
    let brandVulnerabilitieDataArray = [];
    if (propsData && propsData.brandVulnerabilities === null) {
      if(propsData && propsData.comparativeStrengths === null) {
        for (let i = 1; i <= n; i++) {
          brandVulnerabilitieDataArray.push({
            name: "",
            uuid: uuidv4(),
          });
        }
      } else {
        var existingSize = 0;
        for(var d of propsData.comparativeStrengths) {
          if(d['customerNeeds'] && d['competitorStrengths'] && !d['ourBrandStrengths']) {
            brandVulnerabilitieDataArray.push({
              name: d['name'],
              uuid: d['uuid']
            });
            existingSize++;
          }
        }

        for (let i = 1; i <= n-existingSize; i++) {
          brandVulnerabilitieDataArray.push({
            name: "",
            uuid: uuidv4(),
          });
        }
      }
    } else {
      let brandVulnerabilities = propsData && propsData.brandVulnerabilities;
      brandVulnerabilitieDataArray = brandVulnerabilities;
      for (let i = 1; i <= n - brandVulnerabilities.length; i++) {
        brandVulnerabilitieDataArray.push({
          name: "",
          uuid: uuidv4(),
        });
      }
    }

    setBrandVulnerabilitieData(brandVulnerabilitieDataArray);
    let noWinZoneArray = [];
    if (propsData && propsData.noWinZone === null) {
      if(propsData && propsData.comparativeStrengths === null) {
        for (let i = 1; i <= n; i++) {
          noWinZoneArray.push({
            name: "",
            uuid: uuidv4(),
          });
        }
      } else {
        var existingSize = 0;
        for(var d of propsData.comparativeStrengths) {
          if(!d['customerNeeds'] && d['competitorStrengths'] && d['ourBrandStrengths']) {
            noWinZoneArray.push({
              name: d['name'],
              uuid: d['uuid']
            });
            existingSize++;
          }
        }

        for (let i = 1; i <= n-existingSize; i++) {
          noWinZoneArray.push({
            name: "",
            uuid: uuidv4(),
          });
        }
      }
      for (let i = 1; i <= n; i++) {
        noWinZoneArray.push({
          name: "",
          uuid: uuidv4(),
        });
      }
    } else {
      let noWinZone = propsData && propsData.noWinZone;
      noWinZoneArray = noWinZone;
      for (let i = 1; i <= n - noWinZone.length; i++) {
        noWinZoneArray.push({
          name: "",
          uuid: uuidv4(),
        });
      }
    }
    setNoWinZoneData(noWinZoneArray);

    let leftSlideArray = [];
    if (propsData && propsData.brandDifferentiation === null) {

      if(propsData && propsData.comparativeStrengths === null) {
        for (let i = 1; i <= n; i++) {
          leftSlideArray.push({
            name: "",
            uuid: uuidv4(),
          });
        }
      } else {
        var existingSize = 0;
        for(var d of propsData.comparativeStrengths) {
          if(d['customerNeeds'] && !d['competitorStrengths'] && d['ourBrandStrengths']) {
            leftSlideArray.push({
              name: d['name'],
              uuid: d['uuid']
            });
            existingSize++;
          }
        }

        for (let i = 1; i <= n-existingSize; i++) {
          leftSlideArray.push({
            name: "",
            uuid: uuidv4(),
          });
        }
      }
    } else {
      let brandDifferentiations =
        props.td &&
        propsData &&
        propsData.brandDifferentiation &&
        propsData.brandDifferentiation.brandDifferentiations;
      leftSlideArray = brandDifferentiations;
      for (let i = 1; i <= n - brandDifferentiations.length; i++) {
        leftSlideArray.push({
          name: "",
          uuid: uuidv4(),
        });
      }
    }
    setLeftSlide(leftSlideArray);
    let rightSlideArray = [];
    if (propsData && propsData.brandDifferentiation === null) {
      rightSlideArray = [];
    } else {
      let brandDifferentiations =
        props.td &&
        propsData &&
        propsData.brandDifferentiation &&
        propsData.brandDifferentiation.selected;
      rightSlideArray = brandDifferentiations;
    }
    setRightSlide(rightSlideArray);
    if (propsData && propsData.differentiatedBrandValueProposition) {
      setValueProposition(propsData.differentiatedBrandValueProposition);
    }
  }, [steps]);

  const handleData = (idx, key, val) => {
    let array = [...data];
    array[idx][key] = val;
    setData(array);
  };

  const handletableStakeData = (idx, key, val) => {
    let array = [...categoryTableStakeData];
    array[idx][key] = val;
    setCategoryTableStakeData(array);
  };

  const handleBrandVulnerabilitieData = (idx, key, val) => {
    let array = [...brandVulnerabilitieData];
    array[idx][key] = val;
    setBrandVulnerabilitieData(array);
  };

  const handleNoWinZoneData = (idx, key, val) => {
    let array = [...noWinZoneData];
    array[idx][key] = val;
    setNoWinZoneData(array);
  };

  const handleBrandDifferentiation = (idx, key, val) => {
    let array = [...leftSlide];
    array[idx][key] = val;
    setLeftSlide(array);
  };

  const handlePage = async (n) => {
    switch (steps) {
      case 0:
        if (n) {
          setSteps((old) => old + 1);
        } else {
          props.prvStep();
        }
        break;
      case 1:
        await props.callApi({
          comparativeStrengths: data && data.filter((item) => item.name !== ""),
        });
        if (n) {
          setSteps((old) => old + 1);
        } else {
          setSteps((old) => old - 1);
        }
        break;
      case 2:
        if (n) {
          setSteps((old) => old + 1);
        } else {
          setSteps((old) => old - 1);
        }
        break;
      case 3:
        await props.callApi({
          categoryTableStakes:
            categoryTableStakeData &&
            categoryTableStakeData.filter((item) => item.name !== ""),
        });

        if (n) {
          setSteps((old) => old + 1);
        } else {
          setSteps((old) => old - 1);
        }
        break;
      case 4:
        await props.callApi({
          brandVulnerabilities:
            brandVulnerabilitieData &&
            brandVulnerabilitieData.filter((item) => item.name !== ""),
        });

        if (n) {
          setSteps((old) => old + 1);
        } else {
          setSteps((old) => old - 1);
        }
        break;
      case 5:
        await props.callApi({
          noWinZone:
            noWinZoneData && noWinZoneData.filter((item) => item.name !== ""),
        });
        if (n) {
          setSteps((old) => old + 1);
        } else {
          setSteps((old) => old - 1);
        }
        break;
      case 6:
        await props.callApi({
          brandDifferentiation: {
            brandDifferentiations:
              leftSlide && leftSlide.filter((item) => item.name !== ""),
            selected:
              rightSlide && rightSlide.filter((item) => item.name !== ""),
          },
        });

        if (n) {
          setSteps((old) => old + 1);
        } else {
          setSteps((old) => old - 1);
        }
        break;

      case 7:
        await props.callApi({
          differentiatedBrandValueProposition: valueProposition,
        });
        if (n) {
          props.nextStep();
        } else {
          setSteps((old) => old - 1);
        }
        break;

      default:
        break;
    }
  };

  const handleSelect = () => {
    switch (steps) {
      case 1:
        return <Step1 data={data} setData={setData} handleData={handleData} />;
      case 2:
        return <Step2 />;
      case 3:
        return (
          <Step3
            categoryTableStakeData={categoryTableStakeData}
            handletableStakeData={handletableStakeData}
          />
        );
      case 4:
        return (
          <Step4
            brandVulnerabilitieData={brandVulnerabilitieData}
            handleBrandVulnerabilitieData={handleBrandVulnerabilitieData}
          />
        );
      case 5:
        return (
          <Step5
            noWinZoneData={noWinZoneData}
            handleNoWinZoneData={handleNoWinZoneData}
          />
        );
      case 6:
        return (
          <Step6
            leftSlide={leftSlide}
            setLeftSlide={setLeftSlide}
            switchBox={switchBox}
            lidx={lidx}
            indentifyStart={indentifyStart}
            setIndentifyStart={setIndentifyStart}
            setThisSide={setThisSide}
            thisSide={thisSide}
            setLidx={setLidx}
            onMoveUp={onMoveUp}
            onMoveDown={onMoveDown}
            rightSlide={rightSlide}
            ridx={ridx}
            setRidx={setRidx}
            handleBrandDifferentiation={handleBrandDifferentiation}
          />
        );
      case 7:
        return (
          <Step7
            setValueProposition={setValueProposition}
            valueProposition={valueProposition}
            brandDifferentiation={props.td.data?.brandDifferentiation}
          />
        );
      default:
        return <></>;
    }
  };

  const handleValidate = () => {
    let disable = false;
    if (steps === 1) {
      let indx1 =
        data &&
        data.findIndex(
          (item) => item.name !== "" && item.customerNeeds === true
        );
      let indx2 =
        data &&
        data.findIndex(
          (item) => item.name !== "" && item.competitorStrengths === true
        );
      let indx3 =
        data &&
        data.findIndex(
          (item) => item.name !== "" && item.ourBrandStrengths === true
        );
      if (indx1 === -1 || indx2 === -1 || indx3 === -1) {
        disable = true;
      } else {
        disable = false;
      }
    }
    if (steps === 2) {
    }
    return disable;
  };
  return (
    <>
      {steps === 0 ? (
        <OpeningScreen
          title="Strengths and weaknesses"
          description={
            <>
              Brands never exist in a vacuum but in a world of customers,
              non-customers and competitors. <br /> In the next few pages we
              will complete: <br />
              <ul>
                <li> Brand Vulnerabilities </li>
                <li> Table Stakes </li>
                <li> No Win Zone </li>
                <li> Brand Differentiation </li>
              </ul>{" "}
              Later, your brand proposition will draw on the intersection of
              your brand strengths and your customer needs.{" "}
            </>
          }
          img={props?.sas?.img || ""}
          bestPratice={props?.sas?.workshop || false}
          bestEx={props?.sas?.workshopImg || []}
          name={props?.sas?.name || ""}
        />
      ) : (
        <>{handleSelect()}</>
      )}
      <Box display="flex" justifyContent="space-between" mt="auto" mb="0">
        <CustomButton
          name="<   Prev"
          loading={props.btnLoading}
          onclick={() => handlePage(false)}
        />
        <CustomButton
          name="Next   >"
          loading={props.btnLoading}
          disabled={handleValidate()}
          onclick={() => handlePage(true)}
        />
      </Box>
    </>
  );
};

export default StrengthAndWeakness;
