import { Box, Grid, Stack } from "@mui/material";
import React, { useState } from "react";
import { hexToRgb } from "../../../utils/HexToRgba";
import CustomButton from "../../ui/button/CustomButton";
import CustomExpand from "../../ui/customCollapse/CustomExpand";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import CollapseWithGrid from "../../ui/customCollapse/CollapseWithGrid";
import ProjectContainer from "./ProjectContainer";
import { useNavigate } from "react-router";
import AddProjectModal from "../../ui/modals/AddProjectModal";
import { useEffect } from "react";
import { api } from "../../../redux/api/api";
import Loader from "../../ui/loader/Loader";

const ClientContainer = (props) => {
  const loc = useNavigate();
  const [projectData, setProjectData] = useState("");
  const [openProject, setOpenProject] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleExpandClick = () => {
    setExpanded(() => !expanded);
  };
  const getData = async () => {
    setLoading(() => true);
    props.clearForceId();
    const res = await api(
      `projects?clientId=${
        props.search ? props.clientId : props.uuid
      }&sort=dateLastActivity&order=desc&active=true`,
      "",
      "get"
    );
    if (res && res.success && res.projects && res.projects.length) {
      setProjectData(() => res.projects);
    }
    setLoading(() => false);
  };
  const removeProject = (idx) => {
    const dd = [...projectData];
    dd.splice(idx, 1);
    props.addToArchive();
    setProjectData(() => dd);
  };
  useEffect(() => {
    if (expanded && (!projectData || props.forceUpdateId)) getData();
  }, [expanded, props.forceUpdateId]);

  useEffect(() => {
    if (props.search) setExpanded(true);
  }, [props.search]);

  return (
    <>
      <Grid
        container
        item
        padding="10px 20px"
        bgcolor={(theme) => hexToRgb(theme.palette.primary.main, 0.4)}
        borderRadius="10px"
        mt={2}
      >
        <Grid
          item
          lg={8}
          md={7}
          sm={7}
          xs={7}
          margin="auto"
          onClick={handleExpandClick}
          sx={{
            cursor: "pointer",
          }}
        >
          <Box fontSize="20px" color="secondary.main">
            {props.search ? props.clientName : props.name || ""}
          </Box>
        </Grid>
        <Grid item lg={4} md={5} sm={5} xs={5} textAlign="right">
          <Stack direction="row" gap="10px">
            <Box margin="auto" mr="0">
              <CustomButton
                primary={true}
                name="Add Project"
                size="medium"
                endIcon={<AddCircleOutlineRoundedIcon />}
                onclick={() => setOpenProject(true)}
              />
            </Box>
            <Box margin="auto 0">
              <CustomButton
                primary={true}
                name="Settings"
                size="medium"
                onclick={() => {
                  loc(`/clients/settings/${props.uuid}`);
                }}
              />
            </Box>

            <CustomExpand
              expanded={expanded}
              handleExpandClick={handleExpandClick}
              margin={true}
            >
              {expanded ? (
                <RemoveIcon
                  onClick={handleExpandClick}
                  fontSize="large"
                  color="secondary"
                />
              ) : (
                <AddIcon
                  onClick={handleExpandClick}
                  fontSize="large"
                  color="secondary"
                />
              )}
            </CustomExpand>
          </Stack>
        </Grid>
      </Grid>
      <CollapseWithGrid expand={expanded}>
        <Grid item padding={2} container lg={12} md={12} sm={12} xs={12} mb={1}>
          <Grid item lg={6} md={4.5} sm={4.5} xs={4.5}>
            Project Name
          </Grid>
          <Grid item lg={2} md={2} sm={2} xs={2}>
            Status
          </Grid>
          <Grid item lg={1} md={2} sm={2} xs={2}>
            Due Date
          </Grid>
          <Grid item lg={3} md={3.5} sm={3.5} xs={3.5}></Grid>
        </Grid>
        {loading ? (
          <Loader />
        ) : (
          projectData &&
          projectData.map((a, idx) => (
            <ProjectContainer
              {...a}
              key={a.uuid}
              handleClick={props.handleClick}
              clientName={props.search ? props.clientName : props.name}
              removeProject={() => removeProject(idx)}
              handleForceUpdate={props.handleForceUpdate}
            />
          ))
        )}
      </CollapseWithGrid>
      <AddProjectModal
        open={openProject}
        onClose={() => setOpenProject(false)}
        name={props.search ? props.clientName : props.name}
        cid={props.search ? props.clientId : props.uuid}
        handleUpdateDash={() => {
          props.clearForceId();
          props.handleForceUpdate(props.uuid);
        }}
      />
    </>
  );
};

export default React.memo(ClientContainer);
