import { Box } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import CustomerCoreFromPersona from "../workshop/steps/BrandStregy/CustomerCoreFromPersona";
import { useSelector } from "react-redux";
import { api } from "../../../../redux/api/api";

const Customer = () => {
  const pd = useSelector((state) => state.project.project);
  const [btnLoading, setBtnLoading] = useState(false);
  const [customerData, setCustomerData] = useState({
    includeSecondary: false,
    age: "",
    gender: "",
    occupation: "",
    familyStatus: "",
    location: "",
    interests: "",
    personalityAttributes: "",
    mediaUse: "",
    defines: "",
    frustrations: "",
    motivations: "",
    improve: "",
    secondaryAge: "",
    secondaryGender: "",
    secondaryOccupation: "",
    secondaryFamilyStatus: "",
    secondaryLocation: "",
    secondaryInterests: "",
    secondaryPersonalityAttributes: "",
    secondaryMediaUse: "",
    secondaryDefines: "",
    secondaryFrustrations: "",
    secondaryMotivations: "",
    secondaryImprove: "",
  });
  const callApi = async (d) => {
    setBtnLoading(true);
    const res = await api(
      `projects/${pd.uuid}`,
      d,
      "patch",
      "",
      "",
      "Details updated!"
    );
    if (res && res.success) {
      pd.coreCustomer = customerData;
    }
    setBtnLoading(false);
  };
  const setData = (field, value) =>
    setCustomerData((old) => ({ ...old, [field]: value }));
  useEffect(() => {
    if (pd?.coreCustomer) {
      setCustomerData(pd?.coreCustomer);
    }
  }, [pd]);
  return (
    <>
      <Box color="secondary.main" fontSize="20px" fontWeight="800px">
        Defining Your Key Audiences
      </Box>
      <Box mb={2} fontSize="14px" my={3}>
        After discussing the most relevant personas for the brand, identify the
        brand’s core customer or client and where relevant the secondary
        customers or clients.
      </Box>
      <CustomerCoreFromPersona
        customerData={customerData}
        setData2={setData}
        border={true}
        callApi={(d) => callApi(d)}
        btnLoading={btnLoading}
      />
    </>
  );
};

export default Customer;
