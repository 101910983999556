import BrandvasIcon from "../../../assets/images/Brandvas.png";
import { api } from "../../api/api";

import { SET_HEADER_TITLE, SET_THEME, WHITELABELMSG } from "../../constants";
const defaultStyle = {
  primaryColorHexCode: "#CAE2E6",
  secondaryColorHexCode: "#1D2D91",
  googleFont: "Rubik",
  whitelabelMode: false,
  logo: BrandvasIcon,
};

export const setTitle = (title) => {
  return async (dispatch) => {
    dispatch({
      type: SET_HEADER_TITLE,
      payload: { data: title },
    });
  };
};

export const setNewTheme = (theme) => {
  return async (dispatch) => {
    const res = await api(
      "agencies/whitelabel",
      theme,
      "put",
      "",
      "",
      WHITELABELMSG
    );
    if (res && res.success) {
      if (!theme.logo) {
        theme.logo = BrandvasIcon;
      }
      dispatch({
        type: SET_THEME,
        payload: { data: theme.whitelabelMode === true ? theme : defaultStyle },
      });
    }
  };
};
