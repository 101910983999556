import { combineReducers } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import themeReducer from "./theme/themeReducer";
import authReducer from "./auth/authReducer";
import clientReducer from "./client/ClientReducer";
import titleReducer from "./theme/TitleReducer";
import projectReducer from "./project/ProjectReducer";
import templateReducer from "./template/templateReducer";
import profileReducer from "./profile/profileReducer";
import sideBarReducer from "./theme/sideBarReducer";
import adminReducer from "./admin/adminReducer";
import artReducer from "./artApproval/ArtApprovalReducer";

// WHITELIST
const persistConfig = {
  key: "root",
  storage: storage,
  whitelist: ["auth", "theme", "titleH", "client", "sideBar"],
};

const rootReducer = combineReducers({
  art: artReducer,
  auth: authReducer,
  theme: themeReducer,
  client: clientReducer,
  titleH: titleReducer,
  project: projectReducer,
  template: templateReducer,
  profile: profileReducer,
  sideBar: sideBarReducer,
  admin: adminReducer,
});

export default persistReducer(persistConfig, rootReducer);
