import { useEffect } from "react";
import RegisterComponent from "../../../components/register/RegisterComponent";
import { setHTitle } from "../../../utils/SetHeaderFunction";

const Register = () => {
  useEffect(() => {
    setHTitle("", false, "");
  }, []);

  return <RegisterComponent />;
};

export default Register;
