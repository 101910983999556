import { Collapse, Grid } from "@mui/material";

const CollapseWithGrid = (props) => {
  return (
    <Collapse
      in={props.expand}
      timeout="auto"
      unmountOnExit
      style={{ width: "100%" }}
    >
      <Grid container>{props.children}</Grid>
    </Collapse>
  );
};

export default CollapseWithGrid;
