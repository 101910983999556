import { ButtonBase, DialogContent, Grid, Stack } from "@mui/material";
import { ArrowForwardIos } from "@mui/icons-material";
import CatImg from "../../../assets/images/catimg.png";
import FormGroupTextField from "../formgroup/FormGroupTextField";
import CustomButton from "../button/CustomButton";
import ModalContainer from "./ModalContainer";
import { makeStyles } from "@mui/styles";
import { useEffect, useRef, useState } from "react";
import { api } from "../../../redux/api/api";
import SimpleReactValidator from "simple-react-validator";
import { config } from "../../../config";
import { useDispatch, useSelector } from "react-redux";
import { SET_LOGIN_USER } from "../../../redux/constants";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "20px",
    color: theme.palette.secondary.main,
    marginBottom: "20px",
    fontWeight: 600,
  },
  endIcon: {
    marginLeft: "auto !important",
  },
  btnContainer: { width: "40%" },
  cancelBtn: {
    margin: "auto 0",
    textDecoration: "underline !important",
    fontFamily: theme.typography.fontFamily,
    fontSize: "16px",
  },
  dialogContent: {
    height: "500px",
  },
  subTitle: {
    marginBottom: "7px",
    fontWeight: 500,
    fontSize: "14px !important",
  },
  name: {
    marginBottom: "16px",
  },
}));

const ResetPassword = ({ open, onClose }) => {
  const classes = useStyles();
  const simpleValidator = useRef(
    new SimpleReactValidator({
      messages: {
        in: "Password need to match!",
      },
      validators: {
        newPass: {
          // name the rule
          message:
            "Ten character minimum. at least 1 capital letter, at least one lowercase letter, at least one number, at least one symbol",
          rule: (val, params, validator) => {
            return validator.helpers.testRegex(val, config.passValiadtion);
          },
          messageReplace: (message, params) =>
            message.replace(":values", this.helpers.toSentence(params)), // optional
          required: false, // optional
        },
      },
    })
  );
  const user = useSelector((state) => state.auth?.user);

  const dispatch = useDispatch();
  const [data, setData] = useState({
    password: "",
    confirmPassword: "",
  });

  const [loading, setLoading] = useState(false);
  const [, setC] = useState();

  const handleUpdate = async () => {
    setC((old) => !old);

    if (simpleValidator.current.allValid()) {
      setLoading(true);
      const res = await api(
        `users/reset`,
        data,
        "post",
        "",
        "",
        "Password Created!"
      );
      if (res && res.success) {
        const userData = { ...user };
        if (userData.actionsRequired) {
          userData.actionsRequired = {
            ...userData.actionsRequired,
            passwordReset: false,
          };
        }
        dispatch({
          type: SET_LOGIN_USER,
          payload: { data: userData },
        });
      }
      setLoading(false);
    } else {
      simpleValidator.current.showMessages();
    }
    setC((old) => !old);
  };
  useEffect(() => {
    setData({ password: "", confirmPassword: "" });
  }, [open, user]);
  return (
    <ModalContainer
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth={true}
      yellowBack={true}
    >
      <DialogContent className={classes.dialogContent}>
        <Grid container>
          <Grid item lg={7} md={7} sm={7} xs={7} margin="auto" pr={2} pl={2}>
            <div className={classes.title}>
              {onClose ? "Reset" : "Create"} Password
            </div>
            <div className={classes.subTitle}>
              You must create a password before continuing to use Brandvas.{" "}
              <br />
              <br />
              Your password must be have minimum length of 10 and contain at
              least one of each of the following: <br />
              <ul>
                <li>Upper-case letters</li>
                <li>Lower-case letters</li>
                <li>Special characters, such as !@#$%^&amp;+=*</li>
                <li>Numbers</li>
              </ul>
            </div>
            <Stack gap="7px">
              <FormGroupTextField
                label="Password"
                value={data.password}
                type="password"
                onChange={(e) => setData((old) => ({ ...old, password: e }))}
                required={true}
                validator={simpleValidator.current.message(
                  "Password",
                  data.password,
                  "required|newPass",
                  {
                    className: "errorClass",
                  }
                )}
              />
              <FormGroupTextField
                label="Confirm Password"
                type="password"
                value={data.confirmPassword}
                onChange={(e) =>
                  setData((old) => ({ ...old, confirmPassword: e }))
                }
                required={true}
                validator={simpleValidator.current.message(
                  "Confirm Password",
                  data.confirmPassword,
                  `required|in:${data.password}`,
                  {
                    className: "errorClass",
                  }
                )}
              />
            </Stack>
            <Stack direction="row" mt={3} gap="20px">
              <div className={classes.btnContainer}>
                <CustomButton
                  variant="contained"
                  size="large"
                  name={"Set"}
                  endIcon={<ArrowForwardIos />}
                  fullWidth={true}
                  iconClass={classes.endIcon}
                  loading={loading}
                  onclick={handleUpdate}
                />
              </div>
              {onClose ? (
                <ButtonBase onClick={onClose} className={classes.cancelBtn}>
                  Cancel
                </ButtonBase>
              ) : null}
            </Stack>
          </Grid>
          <Grid item lg={5} md={5} sm={5} xs={5}>
            <img src={CatImg} alt={CatImg} height="383px" />
          </Grid>
        </Grid>
      </DialogContent>
    </ModalContainer>
  );
};

export default ResetPassword;
