import { Box, ButtonBase, Grid, Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  ArrowBackIos,
  AddCircleOutlineRounded as AddCircleOutlineRoundedIcon,
} from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router";
import CustomButton from "../button/CustomButton";
import CategoryModal from "../modals/CategoryModal";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setClientView } from "../../../redux/actions/client/ClientAction";
import AddClientWithProject from "../modals/AddClientWithProject";
//TODO
import researchIcon from "../../../assets/images/marketplace.png";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "160px",
    backgroundColor: "#F5F5F5",
    minWidth: "1024px",
  },
  image: {
    height: "230px"
  },
  textContainer: {
    color: theme.palette.secondary.main,
    fontSize: "30px",
    fontWeight: "400",
  },
  imageContainer: {
    paddingBottom: "1em"
  },
  imageContainerImage: {
    maxWidth: "15em",
  },
  subTitle: {
    color: theme.palette.secondary.main,
    fontSize: "20px",
    marginBottom: "2px",
  },
  backBtn: {
    fontFamily: theme.typography.fontFamily,
    color: `${theme.palette.secondary.main} !important`,
    fontWeight: "600",
    fontSize: "16px",
  },
  btnContainer: {
    textAlign: "center",
  },
}));
const PrivateGeneralHeaderTitle = ({ title, subTitle, subTitlePosition }) => {
  const classes = useStyles();
  const loc = useLocation().pathname;
  const history = useNavigate();
  const dispatch = useDispatch();
  const cview = useSelector((state) => state.client.view);
  const [catModal, setCatModal] = useState(false);
  const [clientModal, setClientModal] = useState(false);

  const handleRoute = () => {
    if (window.history.state && window.history.state.idx > 0) {
      history(-1);
    } else if (loc.includes("user") && !loc.includes("admin")) {
      history("/profile/user-management", { replace: true });
    } else if (loc.includes("project")) {
      history("/projects", { replace: true });
    } else if (loc.includes("templates")) {
      history("/templates", { replace: true });
    } else if (loc.includes("/clients")) {
      history("/clients", { replace: true });
    } else if (loc.includes("/research") && !loc.includes("admin")) {
      history("/research", { replace: true });
    } else if (loc === "/tools/pr-tools") {
      history("/projects/add-tool", { replace: true });
    } else if (loc === "/admin/research-documents/add-research") {
      history("/admin/research-documents", { replace: true });
    } else if (loc === "/admin/users/add-user") {
      history("/admin/users", { replace: true });
    } else if (loc === "/admin/agencies/add-agency") {
      history("/admin/agencies", { replace: true });
    }
  };
  const checkForDisplay =
    (loc.includes("user") &&
      loc !== "/profile/user-management" &&
      !loc.includes("admin")) ||
    (loc.includes("projects") && loc !== "/projects") ||
    (loc.includes("templates") && loc !== "/templates") ||
    (loc.includes("clients") && loc !== "/clients") ||
    (loc.includes("research") &&
      loc !== "/research" &&
      !loc.includes("admin")) ||
    (loc.includes("research-documents") && loc.includes("add-research")) ||
    (loc.includes("users") && loc.includes("add-user")) ||
    (loc.includes("agencies") && loc.includes("add-agency")) ||
    loc === "/tools/pr-tools";

  return (
    <div className={`${loc !== '/research' ? classes.container  : `${classes.container} ${classes.image}`}`}>
      <Grid container spacing={2} pt={6}>
        <Grid item lg={1.8} md={2.5} sm={2.5} xs={2.5} margin="auto">
          {checkForDisplay && (
            <div className={classes.btnContainer}>
              <ButtonBase className={classes.backBtn} onClick={handleRoute}>
                <ArrowBackIos fontSize="medium" />
                <div>Back</div>
              </ButtonBase>
            </div>
          )}{" "}
        </Grid>
        <Grid
          item
          lg={7.2}
          md={5.5}
          sm={5.5}
          xs={5.5}
          display={subTitle ? "" : "flex"}
        >
          {subTitle && subTitlePosition === "top" && (
            <div className={classes.subTitle}>{subTitle}</div>
          )}
          {loc !== "/research" && <div className={classes.textContainer}>{title}</div>}
          {loc === "/research" && (
            <div className={classes.imageContainer}>
              <img className={classes.imageContainerImage} src={researchIcon} alt="The research marketplace" />
            </div>
          )}
          {subTitle && subTitlePosition === "bottom" && (
            <div className={classes.subTitle}>{subTitle}</div>
          )}
          {loc === "/clients" && (
            <Box ml={2}>
              <CustomButton
                variant="contained"
                size="medium"
                endIcon={<AddCircleOutlineRoundedIcon />}
                name="Add Client"
                onclick={() => setClientModal(true)}
              />
            </Box>
          )}
        </Grid>

        <Grid
          item
          lg={3}
          md={4}
          sm={4}
          xs={4}
          margin="auto"
          style={{ paddingRight: "2%" }}
        >
          {loc === "/clients" && (
            <Stack direction="row" justifyContent="flex-end">
              <Box
                margin="auto 0"
                p="8px"
                borderRadius="10px"
                bgcolor={cview ? "#D6D6D6" : ""}
                style={{ cursor: "pointer" }}
                onClick={() => dispatch(setClientView(true))}
              >
                <Box color="secondary.main">List View</Box>
              </Box>
              <Box
                margin="auto 0"
                p="8px"
                borderRadius="10px"
                bgcolor={!cview ? "#D6D6D6" : ""}
                style={{ cursor: "pointer" }}
                onClick={() => dispatch(setClientView(false))}
              >
                <Box color="secondary.main">Calendar</Box>
              </Box>
              <Box ml={1.5}>
                <CustomButton
                  variant="outlined"
                  name="Export for WIP"
                  size="medium"
                  onclick={() => history("/clients/wip")}
                />
              </Box>
            </Stack>
          )}
          {loc === "/profile/user-management" ? (
            <Stack gap="14px" direction="row">
              <CustomButton
                variant="contained"
                size="medium"
                endIcon={<AddCircleOutlineRoundedIcon />}
                name="Add User"
                onclick={() => history("/profile/user-management/add-user")}
              />
              <CustomButton
                variant="contained"
                size="medium"
                endIcon={<AddCircleOutlineRoundedIcon />}
                name="Add Category"
                onclick={() => setCatModal(true)}
              />
            </Stack>
          ) : null}
        </Grid>
      </Grid>
      <AddClientWithProject
        open={clientModal}
        onClose={() => setClientModal(false)}
      />
      <CategoryModal
        open={catModal}
        onClose={() => setCatModal(false)}
        edit={false}
      />
    </div>
  );
};

export default PrivateGeneralHeaderTitle;
