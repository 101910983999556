import { Box, DialogContent, Grid, Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ModalContainer from "./ModalContainer";
import { ArrowForwardIos } from "@mui/icons-material";
import CustomButton from "../button/CustomButton";
import { useEffect, useRef, useState } from "react";
import SimpleReactValidator from "simple-react-validator";
import { api } from "../../../redux/api/api";
import FormGroupTextField from "../formgroup/FormGroupTextField";
import CustomPhone from "../formgroup/CustomPhone";
import TextAutoResize from "../formgroup/TextAutoResize";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "20px",
    color: theme.palette.secondary.main,
    marginBottom: "10px",
    fontWeight: 600,
  },
  endIcon: {
    marginLeft: "auto !important",
  },
  btnContainer: { width: "40%", marginTop: "10px" },
  dialogContent: {
    height: "500px",
  },
  fullWidth: {
    width: "100%",
  },
  noteDiv: { fontSize: "12px" },
}));
const intialData = {
  name: "",
  agencyName: "",
  emailAddress: "",
  phone: "",
  phoneNumber: {
    number: "",
    internationalNumber: "",
    nationalNumber: "",
    e164Number: "",
    countryCode: "",
    dialCode: "",
  },
  message: "",
  provider: "PURE_SPECTRUM",
};
const PureSpectrumEnquiryModel = ({ open, onClose }) => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    simpleValidator.current.hideMessages();
  }, [open]);

  const simpleValidator = useRef(new SimpleReactValidator());
  const [, setC] = useState();

  const [spectrumData, setSpectrumData] = useState(intialData);

  const setData = (field, value) => {
    setSpectrumData((old) => ({ ...old, [field]: value }));
  };

  const phoneNumGet = (val, info) => {
    setData("phone", val);
    const phoneNumber = {
      number: info.nationalNumber,
      internationalNumber: info.numberValue,
      nationalNumber: info.nationalNumber,
      e164Number: info.numberValue,
      countryCode: info.countryCode,
      dialCode: "+" + info.countryCallingCode,
    };
    setData("phoneNumber", phoneNumber);
  };

  const addClick = async () => {
    setC((old) => !old);
    if (simpleValidator.current.allValid()) {
      setLoading(true);
      const res = await api(
        "insights/enquiries",
        spectrumData,
        "post",
        "",
        "",
        "Your enquiry has been sent!"
      );
      if (res && res.success) {
        setSpectrumData(intialData);
        onClose();
      }
      setLoading(false);
    } else {
      simpleValidator.current.showMessages();
    }
    setC((old) => !old);
  };

  return (
    <ModalContainer
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth={true}
    >
      <DialogContent className={classes.dialogContent}>
        <Grid container mt={0}>
          <Grid item lg={12} md={12} margin="auto" px="10px">
            <Stack mb="30px">
              <div className={classes.title}>Send PureSpectrum Enquiry</div>
            </Stack>
          </Grid>
          <Grid item lg={6} md={6} mb="15px" px="10px">
            <FormGroupTextField
              label="Name"
              value={spectrumData.name}
              onChange={(e) => setData("name", e)}
              required={true}
              validator={simpleValidator.current.message(
                "Name",
                spectrumData.name,
                "required",
                {
                  className: "errorClass",
                }
              )}
            />
          </Grid>
          <Grid item lg={6} md={6} mb="15px" px="10px">
            <FormGroupTextField
              label="Email Address"
              value={spectrumData.emailAddress}
              onChange={(e) => setData("emailAddress", e)}
              required={true}
              validator={simpleValidator.current.message(
                "Email Address",
                spectrumData.emailAddress,
                "required|email",
                {
                  className: "errorClass",
                }
              )}
            />
          </Grid>
          <Grid item lg={6} md={6} mb="15px" px="10px">
            <FormGroupTextField
              label="Agency"
              value={spectrumData.agencyName}
              onChange={(e) => setData("agencyName", e)}
              required={true}
              validator={simpleValidator.current.message(
                "Agency",
                spectrumData.agencyName,
                "required",
                {
                  className: "errorClass",
                }
              )}
            />
          </Grid>
          <Grid item lg={6} md={6} mb="15px" px="10px">
            <CustomPhone
              label="Phone Number"
              value={spectrumData.phone}
              onChange={(e, info) => phoneNumGet(e, info)}
              required={true}
              validator={simpleValidator.current.message(
                "Phone Number",
                spectrumData.phone,
                "required",
                {
                  className: "errorClass",
                }
              )}
            />
          </Grid>
          <Grid item lg={6} md={6} mb="15px" px="10px">
            <TextAutoResize
              label="Message"
              required={true}
              other={{ minRows: 10, maxRows: 10 }}
              value={spectrumData.message}
              onChange={(e) => setData("message", e)}
              validator={simpleValidator.current.message(
                "Message",
                spectrumData.message,
                "required",
                {
                  className: "errorClass",
                }
              )}
            />
          </Grid>
          <Grid item lg={6} md={6} mb="15px" px="10px">
            <Box mb="12px">Note</Box>
            <Box>
              If you would like to speak directly with PureSpectrum, please call
              Anthony on +61 402 466 230
            </Box>
          </Grid>
          <Grid item lg={12} md={12} mb="15px" px="10px">
            <div className={classes.btnContainer}>
              <CustomButton
                variant="contained"
                size="large"
                name={"Send Enquiry"}
                endIcon={<ArrowForwardIos />}
                fullWidth={true}
                iconClass={classes.endIcon}
                onclick={addClick}
                loading={loading}
              />
            </div>
          </Grid>
        </Grid>
      </DialogContent>
    </ModalContainer>
  );
};

export default PureSpectrumEnquiryModel;
