import { Box, Stack } from "@mui/material";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { brandValues } from "../../../../../../../utils/PersonalitySets";
import CustomButton from "../../../../../../ui/button/CustomButton";
import OpeningScreen from "../../OpeningScreen";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import { memo } from "react";

const descs = [
  <>
    Jerry Garcia, lead singer of The Grateful Dead once said:
    <br />
    "You don't want to be the best at what you do, you want to be the only one"
    <br />
    This principle is compelling when applied to the way we describe our brand's
    point of difference. Using the format below:
  </>,
  "From the list of your brand values, Drag and drop the most important top 4 brand values",
  "For each of the four values that you have identified, write a short description for each brand value",
];

const BrandValues = (props, ref) => {
  const [steps, setSteps] = useState(0);

  const [data1, setData1] = useState(
    brandValues.map((a) => ({ ...a, selected: false, loaded: false }))
  );
  const [brandValuesProof, setBrandValuesProof] = useState({
    brandValueProof1: "",
    brandValueProof2: "",
    brandValueProof3: "",
    brandValueProof4: "",
  });

  useImperativeHandle(ref, () => ({
    bValues: (value) => {
      if (value) {
        const d = {
          default: false,
          name: value,
          selected: false,
          loaded: false,
          uuid: uuidv4(),
        };
        setData1([...data1, d]);
      }
    },
    bSteps: steps,
  }));

  useEffect(() => {
    if (props.td && props.td.data && props.td.data.brandValues) {
      setData1(props.td.data.brandValues);
    }
    if (props.td && props.td.data && props.td.data.brandValuesProof) {
      setBrandValuesProof(props.td.data.brandValuesProof);
    }
  }, [props]);

  const handleSelect = () => {
    switch (steps) {
      case 1:
        return <Step1 brandValues={data1} setBrandValue={(d) => setData1(d)} />;
      case 2:
        return <Step2 brandValues={data1} setBrandValue={(d) => setData1(d)} />;
      case 3:
        return (
          <Step3
            brandValuesProof={brandValuesProof}
            setBrandValuesProof={(a) => setBrandValuesProof(a)}
            brandValues={data1.filter((a) => a.loaded) || []}
          />
        );
      default:
        return <></>;
    }
  };
  const handlePage = async (n) => {
    switch (steps) {
      case 0:
        if (n) {
          setSteps((old) => old + 1);
        } else {
          props.prvStep();
        }

        break;
      case 1:
        await props.callApi({
          brandValues: data1.map((a) => ({
            ...a,
            loaded: !a.selected && a.loaded ? false : a.loaded,
          })),
        });
        if (n) {
          setSteps((old) => old + 1);
        } else {
          setSteps((old) => old - 1);
        }

        break;
      case 2:
        await props.callApi({ brandValues: data1 });
        if (n) {
          setSteps((old) => old + 1);
        } else {
          setSteps((old) => old - 1);
        }

        break;
      case 3:
        await props.callApi({ brandValuesProof: brandValuesProof });
        if (n) {
          props.nextStep();
        } else {
          setSteps((old) => old - 1);
        }
        break;
      default:
        break;
    }
  };
  const handleValidator = () => {
    let disable = false;
    if (steps === 1) {
      if (
        data1 &&
        data1.length &&
        data1.filter((a) => a.selected).length === 8
      ) {
        disable = false;
      } else disable = true;
    } else if (steps === 2) {
      if (
        data1 &&
        data1.length &&
        data1.filter((a) => a.selected && a.loaded).length === 4
      ) {
        disable = false;
      } else disable = true;
    } else if (steps === 3) {
      const len = Object.values(brandValuesProof).filter((a) => a).length;
      if (len !== 4) disable = true;
    }
    return disable;
  };
  return (
    <>
      {steps === 0 ? (
        <OpeningScreen
          title="Your Brand Values"
          description={
            <>
              This is where it all starts to come together. Every successful
              brand is driven by a shared set of Brand Values.
              <br />
              <br />
              These Brand Values guide the way we do what we do.
              <br />
              <br />
              Brand values are related to, but not the same as Organisational
              Values that some businesses have. Whilst organisational values
              direct how an organisation wants its staff to behave, brand values
              direct the way the business behaves relative to delivering on its
              brand promise.
              <br />
              <br />
              Use language that is unique to you. Almost every business wants to
              have the values of; ‘Trustworthy’ and ‘Innovative’ but through
              overuse they have become bland and meaningless. Brand values such
              as; ‘No Bullshit’, ‘Do what you say’ and ‘The Third Option’ are
              more meaningful and more memorable for your staff.
            </>
          }
          img={props?.sas?.img || ""}
          bestPratice={props?.sas?.workshop || false}
          bestEx={props?.sas?.workshopImg || []}
          name={props?.sas?.name || ""}
        />
      ) : (
        <Stack direction="row" gap="30px">
          <Box flex="0.2" display="flex" gap="10px" flexDirection="column">
            <Box color="secondary.main" fontSize="20px" fontWeight="800px">
              Your Brand Values
            </Box>
            <Box mb={2} fontSize="14px">
              {descs[steps - 1]}
            </Box>
          </Box>
          <Box flex="0.8" mb={2}>
            {handleSelect()}
          </Box>
        </Stack>
      )}
      <Box display="flex" justifyContent="space-between" mt="auto" mb="0">
        <CustomButton
          name="<   Prev"
          loading={props.btnLoading}
          onclick={() => handlePage(false)}
        />
        <CustomButton
          name="Next   >"
          loading={props.btnLoading}
          disabled={handleValidator()}
          onclick={() => handlePage(true)}
        />
      </Box>
    </>
  );
};

export default memo(forwardRef(BrandValues));
