import { Box, Stack } from "@mui/material";
import { useState } from "react";
import { nFormatter } from "../../../../../../utils/FormatNumber";
import DetailsPersona from "../../../../../research/ProjectResearchDetails/researches/DetailsPersona";
import CustomButton from "../../../../../ui/button/CustomButton";

const CustomPersonaDetails = (props) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Stack
        border="1px solid red"
        padding="10px !important"
        borderRadius="10px"
        mb={2}
      >
        <Box mb={2} fontSize="20px">
          {props.name} (ID : {props.code})
        </Box>
        <Box flex="0.2" fontSize="14px">
          {props.summary}
        </Box>
        <Stack direction="row" gap="40px" mt={3} mb={2} mr={2}>
          <Box flex="0.8" display="flex" flexDirection="column" gap="10px">
            <Box
              display="flex"
              flexDirection="row"
              gap="20px"
              justifyContent="space-between"
            >
              <Box>
                <Box fontSize="26px" fontWeight="500" mb={1}>
                  {props.progressiveness} %
                </Box>
                <Box fontSize="14px">Consume your product</Box>
              </Box>

              <Box>
                <Box fontSize="26px" fontWeight="500" mb={1}>
                  {nFormatter(props.income || 0)}
                </Box>
                <Box fontSize="14px">Income</Box>
              </Box>

              <Box>
                <Box fontSize="26px" fontWeight="500" mb={1}>
                  {nFormatter(props.population || 0)}
                </Box>
                <Box fontSize="14px">Persona population</Box>
              </Box>

              <Box>
                <Box fontSize="26px" fontWeight="500" mb={1}>
                  {props.percentage} %
                </Box>
                <Box fontSize="14px">Percentage of nation</Box>
              </Box>
            </Box>
            <Box>
              <CustomButton
                name="Full Persona Profile"
                onclick={() => setOpen(true)}
              />
            </Box>
          </Box>
        </Stack>
      </Stack>
      <DetailsPersona open={open} onClose={() => setOpen(false)} {...props} />
    </>
  );
};

export default CustomPersonaDetails;
